import ReactECharts from 'echarts-for-react';
import React, { useEffect, useRef, useState } from 'react';
import { getCampaignMonth, getChannelCampaignMonth } from '../Services/ReportsCampaign';
import { useMobile } from '../App/Partials/useMobile/useMobile';


const EconomyChart = () => {
  const chartRef = useRef(null);
  const [campaignMonthData, setCampaignMonthData] = useState([]);
  const [channelMonthData, setChannelMonthData] = useState({ data: [], cost: 0 });
  const [chartType, setChartType] = useState('channel');
  const [loading, setLoading] = useState(false);
  const [haveButtonsOptions, setHaveButtonsOptions] = useState(false)
  const isMobile = useMobile();

  const getMonthName = (month) => {
    const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    return months[month - 1];
  };

  const handleGetCampaignMonthData = async () => {
    const retroactiveMonths = 6;
    try {
      setLoading(true);
      const res = await getCampaignMonth(retroactiveMonths);

      if (res && res.campaigns) {
        setCampaignMonthData(res);
      }

    } catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  };
  const xAxisData = campaignMonthData && campaignMonthData.campaigns
    ?
    campaignMonthData.campaigns.map((data) => {
      const [year, month] = data.monthYear.split('-');
      const monthName = getMonthName(Number(month));
      return monthName;
    }).reverse()
    : [];

  const dataConsolidate = campaignMonthData && campaignMonthData.campaigns
    ?
    campaignMonthData.campaigns.map((data) => data.totalNotifications * campaignMonthData.cost).reverse()
    : [];

  const handleGetChannelCampaignMonthData = async () => {
    const retroactiveMonths = 6;
    try {
      setLoading(true)
      const res = await getChannelCampaignMonth(retroactiveMonths);
      const cost = res.cost;
      const uniqueChannelsName = new Set(
        res.campaigns.flatMap(campaign => campaign.channels.map(channel => channel.name))
      );
      const hasMultipleChannelNames = uniqueChannelsName.size > 1;
      setHaveButtonsOptions(hasMultipleChannelNames)
      const channelData = res.campaigns.flatMap((campaign) => campaign.channels.map((channel) => ({
        monthYear: campaign.monthYear, name: channel.shortName || channel.name, totalNotifications: channel.totalNotifications
      })));

      setChannelMonthData({ data: channelData, cost });
      if (res && res.campaigns) {
        setCampaignMonthData(res);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  };

  const seriesDataPerChannel = (data, cost) => {
    const sortedData = data.sort((a, b) => {
      if (a.monthYear < b.monthYear) {
        return -1;
      }
      if (a.monthYear > b.monthYear) {
        return 1;
      }
      return 0;
    });
    return sortedData.reduce((acc, item) => {
      const index = acc.findIndex((series) => series.name === item.name);
      if (index === -1) {
        acc.unshift({
          name: item.name,
          type: 'bar',
          stack: 'total',
          data: [
            {
              name: item.monthYear,
              value: item.totalNotifications * cost,
            },
          ],
          itemStyle: { borderWidth: 1 },
        });
      } else {
        acc[index].data.push({
          name: item.monthYear,
          value: item.totalNotifications * cost,
        });
      }
      return acc;
    }, []);
  };
  const { data, cost } = channelMonthData || { data: [], cost: 0 };
  const dataPerChannel = seriesDataPerChannel(data, cost);

  useEffect(() => {
    handleGetChannelCampaignMonthData()
  }, []);

  if (loading) {
    return (
      <div style={{ height: '421px' }}>
        <h5 style={{ color: '#00bf63', fontWeight: '900' }} className='mt-4'>Economizado</h5>
        <div className='d-flex justify-content-center flex-column' style={{ textAlign: "center", alignItems: 'center', height: '100%' }}>
          <i className="fa fa-spinner" style={{ animation: "spin 3s linear infinite", fontSize: 60 }}></i>
        </div>
      </div>
    )
  };

  if (dataConsolidate.length === 0 && channelMonthData.legend === 0) {
    return (
      <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: '421px' }}>
        <i className="fa fa-exclamation-circle" style={{ fontSize: '7em', color: '#ebebeb' }}></i>
        <p style={{ fontSize: '1.2em' }}>No momento não há registros.</p>
      </div>
    );
  };

  return (
    <div className='w-100 d-flex flex-column overflow-hidden'>
      <div>
        <h5 style={{ color: '#00bf63', fontWeight: '900' }} className='mt-4'>Economizado</h5>
      </div>
      {chartType === 'channel' && (
        <ReactECharts
          ref={chartRef}
          option={{
            tooltip: (dataPerChannel.length <= 6) ? {
              trigger: 'item',
              axisPointer: {
                type: 'shadow'
              },
              formatter: (params) => {
                let tooltipContent = '';
                const channelName = params.seriesName;
                const value = params.data.value;
                const formattedValue = new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(value);
                tooltipContent += `${channelName}<br/> Valor: ${formattedValue}`;
                return tooltipContent;
              },
              position: function (point, params, dom, rect, size) {
                if (isMobile) {
                  const x = (size.viewSize[0] - size.contentSize[0]) / 2;
                  const y = Math.min(point[1], size.viewSize[1] - size.contentSize[1]);
                  return [x, y];
                }
                return null;
              }
            } : {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              formatter: (params) => {
                let tooltipContent = '';
                params.forEach((param) => {
                  const channelName = param.seriesName;
                  const value = param.data.value;
                  const formattedValue = new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(value);
                  tooltipContent += `Nome ${channelName}<br/> Valor: ${formattedValue}<br/>`
                });
                return tooltipContent;
              },
              position: function (point, params, dom, rect, size) {
                if (isMobile) {
                  const x = (size.viewSize[0] - size.contentSize[0]) / 2;
                  const y = Math.min(point[1], size.viewSize[1] - size.contentSize[1]);
                  return [x, y];
                }
                return null;
              }
            },
            xAxis: {
              type: 'category',
              data: xAxisData,
              axisLabel: {
                interval: 0,
              },
            },
            yAxis: {
              type: 'value',
              axisTick: {
                show: false,
              },
              splitLine: {
                lineStyle: {
                  color: '#28a74580',
                },
              },
            },
            series: dataPerChannel,
            legend: {
              type: 'scroll',
            },
          }}
          style={{ width: '100%', height: '350px' }}
        />
      )}
      {chartType === 'consolidate' && (
        <ReactECharts
          ref={chartRef}
          option={{
            tooltip: {
              trigger:
                'axis',
              axisPointer: {
                type: 'shadow'
              },
              formatter: (params) => {
                const value = params[0].value;
                const formattedTotal = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
                return `Valor: ${formattedTotal}`;
              },
              borderColor: '#00bf63',
              position: function (point, params, dom, rect, size) {
                if (isMobile) {
                  const x = (size.viewSize[0] - size.contentSize[0]) / 2;
                  const y = Math.min(point[1], size.viewSize[1] - size.contentSize[1]);
                  return [x, y];
                }
                return null;
              }
            },
            xAxis: {
              type: 'category',
              data: xAxisData,
              axisLabel: {
                interval: 0,
              },
            },
            yAxis: {
              type: 'value', axisTick: {
                show: false,
              }, splitLine: {
                lineStyle: {
                  color: '#28a74580',
                },
              },
            },
            series: [{
              data: dataConsolidate,
              type: 'bar',
              itemStyle: {
                color: '#00bf63',
                borderColor: '#28a745',
                borderWidth: 1,
              },
            }],
          }} style={{ width: '100%', height: '350px' }
          }
        />
      )}
      <div className='w-100 d-flex flex-row justify-content-center'>

        {haveButtonsOptions && (
          <div style={{ marginRight: '30px' }}>
            <input
              style={{ marginRight: '5px' }}
              type="radio"
              name="chartType"
              value="channel"
              checked={chartType === 'channel'}
              onChange={() => {
                setChartType('channel');
                handleGetChannelCampaignMonthData();
              }}
            />
            <label htmlFor="channel">Por Canal</label>
          </div>
        )}
        {haveButtonsOptions && (
          <div >
            <input
              style={{ marginRight: '5px' }}
              type="radio"
              name="chartType"
              value="consolidate"
              checked={chartType === 'consolidate'}
              onChange={() => {
                setChartType('consolidate');
                handleGetCampaignMonthData();
              }}
            />
            <label htmlFor="consolidate">Consolidado</label>
          </div>
        )}
      </div>
    </div>
  );
}

export default EconomyChart;