import React, { useEffect, useRef } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useState } from 'react';
import { getAllTags } from '../Services/Tag-Service'
import AddTagModal from './Modals/AddTagModal'
import { Table } from 'reactstrap'
import { TableBody } from './Partials/Table/TableBody';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { TableHeader } from './Partials/Table/TableHeader';
import ConfirmationModal from './Components/ConfirmationModal';
import { FilterHeader } from './Partials/FilterHeader';
import { deleteTag } from '../Services/Tag-Service';
import { useToast } from './Partials/Toast';
import { formatDateString } from '../Utils/timestamp';
import { CardsLayoutTags } from './Partials/CardsLayout/CardsLayoutTags';
import { useMobile } from './Partials/useMobile/useMobile';


function LayoutTags() {
  const [tagList, setTagList] = useState([]);
  const [filteredTagList, setFilteredTagList] = useState([]);
  const [loading, setLoading] = useState();
  const [openModalEditTag, setOpenModalEditTag] = useState(false);
  const [filter, setFilter] = useState('');
  const [maxTags, setMaxTags] = useState(0);
  const scrollRef = useRef();
  const [openConfirmationModal, setOpenConfirmationModal] = useState('');
  const isMobile = useMobile();

  const { showSuccessToast } = useToast();

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  });

  const inputRef = useRef();

  const handleGetTags = async () => {
    try {
      setLoading(true)
      const res = await getAllTags()
      setTagList(res)
      setMaxTags(res?.length)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  const itemsTableHeader = [
    { label: '', order: 0 },
    { label: 'Nome', order: 1 },
    { label: 'Contatos', order: 2 },
    { label: 'Responsável', order: 3 },
    { label: 'Data da criação', order: 4 },
    { label: '', order: 6 }
  ]

  const cellsTableData = [
    {
      type: 'customComponent',
      order: 0,
      paddingLeft: 10,
      width: 120,
      height: 50,
      component: (data) => (
        <div className={"tbody-tags"}
          style={{ borderSpacing: 20, borderCollapse: 'separate' }}>
          <div
            height={60}
            style={{
              position: 'relative',
              marginLeft: '20px'
            }}>
            <i className={`fa fa-tag text-${data?.type}`}
              style={{
                position: 'relative',
                marginLeft: '20px',
                transition: 'color 0.3s ease'
              }} />
          </div>
        </div>
      )
    },
    {
      type: 'longText',
      property: 'name',
      order: 1,
      width: 200,
      height: 50,
      paddingLeft: 10,

    },
    {
      type: 'text',
      property: 'totalContacts',
      order: 3,
      width: 250,
      height: 50,
      paddingLeft: 10,

    },
    {
      type: 'longText',
      order: 4,
      property: "operatorName",
      height: 50,
      width: 250,
      paddingLeft: 10,
    },
    {
      type: 'customComponent',
      order: 5,
      height: 50,
      paddingLeft: 10,
      component: (data) => (
        <ComponentDateFormater data={data} />
      )
    },
    {
      type: 'actions',
      property: 'actions',
      order: 6,
      width: 50,
      toggle: (data) => handleDropdown(data),
      items: [
        {
          label: 'Editar',
          action: (data) =>
            setOpenModalEditTag({ open: true, tagInfo: data }),
          show: () => true,
        },
        {
          label: 'Excluir',
          action: (data) =>
            setOpenConfirmationModal({ open: true, info: data }),
          show: () => true,
        }
      ]
    }
  ]

  const ComponentDateFormater = ({ data }) => {
    return formatDateString(data?.createdAt)
  }

  const handleDeleteTag = async (tag) => {
    try {
      const payload = {
        id: tag?._id
      }
      await deleteTag(payload);
      setOpenConfirmationModal(null)
      handleGetTags()
      showSuccessToast('Tag deletada com sucesso.')
      return
    } catch (error) {
      console.error('An error occurred:', error)
    }
  }

  const handleDropdown = (clickedItem) => {
    const index = filter ? filteredTagList.findIndex((tag) => tag._id === clickedItem._id) : tagList.findIndex((tag) => tag._id === clickedItem._id)

    if (index !== -1) {
      const updatedtagList = filter ? [...filteredTagList] : [...tagList];
      updatedtagList[index] = {
        ...updatedtagList[index],
        dropdownOpen: !updatedtagList[index].dropdownOpen || false,
      };
      filter ? setFilteredTagList(updatedtagList) : setTagList(updatedtagList);
    }
  };

  const getTagsByFilter = async (filter) => {
    setLoading(true)
    const filteredTags = tagList.filter(obj => obj.name.toLowerCase().includes(filter.toLowerCase()))
    setFilteredTagList(filteredTags);
    setLoading(false)
    setFilter(filter)
  };

  useEffect(() => {
    handleGetTags()
  }, []);

  return (
    <div className='layout'>
      <div className='content'>
        <div className="content bg-light p-0 align-items-center text-black flex-column h-100">
          <FilterHeader
            maxRows={maxTags}
            title="Tags"
            placeholder="Procurar por tags"
            inputCallback={getTagsByFilter}
            addButtonChildren={
              <AddTagModal
                onCloseModal={(close) => { setOpenModalEditTag({}) }}
                onAddTag={() => handleGetTags()}
                editingTag={openModalEditTag.open}
                tagInfo={openModalEditTag.tagInfo}
                onEditTag={() => handleGetTags()}
                tagList={tagList} />
            }
            inputRef={inputRef}
          />
          <div className="sidebar-body w-100 pb-4 px-5 pt-0"
            style={{ position: 'relative', height: '80%' }}
            id="sidebar-departments">
            {loading ?
              <div className="overlay-loading-customers">
                <i className="fa fa-spinner"
                  style={{
                    animation: 'spin 3s linear infinite',
                    fontSize: 42
                  }}></i>
              </div>
              :
              <PerfectScrollbar className='hide-rail-x'>
                {
                  isMobile ? (
                    <Table>
                      <CardsLayoutTags
                        data={filteredTagList && filter ? filteredTagList : tagList}
                        loading={loading}
                        setOpenModalEditTag={setOpenModalEditTag}
                        setOpenConfirmationModal={setOpenConfirmationModal}
                        handleDropdown={handleDropdown}
                      />
                      <ConfirmationModal
                        openConfirmationModal={openConfirmationModal}
                        setOpenConfirmationModal={setOpenConfirmationModal}
                        handleDeleteTag={handleDeleteTag}
                        messageModal='Deseja cancelar essa tag ?'
                      />
                    </Table>
                  ) : (
                    <Table>
                      <TableHeader
                        itemsTableHeader={itemsTableHeader}
                        theadClassName={'thead-customers'} />
                      <TableBody
                        data={filteredTagList && filter ? filteredTagList : tagList}
                        cellsTableData={cellsTableData}
                        bodyClassName={'tbodyCampaign'}
                        loading={loading}
                      />
                    </Table>
                  )
                }
                <ConfirmationModal
                  openConfirmationModal={openConfirmationModal}
                  setOpenConfirmationModal={setOpenConfirmationModal}
                  handleDeleteTag={handleDeleteTag}
                  messageModal='Deseja cancelar essa tag ?'
                />
              </PerfectScrollbar>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default LayoutTags
