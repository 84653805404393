import React, { useContext, useMemo, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { ReactComponent as DisconnectedSvg } from "../../assets/disconnected.svg";
import { AttendanceContext } from '../../Context/AttendanceContext';

function DisconnectedModal() {
  const { socketContext } = useContext(AttendanceContext)
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const getWSEvent = () => {
    if (socketContext && socketContext.disconnected) {
      toggle()
    }
  }

  const handleReconnect = () => {
    window.location.reload()
  }

  useMemo(() => {
    getWSEvent()
  }, [socketContext])

  return (
    <Modal zIndex={9999} isOpen={modal} toggle={toggle} centered backdrop="static"
      className="modal-dialog-zoom">
      <ModalBody>
        <div className="connection-error">
          <h4 className="text-center">Sua conexão foi perdida</h4>
          <DisconnectedSvg />
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-center" style={{marginTop: -30, zIndex: 2}}>
        <Button color="primary" size="lg" onClick={() => handleReconnect()}>Reconectar</Button>
      </ModalFooter>
    </Modal>
  )
}

export default DisconnectedModal
