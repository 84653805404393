import React from 'react';
import { Row, Col, Card, CardText, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { formatDateString } from '../../../Utils/timestamp';

export const CardsLayoutTags = ({
    data,
    loading,
    setOpenModalEditTag,
    setOpenConfirmationModal,
    handleDropdown
    }) => {

  const tagColors = {
    primary: 'text-primary',
    secondary: 'text-secondary',
    success: 'text-success',
    danger: 'text-danger',
    warning: 'text-warning',
    info: 'text-info',
    light: 'text-light',
    dark: 'text-dark',
    };
          
  const toggle = (data) => {
    handleDropdown(data);
  };
  
  if (loading) {
    return (
      <div>
        <i className="fa fa-exclamation-circle" style={{ fontSize: '7em', color: '#ebebeb' }}></i>
        <p style={{ fontSize: '1.2em' }}>No momento não há registros.</p>
      </div>
    );
  };

  return (
    <div>
      {data.map((item, i) => (
        <div>
          <Card key={i} className='w-100 p-3 bg-light' style={{
            borderBottom:'2px solid #6161ff',
            borderRadius:'10px',
            borderTop:'none',
            borderLeft:'none',
            borderRight:'none'
            }}>
            <Row className='d-flex align-items-start mb-2 bg-primary' style={{borderRadius:'10px'}}>
              <Col className='d-flex justify-content-start p-2'>
                <CardText>
                  <strong style={{fontSize:'18px'}}>Nome:</strong><br/>
                  {item.name}
                </CardText>
              </Col>
              <Col className='d-flex justify-content-center p-2'>
                <CardText>
                    <div className={"tbody-tags mt-2"}
                        style={{ borderSpacing: 20, borderCollapse: 'separate' }}>
                        <div height={60}>
                            <i className={`fa fa-tag ${tagColors[item.type]}`}/>
                        </div>
                    </div>
                </CardText>
              </Col>
            </Row>
            <Row className='d-flex align-items-start mb-2'>
              <Col className='d-flex justify-content-start p-2'>
                <CardText>
                  <strong style={{fontSize:'18px'}}>Contatos</strong><br/>
                 {item.totalContacts}
                </CardText>
              </Col>
              <Col className='d-flex justify-content-center p-2'>
                <CardText>
                  <strong style={{fontSize:'18px'}}>Responsável</strong><br/>
                  {item.operatorName}
                </CardText>
              </Col>
            </Row>
            <Row className='d-flex align-items-start mb-2'>
              <Col className='d-flex justify-content-start p-2'>
                <CardText>
                    <strong style={{ fontSize: '18px' }}>Data de criação</strong><br/>
                    {formatDateString(item.createdAt)}
                </CardText>
              </Col>
              <Col className='d-flex justify-content-center p-2'>
                <Dropdown isOpen={item.dropdownOpen} toggle={() => toggle(item)}>
                  <DropdownToggle tag="a">
                    <i className={'fa fa-ellipsis-v mt-3'}></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => setOpenModalEditTag({ open: true, tagInfo: item })}>
                        Editar
                    </DropdownItem>
                    <DropdownItem onClick={() => setOpenConfirmationModal({ open: true, info: item })}>
                        Cancelar
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
          </Card>
        </div>
      ))}
    </div>
  );
};