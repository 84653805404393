import React, { useEffect } from "react";
import { sidebarAction } from "../Store/Actions/sidebarAction";
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom";

const LayoutHome = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        document.body.classList.add('form-membership');
        return () => {
            document.body.classList.remove('form-membership');
        };
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(sidebarAction('Início'));
            history.push('/inicio');
        }, 100);

        return () => clearTimeout(timer);
    }, [dispatch, history]);

    return (
        <>
        </>
    );
};

export default LayoutHome;