import { useState, useEffect } from 'react';

const useServiceWorkerRegistration = (swPath) => {
    const [registration, setRegistration] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register(swPath)
                .then(reg => {
                    setRegistration(reg)
                    //console.log('Service Worker rgistrado com sucesso', reg)
                })
                .catch(err => {
                    setError(err)
                    console.err('Erro ao registrar o service worker:', err)
                })
        } else {
            console.error('Service Worker não é suportado neste navegador')
        }
    }, [swPath])
    
    return { registration, error }
};

export default useServiceWorkerRegistration;
