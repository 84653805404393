import React, { useEffect, useState } from "react";
import { Button, Input } from 'reactstrap'
import { useToast } from "./Toast";
import { getAllQuickMessages, deleteQuickMessage, postQuickMessage, putQuickMessage } from "../../Services/Quick-Messages-Service";
import ConfirmationModal from "../Components/ConfirmationModal";
import AddQuickMessageModal from "../Modals/AddQuickMessageModal";

const QuickMessageListComponent = ({
    showQuickMessages,
    setShowQuickMessages,
    inputMsg,
    handleInputChange,
    inputRef }) => {
    const [listQuickMessages, setListQuickMessages] = useState([])
    const [openConfirmationModal, setOpenConfirmationModal] = useState('')
    const [openModalEditQuickMessage, setOpenModalEditQuickMessage] = useState(false);
    const [addQuickMessage, setAddQuickMessage] = useState(false)
    const [listQuickMessagesFilter, setListQuickMessagesFilter] = useState(null)
    const { showSuccessToast } = useToast()

    const getAllQuickMessagesList = async () => {
        const response = await getAllQuickMessages()
        setListQuickMessages(response)
    }

    const handleSaveQuickMessage = async (messageInfo, messageId) => {

        const body = {
            message: messageInfo?.inputMsg,
            shortcut: messageInfo?.shortcut,
        }
        const isEditing = messageId
        try {
            if (isEditing) {
                const bodyMessageFormated = { id: messageInfo?.messageId, body }
                await putQuickMessage(bodyMessageFormated)
                showSuccessToast('Resposta rápida atualizada com sucesso.')
                return
            }
            await postQuickMessage(body)
            showSuccessToast('Resposta rápida criada com sucesso.')
        } catch (error) {
            console.error('An error occurred:', error)
        }
        finally {
            getAllQuickMessagesList()
            setOpenModalEditQuickMessage('')
            setAddQuickMessage(false)
        }
    }

    const handleDeleteQuickMessage = async (message) => {
        try {
            const id = message._id
            await deleteQuickMessage(id)
            showSuccessToast('Mensagem deleta com sucesso')

        } catch (error) {
            console.error('An error occurred:', error)
        } finally {
            setOpenConfirmationModal('')
            getAllQuickMessagesList()
        }
    }
    function removeFirstSlash(str) {
        return str.replace(/^\/+/, '');
    }

    const getQuickMessagesListFiltered = async (filter, shortcut = false) => {
        if (shortcut) {
            filter = removeFirstSlash(filter)
        }
        const filteredQuickMessages =
            listQuickMessages.filter(obj =>
                obj.shortcut.toLowerCase().includes(filter?.toLowerCase()) ||
                obj.message.toLowerCase().includes(filter?.toLowerCase())
            )
        setListQuickMessagesFilter(filteredQuickMessages)

    }

    const handleClick = (message) => {
        modalToggle()
        const concatenatedMessage = inputMsg.startsWith('/') ? message : `${inputMsg} ${message}`;
        inputRef.current.value = concatenatedMessage;

        handleInputChange({ target: inputRef.current });
    };

    const modalToggle = () => {
        setListQuickMessagesFilter(null)
        setShowQuickMessages(false)
    }

    useEffect(() => {
        if (showQuickMessages && inputMsg.startsWith('/')) {
            getQuickMessagesListFiltered(inputMsg, true)
        }

    }, [inputMsg, listQuickMessages])

    useEffect(() => {
        getAllQuickMessagesList()
    }, [])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && document.activeElement === inputRef.current && showQuickMessages) {
                const list = listQuickMessagesFilter || listQuickMessages
                if (list.length === 1) {
                    modalToggle()
                    handleClick(list[0].message);
                    event.preventDefault()
                }
            }
        }

        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [listQuickMessagesFilter, listQuickMessages, showQuickMessages])

    return (
        <>
            {showQuickMessages ? (
                <div
                    style={{
                        backgroundColor: "white",
                        padding: "10px 16px",
                        borderRadius: "5px",
                        marginBottom: "20px",
                        width: "100%",
                        maxHeight: '300px',
                        overflowY: 'auto',
                        cursor: 'pointer'
                    }}
                >
                    <div className="d-block">
                        <div className="d-flex" style={{ alignContent: 'baseline' }}>
                            <div className="my-1 w-100 d-flex">
                                <p style={{ margin: 0, fontWeight: "bold", padding: '10px' }}>
                                    Respostas rápidas
                                </p>
                                <Button
                                    color="light"
                                    className="btn-floating btn-sm ml-2 my-2"
                                    onClick={() => setAddQuickMessage(true)}
                                    title="Adicionar resposta rápida"
                                    style={{ fontSize: '8px', backgroundColor: '#6161FF', }}>
                                    <i className="fa fa-plus text-white" aria-hidden="true" />
                                </Button>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Procurar resposta rápida"
                                    style={{
                                        width: 250,
                                        marginLeft: 20,
                                        marginTop: 5,
                                        backgroundColor: '#ebebeb'
                                    }}
                                    onChange={(e) => {
                                        getQuickMessagesListFiltered(e.target.value);
                                    }}
                                    autoComplete='off'
                                />
                            </div>
                            <button
                                className="btn btn-light"
                                style={{ height: '35px', margin: '10px' }}
                                onClick={() => modalToggle()}
                            >
                                <i className="ti ti-close"></i>
                            </button>

                        </div>
                    </div>
                    <hr />
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <span
                            style={{
                                marginBottom: 0,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: '100%'
                            }}
                        >
                            {listQuickMessages.length > 0 ? (
                                listQuickMessagesFilter && listQuickMessagesFilter.length === 0 ? (
                                    <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: '150px' }}>
                                        <i className="fa fa-exclamation-circle" style={{ fontSize: '5em', color: '#ebebeb' }}></i>
                                        <p style={{ fontSize: '1.2em' }}>No momento não há registros.</p>
                                    </div>
                                ) : (
                                    (listQuickMessagesFilter ? listQuickMessagesFilter : listQuickMessages).map((message, i) => (
                                        <div key={i} className="d-block w-100">
                                            <div className="w-100 d-flex">
                                                <div
                                                    style={{
                                                        width: '25vw',
                                                        maxWidth: '25vw',
                                                        display: 'block',
                                                    }}
                                                    title={message?.message}
                                                    onClick={() => { handleClick(message.message) }}
                                                >
                                                    <p className="m-0" style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}><code style={{ color: '#6161FF' }}>{message.shortcut}</code></p>
                                                    <p className="m-0" style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}>{message.message}</p>
                                                </div>
                                                <div style={{ marginLeft: 'auto' }}>
                                                    <Button
                                                        color="light"
                                                        className="btn-floating ml-2"
                                                        onClick={() => handleClick(message.message)}
                                                        title="Selecionar"
                                                        style={{ fontSize: '10px' }}>
                                                        <i className="fa fa-check text-success" style={{ fontSize: '14px' }} />
                                                    </Button>
                                                    <Button
                                                        color="light"
                                                        className="btn-floating ml-2"
                                                        onClick={() => setOpenConfirmationModal({ open: true, info: message })}
                                                        title="Excluir"
                                                        style={{ fontSize: '10px' }}>
                                                        <i className="fa fa-trash text-danger" style={{ fontSize: '14px' }} />
                                                    </Button>
                                                    <Button
                                                        color="light"
                                                        className="btn-floating ml-2"
                                                        onClick={() => setOpenModalEditQuickMessage({ open: true, quickMessageInfo: message })}
                                                        title="Editar"
                                                        style={{ fontSize: '10px' }}>
                                                        <i className="fa fa-pencil text-primary" style={{ fontSize: '14px' }} />
                                                    </Button>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    ))
                                )
                            ) : (
                                <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: '150px' }}>
                                    <i className="fa fa-exclamation-circle" style={{ fontSize: '5em', color: '#ebebeb' }}></i>
                                    <p style={{ fontSize: '1.2em' }}>No momento não há registros.</p>
                                </div>
                            )}

                        </span>
                    </div >
                </div >
            ) : null}
            <ConfirmationModal
                openConfirmationModal={openConfirmationModal}
                setOpenConfirmationModal={setOpenConfirmationModal}
                confirmationFunction={() => handleDeleteQuickMessage(openConfirmationModal.info)}
                messageModal='Deseja excluir essa mensagem ?'
            />
            <AddQuickMessageModal
                onCloseModal={(close) => { setOpenModalEditQuickMessage({}) }}
                editingQuickMessage={openModalEditQuickMessage.open}
                quickMessageInfo={openModalEditQuickMessage.quickMessageInfo}
                handleSaveQuickMessage={handleSaveQuickMessage}
                getAllQuickMessagesList={getAllQuickMessagesList}
                setAddQuickMessage={setAddQuickMessage}
                addQuickMessage={addQuickMessage}
            />

        </>
    );

}

export default QuickMessageListComponent