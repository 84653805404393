import React, { useEffect, useMemo, useState } from 'react';
import { Input, Label, Modal, ModalBody, Tooltip } from 'reactstrap';
import { postDepartment, putDepartment } from '../../Services/Department-Service';
import { useToast } from '../Partials/Toast';
import 'react-bootstrap-tagsinput/dist/index.css'

function AddNewDepartmentModal({ onAddDepartment, onEditDepartment, editingDepartment, department, onCloseModal }) {
  const { showSuccessToast } = useToast();
  const [name, setName] = useState('');
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const tooltipToggle = () => setTooltipOpen(!tooltipOpen);

  const handleDisableSaveButton = () => {
    if (!name) return true
    return false
  }

  const handleSave = async () => {
    const data = {
      name
    }

    try {
      const isEditing = editingDepartment
      if (isEditing) {
        let editedDepartment = { ...department, ...data }
        const res = await putDepartment(department._id, editedDepartment);
        
        setName(editedDepartment.name);
        onEditDepartment(true)
        modalToggle()
        showSuccessToast('Departamento atualizado com sucesso.');
      } else {
        const res = await postDepartment(data);
  
        onAddDepartment(true);
        modalToggle();
        showSuccessToast('Departamento adicionado com sucesso.');
      }
    } catch (error) {
      console.error('Ocorreu um erro:', error);
    }
  };

  const setFormEditingDepartment = () => {
    setName(department.name)
  }

  const resetForm = () => {
    setName('')
  }

  useEffect(() => {
    if (!modal) {
      resetForm()
    }
    if (modal && editingDepartment && department) {
      setFormEditingDepartment()
    }
  }, [modal])

  useMemo(() => {
    if (editingDepartment && department) {
      modalToggle()
    }
  }, [editingDepartment, department]) 
  
  return (
    <>
      <button className={ "btn btn-primary font-weight-bold"} onClick={modalToggle} id={"add-department"}>
        Adicionar <i className={"fa fa-plus ml-2"} aria-hidden="true"></i>
      </button>
      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={"add-department"}
        toggle={tooltipToggle}>
        {'Adicionar departamento'}
      </Tooltip>
      <Modal isOpen={modal} toggle={(toggle) => {modalToggle(toggle)}} centered className="call modal-dialog-zoom" size='lg' onClosed={(toggle) => {onCloseModal(toggle)}} zIndex={9999}>
        <button className={"btn btn-light btn-modal-close"} onClick={modalToggle}>
          <i className="fa fa-times"></i>
        </button>
        <ModalBody>
          <div className="call-background" style={{ backgroundColor: '#e6e6e6', filter: 'initial', opacity: 'initial' }}></div>
          <div>
            <h4 style={{ color: 'black' }}>{editingDepartment ? 'Editar departamento' : 'Adicionar departamento'}</h4>
            <div className="transfer-info-box">
              <div style={{ padding: '0 50px', color: 'black', fontWeight: 'bold' }}>
                <div className="w-100" style={{display:'flex', flexDirection:'row', gap:'1rem'}}>
                  <div className='w-100' style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                    <Label for="name" className='mt-4'>Nome:</Label>
                    <Input type="text" name="name" id="name" style={{ maxHeight: 200 }} onChange={(e) => setName(e.target.value)} autoComplete='off' value={name} />
                  </div>
                </div>
                <div className='text-right ml-auto'>
                  <button
                    type="button"
                    className="btn btn-primary mt-4 mr-1"
                    data-dismiss="modal"
                    disabled={handleDisableSaveButton()}
                    onClick={() => handleSave()}
                  >
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default AddNewDepartmentModal
