import React, { useEffect, useRef, } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useState } from 'react';
import { Table } from 'reactstrap';
import { TableBody } from './Partials/Table/TableBody';
import { CardsLayoutGroup } from './Partials/CardsLayout/CardsLayoutGroup';
import { TableHeader } from './Partials/Table/TableHeader';
import { getAllGroups, cancelGroup } from '../Services/Group-Service';
import { sidebarAction } from '../Store/Actions/sidebarAction';
import { useDispatch } from "react-redux"
import AddGroupModal from './Modals/AddGroupModal';
import { FilterHeader } from './Partials/FilterHeader';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ConfirmationModal from './Components/ConfirmationModal';
import { useToast } from './Partials/Toast';
import { useMobile } from './Partials/useMobile/useMobile';
import { formatDateString } from '../Utils/timestamp';
import { useHistory } from "react-router-dom";

function LayoutGroup() {
    const [loading, setLoading] = useState();
    const [openModalEditGroup, setOpenModalEditGroup] = useState(false);
    const [filter, setFilter] = useState('');
    const [groupList, setGroupList] = useState(undefined)
    const [maxGroups, setMaxGroups] = useState(0);
    const [openConfirmationModal, setOpenConfirmationModal] = useState('')
    const { showSuccessToast } = useToast()
    const [isVisible, setIsVisible] = useState(false);
    const [filteredGroupList, setFilteredGroupList] = useState([])
    const isMobile = useMobile();
    const dispatch = useDispatch()
    const history = useHistory()
    const inputRef = useRef();

    const handleGetGroups = async () => {
        try {
            setLoading(true)
            const res = await getAllGroups();
            setGroupList(res)
            setMaxGroups(res?.length)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const getGroupsByFilter = async (filter) => {
        setLoading(true)
        const filteredGroups = groupList.filter(obj => obj.name.toLowerCase().includes(filter.toLowerCase()))
        setFilteredGroupList(filteredGroups);
        setLoading(false)
        setFilter(filter)
    };

    const ComponentDateFormater = ({ data }) => {
        return formatDateString(data?.createdAt)
   
    }

    const ContactsLength = ({ data }) => {
        return data?.contacts?.length
    }
    const itemsTableHeader = [
        { label: 'Nome', order: 0 },
        { label: 'Descrição', order: 1 },
        { label: 'Data de Criação', order: 2 },
        { label: 'Canal', order: 3 },
        { label: 'Contatos', order: 4 },
        { label: '', order: 5 }
    ]

    const cellsTableData = [
        {
            type: 'longText',
            property: 'name',
            order: 0,
            width: 250,
            height: 50,
            paddingLeft: 10,

        },
        {
            type: 'longText',
            property: 'description',
            order: 1,
            width: 250,
            height: 50,
            paddingLeft: 10,

        },
        {
            type: 'customComponent',
            order: 2,
            height: 50,
            width: 150,
            paddingLeft: 10,
            component: (data) => (
                <ComponentDateFormater data={data} />
            )

        },
        {
            type: 'text',
            property: 'channel',
            subProperty: 'name',
            order: 3,
            width: 200,
        },
        {
            type: 'customComponent',
            order: 4,
            width: 200,
            height: 50,
            paddingLeft: 10,
            component: (data) => (
                <ContactsLength data={data} />
            )
        },
        {
            type: 'actions',
            property: 'actions',
            order: 5,
            height: 50,

            toggle: (data) => handleDropdown(data),
            items: [
                {
                    label: 'Editar',
                    action: (data) =>
                        setOpenModalEditGroup({ open: true, groupInfo: data }),
                    show: () => true,
                },
                {
                    label: 'Cancelar',
                    action: (data) => setOpenConfirmationModal({ open: true, info: data }),
                    show: () => true,
                },
            ],
        }
    ]

    const handleDropdown = (clickedItem) => {
        const index = filter ? filteredGroupList.findIndex((group) => group._id === clickedItem._id) : groupList.findIndex((group) => group._id === clickedItem._id)

        if (index !== -1) {
            const updatedGroupList = filter ? [...filteredGroupList] : [...groupList];
            updatedGroupList[index] = {
                ...updatedGroupList[index],
                dropdownOpen: !updatedGroupList[index].dropdownOpen || false,
            };
            filter ? setFilteredGroupList(updatedGroupList) : setGroupList(updatedGroupList);
        }
    };

    const handleCancelGroup = async (data) => {
        try {
            const id = data?._id
            await cancelGroup(id);
            showSuccessToast('Grupo cancelado com sucesso')
        } catch (error) {
            console.error('An error occurred:', error)
        } finally {
            handleGetGroups();
            setOpenConfirmationModal(null)
        }
    }

    useEffect(() => {
        inputRef.current && inputRef.current.focus();
    });

    useEffect(() => {
        handleGetGroups()
        setTimeout(() => {
          dispatch(sidebarAction('Grupo contatos'))
        }, 100);
        history.push('/grupo-contatos')
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isVisible && !event.target.closest('#closeDocument')) {
                setIsVisible(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isVisible]);

    return (
        <div className='layout'>
            <div className='content'>
                <div className="content bg-light p-0 align-items-center text-black flex-column h-100">
                    <FilterHeader
                        maxRows={maxGroups}
                        title="Grupo contatos"
                        placeholder="Procurar por grupo contatos"
                        inputCallback={getGroupsByFilter}
                        addButtonChildren={
                            <AddGroupModal
                                onCloseModal={(close) => { setOpenModalEditGroup({}) }}
                                onAddGroup={() => handleGetGroups()}
                                editingGroup={openModalEditGroup.open}
                                groupInfo={openModalEditGroup.groupInfo}
                                onEditGroup={() => handleGetGroups()}
                            />
                        }
                        inputRef={inputRef}
                    />
                    <div className="sidebar-body w-100 pb-4 px-5 pt-0"
                        style={{ position: 'relative', height: '80%' }}
                        id="sidebar-departments">
                        {loading &&
                            <div className="overlay-loading-customers">
                                <i className="fa fa-spinner"
                                    style={{
                                        animation: 'spin 3s linear infinite',
                                        fontSize: 42
                                    }}></i>
                            </div>
                        }
                        {Array.isArray(groupList) ?
                            <PerfectScrollbar className='hide-rail-x'>
                                {
                                    isMobile ? (
                                        <Table>
                                            <CardsLayoutGroup
                                                data={filteredGroupList && filter ? filteredGroupList : groupList}
                                                loading={loading}
                                                setOpenModalEditGroup={setOpenModalEditGroup}
                                                setOpenConfirmationModal={setOpenConfirmationModal}
                                                handleDropdown={handleDropdown}
                                            />
                                            <ConfirmationModal
                                                openConfirmationModal={openConfirmationModal}
                                                setOpenConfirmationModal={setOpenConfirmationModal}
                                                handleCancelGroup={handleCancelGroup}
                                                messageModal='Deseja cancelar essa grupo ?'
                                            />
                                        </Table>
                                    ) : (
                                        <Table>
                                            <TableHeader
                                                itemsTableHeader={itemsTableHeader}
                                                theadClassName={'thead-customers'} />
                                            <TableBody
                                                data={filteredGroupList && filter ? filteredGroupList : groupList}
                                                cellsTableData={cellsTableData}
                                                bodyClassName={'tbodyCampaign'}
                                                loading={loading}
                                            />
                                            <ConfirmationModal
                                                openConfirmationModal={openConfirmationModal}
                                                setOpenConfirmationModal={setOpenConfirmationModal}
                                                handleCancelGroup={handleCancelGroup}
                                                messageModal='Deseja cancelar esse grupo ?'
                                            />
                                        </Table>
                                    )
                                }
                            </PerfectScrollbar>
                            : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LayoutGroup