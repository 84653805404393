import React from 'react'
import toast, { useToasterStore } from 'react-hot-toast'

const boxStyle = {
  display: 'flex',
  gap: 10,
  alignItems: 'center'
}

export const useToast = () => {
  const showSuccessToast = (message, duration = 3000, id) => {
    const toastId = toast.success(
      (t) => (
        <div style={boxStyle}>
          {message}
          <i className="fa fa-times" style={{ cursor: 'pointer' }} onClick={() => toast.dismiss(t.id)} aria-hidden="true"></i>
        </div>
      ),
      {
        className: 'bg-success',
        duration,
        position: 'top-right',
        icon: <i className="fa fa-check" aria-hidden="true" style={{ alignSelf: 'self-start', marginTop: 5 }}></i>,
        id
      }
    );
    return () => {
      toast.dismiss(toastId)
      toast.dismiss(id)
    }
  }

  const showErrorToast = (message, duration = 3000, id) => {
    const toastId = toast.error(
      (t) => (
        <div style={boxStyle}>
          {message}
          <i className="fa fa-times" style={{ cursor: 'pointer' }} onClick={() => toast.dismiss(t.id)} aria-hidden="true"></i>
        </div>
      ), {
      className: 'bg-danger',
      duration,
      position: 'top-right',
      icon: <i className="fa fa-exclamation-circle" aria-hidden="true" style={{ alignSelf: 'self-start', marginTop: 5 }}></i>,
      id
    })
    return () => {
      toast.dismiss(toastId)
      toast.dismiss(id)
    }
  }

  const showWarningToast = (message, duration = 3000, children, id) => {
    const toastId = toast.error(
      (t) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={boxStyle}>
            {message}
            <i className="fa fa-times" style={{ cursor: 'pointer' }} onClick={() => toast.dismiss(t.id)} aria-hidden="true"></i>
          </div>
          {children}
        </div>
      ), {
      className: 'bg-warning',
      duration,
      position: 'top-right',
      icon: <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ alignSelf: 'self-start', marginTop: 5 }}></i>,
      id
    })
    return () => {
      toast.dismiss(toastId)
      toast.dismiss(id)
    }
  }

  const showInfoToast = (message, duration = 3000, children, id, position = 'top-right') => {
    const toastId = toast.error(
      (t) => (
        <div style={{ display: 'flex', flexDirection: 'column', zIndex: 10 }}>
          <div style={boxStyle}>
            {message}
            <i className="fa fa-times" style={{ cursor: 'pointer' }} onClick={() => toast.dismiss(t.id)} aria-hidden="true"></i>
          </div>
          {children}
        </div>
      ), {
      className: 'bg-info',
      duration,
      position,
      icon: <i className="fa fa-info-circle" aria-hidden="true" style={{ alignSelf: 'self-start', marginTop: 5 }}></i>,
      id
    })
    return () => {
      toast.dismiss(toastId)
      toast.dismiss(id)
    }
  }

  const showInfoNotificationToast = (message, duration = Infinity, children, id, position = 'top-right') => {
    const toastId = toast.error(
      (t) => (
        <div style={{ display: 'flex', flexDirection: 'column', zIndex: 10 }}>
          <div style={boxStyle}>
            {message}
            <i className="fa fa-times" style={{ cursor: 'pointer' }} onClick={() => toast.dismiss(t.id)} aria-hidden="true"></i>
          </div>
          {children}
        </div>
      ), {
      className: 'bg-info',
      duration,
      position,
      icon: <i className="fa fa-volume-up" aria-hidden="true" style={{ alignSelf: 'self-start', marginTop: 5 }}></i>,
      id
    })
    return () => {
      toast.dismiss(toastId)
      toast.dismiss(id)
    }
  }


  const showAdvertenceToast = (message, duration = Infinity, children, id, position = 'top-right') => {
    const toastId = toast.error(
      (t) => (
        <div style={{ display: 'flex', flexDirection: 'column', zIndex: 10, }}>
          <div style={boxStyle}>
            {message}
            <i className="fa fa-times" style={{ cursor: 'pointer' }} onClick={() => toast.dismiss(t.id)} aria-hidden="true"></i>
          </div>
          {children}
        </div>
      ), {
      className: 'bg-orange',
      duration,
      position,
      icon: <i className="fa fa-volume-up" aria-hidden="true" style={{ alignSelf: 'self-start', marginTop: 5 }}></i>,
      id
    })
    return () => {
      toast.dismiss(toastId)
      toast.dismiss(id)
    }
  }



  const dismissAllToasts = () => {
    toast.dismiss()
  }

  return { showAdvertenceToast, showInfoNotificationToast, showSuccessToast, showErrorToast, showWarningToast, showInfoToast, dismissAllToasts }
}
