import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css';
import '@icon/themify-icons/themify-icons.css';
import './assets/scss/Index.scss'
import App from './App'

import { UserContextWrapper } from './Context/UserContext';

import { Provider } from "react-redux"
import store from './Store'


ReactDOM.render(
    <Provider store={store}>
        <UserContextWrapper>
            <App />
        </UserContextWrapper>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

