import React, { useState } from 'react';
import ReactECharts from 'echarts-for-react';
import WhitoutDataChartModal from '../App/Modals/WhitoutDataChartModal';
import { Col, Row } from 'reactstrap';

const HorizontalBarChart = ({ title,
  data,
  legendText,
  message,
  colors,
  loading
}) => {

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = data?.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = pageNumber => setCurrentPage(pageNumber);



  if (loading) {
    return (
      <>
      <div style={{ height: '556px' }}>
          <h5 style={{ color: '#6161FF', fontWeight: '200', margin: '0' }}>{title}</h5>
          <div   className='d-flex justify-content-center flex-column'style={{ textAlign: "center", alignItems: 'center', height: '100%' }}>
              <i className="fa fa-spinner" style={{ animation: "spin 3s linear infinite", fontSize: 60}}></i>
          </div>
      </div>
  </>
    )
  }

  if (data?.length === 0) {
    return (
      <div style={{ height: '530px', width: '100%', margin: '0px' }}>
        <h5 style={{ color: '#6161FF', fontWeight: '200', margin: '0' }}>{title}</h5>
        <WhitoutDataChartModal message={message} />
      </div>
    );
  }
  const barWidth = data?.length <= 2 ? '30%' : null;

  const option = {
    tooltip: {
      tooltip: {
        formatter: '{d}'
      },
      trigger: 'item',
    },
    legend: {
      left: 'center',
      orient: 'horizontal',
      padding: [0, 0, 0, 0],
      data: legendText
    },
    xAxis: {
      type: 'value',
      interval: 10,
    },
    yAxis: {
      type: 'category',
      data: currentData?.map(item => item?.departmentName || item?.operatorName),
      axisLabel: {
        margin: 5,
        padding: 20,
        formatter: function (value) {
          const maxLength = 10;
          if (value.length > maxLength) {
            return value.substring(0, maxLength) + '...'
          }
          return value
        }
      },
      left: '5%',
    },
    grid: {
      containLabel: true,
      bottom: '2%'
    },
    series: [

      {
        name: legendText ? legendText[0] : '',
        type: 'bar',
        stack: 'total',
        label: {
          show: false
        },
        emphasis: {
          focus: 'series'
        },
        data: currentData?.map(item => item.started),
        emphasis: {
          focus: 'series'
        },
        barWidth: barWidth,
        color: colors ? colors[0] : '',
      },
      {
        name: legendText ? legendText[1] : '',
        type: 'bar',
        stack: 'total',
        label: {
          show: false
        },
        emphasis: {
          focus: 'series'
        },
        data: currentData?.map(item => item.transfer),
        emphasis: {
          focus: 'series'
        },
        barWidth: barWidth,
        color: colors ? colors[1] : '',
      },
      {
        name: legendText ? legendText[2] : '',
        type: 'bar',
        stack: 'total',
        label: {
          show: false
        },
        emphasis: {
          focus: 'series'
        },
        data: currentData?.map(item => item.finished),
        emphasis: {
          focus: 'series'
        },
        barWidth: barWidth,
        color: colors ? colors[2] : '',
      }
    ],

  }

  return (
    <>
      <div>
        <h5 style={{ color: '#6161FF', fontWeight: '200', margin: '0' }}>{title}</h5>
        <Row>
          <Col>
            <ReactECharts option={option} style={{ height: '530px', width: '100%', }} />
          </Col>
        </Row>
        <Row >
          <Col className="d-flex justify-content-center">
            {
              data.length > 8 &&
              <>
                <button className='btn btn-secondary btn-sm  mr-1 my-3 py-sm-2' onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Anterior</button>
                <button className='btn btn-primary btn-sm py-sm-2 my-3' onClick={() => paginate(currentPage + 1)} disabled={currentData.length < itemsPerPage}>Próximo</button>
              </>
            }
          </Col>
        </Row>
      </div>
    </>
  );
};


export default HorizontalBarChart;
