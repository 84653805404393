import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Context/AuthContext";
import { ReactComponent as Logo } from "../assets/img/logo.svg";
import { signIn } from "./../Services/User-Service";
import jwtDecode from "jwt-decode";
import PDFViewer from "mgr-pdf-viewer-react";

function SignIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setAccessToken } = useContext(AuthContext);

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState("fa fa-eye-slash");

  const show = () => {
    type === "password" ? setType("text") : setType("password");
    icon === "fa fa-eye"
      ? setIcon("fa fa-eye-slash")
      : setIcon("fa fa-eye");
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const response = await signIn(username, password);

    if (response.auth) {
      setAccessToken(response.access_token);
      const { permissions } = jwtDecode(response.access_token);
      const enableDashboard = permissions
        ? permissions.includes("financial_dashboard")
        : false;
      window.location.replace(
        enableDashboard ? "/dashboard-financeiro" : "/chat"
      );
    }
  };

  useEffect(() => document.body.classList.add("form-membership"), []);

  return (
    <div className="form-wrapper">
      <div className="logo">
        <Logo />
      </div>
      <h5>SENDIO | CONNECT</h5>
      <form onSubmit={handleLogin}>
        <div className="form-group input-group-lg">
          <input
            type="text"
            name="email"
            className="form-control"
            placeholder="Usuário"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-group input-group-lg d-flex">
          <input
            type={type}
            name="password"
            className="form-control"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              position: 'relative',
              display: 'inline-block',
              paddingRight: '50px'
            }}
          />
          <div
            className="form-group input-group-lg"
            onClick={show}
            style={{
              alignItems: 'center',
              cursor: 'pointer',
              borderRadius: '0.25rem',
              position: 'absolute',
              marginLeft: window.innerWidth <= 576 ? '70vw' : '285px'

            }}
          >
            <i className={icon}
              style={{
                padding: '16px',
                fontSize: '15px',
              }}
            />
          </div>
        </div>
        <button type="submit" className="btn btn-primary btn-block btn-lg">
          Entrar
        </button>
      </form>
    </div>
  );
}

export default SignIn;
