import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mobileSidebarAction } from "../Store/Actions/mobileSidebarAction";
import { sidebarAction } from "../Store/Actions/sidebarAction";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { mobileMainQueueAction } from "../Store/Actions/mobileMainQueueAction";
import { mainQueueAction } from "../Store/Actions/mainQueueAction";

const routeMapping = {
  Início: { url: '/inicio', action: "Início" },
  Departamentos: { url: "/departamentos", action: "Departamentos" },
  'Grupo contatos': { url: "/grupo-contatos", action: "Grupo contatos" },
  Usuarios: { url: "/usuarios", action: "Usuarios" },
  Canais: { url: "/canais", action: "Canais" },
  Contatos: { url: "/contatos", action: "Contatos" },
  Chats: { url: "/chat", action: "Chats" },
  Dashboard: { url: "/dashboard-financeiro", action: "Dashboard" },
  "Dashboard Campanhas": { url: "/dashboard-campanhas", action: "Dashboard Campanhas" },
  Tags: { url: "/tags", action: "Tags" },
  Campanhas: { url: "/campanhas", action: "Campanhas" },
  "Dashboard Atendimentos": { url: "/dashboard-atendimentos", action: "Dashboard Atendimentos" }
};

export const NavigationItems = (props) => {
  const { selectedSidebar } = useSelector((state) => state);
  const { item, tooltipName, drawerOpen, i, setDrawerOpen } = props;

  const [showSubItems, setShowSubItems] = useState(false);
  const [subItemsPosition, setSubItemsPosition] = useState({ top: 0, left: 0 });

  const toggleSubItems = () => setShowSubItems(!showSubItems);

  const history = useHistory();
  const dispatch = useDispatch();
  const itemRef = useRef();
  const drawerElementRef = useRef(null);
  const subItemsMenuRef = useRef(null);

  const linkDispatch = (e, name) => {
    if (
      routeMapping[name] &&
      !window.location.href.includes(routeMapping[name].url)
    ) {
      e.preventDefault();
      history.push(routeMapping[name].url);
      dispatch(sidebarAction(routeMapping[name].action));
    } else {
      e.preventDefault();
      dispatch(mobileSidebarAction(true));
    }
    
    const isMobile = document.body.clientWidth < 768
    if(name === 'Chats' && isMobile){
      dispatch(mainQueueAction(true));
      dispatch(mobileMainQueueAction(true));
    }
    setDrawerOpen(false)
  };

  const checkSubItems = (e) => {
    e.preventDefault();
    if (item.subItems) {
      toggleSubItems();
    } else {
      linkDispatch(e, item.name);
    }
  };

  const handleMouseEnter = () => {
    if (!drawerOpen && item.subItems) {
      setShowSubItems(true);
    }
  };

  useEffect(() => {
    let path = window.location.pathname.replace("/", "");
    path = path.charAt(0).toUpperCase() + path.slice(1);
    if (routeMapping[path]?.url === window.location.pathname) {
      dispatch(sidebarAction(path));
    }
  }, []);

  const setSubItemsPositionHandler = useCallback(() => {
    if (itemRef.current) {
      const { top, left } = itemRef.current.getBoundingClientRect();
      setSubItemsPosition({ top, left: left + 60 });
    }
  }, []);

  const handleOutsideDetection = useCallback((event) => {
    if (subItemsMenuRef.current) {
      const elementId = event.target.id;
      const subItemsMenuId =
        subItemsMenuRef.current.id || document.getElementById("nav-" + item.id);
      const prefixes = ["nav-", "ul-", "li-", "a-", "chats", "span-"];

      if (prefixes.some((prefix) => elementId?.includes(prefix))) return;

      if (subItemsMenuId !== `nav-${elementId}`) {
        setShowSubItems(false);
      }
    }
  }, []);

  useEffect(() => {
    drawerElementRef.current = document.getElementById("drawer");
    setSubItemsPositionHandler();

    if (drawerElementRef.current) {
      drawerElementRef.current.addEventListener(
        "scroll",
        setSubItemsPositionHandler
      );
    }

    document.addEventListener("click", handleOutsideDetection);
    document.addEventListener("mousemove", handleOutsideDetection);

    return () => {
      if (drawerElementRef.current) {
        drawerElementRef.current.removeEventListener(
          "scroll",
          setSubItemsPositionHandler
        );
        document.removeEventListener("mousemove", handleOutsideDetection);
      }
    };
  }, [setSubItemsPositionHandler]);

  return (
    <>
      <li
        key={i}
        style={drawerOpen ? { width: 200 } : null}
        onMouseEnter={handleMouseEnter}
        onClick={checkSubItems}
        ref={itemRef}
        className={`${selectedSidebar === item.name ? "active" : ""} d-flex parent-li justify-content-center ${drawerOpen ? 'parent-li-open' : 'w-100'}`}
        title={item.name}
      >
        {item.icon}
        <span className={drawerOpen ? "mr-auto ml-2" : ""}>
          {drawerOpen ? item.name : ""}
        </span>
        {drawerOpen && item.subItems ? (
          <span className="ml-auto">
            <i
              className={showSubItems ? "fa fa-chevron-up" : "fa fa-chevron-down"}
              style={{ fontSize: 14 }}
            ></i>
          </span>
        ) : null}
        {showSubItems && !drawerOpen ? (
          <nav
            className="sub-items-nav"
            style={{ top: subItemsPosition.top, left: subItemsPosition.left }}
            ref={subItemsMenuRef}
            id={"nav-" + item.id}
          >
            <ul id={"ul-" + item.id}>
              {item.subItems.map((subItem, idx) => (
                <li key={idx} id={"li-" + item.id + idx}>
                  <a
                    onClick={null}
                    href={subItem.name}
                    id={"a-" + item.id + idx}
                    className="active d-flex"
                    title={subItem.name}
                  >
                    {subItem.icon}
                    <span className="ml-2" id={"span-" + item.id + idx}>
                      {subItem.name}
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        ) : null}
      </li>
      {showSubItems && drawerOpen ? (
        <div>
          {item.subItems.map((subItem, idx) => (
            <a
              onClick={null}
              href={subItem.name}
              key={idx}
              className="active d-flex justify-content-center"
              title={subItem.name}
            >
              {subItem.icon}
              <span className="ml-2">{subItem.name}</span>
            </a>
          ))}
        </div>
      ) : null}
    </>
  );
};
