import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Tooltip,
} from "reactstrap";
import store from "../../../Store";
import { mobileProfileAction } from "../../../Store/Actions/mobileProfileAction";
import { mobileQueuesAction } from "../../../Store/Actions/mobileQueuesAction";
import { profileAction } from "../../../Store/Actions/profileAction";
import { queuesAction } from "../../../Store/Actions/queuesAction";
import FinishAttendanceModal from "../../Modals/FinishAttendanceModal";
import DefaultImage from "../../../assets/img/no-image.png";
import TransferAttendanceModal from "../../Modals/TransferAttendanceModal";
import { AttendanceContext } from "../../../Context/AttendanceContext";
import { useContext } from "react";
import { postStartAttendance } from "../../../Services/Attendance-Service";
import { sidebarAction } from "../../../Store/Actions/sidebarAction";
import { tagStyle } from "../Tags";
import AddCustomerModal from "../../Modals/AddCustomerModal";
import WalletCustomerModal from "../../Modals/WalletCustomerModal";
import TagListContactModal from '../../Modals/TagListContactModal';
import { mobileMainQueueAction } from "../../../Store/Actions/mobileMainQueueAction";
import { mainQueueAction } from "../../../Store/Actions/mainQueueAction";

function ChatHeader(props) {
  const {
    setChatReadOnly,
    setActiveChatCustomer,
    chatReadOnly,
    activeChatCustomer,
    setGetCustomers,
  } = useContext(AttendanceContext);
  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [openTagListModal, setOpenTagListModal] = useState(false);

  const handleTagList = () => {
    setOpenTagListModal(true);
  };

  const profileActions = () => {
    dispatch(profileAction(true));
    dispatch(mobileProfileAction(true));
    if (
      store.getState().queuesSidebar &&
      store.getState().mobileQueuesSidebar
    ) {
      dispatch(queuesAction(false));
      dispatch(mobileQueuesAction(false));
    }
    if (
      store.getState().mainQueueSidebar &&
      store.getState().mobileMainQueueSidebar
    ) {
      dispatch(mainQueueAction(false));
      dispatch(mobileMainQueueAction(false));
    }
  };

  const handleStartAttendance = async () => {
    const res = await postStartAttendance(
      props.selectedChat.phone,
      props.selectedChat.channel.id
    );
    if (res) {
      setChatReadOnly({ readOnly: false });
      setActiveChatCustomer({
        ...props.selectedChat,
        attendance: { id: res._id },
      });
      dispatch(sidebarAction("Chats"));
    }
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tooltipToggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div className="chat-header d-block mb-5  ">
      <div className="d-flex justify-content-between mb-2 chat-header-box">
        <div className="chat-header-user">
          <img
            className="rounded-circle"
            style={{ cursor: "pointer", marginRight: 10 }}
            src={props.selectedChat.image || DefaultImage}
            onError={(e) => (e.target.src = DefaultImage)}
            alt={"Imagem usuário " + props.selectedChat.name}
            width={50}
            height={50}
          />
          <div className="d-block" style={{ zIndex: 1 }}>
            <h5>{props.selectedChat.name}</h5>
            <div
              style={{
                gap: 10,
                zIndex: 1,
                fontSize: '10px'
              }}>
              <i>{props.selectedChat.phoneFormatted}</i>
              {props.selectedChat.externalIds
                ? props.selectedChat.externalIds.map((externalId, i) => (
                  <div
                    key={i}
                    className={`bg-dark-bright`}
                    style={{
                      ...tagStyle,
                      fontSize: '10px',
                      padding: "1px 0",
                      height: '100%',
                      color:'#212529'
                    }}
                  >
                    <span
                      style={{
                        position: "relative",
                        bottom: -1,
                        fontWeight: "bold",
                      }}
                      title={externalId}
                    >
                      Cód.: {externalId}
                    </span>
                  </div>
                ))
                : null}

            </div>
          </div>
        </div>
        <div className="chat-header-action">
          {!chatReadOnly?.readOnly ? (
            <ul className="list-inline d-flex">
              <li className="list-inline-item mb-0 d-flex align-items-center">
                <WalletCustomerModal />
              </li>
              <li className="list-inline-item mb-0">
                <TransferAttendanceModal />
              </li>
              <li className="list-inline-item mb-0">
                <FinishAttendanceModal />
              </li>
              <li
                className="list-inline-item mb-0"
                data-toggle="tooltip"
              >
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggle}
                >
                  <DropdownToggle
                    tag="span"
                    data-toggle="dropdown"
                    aria-expanded={dropdownOpen}
                  >
                    <button className="btn btn-secondary">
                      <i className="ti ti-more"></i>
                    </button>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={profileActions}>Perfil</DropdownItem>
                    <DropdownItem onClick={handleTagList}>Tags</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <TagListContactModal
                  openTagListContactModal={openTagListModal}
                  setOpenTagListContactModal={setOpenTagListModal}
                />
              </li>
            </ul>
          ) : (
            <ul className="list-inline d-flex">
              <li className="list-inline-item">
                <AddCustomerModal
                  editingCustomer
                  customer={activeChatCustomer}
                  onEditCustomer={() => {
                    setGetCustomers(true);
                  }}
                />
              </li>
              <li className="list-inline-item">
                <Tooltip
                  placement="bottom"
                  isOpen={tooltipOpen}
                  target={"start"}
                  toggle={tooltipToggle}
                >
                  Iniciar atendimento
                </Tooltip>
                <button
                  className="btn btn-info d-flex flex-column"
                  id="start"
                  onClick={() => handleStartAttendance()}
                  disabled={chatReadOnly.from === "contact"}
                >
                  <i
                    className="fa fa-phone"
                    aria-hidden="true"
                  ></i>
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
      <div
        className="d-flex"
        style={{ gap: 10 }}
      >
      </div>
    </div>
  );
}

export default ChatHeader;
