import React, { useState } from 'react';
import { Modal, ModalBody, Input, Label } from 'reactstrap';
import DefaultImage from "../../assets/img/no-image.png"

const TransferModal = ({
  setPrivateMessage,
  activeChatCustomer,
  departments,
  operators,
  handleOnChangeDepartment,
  setChoosedOperator,
  handleTransfer,
  operatorStatusIndicator,
  modal,
  modalToggle,
  modalToggleTransfer,
}) => {
  const [choosedDepartment, setChoosedDepartment] = useState('');

  return (
    <Modal zIndex={9999}
      isOpen={modal}
      toggle={modalToggle || modalToggleTransfer}
      centered className="call modal-dialog-zoom"
      size='lg'>
      <button className={"btn btn-light btn-modal-close"} onClick={modalToggle || modalToggleTransfer}>
        <i className="fa fa-times"></i>
      </button>
      <ModalBody>
        <div className="call-background" style={{ backgroundColor: '#e6e6e6', filter: 'initial', opacity: 'initial' }}></div>
        <div>
          <h4 style={{ color: 'black' }}>Transferir atendimentos</h4>
          <div className="transfer-info-box">
            <div className="chat-header-user">
              <img
                className="rounded-circle"
                src={activeChatCustomer.image || DefaultImage}
                alt={'Imagem usuário ' + activeChatCustomer.name}
                width={75}
                height={75}
                onError={(e) => e.target.src = DefaultImage}
              />
              <div>
                <h5 style={{ color: 'black', fontWeight: 'bold' }}>{activeChatCustomer.name}</h5>
              </div>
            </div>
            <hr style={{ width: '90%' }} />
            <div style={{ padding: '0 50px', color: 'black', fontWeight: 'bold' }}>
              <div style={{ gap: 10 }} className="d-flex align-content-center w-100 mt-1">
                <div className="w-100">
                  <Label for="department">Selecione um departamento:</Label>
                  <Input defaultValue={''} type="select" name="department" id="department" onChange={(e) => { handleOnChangeDepartment(e.target.value); setChoosedDepartment(e.target.value); }}>
                    <option disabled value=''>Selecione</option>
                    {departments?.map((department, i) => (
                      <option key={i} value={department._id}>
                        {department.name}
                      </option>
                    ))}
                  </Input>
                </div>
                {activeChatCustomer.attendance.id &&
                  <div className="w-100">
                    <Label for="operator">Selecione um operador:</Label>
                    <Input defaultValue={''} type="select" name="operator" id="operator" onChange={(e) => { setChoosedOperator(e.target.value) }} disabled={!choosedDepartment}>
                      <option value=''>Todos</option>
                      {operators?.map((operator, i) => (
                        <option key={i} value={operator._id}>
                          {operatorStatusIndicator(operator.status)} {operator.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                }
              </div>
              <Label for="descricao" className='mt-4'>Comentário do atendimento:</Label>
              <div className="d-flex" style={{ gap: 10 }}>
                <div className="w-100">
                  <Input type="text" name="descricao" id="descricao" style={{ maxHeight: 200 }} onChange={(e) => setPrivateMessage(e.target.value)} autoComplete='off' />
                </div>
                <button type="button" onClick={handleTransfer} disabled={!choosedDepartment}
                  className="btn btn-dark btn-md align-self-end">
                  Transferir
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default TransferModal;
