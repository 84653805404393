import React, { useEffect, useState } from 'react';
import { Input, Label, Modal, ModalBody, Tooltip } from 'reactstrap';
import Select from 'react-select';
import { postTag, uptadeTag } from '../../Services/Tag-Service'
import { useToast } from '../Partials/Toast';
import { useMemo } from 'react';
import 'react-bootstrap-tagsinput/dist/index.css'
import AddButton from '../Components/AddButton';


const tagsTypes = [
  "primary",
  "secondary",
  "success",
  "danger",
  "warning",
  "info",
  "light",
  "dark",
];

export const styleIcon = {
  padding: '5px 6px',
  borderRadius: '8px',
  backgroundColor: '#e6e6e6',

}

export const styleIconType = {
  marginRight: '1px',
  fontWeight: 'normal',
  padding: '3px 0',
  fontSize: '95%',
  textAlign: 'center'
}

export const CustomOption = ({ innerProps, data }) => (

  <div {...innerProps}
    style={styleIconType}
  >
    <span
      style={{
        ...styleIconType,
      }}>{data.icon}</span>
    {data.label}
  </div>
);

export const CustomSingleValue = ({ innerProps, data }) => (
  <div {...innerProps}
    style={{
      display: 'flex',
      marginTop: '-25px',
      zIndex: 9999,
      color: '#495057',
    }}>
    <span style={styleIconType}>{data.icon}</span>
    {data.label}
  </div>
);

function AddTagModal({ 
  onAddTag, 
  onEditTag, 
  editingTag, 
  tagList, 
  tagInfo, 
  onCloseModal }) {


  const { showSuccessToast, showErrorToast } = useToast();
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [tooltipEdit, setTooltipEdit] = useState(false);
  const [tagType, setTagType] = useState("");
  const [tagId, setTagId] = useState("");
  const [tagName, setTagName] = useState("")
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const tooltipEditBlur = () => setTooltipEdit(!tooltipEdit);


  const options = tagsTypes.map(tag => ({
    value: tag,
    //value: "",
    icon: <i className={`fa fa-tag text-${tag}`}
      style={styleIcon}
    />
  }));



  const handleDisableSaveButton = () => {
    if (!tagName || !tagType) {
      setDisableSaveButton(true)
      return
    }
    const tagNameRepeated = tagList?.some(tag => tag?.name?.toLowerCase() === tagName?.toLowerCase().trim() && tag?.type === tagType?.value);
    setDisableSaveButton(false)
  }

  const handleSave = async (start = false) => {
    const body = {
      name: tagName,
      type: tagType.value,
    }
    try {
      const isEditing = editingTag
      if (isEditing) {
        const payload = {
          id: tagId,
          body
        }
        const res = await uptadeTag(payload)

        modalToggle()
        onEditTag(true)
        showSuccessToast('Tag atualizado com sucesso.')
        return
      }

      const res = await postTag(body)
      tagList = [{ _id: res._id, name: tagName, type: tagType.value }, ...tagList]
      onAddTag(true)
      modalToggle()
      showSuccessToast('Tag criada com sucesso.')
    } catch (error) {
      console.error('An error occurred:', error)
    }

  }

  const setFormEditingTag = () => {
    setTagName(tagInfo.name)
    setTagType({ value: tagInfo.type })
    setTagId(tagInfo._id)
  }

  const resetForm = () => {
    setTagType('')
    setTagName('')
    setTagType(null)
    setTagId('')
  }

  useEffect(() => {
    if (!modal) {
      resetForm()
    }

    if (modal && editingTag && tagInfo) {
      setFormEditingTag()
    }

    modal && handleDisableSaveButton()
  }, [modal])

  useEffect(() => {
    handleDisableSaveButton()
    return () => handleDisableSaveButton()
  }, [tagType, tagName])

  useMemo(() => {
    if (editingTag) {
      modalToggle()
    }
  }, [editingTag])
  return (
    <>
      <AddButton tooltipText="Adicionar tag" onClick={modalToggle} />
      <Modal isOpen={modal} toggle={(toggle) => { modalToggle(toggle) }} centered className="call modal-dialog-zoom" size='lg' onClosed={(toggle) => { onCloseModal(toggle) }} zIndex={9999}>
        <button className={"btn btn-light btn-modal-close"} onClick={modalToggle}>
          <i className="fa fa-times"></i>
        </button>
        <ModalBody>
          <div className="call-background" style={{ backgroundColor: '#e6e6e6', filter: 'initial', opacity: 'initial' }}></div>
          <div>
            <h4 style={{ color: 'black' }}>{editingTag ? 'Editar tag' : 'Adicionar tag'}</h4>
            <div className="transfer-info-box" >
              <div style={{ padding: '0 50px', color: 'black', fontWeight: 'bold' }}>
                <Label for="name">Nome da tag:</Label>
                <div className="d-flex align-content-center w-100 mt-4">
                  <div style={{ width: '90px', important: true }}>
                    <Select
                      options={options}
                      name='tags'
                      closeMenuOnSelect={true}
                      noOptionsMessage={(e) => ''}
                      placeholder={
                        <div style={{ textAlign: 'left' }}>
                          <i className={`fa fa-tag text-${tagType?.value}`}
                            style={styleIcon} />
                        </div>
                      }
                      menuPortalTarget={document.querySelector('body')}
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 10000 }),
                        valueContainer: css => ({
                          ...css,
                          flexWrap: "nowrap", whiteSpace: "nowrap",
                          overflow: "hidden", textOverflow: 'ellipsis'
                        })
                      }}
                      isMulti={false}
                      isDisabled={false}
                      onChange={e => { setTagType(e) }}
                      components={{
                        Option: CustomOption,
                        SingleValue: CustomSingleValue,
                      }}
                    //value={settagType}
                    />
                  </div>
                  <div className="w-100" >
                    <Input type="text" name="name" id="name"
                      placeholder="Escreva o nome da tag"
                      style={{
                        maxHeight: 150,
                      }}
                      onChange={(e) => {
                        setTagName(e.target.value);
                      }}
                      autoComplete='off' value={tagName} />
                  </div>
                </div>
                <div className='text-right ml-auto'>
                  <button
                    type="button"
                    className="btn btn-primary mt-4 mr-1"
                    data-dismiss="modal"
                    disabled={disableSaveButton}
                    id={"edit-tag-btn"}
                    onClick={() => handleSave()}
                  >
                    Salvar
                  </button>
                  {/* {disableSaveButton && (
                    <Tooltip
                      placement="bottom"
                      target={"edit-tag-btn"}
                      isOpen={tooltipEdit}
                      toggle={() => tooltipEditBlur()}
                    >
                      {'Preencha todos os campos.'}
                    </Tooltip>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default AddTagModal
