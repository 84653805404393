import React, { useState } from 'react';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

const OptionsDropdown = ({ type, setShowQuickMessages }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle tag="a">
        <Button color="light" className="btn-floating" style={{ fontSize: 24 }}>
          +
        </Button>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => type('myContact')} className='my-0 px-2 pb-0' >
          <Button
            className="btn-floating btn-sm "
            style={{
              fontSize: '15px',
              backgroundColor: '#ea4c89',
            }}>
            <i className="fa fa-user text-white" aria-hidden="true" />
          </Button>
          <span className='m-1'>Meu contato</span>
        </DropdownItem>
        <DropdownItem onClick={() => type('contact')} className='my-0 px-2 pb-0'>
          <Button
            className="btn-floating btn-sm "
            style={{
              fontSize: '15px',
              backgroundColor: '#db4437',
            }}>
            <i className="fa fa-users text-white" aria-hidden="true" />
          </Button>
          <span className='m-1'>Contato </span>
        </DropdownItem>
        <DropdownItem onClick={() => type('doc')} className='my-0 px-2 pb-0'>
          <Button
            className="btn-floating btn-sm "
            style={{
              fontSize: '15px',
              backgroundColor: '#55acee',
            }}>
            <i className="fa fa-file text-white" aria-hidden="true" />
          </Button>
          <span className='m-1'>Documentos </span>
        </DropdownItem>
        <DropdownItem onClick={() => type('image')} className='my-0 px-2 pb-0'>
          <Button
            className="btn-floating btn-sm "
            style={{
              fontSize: '15px',
              backgroundColor: '#43d854',
            }}>
            <i className="fa fa-file-image-o text-white" aria-hidden="true" />
          </Button>
          <span className='m-1'>Fotos e vídeos</span>
        </DropdownItem>
        <DropdownItem onClick={() => setShowQuickMessages(true)} className='my-0 px-2 pb-0'>
          <Button
            className="btn-floating btn-sm "
            style={{
              fontSize: '15px',
              backgroundColor: '#dcb40c',
            }}>
            <i className="fa fa-fw fa-bolt text-white" aria-hidden="true" />
          </Button>
          <span className='m-1'>Respostas rápidas </span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
};

export default OptionsDropdown
