import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

export const TableHeader = ({ itemsTableHeader, theadClassName }) => {
  const orderedItemsTableHeader = itemsTableHeader.sort((a, b) => a.order - b.order)

  return (
    <thead className={theadClassName + ' thead'}>
      <tr>
        {orderedItemsTableHeader.map((item, i) => (
          <th key={i}>
            <span id={`header-${item.tooltipId}`}>{item.label}</span>
            <RenderTooltip item={item}/>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const RenderTooltip = ({ item }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <>
      {item.tooltip ? (
        <Tooltip
          placement="left"
          isOpen={tooltipOpen}
          target={`header-${item.tooltipId}`}
          toggle={toggleTooltip}
        >
          {item.tooltipText}
        </Tooltip>
      ) : null}
    </>
  )
}