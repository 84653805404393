import React, { useEffect, useState, useContext } from 'react';
import { Input, Label, Modal, ModalBody, ModalHeader, Tooltip, } from 'reactstrap';
import { getChannels, getTags, postStartAttendance } from '../../Services/Attendance-Service';
import Select from 'react-select';
import { PhoneInput } from 'react-international-phone';
import { isPhoneValid } from '../../Utils/phone-formatter';
import { postCustomer, putCustomer } from '../../Services/Contact-Service';
import { AttendanceContext } from '../../Context/AttendanceContext';
import { useToast } from '../Partials/Toast';
import { useDispatch } from 'react-redux';
import { sidebarAction } from '../../Store/Actions/sidebarAction';
import PhoneCode from './../../Pages/PhoneCode';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { mobileSidebarAction } from '../../Store/Actions/mobileSidebarAction';
import { selectedChatAction } from '../../Store/Actions/selectedChatAction';
import 'react-bootstrap-tagsinput/dist/index.css'
import { useRef } from 'react';
import { getAllGroupsById } from '../../Services/Group-Service';
import MultiSelect from '../Partials/MultiSelect';
import { useAuth, useCanAccessAction } from '../../Context/AuthContext';
import { getDepartmentByUserId } from '../../Services/Department-Service';
import ConfirmationModal from '../Components/ConfirmationModal';


import { styleIcon, styleIconType, CustomSingleValue } from './AddTagModal';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'

function AddCustomerModal({
  onAddCustomer,
  onEditCustomer,
  editingCustomer,
  customer,
  onCloseModal }) {

  const { startAttendance, setActiveChatCustomer } = useContext(AttendanceContext)
  const { showSuccessToast, showErrorToast } = useToast()
  const [channels, setChannels] = useState([])
  const [tags, setTags] = useState([])
  const [groups, setGroups] = useState([])
  const [name, setName] = useState('')
  const [externalIds, setExternalIds] = useState([])
  const [phone, setPhone] = useState('')
  const [disableEditPhone, setDisableEditPhone] = useState(false)
  const [choosedChannel, setChoosedChannel] = useState([])
  const [choosedTags, setChoosedTags] = useState([])
  const [choosedGroups, setChoosedGroups] = useState([])
  const [departmentsOperatorList, setDepartmentsOperatorList] = useState('')
  const [choosedDepartmentByOperator, setChoosedDepartmentOperator] = useState('')
  const [openConfirmationModal, setOpenConfirmationModal] = useState('');

  const dispatch = useDispatch();
  const history = useHistory()
  const ref = useRef()
  const decodedToken = useAuth()
  const canManageChat = useCanAccessAction('manage_chat')

  const [modal, setModal] = useState(false);

  const modalToggle = () => setModal(!modal);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipOpenExternalId, setTooltipOpenExternalId] = useState(false);
  const [tooltipOpenChoosedTags, setTooltipOpenChoosedTags] = useState(false);

  const tooltipToggle = () => setTooltipOpen(!tooltipOpen);
  const tooltipToggleExternalId = () => setTooltipOpenExternalId(!tooltipOpenExternalId);
  const tooltipToggleChoosedTags = () => setTooltipOpenChoosedTags(!tooltipOpenChoosedTags);

  const CustomOption = ({ innerProps, isFocused, data }) => (

    <div {...innerProps}
      style={{
        ...styleIconType,
        backgroundColor: isFocused ? "#e6e6e6" : "",
        textAlign: "left",
        margin: "5px"
      }}
    >
      <span
        style={{
          ...styleIconType,
        }}>{data.icon}</span>
      {data.label}
    </div>
  );

  const handleGetChannels = async () => {
    const res = await getChannels()
    setChannels(res.map(channel => {
      return {
        value: channel._id,
        label: channel.shortName || channel.name,
      }
    }))
    if (res.length === 1) {
      setChoosedChannel([{
        value: res[0]._id,
        label: res[0]?.shortName || res[0]?.name
      }])
    }
  }

  const handleGetOperators = async () => {
    const res = await getTags()
    setTags(res.map(tag => {
      return {
        value: tag._id,
        label: tag.name,
        type: tag.type,
        icon: <i className={`fa fa-tag text-${tag.type}`}
          style={styleIcon}
        />
      }
    }))
  }

  const handleDisableSaveButton = () => {
    const validPhone = isPhoneValid(phone)
    if (!name || !validPhone || !choosedChannel) return true

    return false
  }

  const handleDisableStartAttendance = () => {
    if (!canManageChat) return true;

    if (!choosedChannel) return true

    if (choosedChannel.length !== 1) return true

    const validPhone = isPhoneValid(phone)
    if (choosedChannel.length === 1 && !name && validPhone) return true

    if (choosedChannel.length === 1 && name && !validPhone) return true

    if (choosedChannel.length === 1 && !name && !validPhone) return true

    return false
  }

  const handleSave = async (editingCustomer, departmentsOperatorList, start = false, departmentId) => {
    const phoneWithoutFormatting = phone.replaceAll(' ', '').replace('(', '').replace(')', '').replaceAll('+', '').replaceAll('-', '')

    const body = {
      name,
      phone: phoneWithoutFormatting,
      channels: choosedChannel.map(channel => channel.value),
      tags: choosedTags ? choosedTags.map(tag => tag.value) : [],
      groups: choosedGroups ? choosedGroups.map(group => group.value) : [],
      externalIds
    }

    if (externalIds && externalIds.length > 2) {
      start ? showErrorToast('Não foi possível iniciar antes de salvar.\n Utilize apenas 2 códigos.') : showErrorToast('Não foi possível salvar.\n Utilize apenas 2 códigos.')
      return
    }

    if (choosedTags && choosedTags.length > 3) {
      start ? showErrorToast('Não foi possível iniciar antes de salvar.\n Utilize apenas 2 códigos.') : showErrorToast('Não foi possível salvar.\n Utilize apenas 3 tags.')
      return
    }

    try {
      const isEditing = editingCustomer

      if (isEditing) {
        let editedCustomer = { ...customer, ...body }
        editedCustomer.phone = editedCustomer.phone.replace('+', '')
        delete editedCustomer.channel

        const res = await putCustomer(customer.phone, editedCustomer);
        if (start) {
          if (departmentsOperatorList.length > 1 && choosedDepartmentByOperator === '') {
            setOpenConfirmationModal({ open: true, info: departmentsOperatorList });
            return
          }
          const { _id } = await postStartAttendance(customer.phone, res.channels[0]._id, departmentId.value);
          const newCustomer = { ...customer, fromCustomers: true, attendance: { id: _id } }
          setActiveChatCustomer(newCustomer)
          setTimeout(() => {
            dispatch(mobileSidebarAction(false));
            dispatch(selectedChatAction(newCustomer))
            dispatch(sidebarAction('Chats'))
          }, 100);
          history.push('/chat')
        } else {
          onEditCustomer(true)
          modalToggle()
        }
        showSuccessToast('Contato atualizado com sucesso.')
        return
      }

      const res = await postCustomer(body);

      if (start) {
        startAttendance(
          {
            ...res,
            channel: {
              id: res.channels[0]._id
            }
          },
        )
        modalToggle()
        dispatch(sidebarAction('Chats'))
      } else {
        onAddCustomer(true)
        modalToggle()
      }

      showSuccessToast('Contato adicionado com sucesso.')
    } catch (error) {
      console.error('An error occurred:', error)
    }
  }

  const setFormEditingCustomer = () => {
    setName(customer.name)
    setPhone(customer.phone)
    setExternalIds(customer.externalIds || [])
    setChoosedChannel(
      customer.channel.length ?
        customer.channel.map(channel => {
          return {
            value: channel.id,
            label: channel.name
          }
        }) : (
          [{
            value: customer.channel.id,
            label: customer.channel.name
          }]
        )
    )
    setChoosedGroups(
      customer.groups?.map(group => {
        return {
          value: group.id,
          label: group.name,
        }
      })
    )
    if (customer.tags?.length) {
      setChoosedTags(
        customer.tags?.map(tag => {
          return {
            value: tag.id,
            label: tag.name,
            type: tag.type
          }
        })
      )
    }
    if (customer.status === 'Invalid') {
      setDisableEditPhone(false)
    } else {
      setDisableEditPhone(true)
    }
  }

  const handleGetChoosedGroups = (selected) => {
    setChoosedGroups(selected)
  }

  const resetForm = () => {
    setChoosedChannel(null)
    setChoosedTags(null)
    setName('')
    setPhone('')
    setExternalIds([])
    setDisableEditPhone(false)
    setChoosedGroups([])
    setChannels([])
    setDepartmentsOperatorList('')
    setChoosedDepartmentOperator('')
  }

  const getDepartmentByIdOperator = async (id) => {
    const response = await getDepartmentByUserId(id)
    setDepartmentsOperatorList(response.map(department =>
    ({
      value: department._id,
      label: department.name,
    })));
    if (response.length === 1) {
      setChoosedDepartmentOperator({
        value: response[0]?._id,
        label: response[0]?.name
      })
    }
  }

  useEffect(() => {
    const id = decodedToken._id
    if (!modal) {
      resetForm()
    }
    if (modal && editingCustomer && customer) {

      setFormEditingCustomer()
    }
    modal && handleGetChannels()
    modal && handleGetOperators()
    modal && getDepartmentByIdOperator(id)
  }, [modal])

  useEffect(() => {
    const handleGetGroupsChannel = async (channelId) => {
      const res = await getAllGroupsById(channelId);
      return res;
    };

    const fetchGroups = async () => {
      if (choosedChannel) {
        try {
          const res = await Promise.all(choosedChannel.map(channel => handleGetGroupsChannel(channel?.value)));
          setGroups(res.flat().map(group => {
            return {
              value: group._id,
              label: group.name,
            }
          }))
        } catch (error) {
          console.error('Error fetching groups:', error);
        }
      }
    };

    fetchGroups();
  }, [choosedChannel]);

  useMemo(() => {
    if (editingCustomer && customer) {
      modalToggle()
    }
  }, [editingCustomer, customer])

  return (
    <>
      <button className={"btn btn-primary font-weight-bold w-auto"} onClick={modalToggle} id={"add-customer"}>
        Adicionar <i className={"fa fa-plus ml-auto pl-2"} aria-hidden="true"></i>
      </button>
      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={"add-customer"}
        toggle={tooltipToggle}>
        {'Adicionar contato'}
      </Tooltip>
      <Modal isOpen={modal} toggle={(toggle) => { modalToggle(toggle) }} zIndex={990}
        centered className="call modal-dialog-zoom" size='lg' onClosed={(toggle) => { onCloseModal(toggle) }}>
        <button className={"btn btn-light btn-modal-close"} onClick={modalToggle}>
          <i className="fa fa-times"></i>
        </button>
        <ModalBody>
          <div className="call-background" style={{ backgroundColor: '#e6e6e6', filter: 'initial', opacity: 'initial' }}></div>
          <div>
            <h4 style={{ color: 'black' }}>{editingCustomer ? 'Editar contato' : 'Adicionar contato'}</h4>
            <div className="transfer-info-box">
              <div style={{ padding: '0 50px', color: 'black', fontWeight: 'bold' }}>
                <div className='d-flex justify-content-between text-left'>
                  <div className="w-100 text-left ml-2">
                    <Label for="name" className='mt-4'>Nome do contato:</Label>
                    <Input type="text" name="name" id="name" style={{ maxHeight: 200 }} onChange={(e) => setName(e.target.value)} autoComplete='off' value={name} />
                  </div>
                  <div className="w-100 text-left ml-2">
                    <Label for="phone" className='mt-4'>Telefone do contato:</Label>
                    <PhoneInput
                      countrySelectorStyleProps={{
                        buttonClassName: 'btn-light btn btn-sm',
                        dropdownStyleProps: {
                          className: 'phone-list shadow-md',
                          listItemClassName: 'phone-item-list'
                        }
                      }}
                      forceDialCode
                      className='phone-input'
                      inputClassName='form-control'
                      defaultCountry="br"
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                      disabled={disableEditPhone}
                    />
                  </div>
                </div>
                <div className='d-flex justify-content-between text-left'>
                  <div className="w-100 text-left ml-2 mt-4" style={{ maxWidth: '49%' }}>
                    <Label for="channels">Selecionar canal(is):</Label>
                    {channels.length > 1 && !choosedChannel ?
                      <Select
                        options={channels}
                        name='channels'
                        closeMenuOnSelect={false}
                        noOptionsMessage={(e) => 'Nenhum canal disponível.'}
                        placeholder="Selecione"
                        classNamePrefix={'multiselect'}
                        menuPortalTarget={document.querySelector('body')}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), valueContainer: css => ({ ...css, flexWrap: "nowrap", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' }) }} isMulti={channels.length > 1}
                        onChange={e => { setChoosedChannel(e) }}
                        value={choosedChannel}
                      />
                      : <Select
                        options={channels}
                        name='channels'
                        value={choosedChannel}
                        closeMenuOnSelect={false}
                        noOptionsMessage={(e) => 'Nenhum canal disponível.'}
                        placeholder="Selecione"
                        classNamePrefix={'multiselect'}
                        menuPortalTarget={document.querySelector('body')}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), valueContainer: css => ({ ...css, flexWrap: "nowrap", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' }) }} isMulti={channels.length > 1}
                        onChange={e => setChoosedChannel(e)}
                      />}
                  </div>
                  <div className="d-flex align-content-center w-100 mt-4 ml-2" style={{ maxWidth: '49%', maxheight: '10px' }}>
                    <div className="w-100">
                      <Label for="groups">Selecionar grupos:</Label>
                      {groups.length > 1 ?
                        (
                          <MultiSelect
                            key='example_group'
                            options={groups}
                            onChange={handleGetChoosedGroups}
                            value={choosedGroups}
                            isSelectAll={true}
                            menuPlacement={'bottom'}
                            placeholder='Selecione'
                            selectAllName='Selecionar todos os grupos'
                            firstNameSelect='Todos os'
                            lastNameSelec='grupos selecionados'
                            isMult={false}
                            messageForSingular='grupo selecionado'
                            messageForPlural='grupos selecionados'
                          />
                        ) : (
                          <Select
                            options={groups}
                            name='groups'
                            closeMenuOnSelect={false}
                            noOptionsMessage={(e) => 'Nenhum grupo disponível.'}
                            placeholder="Selecione"
                            classNamePrefix={'multiselect'}
                            menuPortalTarget={document.querySelector('body')}
                            styles={{
                              menuPortal: base => ({
                                ...base, zIndex: 9999
                              }),
                              valueContainer: css => ({
                                ...css,
                                flexWrap: "nowrap",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: 'ellipsis'
                              })
                            }}
                            isMulti={true}
                            isDisabled={groups?.length ? false : true}
                            onChange={e => setChoosedGroups(e)}
                          />
                        )}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-end">
                  <div className="w-100 ml-2 text-left">
                    <Label for="tags" className='mt-4'>Selecionar tags:</Label>
                    <div className="d-flex align-items-center">
                      <div className="w-100">
                        <Select
                          options={tags}
                          name='tags'
                          closeMenuOnSelect={false}
                          noOptionsMessage={(e) => 'Nenhuma tag disponível.'}
                          placeholder={choosedTags ? choosedTags.map((choosedTag) => (choosedTag.label)) : 'Selecione'}
                          classNamePrefix={'multiselect'}
                          menuPortalTarget={document.querySelector('body')}
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            valueContainer: css => ({
                              ...css,
                              flexWrap: "nowrap",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: 'ellipsis',
                              maxWidth: '30vw'

                            })
                          }}
                          isMulti={true}
                          isDisabled={tags?.length ? false : true}
                          onChange={e => setChoosedTags(e)}
                          components={{
                            Option: CustomOption,
                            SingleValue: CustomSingleValue,
                          }}
                          value={choosedTags}
                        />
                      </div>
                      <i className={"fa fa-question bg-dark p-2 rounded-lg"} aria-hidden="true" id="choosedTags-tooltip" style={{ marginLeft: 10, marginRight: -10 }}
                        ref={ref}></i>
                    </div>
                    <Tooltip
                      placement="bottom"
                      isOpen={tooltipOpenChoosedTags}
                      target="choosedTags-tooltip"
                      toggle={tooltipToggleChoosedTags}>
                      Máximo de 3 tags por contato.
                    </Tooltip>
                  </div>
                </div>
                <div className="d-flex align-items-end">
                  <div className="w-100 ml-2 text-left">
                    <Label for="externalIds" className='mt-4'>Código(s) do contato:</Label>
                    <div className="d-flex align-items-center input-tags">
                      <TagsInput value={externalIds} onChange={setExternalIds} addOnBlur maxTags={2} onlyUnique inputProps={{ placeholder: '' }} />
                      <i className={"fa fa-question bg-dark p-2 rounded-lg"} aria-hidden="true" id="externalIds-tooltip" style={{ marginLeft: 10, marginRight: -10 }}
                        ref={ref}></i>
                    </div>
                    <Tooltip
                      placement="bottom"
                      isOpen={tooltipOpenExternalId}
                      target="externalIds-tooltip"
                      toggle={tooltipToggleExternalId}>
                      Utilizado para referenciar o contato a sistemas externos.
                      Máximo de 2 códigos.
                    </Tooltip>
                  </div>
                </div>
                <div className='text-right ml-auto'>
                  <button
                    type="button"
                    className="btn btn-secondary mt-4 mr-1"
                    disabled={handleDisableStartAttendance()}
                    onClick={() => handleSave(editingCustomer, departmentsOperatorList, true, choosedDepartmentByOperator)}
                  >
                    Iniciar atendimento
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary mt-4 "
                    data-dismiss="modal"
                    disabled={handleDisableSaveButton()}
                    onClick={() => handleSave(editingCustomer)}
                  >
                    Salvar
                  </button>
                  <ConfirmationModal
                    openConfirmationModal={openConfirmationModal}
                    setOpenConfirmationModal={setOpenConfirmationModal}
                    setChoosedOption={setChoosedDepartmentOperator}
                    choosedOption={choosedDepartmentByOperator}
                    listOptions={departmentsOperatorList}
                    editing={editingCustomer}
                    confirmationFunction={() => handleSave(editingCustomer, openConfirmationModal.info, openConfirmationModal.open, choosedDepartmentByOperator)}
                    messageModal='Selecione o departamento para iniciar o atendimento'
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal >
    </>
  )
}

export default AddCustomerModal
