import React from 'react';
import { Label } from 'reactstrap';
import DatePickerComponent from './DatepickerComponent';

const DatepickerCampaign = ({
  hasOffer,
  schedulingDate,
  filterTime,
  selectedDateInitial,
  selectedDateFinal,
  setSelectedDateInitial,
  setSelectedDateFinal,
  customInput,
  isDisabled }) => {
    
  return (
    <>
      {hasOffer ? (
        <>
          <div className="w-100 text-left ml-2">
            <Label className='mt-4'>Data e hora início:</Label>
            <div className="text-left">
              <DatePickerComponent
                onFocus={() => { }}
                filterTime={filterTime}
                selectedDate={selectedDateInitial}
                setSelectedDate={setSelectedDateInitial}
                placeholderText={'Data e hora início'}
                customInput={customInput}
                schedulingDate={schedulingDate}
                isDisabled={isDisabled}
              //dateOptions={schedulingDate}
              />
            </div>
          </div>
          <div className="w-100 text-left ml-2">
            <Label className='mt-4'>Data e hora final:</Label>
            <div className="text-left">
              <DatePickerComponent
                filterTime={filterTime}
                selectedDate={selectedDateFinal}
                setSelectedDate={setSelectedDateFinal}
                placeholderText={'Data e hora final'}
                customInput={customInput}
                schedulingDate={schedulingDate}
                isDisabled={isDisabled}
              //dateOptions={schedulingDate} 
              />
            </div>
          </div>
        </>
      ) : (
        <div className="text-left ml-2">
          <Label className='w-100 mt-4'>Data e hora:</Label>
          <DatePickerComponent
            onFocus={() => {}}
            filterTime={filterTime}
            selectedDate={selectedDateInitial}
            setSelectedDate={setSelectedDateInitial}
            placeholderText={'Data e hora'}
            schedulingDate={schedulingDate}
            customInput={customInput}
            isDisabled={isDisabled}
          />
        </div>
      )}
    </>
  );
}

export default DatepickerCampaign;
