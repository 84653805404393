import React, { useContext, useState } from "react";
import { convertText } from "../../../Utils/convert-text";
import MessageActions from "./MessageActions";
import { timestampToHour } from "../../../Utils/timestamp";
import { Ack } from "../Ack";
import MessageAttachments from "./MessageAttachments";
import { parseVCARD } from "../../../Utils/parse-vcard";
import DefaultImage from "../../../assets/img/no-image.png"
import { MessageReaction } from "./MessageReaction";
import { MessageContext } from "../../../Context/MessageContext";
import { MessageReactionIcon } from "./MessageReactionIcon";
import { AttendanceContext } from "../../../Context/AttendanceContext";

export const DeletedMessage = () => {
  return (
    <div className="message-content">
      <i className="fa fa-ban"></i> Mensagem apagada.
    </div>
  )
}

const RepliedMessage = ({ message, activeChatCustomer }) => {
  return (
    <>
      {message.quotedMsg ?
        <span style={{ backgroundColor: '#e6e6e6', padding: 10, borderRadius: 5 }}>
          <p style={{ margin: 0, fontWeight: 'bold' }}>{message.quotedMsg.origin === 'customer' ? activeChatCustomer.name : 'Você'}</p>
          {message.quotedMsg.attachment ? ShowAttachment(message) : convertText(message.quotedMsg.body)}
        </span>
        : null}
    </>
  )
}

const ShowAttachment = (message) => {
  const { quotedMsg } = message;
  const attachment = quotedMsg.attachment;
  const { attachmentName } = quotedMsg
  if (attachment && attachment.includes('.jpg')) {
    return (
      <div className="d-flex flex-column">
        Anexo
        <img
          src={attachment}
          alt="Imagem"
          width={100}
          height={100}
          style={{ objectFit: 'cover' }}
        />
      </div>
    );
  } else {
    return <div>
      <i className="fa fa-file mr-1" />
      {attachmentName ? attachmentName : 'Anexo'}
    </div>;
  }
};


const VcardMessage = ({ message }) => {
  const vcadsString = message.body
  const pattern = /BEGIN:VCARD[\s\S]*?END:VCARD/g;
  const vcards = vcadsString?.match(pattern);
  if (!vcards) return

  return (
    <div>
      {vcards.map((vcard, i) => {
        const parsedVcard = parseVCARD(vcard);
        return (
          <>
            <div key={i} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <img
                className="rounded-circle"
                style={{ cursor: 'pointer', marginRight: 10 }}
                src={DefaultImage}
                alt={'Imagem vcard'}
                width={40}
                height={40}
              />
              <p style={{ margin: '0 auto' }}>
                {parsedVcard.fullName} <br />
                {parsedVcard.phoneNumber}
              </p>
            </div>
            {i === vcards.length - 1 ? '' : <hr className="my-0 p-1" />}
          </>
        );
      })}
    </div>
  );
}

const PrivateMessage = ({ message }) => {
  return (
    <div className="text-center info">
      <p>
        <strong><i className="fa fa-lock"></i> Mensagem Interna.</strong>
      </p>
      <p>Operador: {message.fromName}</p>
      <p>Departamento: {message.departmentName}</p>
      <p>{message.body}</p>
    </div>
  )
}

const UniqueDate = ({ message }) => {
  return (
    <small className="text-center">
      <strong>{message.uniqueDate}</strong>
    </small>
  )
}

const Message = ({ message }) => {
  if (message.hasMedia) return <MessageAttachments message={message} />

  if (message.type === 'vcard') return <VcardMessage message={message} />

  if (message.type === 'private') return <PrivateMessage message={message} />

  if (message.type === 'uniqueDate') return <UniqueDate message={message} />

  return (
    <span>
      {convertText(message.body)}
    </span>
  )
}
const MessageItem = ({ message, activeChatCustomer, i, replyingMessage, showMessageHistoryModal, deleteMessage, editMessage }) => {
  const { showActions, setShowActions } = useContext(MessageContext)
  const { chatReadOnly } = useContext(AttendanceContext)

  const showActionsOrEmojis = (showActions.actions || showActions.emojiOpened) && showActions.id === message._id && !chatReadOnly.readOnly

  const isNotPrivate = message.type !== 'private'

  const shouldShowActions = showActions.actions && showActions.id === message._id && !showMessageHistoryModal && isNotPrivate && !chatReadOnly.readOnly

  return (
    <div
      className={message.fromMe && isNotPrivate ? 'message-item outgoing-message' : !isNotPrivate ? 'message-item message-private' : message.uniqueDate ? 'message-item message-date' : 'message-item'}
      key={i}
      id={i === 0 ? 'lastMessage' : ''}
    >
      {
        message.deletedAt || message.deleted
          ? <DeletedMessage />
          :
          <div style={!message.uniqueDate ? { display: 'flex', alignItems: 'center', gap: 10, flexDirection: message.fromMe ? 'row' : 'row-reverse' } : {}}
            onMouseEnter={() => { isNotPrivate && !message.uniqueDate && setShowActions({ actions: true, id: message._id, emojiOpened: showActions.emojiOpened }) }}
            onMouseLeave={() => { isNotPrivate && !message.uniqueDate && setShowActions({ actions: false, id: message._id, emojiOpened: showActions.emojiOpened }) }}>
            {isNotPrivate && !message.uniqueDate ? <MessageReaction message={message} showActionsOrEmojis={showActionsOrEmojis && !showMessageHistoryModal} /> : null}
            <div
              className={"message-content " + (message.hasMedia ? 'message-file' : '') + (message.type === 'vcard' ? 'vcard' : '')}
              style={{ position: 'relative' }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5, wordWrap: "break-word" }}>
                <RepliedMessage message={message} activeChatCustomer={activeChatCustomer} />
                <Message message={message} />
                {message.reactions ? <MessageReactionIcon reactions={message.reactions} fromMe={message.fromMe} /> : null}
              </div>
              {shouldShowActions ?
                <MessageActions customer={activeChatCustomer} replyingMessage={replyingMessage} deleteMessage={() => deleteMessage(message)} message={message} editMessage={(message) => editMessage(message)} />
                : null}
            </div>
          </div>
      }
      {isNotPrivate && !message.uniqueDate ?
        <div className="message-action">
          {message.isEdited ? <span className="mr-1">Editado</span> : null}
          {timestampToHour(message.timestamp, true)}
          {message.fromMe ? <Ack ack={message.ack} /> : null}
        </div>
        : null}
    </div>
  );
};

export default MessageItem