import React, { useState, useMemo, useRef, useEffect } from "react";
import { InputGroup, InputGroupText, Input, InputGroupAddon, Label, Modal, ModalBody } from 'reactstrap';

import Emojis from "../Partials/Emojis";

const AddQuickMessageModal = ({
    onCloseModal,
    editingQuickMessage,
    quickMessageInfo,
    handleSaveQuickMessage,
    addQuickMessage,
    setAddQuickMessage
}) => {
    const [modal, setModal] = useState(false);
    const [inputMsg, setInputMsg] = useState('')
    const [messageSubmitted, setMessageSubmitted] = useState('')
    const [paddingInput, setPaddingInput] = useState('')
    const [shortcut, setShortcut] = useState('')

    const modalToggle = () => setModal(!modal);
    const inputRef = useRef(null)

    const handleChange = (e) => {
        setInputMsg(e)
    }

    const handleEnter = (e) => {
        if (e.keyCode === 13 && e.shiftKey) {
            if (e.type === 'keypress' || e.type === 'keydown') {
                pasteIntoInput('\n')
            }
            e.preventDefault()
        }
    }

    const pasteIntoInput = (text) => {
        const el = inputRef.current
        el.focus()

        if (typeof el.selectionStart === 'number' && typeof el.selectionEnd === 'number') {
            const val = el.value
            const selStart = el.selectionStart
            el.value = val.slice(0, selStart) + text + val.slice(el.selectionEnd)
            el.selectionEnd = el.selectionStart = selStart + text.length
        } else if (typeof document.selection !== 'undefined') {
            const textRange = document.selection.createRange()
            textRange.text = text
            textRange.collapse(false)
            textRange.select()
        }
    }

    const setFormEditingQuickMessage = () => {
        setShortcut(quickMessageInfo?.shortcut)
        setInputMsg(quickMessageInfo?.message)
    }

    const resetForm = () => {
        setShortcut('')
        setInputMsg('')
        setAddQuickMessage(false)
    }

    useEffect(() => {
        if (!modal) {
            resetForm()
        }

        if (modal && editingQuickMessage && quickMessageInfo) {
            setFormEditingQuickMessage()
            return
        }

    }, [modal])

    useMemo(() => {
        if (editingQuickMessage) {
            modalToggle()
        }
    }, [editingQuickMessage])


    useEffect(() => {
        if (addQuickMessage) {
            modalToggle()
        }
    }, [addQuickMessage])

    return (
        <>
            <Modal isOpen={modal} toggle={(toggle) => { modalToggle(toggle) }} centered className="call modal-dialog-zoom" size='lg' onClosed={(toggle) => { onCloseModal(toggle) }} zIndex={9999}>
                <button className={"btn btn-light btn-modal-close"} onClick={modalToggle}>
                    <i className="fa fa-times"></i>
                </button>
                <ModalBody>
                    <div className="call-background" style={{ backgroundColor: '#e6e6e6', filter: 'initial', opacity: 'initial' }}></div>
                    <div>
                        <h4 style={{ color: 'black' }}>{editingQuickMessage ? 'Editar resposta rápida' : 'Adicionar resposta rápida'}</h4>
                        <div className="transfer-info-box" >
                            <div style={{ padding: '0 50px', color: 'black', fontWeight: 'bold' }}>
                                <Label for='nameCampaign' className='mt-4'>Atalho:</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>/</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type='text'
                                        placeholder='Atalho...'
                                        name='Shortcut'
                                        id='name'
                                        style={{ maxHeight: 200 }}
                                        onChange={(e) => setShortcut(e.target.value)}
                                        autoComplete='off'
                                        value={shortcut || ''}
                                    />
                                </InputGroup>
                                <Label for='nameCampaign' className='mt-4'>Mensagem:</Label>
                                <div className='d-flex align-items-center'>
                                    <Emojis
                                        onChange={handleChange}
                                        inputMsg={inputMsg}
                                        messageSubmitted={messageSubmitted}
                                        closeOnChange pickerBoxStyle={{ bottom: 0, left: 130, top: 60 }} />
                                    <Input
                                        type='textarea'
                                        onKeyDown={handleEnter}
                                        ref={inputRef}
                                        className='form-control'
                                        placeholder='Resposta rápida...'
                                        value={inputMsg || ''}
                                        onChange={(e) => { setInputMsg(e.target.value) }}
                                        id='input-chat'
                                        style={{ paddingRight: paddingInput, resize: 'none', height: 100, border: 0, marginLeft: 10 }}
                                        autoComplete='off'

                                    />
                                </div>

                            </div>
                            <div className='text-right ml-auto'>
                                <button
                                    type="button"
                                    className="btn btn-primary mt-4 mr-1"
                                    data-dismiss="modal"
                                    id={"edit-tag-btn"}
                                    onClick={() => {
                                        handleSaveQuickMessage({ inputMsg, shortcut, messageId: quickMessageInfo?._id }, editingQuickMessage);
                                        modalToggle();
                                    }}
                                >
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal >
        </>
    )

}

export default AddQuickMessageModal