import React from 'react'

export const MultiTextCell = ({ cell }) => {
  const { item, property, subProperty, index, width, action } = cell

  const getMultiTextContent = () => {
    if (item[property]) {
      return item[property].map((text) => text[subProperty]).join(', ')
    }
    return ''
  }

  const multiTextContent = getMultiTextContent()

  return (
    <td height={70} key={index} width={width} onClick={() => action ? action(item) : null}>
      <p className='m-0 overflow-hidden text-nowrap' style={{ width, textOverflow: 'ellipsis' }} title={multiTextContent}>{multiTextContent}</p>
    </td>
  )
}
