import React from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { ImageCell } from './TableCells/ImageCell'
import { LongTextCell } from './TableCells/LongTextCell'
import { TextCell } from './TableCells/TextCell';
import { MultiTextCell } from './TableCells/MultiTextCell';
import { CustomComponentCell } from './TableCells/CustomComponentCell';
import { ActionsCell } from './TableCells/ActionsCell';

const CellType = {
  image: 'image',
  longText: 'longText',
  text: 'text',
  multiText: 'multiText',
  customComponent: 'customComponent',
  actions: 'actions',
};

export const TableDataItem = ({ item, cellsTableData }) => (
  cellsTableData && cellsTableData.map((cellsToShow, index) => (
    renderContent(cellsToShow.type, cellsToShow.property, index, item, cellsTableData)
  ))
)

const renderContent = (type, property, index, item, cellsTableData) => {
  const orderedCellsTableData = cellsTableData.sort((a, b) => a.order - b.order);
  const { width, relative, subProperty, childrenItem, imageHeight, imageWidth, className, mask, action } = orderedCellsTableData.find((data) => data.property === property) || {}
  const propertyExist = item.hasOwnProperty(property)
  const cellProperties = {
    index,
    item,
    width,
    relative,
    propertyExist,
    childrenItem,
    imageHeight,
    imageWidth,
    className,
    subProperty,
    type,
    property,
    cellsTableData,
    mask,
    action
  }

  switch (type) {
    case CellType.image:
      return <ImageCell cell={cellProperties}/>
    case CellType.longText:
      return <LongTextCell cell={cellProperties}/>
    case CellType.text:
      return <TextCell cell={cellProperties}/>
    case CellType.multiText:
      return <MultiTextCell cell={cellProperties}/>
    case CellType.customComponent:
      return <CustomComponentCell cell={cellProperties}/>
      case CellType.actions:
        return <ActionsCell cell={cellProperties}/>
    default:
      return null;
  }
}