import React, { createContext, useState, useEffect } from "react";
import { getLoggedDetails } from "../Services/User-Service";

export const UserContext = createContext({
    settings: {},
    setSettings: () => { },
    getLoggedDetailsSettings: () => { },
});

export const UserProvider = ({ children }) => {
    const [settings, setSettings] = useState('');

    const getLoggedDetailsSettings = async () => {
        const response = await getLoggedDetails();
        const notification = response?.settings?.notification;
        setSettings(notification);
    };

    return (
        <UserContext.Provider value={{ settings, setSettings, getLoggedDetailsSettings }}>
            {children}
        </UserContext.Provider>
    );
};

export const UserContextWrapper = ({ children }) => {
    return (
        <UserProvider>
            {children}
        </UserProvider>
    );
};
