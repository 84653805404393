import React from 'react'
import DefaultImage from '../../../../assets/img/no-image.png'
import { ChildrenItemCell } from './ChildrenItemCell'

export const ImageCell = ({cell}) => {
  const { index, width, relative, className, item, imageWidth, imageHeight, childrenItem, action } = cell

  return (
    <td height={70} key={index} width={width} className={relative ? 'position-relative' : ''} onClick={() => action ? action(item) : null}>
      <img
        className={className}
        src={item.image || DefaultImage}
        alt={item.name}
        width={imageWidth}
        height={imageHeight}
        onError={(e) => { e.target.src = DefaultImage }}
      />
      {childrenItem && ChildrenItemCell(item, childrenItem)}
    </td>
  )
}