import React from 'react';
import { Row, Col, Card, CardText, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DefaultImage from "../../../assets/img/no-image.png"

export const CardsLayoutCustomer = ({
  data,
  loading,
  handleShowProfile,
  setOpenModalEditCustomer,
  handleStartAttendance,
  handleCustomerMessages,
  handleDropdown,
  setOpenConfirmationModal
}) => {

  const toggle = (data) => {
    handleDropdown(data);
  };
  if (loading) {
    return (
      <div>
        <i className="fa fa-exclamation-circle" style={{ fontSize: '7em', color: '#ebebeb' }}></i>
        <p style={{ fontSize: '1.2em' }}>No momento não há registros.</p>
      </div>
    );
  };

  return (
    <div>
      {data.map((item, i) => (
        <div>
          <Card key={i} className='w-100 p-3 bg-light' style={{
            borderBottom: '2px solid #6161ff',
            borderRadius: '10px',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none'
          }}>
            <Row className='d-flex align-items-start mb-2 bg-primary' style={{ borderRadius: '10px' }}>
              <Col className='justify-content-start p-2'
                onClick={() => handleShowProfile(item)} style={{
                  display: 'inline-block',
                }}>
                <CardText style={{ display: 'flex' }}>
                  <img src={item.image || DefaultImage} alt="Imagem" className="rounded-circle mr-2" style={{ width: 40, height: 40 }} />
                  <strong
                    style={{
                      fontSize: '18px',
                      width: '110px',
                      alignContent: 'space-evenly',

                    }}>{item.name}</strong><br />
                </CardText>
              </Col>
              <Col className='justify-content-center p-2 text-center ml-auto'>
                <Dropdown isOpen={item.dropdownOpen} toggle={() => toggle(item)}>
                  <DropdownToggle className='text-light' tag="a">
                    <i className={'fa fa-ellipsis-v my-3 '}></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleShowProfile(item)}>
                      Perfil
                    </DropdownItem>
                    {item.showOptionStartAttendance &&
                      <DropdownItem onClick={() => handleStartAttendance(item)}>
                        Iniciar atendimento
                      </DropdownItem>
                    }
                    <DropdownItem onClick={() => handleCustomerMessages(item)}>
                      Visualizar conversa
                    </DropdownItem>
                    <DropdownItem onClick={() => setOpenModalEditCustomer({ open: true, customer: item })}>
                      Editar
                    </DropdownItem>
                    <DropdownItem onClick={() => setOpenConfirmationModal({ open: true, customer: item, isDelete: true })}>
                      Excluir
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
            <Row className='d-flex align-items-start mb-2'>
              <Col className='d-flex justify-content-start p-2' onClick={() => handleShowProfile(item)}>
                <CardText>
                  <strong style={{ fontSize: '18px' }}>Número</strong><br />
                  {item.phoneFormatted}
                </CardText>
              </Col>
              <Col className='d-flex justify-content-center p-2 text-center' onClick={() => handleShowProfile(item)}>
                <CardText>
                  <strong style={{ fontSize: '18px' }}>Canal</strong><br />
                  {item.channel.name}
                </CardText>
              </Col>
            </Row>
            <Row className='d-flex align-items-start mb-2'>
              <Col className='d-flex justify-content-start p-2' onClick={() => handleShowProfile(item)}>
                <CardText>
                  <strong style={{ fontSize: '18px' }}>Grupos</strong><br />
                  {item.groups.length}
                </CardText>
              </Col>
              <Col className='d-flex justify-content-center p-2' onClick={() => handleShowProfile(item)}>
                <CardText>
                  {item?.tags?.length ? (
                    <>
                      <strong style={{ fontSize: '18px' }}>Tags</strong>
                      <br />
                      {item.tags.map((tag, i) => (
                        <>
                          <span key={i}>{tag.name}</span><br />
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <strong style={{ fontSize: '18px' }}>Tags</strong>
                      <br />
                      {'Sem tag'}
                    </>
                  )}
                </CardText>
              </Col>
            </Row>
          </Card>
        </div>
      ))}
    </div>
  );
};