import React, { useMemo } from "react"
import { AudioPlayer } from "../AudioPlayer"
import { useState } from 'react'
import { Image } from "@zzwing/react-image";
import PDFViewer from 'mgr-pdf-viewer-react';
import { ModalBody } from 'reactstrap'
import { useToast } from "../Toast";
import { saveAs } from 'file-saver';

const MessageAttachments = ({ message, isEditContent }) => {
  const { showInfoToast, showWarningToast } = useToast()
  const [scale, setScale] = useState(0.15);

  const imageStyle = useMemo(() => {
    return isEditContent
      ? { width: '100%', height: '100%' }
      : { width: 300, height: 300 };
  }, [isEditContent]);

  const downloadAttachmentFunction = async (message, filename = 'arquivo') => {

    if (!message || !message.attachment) {
      showWarningToast('Falha no download.');
      return;
    }
    try {
      const response = await fetch(message.attachment);
      if (!response.ok) {
        throw new Error('Erro na requisição do anexo.');
      }
      const blob = await response.blob();
      saveAs(blob, filename);
    } catch (error) {
      console.error('Erro ao baixar o anexo:', error);
      showWarningToast('Ocorreu um erro ao tentar baixar o anexo.');
    }
  };

  if (message?.type === 'video' || message?.mediaType === 'video/mp4') {
    return (
      message?.mediaType === 'video/mp4' ?
        <>
          <ModalBody style={{
            backgroundColor: '#e6e6e6',
            position: 'absolute',
            marginLeft: '3vw',
            marginTop: '-30px',
            borderRadius: '10px',
            boxShadow: '0 0 5px black',
            zIndex: '9999'
          }}>

            <div style={{ position: 'relative', width: '250px', height: '300px' }}>
              <video
                src={message.content || ''}
                controls
                width={'100%'}
                height={300}
                controlsList="nodownload noplaybackrate nopictureinpicture"
                disablePictureInPicture
                preload="metadata"
              />
            </div>
          </ModalBody>
        </>
        :
        <>
          <video
            src={message.attachment || ''}
            controls
            width={'100%'}
            height={300}
            controlsList="nodownload noplaybackrate nopictureinpicture"
            disablePictureInPicture
            preload="metadata"
          />
          <div className="flex items-center">
            {message.body}
          </div>
        </>
    )
  }

  if (message?.type === 'image' || message?.mediaType === "image/jpeg" || message?.mediaType === "image/png") {
    return (
      message?.type === 'image' ?
        <div
          onClick={() => {
            message.attachment ? showInfoToast('Use o scroll do mouse para dar zoom!', 3000, '', '', 'top-left') :
              showWarningToast('Falha na visualização. \n Por favor, peça para que seja reenviado.')
          }}
          style={{ cursor: 'pointer' }}>
          <Image
            src={message.attachment ? message.attachment : 'Erro'}
            alt={isEditContent ? 'ImageForEditText' : message.body}
            //width={300} height={300}
            style={{ objectFit: 'contain', ...imageStyle }} />
          <div className="flex items-center">
            {message.body}
          </div>
        </div>
        :
        <ModalBody style={{
          backgroundColor: '#e6e6e6',
          position: 'absolute',
          marginLeft: '3vw',
          marginTop: '-30px',
          borderRadius: '10px',
          boxShadow: '0 0 5px black',
          maxWidth: "150px",
          maxHeight: "150px",
          objectFit: 'contain',
        }}>
          <div
            onClick={() => {
              message.content ?
                showInfoToast('Use o scroll do mouse para dar zoom!', 3000, '', '', 'top-left') :
                showWarningToast('Falha na visualização. \n Por favor, peça para que seja reenviado.')
            }}
          >
            <Image src={message.content ? message.content : 'Erro'}
              alt={message.body}
              width={120} height={120}
              style={{
                objectFit: 'contain',
              }}
            />
          </div>
        </ModalBody>


    )
  }

  if (message?.type === 'ptt' || message?.type === 'audio') {
    return (
      <AudioPlayer src={message.attachment || ''} />
    )
  }

  if (message?.type === 'document' || message?.mediaType === "application/pdf") {
    return (
      message?.type === 'document' ?
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ margin: '0 auto' }}>
            <i className="fa fa-file mr-1" onClick={() => downloadAttachmentFunction(message, message?.attachmentName)} style={{ fontSize: '18px', cursor: 'pointer' }} />
            {message.attachmentName ? message.attachmentName : 'Arquivo'}
            {message?.body && (
              <>
                <hr />
                {message.body}
              </>
            )}
          </p>
        </div>
        :
        <ModalBody style={{
          backgroundColor: '#e6e6e6',
          position: 'absolute',
          marginLeft: '3vw',
          marginTop: '-30px',
          borderRadius: '10px',
          boxShadow: '0 0 5px black',
          zIndex: '9999'
        }}
        >
          <div>
            <PDFViewer
              loader={
                <i className="fa fa-spinner text-black" style={{ animation: 'spin 3s linear infinite', fontSize: 40, height: 'fit-content' }}></i>}
              document={{
                url: message?.content
              }}
              hideNavbar
              scale={scale}
            />
            <a style={{
              display: 'flex',
              gap: 5,
              alignItems: 'center',
              cursor: 'pointer',

            }}
              href={message?.content}
              target="_blank"
              rel="noopener noreferrer">
              <i className="fa fa-download" ></i>
              {
                message.body ? message.body : 'Download'
              }
            </a>

          </div>

        </ModalBody>
    )
  }
}

export default MessageAttachments