import React, { useState, useEffect } from 'react';
import { Label, Input } from 'reactstrap';
import Select from 'react-select';
import { AudioPlayer } from '../Partials/AudioPlayer';
import MessageAttachments from '../Partials/Message/MessageAttachments';

const PreviewCampaignComponent = ({
    campaign, }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [imgHover, setImgHover] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isVisible && !event.target.closest('#closeDocument')) {
                setIsVisible(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isVisible]);

    switch (campaign.mediaType) {
        case 'video/mp4':
        case 'image/jpeg':
        case 'application/pdf':
        case 'image/png':
            return <div className="d-flex align-items-end">
                <i className="fa fa-eye index-mobile"
                    aria-hidden="true"
                    style={{ fontSize: '20px', marginLeft: '10px' }}
                    onClick={() => {
                        setIsVisible(!isVisible);
                        setImgHover(false);
                    }}>
                    <div  style={{ fontSize: '16px', fontFamily: 'Nunito, sans-serif' }}>
                        {imgHover && !isVisible && <MessageAttachments message={campaign} />}
                        {isVisible && <MessageAttachments message={campaign} />}
                    </div>
                </i>
            </div>
        case 'audio/mp3':
            return <i className="fa fa-play"
                aria-hidden="true"
                style={{
                    fontSize: '20px',
                    marginLeft: '10px'
                }}
                onClick={() => {
                    setIsVisible(!isVisible);
                    setImgHover(false);
                }}>
                {imgHover && !isVisible && <AudioPlayer src={campaign.content} campaingStyle={true} />}
                {isVisible && <AudioPlayer src={campaign.content} campaingStyle={true} />}
            </i>
        default:
            return <i className="fa fa-eye-slash" aria-hidden="true" style={{ fontSize: '20px', marginLeft: '10px' }} />
    }
}

export default PreviewCampaignComponent