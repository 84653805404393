const dateOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  timeZone: 'America/Sao_Paulo'
}

const timeOptions = {
  hour: '2-digit',
  minute: '2-digit',
  timeZone: 'America/Sao_Paulo'
}

const formatTime = (date) => {
  return date.toLocaleTimeString('pt-BR', timeOptions);
};

export const formatDate = (date) => {
  return date.toLocaleDateString('pt-BR', dateOptions);
};

export const formatTimeString = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString('pt-BR', timeOptions);
};

export const formatDateString = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('pt-BR', dateOptions);
};

const areDatesEqual = (date1, date2) => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};

const getYesterday = (date) => {
  const yesterday = new Date(date);
  yesterday.setDate(date.getDate() - 1);
  return yesterday;
};

export const timestampToHour = (timestamp, ignoreDate = false) => {
  const currentDate = new Date();
  const messageDate = new Date(timestamp * 1000);

  if (areDatesEqual(messageDate, currentDate) && !ignoreDate) {
    return formatTime(messageDate);
  }

  const yesterday = getYesterday(currentDate);
  if (areDatesEqual(messageDate, yesterday) && !ignoreDate) {
    return 'Ontem';
  }

  if (ignoreDate) return formatTime(messageDate)

  return formatDate(messageDate);
};

export const timestampToDate = (timestamp) => {
  return formatDate(new Date(timestamp * 1000))
}

export const timestampToDateWithHour = (timestamp) => {
  const date = formatDate(new Date(timestamp * 1000))
  const time = formatTime(new Date(timestamp * 1000))

  return `${date} ${time}`
}

export const formatDateForId = (inputDate,stringComplement) => {
  const [day, month, year] = inputDate.split('/');
  return `${stringComplement}${year}${month}${day}`;
}
