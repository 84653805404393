import React, { useMemo, useState } from "react";
import ChatHeader from "../Chat/ChatHeader";
import MessageItem from "./MessageItem";
import ChatFooter from "../Chat/ChatFooter";
import PerfectScrollbar from "react-perfect-scrollbar"
import { formatDateForId, timestampToDate } from "../../../Utils/timestamp";

function MessageHistory({ messageHistory,
  activeChatCustomer,
  handleMessageHistory,
  handleSubmit,
  handleChange,
  inputMsg,
  scrollRef,
  deleteMessage,
  showLoading,
  lastMessage,
  chatReadOnly,
  editMessage,
  setAcceptedFiles,
  acceptedFiles
}) {
  const [replyingMessage, setReplyingMessage] = useState()

  function areSameDate(timestamp1, timestamp2) {
    const date1 = new Date(timestamp1 * 1000);
    const date2 = new Date(timestamp2 * 1000);

    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }


  function formatDate(message) {
    const timestampInMilliseconds = message.timestamp * 1000;
    const date = new Date(timestampInMilliseconds);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const memoizedMessages = useMemo(() => {
    const updatedMessageHistory = [...messageHistory];

    if (messageHistory.length > 1) {
      const lastMessage = messageHistory[messageHistory.length - 1];
      const previousLastMessage = messageHistory[messageHistory.length - 2];
      const sameDate = areSameDate(lastMessage.timestamp, previousLastMessage.timestamp);

      if (!sameDate && lastMessage?.type !== "uniqueDate") {
        const formattedDate = formatDate(lastMessage);
        const formattedDateString = String(formattedDate)
        const messageForPush = {
          id: `${formatDateForId(timestampToDate(lastMessage.timestamp), 'uniqueDate_')}`,
          formattedDate: formattedDateString,
          uniqueDate: formattedDateString,
          type: "uniqueDate",
          timestamp: lastMessage.timestamp
        };
        updatedMessageHistory.splice(messageHistory.length - 1, 0, messageForPush);
      }
    }
    return updatedMessageHistory.map((message, i) => (
      <React.Fragment key={message._id || message.id}>
        <MessageItem
          message={message}
          activeChatCustomer={activeChatCustomer}
          i={i}
          replyingMessage={setReplyingMessage}
          showMessageHistoryModal={false}
          editMessage={(message) => editMessage(message)}
          deleteMessage={(message) => deleteMessage(message)}
        />
      </React.Fragment>
    ));
  }, [messageHistory, activeChatCustomer]);


  return (
    <React.Fragment>
      <ChatHeader selectedChat={activeChatCustomer} />
      {showLoading && !lastMessage ?
        <div style={{ textAlign: 'center' }}>
          <i className="fa fa-spinner" style={{ animation: 'spin 3s linear infinite', fontSize: 42 }}></i>
        </div>
        : null}
      <PerfectScrollbar containerRef={ref => scrollRef.current = ref} onScrollY={(ref) => ref.scrollTop === 0 ? handleMessageHistory() : null}>
        <div className="chat-body">
          <div className="messages" style={{ paddingBottom: 5 }}>
            {messageHistory ? memoizedMessages : null}
          </div>
        </div>
      </PerfectScrollbar>
      {!chatReadOnly?.readOnly ?
        <ChatFooterMemoized
          onSubmit={(e) => {
            handleSubmit(e, replyingMessage)
            setReplyingMessage(undefined)
          }}
          onChange={handleChange}
          inputMsg={inputMsg}
          replyingMessage={replyingMessage}
          cancelReply={() => setReplyingMessage(undefined)}
          acceptedFiles={acceptedFiles}
          setAcceptedFiles={setAcceptedFiles}
        />
        : null}
    </React.Fragment>
  );
}

const ChatFooterMemoized = React.memo(ChatFooter);

export default MessageHistory