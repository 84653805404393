import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

function DeleteMessageModal({openDeleteMessageModal, setOpenDeleteMessageModal, deleteMessage}) {
  return (
    <Modal zIndex={9999} isOpen={openDeleteMessageModal?.open} toggle={() => setOpenDeleteMessageModal(null)} centered className="call modal-dialog-zoom">
      <ModalBody>
        <div className="call-background" style={{ backgroundColor: '#e6e6e6', filter: 'initial', opacity: 'initial' }}></div>
        <div>
          <h4 style={{ color: 'black' }}>Deseja apagar a mensagem?</h4>
          <div>
            <button type="button" onClick={() => setOpenDeleteMessageModal(null)}
              className="btn btn-secondary btn-xs" style={{marginRight: 10}}
              data-dismiss="modal">
              Cancelar
            </button>
            <button type="button" onClick={() => deleteMessage(openDeleteMessageModal.message)}
              className="btn btn-primary btn-xs">
              Confirmar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DeleteMessageModal
