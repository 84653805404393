import React from "react";
import ProgressBar from "./ProgressBar";

const ChannelStatusCampaignCheck = ({ channel }) => {

    if (channel.status!== "created") {
        return (
            <div style={{marginBottom: "35px", marginLeft: "5px"}}>
                <ProgressBar value={parseFloat(channel.percentage)} />
            </div>
        )
    }

}

export default ChannelStatusCampaignCheck;