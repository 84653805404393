import React, { useEffect } from 'react'
import MicRecorder from 'mic-recorder-to-mp3'
import { Button } from 'reactstrap'
import { useToast } from './Toast'


const AudioRecorder = ({
  isBlocked,
  setIsBlocked,
  blobURL,
  setBlobURL,
  isRecording,
  setIsRecording,
  setBlob,
  setStartTimeCounter,
  Mp3Recorder
}) => {
  const { showErrorToast, showAdvertenceToast } = useToast()
  const isMobile = document.body.clientWidth < 768

  const start = () => {

    if (isBlocked) {
      showErrorToast('Permissão negada. Autorize a gravação de áudios no seu navegador.')
    } else {
      setStartTimeCounter(true)
      setBlobURL('')
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true)
        })
        .catch((e) => console.error(e))
    }
  }

  const stop = () => {
    setStartTimeCounter(false)
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob)
        setBlob(blob)
        setBlobURL(blobURL)
        setIsRecording(false)
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true })
    .then(() => {
      setIsBlocked(false);
    })
    .catch((error) => {
      showAdvertenceToast('Para habilitar a gravação de áudio é necessário autorização no seu navegador.', 3000, undefined, 'audioContext');
      setIsBlocked(true);
    });
  }, [])

  return (
    <>
      {!isRecording && !blobURL && (
        <Button color="light" className="btn-floating" onClick={start}>
          <i className="fa fa-microphone"></i>
        </Button>
      )}
      {isRecording && (
        <Button color="light" className="btn-floating" onClick={stop} id="stop-recording">
          <i className="fa fa-pause" style={{ color: 'red' }}></i>
        </Button>
      )}

      {blobURL ?
        <>
          <Button color="light" className="btn-floating"
            onClick={() => {
              setBlobURL();
            }}>
            <i className="fa fa-trash"></i>
          </Button>
          <div className='my-1' style={{ width: isMobile ? '200px' : '' }}>
            <audio src={blobURL}
              style={{
                width: isMobile ? '100%' : '',
                maxHeight: '30px',
                marginTop:'6px'
              }}
              controlsList={`nofullscreen nodownload noremoteplayback noplaybackrate ${isMobile ? 'novolume' : ''}`}
              controls id="audio-recorder" />
          </div>
        </>
        : null}
    </>
  )
}

export default AudioRecorder
