import React, { useState, useEffect, Fragment } from 'react'
import { TableDataItem } from './TableDataItem';

export const TableData = ({ data, i, cellsTableData }) => {
  return (
    <Fragment key={i}>
      <tr key={i} style={{ borderSpacing: 20, borderCollapse: 'separate' }}>
        <TableDataItem item={data} cellsTableData={cellsTableData}/>
      </tr>
      <tr style={{ opacity: 0, cursor: 'default' }}><td></td></tr>
    </Fragment>
  )
}