import api from './Axios.config'

const url = process.env.REACT_APP_API_URL + '/api/configuration/v1'

export const getAllTags = async () => {
  try {
    const response = await api.get(`${url}/tags`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get all tags')
  }
}


export const postTag = async (body) => {
  
  try {
    const response = await api.post(`${url}/tags`, body)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to create tag')
  }
}

export const deleteTag = async (payload) => {
  try {
    const response = await api.delete(`${url}/tags/${payload.id}`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to delete tags')
  }
}

export const uptadeTag = async (payload) => {
  try {
    const response = await api.put(`${url}/tags/${payload.id}`,payload.body, {
    })
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to update tags')
  }
}

