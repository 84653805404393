import React from 'react';
import { Row, Col, Card, CardText, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DefaultImage from "../../../assets/img/no-image.png";

export const CardsLayoutChannels = ({
  data,
  loading,
  handleDropdown,
  handleStatusClassName,
  translateStatusIcon,
  qrCodes,
  ChannelAction
  }) => {
  const toggle = (data) => {
    handleDropdown(data);
  };
  
  if (loading) {
    return (
      <div>
        <i className="fa fa-exclamation-circle" style={{ fontSize: '7em', color: '#ebebeb' }}></i>
        <p style={{ fontSize: '1.2em' }}>No momento não há registros.</p>
      </div>
    );
  };

  return (
    <div>
      {data.map((item, i) => (
        <div key={i}>
          <Card className='w-100 p-3 bg-light' style={{
            borderBottom: '2px solid #6161ff',
            borderRadius: '10px',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none'
          }}>
            <Row className='d-flex align-items-start mb-2 bg-primary' style={{ borderRadius: '10px' }}>
              <Col className='d-flex justify-content-start p-2'>
                <CardText>
                  <strong style={{ fontSize: '18px' }}>Número</strong><br />
                  {item.cellPhone}
                </CardText>
              </Col>
              <Col className='d-flex justify-content-center p-2'>
                <CardText>
                  <img src={item.image || DefaultImage} alt="Imagem" className="rounded-circle mr-2" style={{ width: 40, height: 40 }} />
                </CardText>
              </Col>
            </Row>
            <Row className='d-flex align-items-start mb-2'>
              <Col className='d-flex justify-content-start p-2 text-start'>
                <CardText>
                  <strong style={{ fontSize: '18px' }}>Nome</strong><br />
                  {item.name}
                </CardText>
              </Col>
              <Col className='d-flex justify-content-center p-2'>
                <CardText>
                  <strong style={{ fontSize: '18px' }}>
                    <span className={handleStatusClassName(item)}>
                        {translateStatusIcon(item)}
                    </span>
                    </strong><br />
                    <p>Status</p>
                </CardText>
              </Col>
            </Row>
            <Row className='d-flex align-items-start mb-2'>
              <Col className='d-flex justify-content-start p-2'>
                <Dropdown isOpen={item.dropdownOpen} toggle={() => toggle(item)}>
                  <DropdownToggle tag="a">
                    <i className={'fa fa-ellipsis-v mt-3'}></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => ChannelAction(item)}>
                        <ChannelAction channel={data} qrCodes={qrCodes} loading={loading}/>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
          </Card>
        </div>
      ))}
    </div>
  );
};