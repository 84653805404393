import React, { useState, useEffect } from 'react';
import { Col, Row, Card, CardTitle, CardBody } from 'reactstrap';
import { getTotalCampaign, getTotalContact, getTotalNotification } from '../Services/ReportsCampaign';

const CardsCampaign = () => {
  const [contacts, setContacts] = useState(0);
  const [campaign, setCampaign] = useState(0);
  const [notification, setNotification] = useState(0);

  const handleGetTotalCampaign = async () => {
    try {
      const res = await getTotalCampaign();
  
      let totalCampaign = 0;
  
      Object.keys(res).forEach(key => {
        totalCampaign += res[key];
      });

      const formattedTotal = totalCampaign.toFixed(0).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      setCampaign(formattedTotal);
      
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetTotalContact = async () => {
    try {
      const res = await getTotalContact();

      let totalContact = 0;

      Object.keys(res).forEach(key => {
        totalContact += res[key];
      });

      const formattedTotal = totalContact.toFixed(0).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      setContacts(formattedTotal);

    } catch (error) {
      console.error(error);
    }
  };

  const handleGetTotalNotification = async () => {
    try {
      const res = await getTotalNotification();
    
      let totalNotification = 0;

      res.campaigns.forEach(item => {
        totalNotification += item.totalNotifications;
      });
      
      const formattedTotal = totalNotification.toFixed(0).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      setNotification(formattedTotal);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetTotalCampaign();
    handleGetTotalContact();
    handleGetTotalNotification();
  }, []);

  return (
    <Row className='d-flex p-2' style={{gap:'5px'}}>
      <Col>
        <Card style={{backgroundColor:'#00bf63', color:'#fff'}}>
          <CardTitle style={{fontSize:'20px'}} className='p-2 text-center'>
            Contatos
          </CardTitle>
          <CardBody style={{fontSize:'52px', textAlign:'center', fontWeight:'600'}}>
            {contacts}
          </CardBody>
        </Card>
      </Col>
      <Col>
        <Card style={{backgroundColor:'#6161ff', color:'#fff'}}>
          <CardTitle style={{fontSize:'20px'}} className='fs-5 p-2 text-center'>
            Campanhas
          </CardTitle>
          <CardBody style={{fontSize:'52px', textAlign:'center', fontWeight:'600'}}>
            {campaign}
          </CardBody>
        </Card>
      </Col>
      <Col>
        <Card style={{backgroundColor:'#ff914d',color:'#fff'}}>
          <CardTitle style={{fontSize:'20px'}} className='fs-5 p-2 text-center'>
            Notificações
          </CardTitle>
          <CardBody style={{fontSize:'52px', textAlign:'center', fontWeight:'600'}}>
            {notification}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CardsCampaign;