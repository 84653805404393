import React from 'react'

export const TextCell = ({ cell }) => {
  const { item, property, subProperty, index, width, mask, action } = cell

  const getContent = () => {
    if (subProperty) {
      return item[property]?.[subProperty]
    } else if (property) {
      return item[property]
    }
    return '-'
  }

  const content = getContent()

  return (
    <td height={70} key={index} width={width} onClick={() => action ? action(item) : null}>
      <span>{mask ? mask(content) : content}</span>
    </td>
  )
}