import React from 'react';
import { Label, Input } from 'reactstrap';
import Select from 'react-select';

const MediaTypeComponent = ({
    mediaTypeList,
    setDetails,
    setChoosedTypeMedia,
    choosedTypeMedia,
    setDetailsEdit,
    isDisabled
}) => {
    return (
        <>
            {mediaTypeList.length > 0 ? (
                <>
                    <Label className='mt-4'>Tipo de mídia:</Label>
                    <Select
                        options={mediaTypeList} 
                        placeholder="Selecione o tipo do arquivo"
                        classNamePrefix={'multiselect'}
                        menuPortalTarget={document.querySelector('body')}
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            valueContainer: css => ({
                                ...css,
                                flexWrap: "nowrap",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: 'ellipsis',
                                backgroundColor: isDisabled ? '#e9ecef' : '',
                                border:isDisabled ? '1px solid #ced4da' : '', 
                            })
                        }}
                        onChange={e => { setDetails(''); setChoosedTypeMedia(e); setDetailsEdit(''); }}
                        value={choosedTypeMedia}
                        isDisabled={isDisabled}
                    />
                </>
            ) : (
                <>
                    <Label className='mt-4'
                        placeholder="Selecione o tipo do arquivo">Tipo de mídia:</Label>
                    <Input
                        disabled={true}
                        placeholder={'Sem opção disponível'}
                    />
                </>
            )}
        </>
    );
}

export default MediaTypeComponent;
