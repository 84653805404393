import React, { useContext, useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { getAllTags } from '../../Services/Tag-Service';
import { postTagToContact, deleteTagToContact } from '../../Services/Contact-Service';
import PerfectScrollbar from "react-perfect-scrollbar";
import { AttendanceContext } from '../../Context/AttendanceContext';

const boxContactStyle = {
  display: 'flex',
  gap: 10,
  alignItems: 'center',
  padding: '10px 0',
  borderTop: '1px solid lightgray',
  borderLeft: '3px solid transparent',
  borderRight: '3px solid transparent',
  borderBottom: '3px solid transparent',
  cursor: 'pointer',
}

const boxContactsStyle = {
  color: 'black',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 20
}

function ContactsTagList({ setOpenTagListContactModal }) {
  const [tagList, setTagList] = useState([]);
  const { activeChatCustomer, setActiveChatCustomer } = useContext(AttendanceContext)
  const [selectedTags, setSelectedTags] = useState(
    activeChatCustomer?.tags?.map(tag => {
      return {
        ...tag,
        _id: tag.id
      }
    })
  );

  const handleGetTags = async () => {
    try {
      const res = await getAllTags()
      setTagList(res)
    } catch (error) {
      console.error(error)
    }
  };

  useEffect(() => {
    handleGetTags()
  }, []);

  const handleAssignTag = async (tag) => {
    const updatedTags = [...selectedTags || ''];
    const checkedTag = updatedTags?.find(item => item._id === tag._id || item._id === tag.id);

    if (checkedTag) {
      updatedTags.splice(updatedTags.indexOf(checkedTag), 1);
      try {
        await deleteTagToContact(activeChatCustomer.channel.id, activeChatCustomer.phone, tag._id);
        setActiveChatCustomer({
          ...activeChatCustomer,
          tags: updatedTags
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      if (updatedTags.length < 3) {
        try {
          const body = {
            channelId: activeChatCustomer.channel.id,
            tagId: tag._id
          };
          await postTagToContact(body, activeChatCustomer.phone);
          updatedTags.push({ ...tag, id: tag._id });
          setActiveChatCustomer({
            ...activeChatCustomer,
            tags: updatedTags
          });
        } catch (error) {
          console.error(error);
        }
      }
    }

    setSelectedTags(updatedTags);
  };

  return (
    <div style={boxContactsStyle}>
      {!tagList.length ?
        <div style={{ textAlign: 'center' }}>
          <h4>Nenhuma tag disponível.</h4>
          <button type="button" onClick={() => setOpenTagListContactModal(null, false)}
            className="btn btn-danger btn-xs" style={{ marginRight: 10 }}
            data-dismiss="modal">
            Fechar
          </button>
        </div>
        :
        <>
          <div className="list-inline d-flex justify-content-between">
            <h4 className="mt-2">Tags</h4>
            <div className="list-inline-item">
              <a onClick={() => setOpenTagListContactModal(null, false)}
                className="btn btn-light">
                <i className="ti ti-close"></i>
              </a>
            </div>
          </div>
          <PerfectScrollbar style={{ height: '80%', overflowY: 'scroll' }}>
            {tagList.map((tag) => {
              const checkedTag = Boolean(selectedTags?.find(item => item._id === tag._id || item._id === tag.id))

              return (
                <div key={tag._id} style={boxContactStyle}>
                  <input
                    type="checkbox"
                    onChange={() => handleAssignTag(tag)}
                    checked={checkedTag}
                    style={{ marginLeft: '20px' }}
                  />
                  <span><i className={`fa fa-tag text-${tag.type}`} style={{ marginLeft: '20px', transition: 'color 0.3s ease' }} /></span>
                  <span>{tag.name}</span>
                </div>
              )
            }
            )}
          </PerfectScrollbar>
        </>
      }
    </div>
  );
}

function TagListContactModal({ openTagListContactModal, setOpenTagListContactModal }) {
  return (
    <Modal zIndex={9999} isOpen={openTagListContactModal} toggle={() => setOpenTagListContactModal(null, false)} centered className="modal-dialog-zoom call">
      <ModalBody style={{ height: '90vh', backgroundColor: '#e6e6e6', padding: 50, textAlign: 'center' }}>
        <ContactsTagList setOpenTagListContactModal={setOpenTagListContactModal} />
      </ModalBody>
    </Modal>
  );
}

export default TagListContactModal;