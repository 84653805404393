import React, { useContext, useMemo, useState } from 'react'
import { Button } from "reactstrap";
import { ReactComponent as EmojiSVG } from "../../assets/emoji.svg";
import { EmojiPickerBox } from './EmojiPickerBox';
import { MessageContext } from './../../Context/MessageContext';
import { useEffect } from 'react';

const boxOpenedEmojis = {
  position: 'relative'
}

const openedEmojis = {
  position: 'absolute',
  bottom: 60,
  left: 0,
  zIndex: 3
}

const Emojis = ({ onChange, inputMsg, messageSubmitted, closeOnChange, pickerBoxStyle }) => {
  const [showEmojisList, setShowEmojisList] = useState(false)
  const { setOpenOverlay, overlayClicked, setClickOverlay } = useContext(MessageContext)

  const onChangeEmoji = ({emoji}) => {
    onChange(inputMsg + emoji)
    if(closeOnChange){
      setShowEmojisList(false)
    }
  }

  useMemo(() => {
    if(messageSubmitted){
      setShowEmojisList(false)
    }
  }, [messageSubmitted])
  
  useMemo(() => {
    if(overlayClicked){
      setShowEmojisList(false)
      setOpenOverlay(false)
      setClickOverlay(false)
    }
  }, [overlayClicked])

  return (
    <div>
      <Button color="light" className="btn-floating" onClick={() => {setShowEmojisList(!showEmojisList);setOpenOverlay(true)}} style={showEmojisList ? boxOpenedEmojis : {}}>
        <EmojiSVG />
      </Button>
      {showEmojisList ?
        <div style={showEmojisList ? {...openedEmojis, ...pickerBoxStyle} : {}}>
          <EmojiPickerBox onChangeEmoji={(emoji) => onChangeEmoji(emoji)}/>
        </div>
      : null}
    </div>
  )
}

export default Emojis