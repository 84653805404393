const ValidationCampaignStatus = (campaign) => {

    const hasSomeScheduled = campaign?.channels?.some((c) => {
        return c.status === "scheduled"
    })

    const hasSomeSended = campaign?.channels?.some((c) => {
        return c.status === "sended"
    })

    const hasSomeInProgress = campaign?.channels?.some((c) => {
        return c.status === "inProgress"
    })

    const allCreated = campaign?.channels?.every((c) => {
        return c.status === "created"
    })

    if (campaign?.deactivatedAt) return true

    if (hasSomeSended || hasSomeInProgress) return true

    if (campaign?.types && campaign?.types[0] === "offer" && campaign?.dateTo) {
        let currentDate = new Date();
        currentDate.setMinutes(currentDate.getMinutes());
        const startDateCampaign = new Date(campaign.dateFrom)
        const closingDateCampaign = new Date(campaign.dateTo)
        if (currentDate > closingDateCampaign) return true
        if (currentDate < closingDateCampaign && currentDate > startDateCampaign) return false
    }

    if (allCreated) return false
    
    if (hasSomeScheduled) return false



}
export default ValidationCampaignStatus;
