import React, { useEffect, useState, useMemo } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { getAllContacts, getContacts } from '../../Services/Contact-Service';
import PerfectScrollbar from "react-perfect-scrollbar"
import DefaultImage from "../../assets/img/no-image.png"

const boxContactStyle = {
  display: 'flex',
  gap: 10,
  alignItems: 'center',
  padding: '10px 0',
  borderTop: '1px solid lightgray',
  borderLeft: '3px solid transparent',
  borderRight: '3px solid transparent',
  borderBottom: '3px solid transparent',
  cursor: 'pointer',
}

const boxContactsStyle = {
  color: 'black',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 20
}

function ContactsList({ selectedContact, contacts, setOpenVcardModal, setSelectedContact, handleInfiniteContacts, page }) {

  return (
    <>
      <div style={boxContactsStyle}>
        {!contacts.length ?
          <div style={{ textAlign: 'center' }}>
            <h4>Nenhum contato disponível.</h4>
            <button type="button" onClick={() => setOpenVcardModal(null, false)}
              className="btn btn-danger btn-xs" style={{ marginRight: 10 }}
              data-dismiss="modal">
              Fechar
            </button>
          </div>
          :
          <>
            <h4>Lista de contatos:</h4>
            <PerfectScrollbar style={{ height: '80%', overflowX: 'hidden !important' }} onYReachEnd={(e) => { handleInfiniteContacts(page + 1) }}>
              {contacts.map((contact, i) => (
                <div key={contact.phone+i} style={boxContactStyle} onClick={() => setSelectedContact(contact)} className={selectedContact === contact ? 'selected-contact' : null}>
                  <img
                    className="rounded-circle" style={{ cursor: 'pointer', marginRight: 10 }}
                    src={contact.image || DefaultImage}
                    alt={'Imagem usuário ' + contact.name}
                    width={40}
                    height={40}
                    onClick={null}
                    onError={(e) => e.target.src = DefaultImage}
                  />
                  <span>{contact.name ? contact.name : contact.phoneFormatted}</span>
                </div>
              ))}
            </PerfectScrollbar>
            <div style={{ textAlign: 'center' }}>
              <button type="button" onClick={() => setOpenVcardModal(null, false)}
                className="btn btn-secondary btn-xs" style={{ marginRight: 10 }}
                data-dismiss="modal">
                Cancelar
              </button>
              <button type="button" onClick={() => setOpenVcardModal(selectedContact, true)}
                className="btn btn-primary btn-xs">
                Confirmar
              </button>
            </div>
          </>
        }
      </div>
    </>
  );
}

function SendSelfContact({ setOpenVcardModal }) {
  return (
    <>
      <div>
        <h4 style={{ color: 'black' }}>Deseja encaminhar seu contato?</h4>
        <div>
          <button type="button" onClick={() => setOpenVcardModal(null, false)}
            className="btn btn-secondary btn-xs" style={{ marginRight: 10 }}
            data-dismiss="modal">
            Cancelar
          </button>
          <button type="button" onClick={() => setOpenVcardModal(null, true)}
            className="btn btn-primary btn-xs">
            Confirmar
          </button>
        </div>
      </div>
    </>
  );
}

function VcardModal({ openVcardModal, setOpenVcardModal, sendSelfContact = false }) {
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState({});
  const [page, setPage] = useState(1)
  const [maxPages, setMaxPages] = useState()

  const handleGetContacts = async () => {

    if(sendSelfContact || maxPages < page) return

    const { data, meta } = await getContacts(page);
    setContacts(data);
    setMaxPages(meta.pages)
  }

  const handleInfiniteContacts = async (page) => {
    if(sendSelfContact || maxPages < page) return
    const { data, meta } = await getContacts(page);
    setContacts(prev => [...prev, ...data])
    setMaxPages(meta.pages)
    setPage(page)
  }

  useEffect(() => {

    if (!openVcardModal){
      setPage(1)
      setMaxPages()
    };
    if (openVcardModal){
      handleGetContacts();
    }
  }, [openVcardModal]);

  return (
    <Modal zIndex={9999} isOpen={openVcardModal} toggle={() => setOpenVcardModal(null, false)} centered className="modal-dialog-zoom call">
      <ModalBody style={{ height: sendSelfContact ? 'auto' : '90vh', backgroundColor: '#e6e6e6', padding: 50, textAlign: sendSelfContact ? 'center' : 'none' }}>
        {openVcardModal ?
          sendSelfContact ?
            <SendSelfContact setOpenVcardModal={setOpenVcardModal} />
            : <ContactsList
              selectedContact={selectedContact}
              contacts={contacts}
              setOpenVcardModal={setOpenVcardModal}
              setSelectedContact={setSelectedContact}
              page={page}
              handleInfiniteContacts={(page) => handleInfiniteContacts(page)}
            />
          : null}
      </ModalBody>
    </Modal>
  );
}

export default VcardModal;
