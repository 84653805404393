const mobileMainQueueSidebarReducer = (state = true, action) => {
    switch (action.type) {
        case 'MOBILE_MAIN_QUEUE':
            return !state;
        default:
            return state
    }
};

export default mobileMainQueueSidebarReducer;
