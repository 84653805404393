const mobileQueuesSidebarReducer = (state = true, action) => {
    switch (action.type) {
        case 'MOBILE_QUEUES':
            return !state;
        default:
            return state
    }
};

export default mobileQueuesSidebarReducer;
