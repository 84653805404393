import React from "react"
import { Card, CardFooter, CardTitle } from "reactstrap"
import { formatDateDashboard } from "./PieChart"

export const Cards = ({notifiedClients, dueTodayData, reminderData, delayedData, serasaData}) => {
  const cards = [
    {
      title: `Vencendo hoje`,
      subtitle: 'Parcelas',
      value: dueTodayData?.numberOfInvoices || 0,
      type: 'primary'
    },
    {
      title: `Notificações`,
      subtitle: 'Clientes',
      value: notifiedClients || 0,
      type: 'success'
    },
    {
      title: `A vencer: ${reminderData?.dueDate ? formatDateDashboard(reminderData.dueDate) : ''}`,
      subtitle: 'Parcelas',
      value: reminderData?.numberOfInvoices || 0,
      type: 'info'
    },
    {
      title: `Vencido: ${delayedData?.dueDate ? formatDateDashboard(delayedData.dueDate) : ''}`,
      subtitle: 'Parcelas',
      value: delayedData?.numberOfInvoices || 0,
      type: 'warning'
    },
    {
      title: `A ser negativado: ${serasaData?.dueDate ? formatDateDashboard(serasaData.dueDate) : ''}`,
      subtitle: 'Parcelas',
      value: serasaData?.numberOfInvoices || 0,
      type: 'danger'
    },
  ]

  return (
    <div className="grid-cards">
      {cards.map((card, i) => (
        <Card body className={'border-0 p-2 bg-'+card.type} key={i}>
          <CardTitle tag="p">
            {card.title}
          </CardTitle>
          <CardFooter className="border-0 bg-transparent text-center p-0">
            <p style={{fontSize: 32, margin: '-10px 0'}} className="font-weight-bold">
              {card.value}
            </p>
            <span className="m-0">
              {card.subtitle}
            </span>
          </CardFooter>
        </Card>
      ))}
    </div>
  )
}