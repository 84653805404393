import React from 'react'
import PerfectScrollbar from "react-perfect-scrollbar"
import { useDispatch, useSelector } from "react-redux"
import { mobileProfileAction } from "../../Store/Actions/mobileProfileAction"
import { profileAction } from "../../Store/Actions/profileAction"
import DefaultImage from "../../assets/img/no-image.png"
import { useEffect } from 'react';
import { useContext } from 'react';
import { AttendanceContext } from '../../Context/AttendanceContext';

function Profile() {
  const { setActiveChatCustomer, setChatReadOnly } = useContext(AttendanceContext)
  const dispatch = useDispatch();

  const { profileSidebar, mobileProfileSidebar, selectedChat } = useSelector(state => state);
  const profileActions = (e) => {
    e.preventDefault();
    dispatch(profileAction(false))
    dispatch(mobileProfileAction(false))
  };

  const translateAttendanceStatus = (type) => {
    switch (type) {
      case 'waitingAttendance':
        return 'Aguardando atendimento'
      case 'autoAttendance':
        return 'Auto atendimento'
      default:
        return 'Em atendimento'
    }
  }

  const showPushname = () => {
    if (selectedChat.pushname) {
      if (selectedChat.pushname === selectedChat.name) {
        return
      }

      return selectedChat.pushname
    }
  }

  const chatRoute = window.location.href.includes('/chat')

  useEffect(() => {
    if (selectedChat._id && selectedChat.contact) {
      if (selectedChat.attendance?.type === 'inAttendance') {
        setActiveChatCustomer(selectedChat)
        setChatReadOnly({ readOnly: true, from: 'contact' })
      }
    }
  }, [selectedChat])

  return (
    <div className={`sidebar-group ${mobileProfileSidebar ? "mobile-open" : ""}`}>
      <div className={profileSidebar ? `sidebar active mr-0 ${chatRoute && 'ml-4'}` : 'sidebar'}>
        <header>
          <span>Sobre</span>
          <ul className="list-inline">
            <li className="list-inline-item">
              <a href="/#/" onClick={(e) => profileActions(e)}
                className="btn btn-light">
                <i className="ti ti-close"></i>
              </a>
            </li>
          </ul>
        </header>
        <div className="sidebar-body">
          <PerfectScrollbar>
            <div className="text-center">
              <figure className="avatar avatar-xl mb-4">
                <img src={selectedChat.image || DefaultImage} className="rounded-circle" alt="avatar" onError={(e) => e.target.src = DefaultImage} />
              </figure>
              <h5 className="text-primary mb-1 px-3">{selectedChat.name}</h5>
              <small className="text-muted">{showPushname()}</small>
            </div>
            {selectedChat.attendance ?
              <>
                {selectedChat.attendance.type ?
                  <div className="pl-4 pr-4">
                    <h6>Status</h6>
                    <p className="text-muted">{translateAttendanceStatus(selectedChat.attendance.type)}</p>
                  </div>
                  : null}
                {selectedChat.attendance.departmentName ?
                  <>
                    <hr />
                    <div className="pl-4 pr-4">
                      <h6>Departamento</h6>
                      <p className="text-muted">{selectedChat.attendance.departmentName}</p>
                    </div>
                  </>
                  : null}
                {selectedChat.attendance.operatorName ?
                  <>
                    <hr />
                    <div className="pl-4 pr-4">
                      <h6>Operador</h6>
                      <p className="text-muted">{selectedChat.attendance.operatorName}</p>
                    </div>
                  </>
                  : null}
              </>
              : null}
            <hr />
            <div className="pl-4 pr-4">
              <h6>Telefone</h6>
              <p className="text-muted">{selectedChat.phoneFormatted}</p>
            </div>
            <hr />
            {selectedChat.externalIds ?
              <div>
                <div className="pl-4 pr-4">
                  <h6>Código(s)</h6>
                  <div className='d-flex' style={{gap: 10}}>
                    {selectedChat.externalIds.map((externalId, index) => (
                      <p className="text-muted mb-0" key={index} title={externalId}>{externalId}{index < selectedChat.externalIds.length - 1 ? ', ' : ''}</p>
                    ))}
                  </div>
                </div>
                <hr />
              </div>
              : null}
            {/* <div className="pl-4 pr-4">
                            <h6>Social Links</h6>
                            <ul className="list-inline social-links">
                                <li className="list-inline-item">
                                    <a href="/#/" className="btn btn-sm btn-floating btn-facebook">
                                        <i className="fa fa-facebook"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="/#/" className="btn btn-sm btn-floating btn-twitter">
                                        <i className="fa fa-twitter"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="/#/" className="btn btn-sm btn-floating btn-dribbble">
                                        <i className="fa fa-dribbble"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="/#/" className="btn btn-sm btn-floating btn-whatsapp">
                                        <i className="fa fa-whatsapp"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="/#/" className="btn btn-sm btn-floating btn-linkedin">
                                        <i className="fa fa-linkedin"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="/#/" className="btn btn-sm btn-floating btn-google">
                                        <i className="fa fa-google"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="/#/" className="btn btn-sm btn-floating btn-behance">
                                        <i className="fa fa-behance"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="/#/" className="btn btn-sm btn-floating btn-instagram">
                                        <i className="fa fa-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <hr/>
                        <div className="pl-4 pr-4">
                            <div className="form-group">
                                <div className="form-item custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch11"/>
                                    <label className="custom-control-label" htmlFor="customSwitch11">Block</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-item custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" defaultChecked
                                           id="customSwitch12"/>
                                    <label className="custom-control-label" htmlFor="customSwitch12">Mute</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-item custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch13"/>
                                    <label className="custom-control-label" htmlFor="customSwitch13">Get
                                        notification</label>
                                </div>
                            </div>
                        </div> */}
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  )
}

export default Profile
