import api from './Axios.config'

const url = process.env.REACT_APP_API_URL + '/api/contact/v1'
const urlReports = process.env.REACT_APP_API_URL + '/api/report-attendance/v1'

export const getInAutoAttendance = async () => {
  try {
    const response = await api.get(`${url}/auto-attendances`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get auto attendances')
  }
}

export const getWaitingAttendance = async () => {
  try {
    const response = await api.get(`${url}/waiting-attendances?order=asc`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get waiting attendances')
  }
}

export const getInAttendance = async () => {
  try {
    const response = await api.get(`${url}/in-attendances`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get in attendances')
  }
}

export const postStartAttendance = async (cellPhone, channelId, departmentId = null) => {
  try {
    const response = await api.post(`${url}/start-attendances`, { cellPhone, channelId, departmentId })
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to start attendance')
  }
}

export const putFinishAttendance = async (
  id,
  reason,
  description = ''
) => {
  try {
    const response = await api.put(`${url}/attendances/${id}/finishes`, {
      reasonTypeId: reason,
      reasonDescription: description,
    })
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to finish attendance')
  }
}

export const getFinishReasons = async () => {
  try {
    const response = await api.get(`${process.env.REACT_APP_API_URL}/api/attendance/v1/reason-types`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get finish reasons')
  }
}

export const getDepartments = async () => {
  try {
    const response = await api.get(`${process.env.REACT_APP_API_URL}/api/department/v1/departments`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get departments')
  }
}

export const getChannels = async () => {
  try {
    const response = await api.get(`${process.env.REACT_APP_API_URL}/api/configuration/v1/channels`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get channels')
  }
}

export const getChannelsActives = async () => {

  try {
    const response = await api.get(`${process.env.REACT_APP_API_URL}/api/configuration/v1/channels`, {
      params: {
        active: true
      }
    })
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get channels')
  }
}

export const getTags = async () => {
  try {
    const response = await api.get(`${process.env.REACT_APP_API_URL}/api/configuration/v1/tags`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get tags')
  }
}

export const getMediaTypes = async () => {
  try {
    const response = await api.get(`${process.env.REACT_APP_API_URL}/api/attendance/v1/media-types`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get medias types')

  }
}

//resports

export const getTotalAttendance = async (dateFrom, dateTo) => {
  try {
    const response = await api.get(`${urlReports}/total-status-attendances?dateFrom=${dateFrom}&dateTo=${dateTo}`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get attendance')
  }
}

export const getTotalStatusQueues = async () => {
  try {
    const response = await api.get(`${urlReports}/total-status-queues`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get status queue')
  }
}

export const getTotalDailyStatusAttendace = async (dateFrom, dateTo) => {
  try {
    const response = await api.get(`${urlReports}/total-daily-status-attendances?dateFrom=${dateFrom}&dateTo=${dateTo}`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get status attendace')
  }
}

export const getTotalFinishedReasons = async (dateFrom, dateTo) => {
  try {
    const response = await api.get(`${urlReports}/total-finished-reasons?dateFrom=${dateFrom}&dateTo=${dateTo}`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get status department attendace')
  }

}

export const getTotalDailyStatusDepartmentAttendances = async (dateFrom, dateTo) => {
  try {
    const response = await api.get(`${urlReports}/total-daily-status-department-attendances?dateFrom=${dateFrom}&dateTo=${dateTo}`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get status department attendace')
  }

}

export const getTotalDailyStatusOperatorAttendances = async (dateFrom, dateTo) => {
  try {
    const response = await api.get(`${urlReports}/total-daily-status-operator-attendances?dateFrom=${dateFrom}&dateTo=${dateTo}`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get status department attendace')
  }

}

export const getTotalFinishedReasonTypes = async (dateFrom, dateTo) => {
  try {
    const response = await api.get(`${urlReports}/total-finished-reason-types?dateFrom=${dateFrom}&dateTo=${dateTo}`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get status department attendace')
  }

}