import React from 'react';
import ReactECharts from 'echarts-for-react';

import * as echarts from 'echarts/core';
import { TooltipComponent, LegendComponent } from 'echarts/components';
import { PieChart as EchartPieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { SVGRenderer } from 'echarts/renderers';
echarts.use([TooltipComponent, LegendComponent, EchartPieChart, SVGRenderer, LabelLayout])

export const formatDateDashboard = (date) =>  {
  if(!date) return ''

  const parts = date.split('-');
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];
  
  return `${day}/${month}/${year}`;
}

const PieChart = ({dueTodayData, reminderData, delayedData, serasaData}) => {
  // const checkZeroValues = (type) => {
  //   if(!dueTodayData?.totalCorrectedBalanceAmount && !reminderData?.totalCorrectedBalanceAmount && !delayedData?.totalCorrectedBalanceAmount && !serasaData?.totalCorrectedBalanceAmount) return 0

  //   const data = {
  //     dueTodayData,
  //     reminderData,
  //     delayedData,
  //     serasaData,
  //   };
  
  //   const selectedData = data[type];
  
  //   if (selectedData && selectedData.totalCorrectedBalanceAmount === 0) {
  //     return ''
  //   }
  
  //   return 0
  // }

  const formatTotalCorrectedBalanceAmount = (value) => {
    return new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(value)
  }

  const option = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      bottom: '0%',
      orient: 'vertical',
      align: 'left',
      textStyle: {
        color: '#707070',
        backgroundColor: '#e6e6e6',
        padding: [10, 5, 10, 10],
        // padding: [20, 5, 20, 10],
        width: 250,
        borderRadius: 5,
        fontSize: 12,
        fontWeight: 'bold',
      },
      formatter: function (params) {
        switch (params) {
          case 'Vencendo hoje':
            return `Vencendo hoje\n\nValor: ${dueTodayData?.totalCorrectedBalanceAmount ? formatTotalCorrectedBalanceAmount(dueTodayData.totalCorrectedBalanceAmount) : 'R$ 0'}` 
          case 'A vencer':   
          return `A vencer\n\nValor: ${reminderData?.totalCorrectedBalanceAmount ? formatTotalCorrectedBalanceAmount(reminderData.totalCorrectedBalanceAmount) : 'R$ 0'}` 
          case 'Vencido':
            return `Vencido\n\nValor: ${delayedData?.totalCorrectedBalanceAmount ? formatTotalCorrectedBalanceAmount(delayedData.totalCorrectedBalanceAmount) : 'R$ 0'}` 
          case 'A ser negativado':
            return `A ser negativado\n\nValor: ${serasaData?.totalCorrectedBalanceAmount ? formatTotalCorrectedBalanceAmount(serasaData.totalCorrectedBalanceAmount) : 'R$ 0'}` 
          default:
            break;
        }
      },
      data: [
        { name: 'Vencendo hoje', icon: 'none' },
        { name: 'A vencer', icon: 'none' },
        { name: 'Vencido', icon: 'none' },
        { name: 'A ser negativado', icon: 'none' },
      ]
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['40%', '80%'],
        // minAngle: 10,
        bottom: '50%',
        label: {
          show: false,
        },
        labelLine: {
          show: false
        },
        data: [
          { 
            value: dueTodayData?.totalCorrectedBalanceAmount ? dueTodayData.totalCorrectedBalanceAmount : 0, 
            name: 'Vencendo hoje', 
            itemStyle: {
              color: '#3db16b',
            },
            tooltip: {
              position: 'right',
              formatter: function (params) {
                return `<strong>Vencendo hoje</strong><br />
                        Quantidade de parcelas: ${dueTodayData?.numberOfInvoices || 0}<br />
                        Valor total: ${dueTodayData?.totalCorrectedBalanceAmount ? formatTotalCorrectedBalanceAmount(dueTodayData.totalCorrectedBalanceAmount) : 'R$ 0'}`;
              }
            }
          },
          { 
            value: reminderData?.totalCorrectedBalanceAmount ? reminderData.totalCorrectedBalanceAmount : 0, 
            name: 'A vencer',
            itemStyle: {
              color: '#00bcd4'
            },
            tooltip: {
              position: 'right',
              formatter: function (params) {
                return `<strong>A vencer: </strong>${reminderData?.dueDate ? formatDateDashboard(reminderData.dueDate) : ''}<br />
                        Quantidade de parcelas: ${reminderData?.numberOfInvoices || 0}<br />
                        Valor total: ${reminderData?.totalCorrectedBalanceAmount ? formatTotalCorrectedBalanceAmount(reminderData.totalCorrectedBalanceAmount) : 'R$ 0'}`;
              }
            }
          },
          { 
            value: delayedData?.totalCorrectedBalanceAmount ? delayedData.totalCorrectedBalanceAmount : 0, 
            name: 'Vencido',
            itemStyle: {
              color: '#ff9d36'
            },
            tooltip: {
              position: 'right',
              formatter: function (params) {
                return `<strong>Vencido: </strong>${delayedData?.dueDate ? formatDateDashboard(delayedData?.dueDate) : ''}<br />
                        Quantidade de parcelas: ${delayedData?.numberOfInvoices || 0}<br />
                        Valor total: ${delayedData?.totalCorrectedBalanceAmount ? formatTotalCorrectedBalanceAmount(delayedData.totalCorrectedBalanceAmount) : 'R$ 0'}`;
              }
            }
          },
          { 
            value: serasaData?.totalCorrectedBalanceAmount ? serasaData.totalCorrectedBalanceAmount : 0, 
            name: 'A ser negativado',
            itemStyle: {
              color: '#ea4141'
            },
            tooltip: {
              position: 'right',
              formatter: function (params) {
                return `<strong>A ser negativado: </strong>${serasaData?.dueDate ? formatDateDashboard(serasaData?.dueDate) : ''}<br />
                        Quantidade de parcelas: ${serasaData?.numberOfInvoices || 0}<br />
                        Valor total: ${serasaData?.totalCorrectedBalanceAmount ? formatTotalCorrectedBalanceAmount(serasaData.totalCorrectedBalanceAmount) : 'R$ 0'}`;
              }
            }
          },
        ],
      }
    ],
  };

  return (
    <ReactECharts
      option={option}
      notMerge={true}
      lazyUpdate={true}
      style={{ width: '100%', height: '550px', position: 'sticky !important', top: 0 }}
      renderer="svg"
    />
  );
};

export default PieChart