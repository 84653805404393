import React, { useContext, useEffect, useCallback, useRef } from "react";
import { Button, Input } from "reactstrap";
import { convertText } from "../../../Utils/convert-text";
import PreviewAttachmentModal from "../../Modals/PreviewAttachmentModal";
import OptionsDropdown from "../OptionsDropdown";
import { AttendanceContext } from "../../../Context/AttendanceContext";
import { useState } from "react";
import VcardModal from "../../Modals/VcardModal";
import AudioRecorder from "../AudioRecorder";
import Emojis from "../Emojis";
import SoundWaveComponent from "./SoundWaveComponent";
import AttendanceTimer from "../AttendanceTimer";
import MicRecorder from 'mic-recorder-to-mp3'
import { getMediaTypes } from "../../../Services/Attendance-Service";
import QuickMessageListComponent from "../QuickMessageListComponent";


const Mp3Recorder = new MicRecorder({ bitRate: 128 })

const ReplyingMessage = ({ replyingMessage, cancelReply }) => {
  return (
    <>
      {replyingMessage ? (
        <div
          style={{
            backgroundColor: "white",
            padding: "10px 16px",
            borderRadius: "5px",
            marginBottom: "20px",
            width: "100%",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>
            {replyingMessage.fromMe ? "Você" : replyingMessage.fromName}
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span
              style={{
                marginBottom: 0,
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: 400,
              }}
            >
              {replyingMessage.hasMedia ? (
                <span>
                  <i className="fa fa-file"></i> {replyingMessage?.attachmentName ? replyingMessage.attachmentName : 'Arquivo'}
                </span>
              ) : (
                convertText(replyingMessage.body)
              )}
            </span>
            <button
              className="btn btn-light"
              onClick={cancelReply}
            >
              <i className="ti ti-close"></i>
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

function ChatFooter({
  inputMsg,
  replyingMessage,
  onChange,
  onSubmit,
  cancelReply,
  acceptedFiles,
  setAcceptedFiles
}) {
  const [isRecording, setIsRecording] = useState(false);
  const [blobURL, setBlobURL] = useState("");
  const [blob, setBlob] = useState("");
  const [isBlocked, setIsBlocked] = useState(false);
  const [messageSubmitted, setMessageSubmitted] = useState(false);
  const { setAttachment, setOpenModalAttachment } =
    useContext(AttendanceContext);
  const [openVcardModal, setOpenVcardModal] = useState(false);
  const [sendSelfContact, setSendSelfContact] = useState(false);
  const [paddingInput, setPaddingInput] = useState();
  const inputRef = useRef(null);
  const [timerNowIsoDate, setTimerNowIsoDate] = useState(null);
  const [startTimeCounter, setStartTimeCounter] = useState(false)
  const [inputAttachmentDocFiles, setInputAttachmentDocFiles] = useState([])
  const [inputAttachmentImageFiles, setinputAttachmentImageFiles] = useState({})
  const [extensionFromMime, setExtensionFromMime] = useState({})
  const [extensionsForDefaultPreview, setExtensionsForDefaultPreview] = useState([])
  const [showQuickMessages, setShowQuickMessages] = useState(false)

  const handleInputPlaceholder = () => {
    if (replyingMessage) {
      return;
    }

    if (isRecording) {
      return "Gravando áudio";
    } else if (!isRecording && blobURL) {
      return;
    }

    return "Mensagem";
  };

  const isUsingAudioRecorder = isRecording || blobURL;

  const handleSubmit = (e) => {
    if (isUsingAudioRecorder) {
      if (isRecording) {
        document.getElementById("stop-recording").click();
        setTimeout(() => {
          document.getElementById("submit").click();
        }, 100);
        return;
      }

      handleSubmitAudio();
      return;
    }

    e.preventDefault();
    onSubmit(e);
    document.getElementById("input-chat").style.height = '40px'
    setMessageSubmitted(true);
  };

  const handleSubmitAudio = () => {
    const reader = new FileReader();

    reader.readAsDataURL(blob);

    reader.onload = () => {
      const base64DataUrl = reader.result;
      const base64String = base64DataUrl.split(",")[1];

      onSubmit({ content: base64String, type: "audio/mp3" });
      resetRecording();
    };

    reader.onerror = (error) => {
      console.error("Error", error);
    };
  };

  const resetRecording = () => {
    setBlob("");
    setBlobURL("");
    setIsRecording(false);
    setIsBlocked();
  };

  const handleChange = (e) => {
    if (!e.target) {
      onChange(e);
      setMessageSubmitted(false);
      return;
    }

    onChange(e.target.value);
  };

  const handleSendAttachment = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setAttachment(file);
      setOpenModalAttachment(true);
    }
  };

  const handleOpenAttachments = (type) => {
    switch (type) {
      case "image":
        document.getElementById("inputAttachmentImage").click();
        break;
      case "doc":
        document.getElementById("inputAttachmentDoc").click();
        break;
      case "contact":
        setOpenVcardModal(true);
        break;
      case "myContact":
        setOpenVcardModal(true);
        setSendSelfContact(true);
        break;
      default:
        break;
    }
  };

  const pasteIntoInput = (text) => {
    const el = inputRef.current;
    el.focus();

    if (
      typeof el.selectionStart === "number" &&
      typeof el.selectionEnd === "number"
    ) {
      const val = el.value;
      const selStart = el.selectionStart;
      el.value = val.slice(0, selStart) + text + val.slice(el.selectionEnd);
      el.selectionEnd = el.selectionStart = selStart + text.length;
    } else if (typeof document.selection !== "undefined") {
      const textRange = document.selection.createRange();
      textRange.text = text;
      textRange.collapse(false);
      textRange.select();
    }
  };

  const handleEnter = (e) => {
    if (showQuickMessages) {
      return
    }
    if (e.keyCode === 13 && !e.shiftKey) {
      if (e.type === "keypress" || e.type === "keydown") {
        pasteIntoInput("\n");
        handleSubmit(e);
      }
      e.preventDefault();
    }
  };

  const detectPasteFile = () => {
    document.addEventListener("paste", function (evt) {
      const clipboardItems = evt.clipboardData.items;
      const items = [].slice
        .call(clipboardItems)
        .filter(function (item) {
          const isAllowedFile = acceptedFiles?.includes(item.type);

          return isAllowedFile;
        })
        .filter((item) => item.kind === "file");

      if (items.length === 0) {
        return;
      }
      const item = items[0];
      const blob = item.getAsFile();

      let file = new File(
        [blob],
        blob.name,
        { type: blob.type, lastModified: new Date().getTime() },
        "utf-8"
      );
      handleSendAttachment({ target: { files: [file] } });
    });
  };

  const getMediaTypesForSendFiles = async () => {
    const response = await getMediaTypes()

    const allMimetypes = [];

    for (const category in response) {
      response[category].forEach(item => {
        allMimetypes.push(item.mimetype);
      });
    }
    setAcceptedFiles(allMimetypes)

    const mimetypesImg = [];

    ["image", "video"].forEach(category => {
      response[category].forEach(item => {
        mimetypesImg.push(item.mimetype);
      });
    });
    const mimetypeObjectImg = { mimetypesImg: mimetypesImg.join(',') }
    setinputAttachmentImageFiles(mimetypeObjectImg)

    const mimetypesDoc = [];

    ["document"].forEach(category => {
      response[category].forEach(item => {
        mimetypesDoc.push(item.mimetype);
      });
    });

    const mimetypeObjectDoc = { mimetypesDoc: mimetypesDoc.join(',') }
    setInputAttachmentDocFiles(mimetypeObjectDoc)

    const mimeExtensions = {};

    for (const categoryAllExtensions in response) {
      response[categoryAllExtensions].forEach(item => {
        let label;
        switch (categoryAllExtensions) {
          case 'document':
            label = item.extension[0].toUpperCase().replace('.', '');
            break;
          case 'image':
            label = 'IMAGE';
            break;
          case 'audio':
            label = 'AUDIO';
            break;
          case 'video':
            label = 'VIDEO';
            break;
        }

        mimeExtensions[item.mimetype] = label;
      })
    }

    setExtensionFromMime(mimeExtensions)

    const mimeTypesForDefaultPreview = []

    for (const categoryDefaultPreview in response) {
      if (categoryDefaultPreview === 'document') {
        response[categoryDefaultPreview].forEach(doc => {
          if (doc.mimetype !== 'application/pdf') {
            mimeTypesForDefaultPreview.push(doc.mimetype);
          }
        })
      }
    }

    setExtensionsForDefaultPreview(mimeTypesForDefaultPreview)
  }

  useEffect(() => {
    getMediaTypesForSendFiles()
    detectPasteFile();
    document.getElementById("input-chat") &&
      document.getElementById("input-chat").focus();
    document.getElementById("form-buttons") &&
      setPaddingInput(document.getElementById("form-buttons").clientWidth);
  }, []);


  const adjustTextareaHeight = (textarea) => {
    if (!textarea) return;
    textarea.style.height = '40px';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleInputChange = (event) => {

    handleChange(event);

    if (event.code !== 'Enter') {
      adjustTextareaHeight(event.target);
    }
    if (event.target?.value?.startsWith('/')) {
      setShowQuickMessages(true)
    } else {
      setShowQuickMessages(false)
    }

  };

  const handleResetAudio = () => {
    Mp3Recorder.stop()
    setStartTimeCounter(false)
    setBlob("");
    setBlobURL('')
    setIsRecording(false);
  }

  const getIsoDateNow = () => {
    const currentDate = new Date();
    const currentISOString = currentDate.toISOString();
    setTimerNowIsoDate(currentISOString)
  }

  useEffect(() => {
    if (startTimeCounter) return getIsoDateNow()
    return setTimerNowIsoDate(null)
  }, [startTimeCounter])

  return (
    <>
      <div
        className="chat-footer"
        style={replyingMessage || showQuickMessages ? { flexDirection: "column" } : {}}
      >
        <ReplyingMessage
          replyingMessage={replyingMessage}
          cancelReply={cancelReply}
        />
        <QuickMessageListComponent
          showQuickMessages={showQuickMessages}
          setShowQuickMessages={setShowQuickMessages}
          inputMsg={inputMsg}
          handleInputChange={handleInputChange}
          inputRef={inputRef}
        />

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          {isRecording ? (
            <div style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              marginLeft: 'auto'

            }}>
              <div className="d-flex">
                <AttendanceTimer time={timerNowIsoDate} recording={true} />
                <SoundWaveComponent handleResetAudio={handleResetAudio} />
              </div>
            </div>
          ) : ((
            <>
              {
                !blobURL && (
                  <Emojis
                    onChange={handleChange}
                    inputMsg={inputMsg}
                    messageSubmitted={messageSubmitted}
                  />
                )
              }
              <textarea
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && e.target.value === '') return e.preventDefault()

                  handleEnter(e)
                }}
                ref={inputRef}
                className="form-control"
                placeholder={handleInputPlaceholder()}
                value={isRecording ? "" : inputMsg}
                disabled={isUsingAudioRecorder}
                onChange={(e) => {
                  handleInputChange(e)
                }}
                id="input-chat"
                style={{
                  paddingRight: paddingInput,
                  resize: 'none',
                  height: 40,
                  maxHeight: 200,
                  border: 0,
                  backgroundColor: 'white'
                }}
                autoComplete="off"
              />
            </>
          ))}
          <Input
            type="file"
            id="inputAttachmentImage"
            onChange={(e) => {
              handleSendAttachment(e);
              e.target.value = null;
            }}
            style={{ display: "none" }}
            accept={inputAttachmentImageFiles.mimetypesImg}
          />
          <Input
            type="file"
            id="inputAttachmentDoc"
            onChange={(e) => {
              handleSendAttachment(e);
              e.target.value = null;
            }}
            style={{ display: "none" }}
            accept={inputAttachmentDocFiles.mimetypesDoc}
          />
        </form>
        <div
          className="form-buttons"
          style={{
            background: 'white',
            marginRight: '-5px',
            ...(replyingMessage || showQuickMessages ? { bottom: 10 } : {}),
          }}
          id="form-buttons"
        >
          {!isUsingAudioRecorder ? (
            <OptionsDropdown type={(type) => handleOpenAttachments(type)} setShowQuickMessages={setShowQuickMessages} />
          ) : null}
          <AudioRecorder
            isBlocked={isBlocked}
            setIsBlocked={setIsBlocked}
            blobURL={blobURL}
            setBlobURL={setBlobURL}
            isRecording={isRecording}
            setIsRecording={setIsRecording}
            setBlob={setBlob}
            setStartTimeCounter={setStartTimeCounter}
            Mp3Recorder={Mp3Recorder}
          />
          <Button
            color="primary"
            className="btn-floating"
            onClick={handleSubmit}
            id="submit"
          >
            <i className="fa fa-send"></i>
          </Button>
        </div>
      </div>
      <PreviewAttachmentModal extensionFromMime={extensionFromMime} extensionsForDefaultPreview={extensionsForDefaultPreview} />
      <VcardModal
        openVcardModal={openVcardModal}
        setOpenVcardModal={(contact, open) => {
          if (!open) {
            setOpenVcardModal(false);
            setSendSelfContact(false);
            return;
          }

          onSubmit({ ...contact, type: contact ? "vcard" : "myVcard" });
          setOpenVcardModal(false);
          setSendSelfContact(false);
        }}
        sendSelfContact={sendSelfContact}
      />
    </>
  );
}

export default ChatFooter;