import { useToast } from '../App/Partials/Toast'
import api from './Axios.config'
import { getSocketInstance } from './Socket-Service'

const url = process.env.REACT_APP_API_URL + '/api/user/v1'
const { showSuccessToast } = useToast()

export const signIn = async (username, password) => {
  const credentials = `${username}:${password}`
  const encodedCredentials = window.btoa(credentials)
  const authHeader = `Basic ${encodedCredentials}`

  try {
    const response = await api.post(
      `${url}/login`,
      {},
      {
        headers: {
          Authorization: authHeader,
        },
      }
    )
    sessionStorage.setItem('accessToken', response.data.access_token)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    return error
  }
}

export const signOut = () => {
  const accessToken = sessionStorage.getItem('accessToken') || ''
  const socketInstance = getSocketInstance(accessToken)
  socketInstance.off()
  sessionStorage.removeItem('accessToken')
  sessionStorage.removeItem('customer')
  window.location.href = '/'
}

export const getUsersByDepartment = async (departmentId) => {
  try {
    const response = await api.get(`${process.env.REACT_APP_API_URL}/api/user/v1/users?departmentId=${departmentId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get users')
  }
}

export const postTransferAttendance = async (id, body) => {
  try {
    const response = await api.post(`${process.env.REACT_APP_API_URL}/api/contact/v1/attendances/${id}/transfers`, body)
    showSuccessToast('Atendimento transferido com sucesso.')
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to transfer attendance')
  }
}

export const getCustomers = async (filter = '', page, status) => {
  try {
    const response = await api.get(`${process.env.REACT_APP_API_URL}/api/contact/v1/customers?filter=${filter}&page=${page}&status=${status}`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get customers')
  }
}

export const getCustomersByChannelId = async (channelId, filter = '', page) => {
  try {
    const response = await api.get(`${process.env.REACT_APP_API_URL}/api/contact/v1/customers?filter=${filter}&page=${page}&channelId=${channelId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get customers')
  }
}

export const customerQueueTransfer = async (payload) => {

  try {
    const response = await api.put(`${process.env.REACT_APP_API_URL}/api/contact/v1/waiting-attendances/${payload.cellPhone}/change-departments`, payload.body);
    showSuccessToast('Atendimento transferido com sucesso.')
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed transfer in queue')
  }
}

export const removeCustomer = async (channelId, cellPhone) => {
  try {
    await api.delete(`${process.env.REACT_APP_API_URL}/api/contact/v1/customers/${cellPhone}?channelId=${channelId}`);
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed remove customer')
  }
}

export const getLoggedDetails = async () => {
  try {
    const response = await api.get((`${url}/logged-users`))
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed get Details')
  }
}

export const putDetails = async (payload) => {
  try {
    const response = await api.put(`${url}/notification-settings`, payload);
    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar configurações:', error);
    throw new Error('Falha ao atualizar configurações');
  }
}