import { Input, Row, Col, Label } from "reactstrap"
import React from 'react'
import Select from 'react-select';
import { optionsCustomersStatus } from './../LayoutCustomers'
import { useMobile } from "./useMobile/useMobile";

export const FilterHeader = ({
  maxRows,
  title,
  addButtonChildren,
  inputRef,
  inputCallback,
  placeholder,
  hasSelect,
  customersStatusList,
  setCustomersStatusList
}) => {

  const isMobile = useMobile();

  return (
    <>
      <Row className='styleRow'>
        <Col className="style-col-top col-3">
          <p className="font-weight-bold m-3" style={{ fontSize: 18 }}>
            <span className="bg-primary p-2 rounded-lg px-3">{maxRows || 0}</span>
          </p>
          <p className="font-weight-bold m-2" style={{ fontSize: 18 }}>
            <span>{title}</span>
          </p>
        </Col>
        <Col className="style-col-bottom col-9">
          {inputRef ?
            <form onSubmit={(e) => e.preventDefault()} id="form-customers" className='w-50'>
              <Input type="text" className="form-control input-customers" id="input-customers" placeholder={placeholder} ref={inputRef}
                onChange={(e) => { inputCallback(e.target.value) }} autoComplete='off' />
            </form>
            : null}
          {hasSelect &&
            <div
              className={`d-flex ${isMobile ? 'ml-auto mt-1' : 'mr-auto ml-2'}`}
              style={{ zIndex: 100 }}
            >
              <Label for='typecampaign' className="font-weight-bold my-2" style={{ fontSize: 14 }}>Situação:</Label>
              <Select
                className={isMobile ? 'ml-2' : 'mx-2'}
                options={optionsCustomersStatus}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                  valueContainer: css => ({
                    ...css,
                    flexWrap: 'nowrap',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: isMobile ? '65px' : '200px',
                  })
                }}
                isSearchable={false}
                onChange={(e) => {
                  setCustomersStatusList(e);
                }}
                value={customersStatusList}
              />
            </div>
          }
          {addButtonChildren}
        </Col>
      </Row >
      <hr className="w-100 h-2 mb-3 mt-0" style={{ borderColor: 'lightgray' }} />
    </>
  )
}