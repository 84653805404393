import React, { useContext, useEffect } from 'react'
import { MessageContext } from '../../Context/MessageContext'

export const Overlay = () => {
  const { openOverlay, setClickOverlay } = useContext(MessageContext)

  useEffect(() => {
    const handleEscapeKeyPress = (event) => {
      if (event.key === 'Escape') {
        setClickOverlay(true)
      }
    }

    if (openOverlay) {
      document.addEventListener('keydown', handleEscapeKeyPress)
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
  }, [openOverlay, setClickOverlay])

  if (!openOverlay) return <></>

  return (
    <div id="overlay" onClick={() => setClickOverlay(true)}></div>
  )
}
