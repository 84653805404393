import React from 'react';

export const AudioPlayer = ({ src, campaingStyle }) => {
  // const audioRef = useRef(null);
  // const [isPlaying, setIsPlaying] = useState(false);
  // const [currentTime, setCurrentTime] = useState(0);
  // const [volume, setVolume] = useState(0.5);
  // const [isDragging, setIsDragging] = useState(false);

  // useEffect(() => {
  //   if (audioRef.current) {
  //     audioRef.current.volume = volume;
  //   }
  // }, [volume]);

  // const handlePlayPause = () => {
  //   if (audioRef.current) {
  //     if (isPlaying) {
  //       audioRef.current.pause();
  //     } else {
  //       audioRef.current.play();
  //     }
  //     setIsPlaying(!isPlaying);
  //   }
  // };

  // const handleTimeUpdate = () => {
  //   if (audioRef.current && !isDragging) {
  //     setCurrentTime(audioRef.current.currentTime);
  //   }
  // };

  // const handleDragStart = () => {
  //   setIsDragging(true);
  // };

  // const handleDragEnd = () => {
  //   if (audioRef.current) {
  //     audioRef.current.currentTime = currentTime;
  //   }
  //   setIsDragging(false);
  // };

  // const handleAudioEnded = () => {
  //   if (audioRef.current) {
  //     audioRef.current.currentTime = 0;
  //     setCurrentTime(0);
  //     setIsPlaying(false);
  //   }
  // };

  // const handleVolumeChange = (event) => {
  //   const { value } = event.target;
  //   setVolume(Number(value));
  // };

  // const formatTime = (timestamp) => {
  //   if (timestamp === 0) {
  //     const duration = Math.round(audioRef.current?.duration || 0);
  //     const minutes = Math.floor(duration / 60);
  //     const seconds = Math.floor(duration % 60);
  //     const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  //     return `${minutes}:${formattedSeconds}`;
  //   }

  //   const minutes = Math.floor(timestamp / 60);
  //   const seconds = Math.floor(timestamp % 60);
  //   const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  //   return `${minutes}:${formattedSeconds}`;
  // };

  // const buttonStyles = {
  //   marginBottom: '2px',
  // };

  // const sliderStyles = {
  //   marginTop: '4px',
  //   backgroundColor: '#ccc',
  //   width: '100%',
  //   borderRadius: '9999px',
  //   height: '5px',
  //   appearance: 'none',
  //   outline: 'none',
  //   boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  //   webkitAppearance: 'slider-vertical',
  //   mozAppearance: 'slider-vertical',
  //   cursor: 'pointer', // To show draggable cursor
  // };

  return (
    <div
      style={ campaingStyle && {
        position: 'absolute',
      }}
      >
      <audio src={src}
        controls
        controlsList="nofullscreen nodownload noremoteplayback noplaybackrate foobar"></audio>
    </div>
    // <div>
    //   <audio ref={audioRef} src={src} onTimeUpdate={handleTimeUpdate} onEnded={handleAudioEnded} />

    //   <div style={{ display: 'flex', alignItems: 'center' }}>
    //     <button onClick={handlePlayPause} style={buttonStyles}>
    //       {isPlaying ? (
    //         <>Pause</>
    //       ) : (
    //         <>Play</>
    //       )}
    //     </button>

    //     <div style={{ display: 'flex', flexDirection: 'column' }}>
    //       <input
    //         style={sliderStyles}
    //         type="range"
    //         min="0"
    //         max={audioRef.current?.duration || 0}
    //         value={currentTime}
    //         onChange={(e) => setCurrentTime(Number(e.target.value))}
    //         onMouseDown={handleDragStart}
    //         onMouseUp={handleDragEnd}
    //         onMouseMove={(e) => isDragging && setCurrentTime(Number(e.target.value))}
    //         onMouseLeave={handleDragEnd}
    //       />
    //       <span style={{ fontSize: '12px', marginTop: '2px' }}>{formatTime(currentTime)}</span>
    //     </div>
    //   </div>
    // </div>
  );
};
