import React, { useContext, useEffect, useState } from 'react';
import { CustomInput, FormGroup, Input, Label, Modal, ModalBody, Tooltip } from 'reactstrap';
import { AttendanceContext } from '../../Context/AttendanceContext';
import { useToast } from '../Partials/Toast';
import Wallet from "../../assets/img/wallet.png"
import { putWalletCustomer } from '../../Services/Contact-Service';
import { useAuth } from '../../Context/AuthContext';

function WalletCustomerModal() {
  const { showSuccessToast } = useToast()
  const { _id } = useAuth()
  const {activeChatCustomer, setActiveChatCustomer} = useContext(AttendanceContext)
  const customerInOperatorWallet = activeChatCustomer?.walletOperatorId === _id

  // const [modal, setModal] = useState(false);

  // const modalToggle = () => setModal(!modal);

  // const [tooltipOpen, setTooltipOpen] = useState(false);

  // const tooltipToggle = () => setTooltipOpen(!tooltipOpen);

  const handleConfirm = async () => {
    const body = {
      join: !customerInOperatorWallet,
      channelId: activeChatCustomer.channel.id
    }
    try {
      const res = await putWalletCustomer(activeChatCustomer.phone, body);
      if(res.walletOperatorId){
        showSuccessToast('Contato adicionado a sua carteira com sucesso.')
        setActiveChatCustomer({...activeChatCustomer, walletOperatorId: res.walletOperatorId})
      } else {
        showSuccessToast('Contato removido da sua carteira com sucesso.')
        setActiveChatCustomer({...activeChatCustomer, walletOperatorId: null})
      }
    } catch (error) {
      console.log('error', error)
    }
    return
  }

  return (
    <div>
      {/* <button className="btn btn-info" onClick={modalToggle} id="add-wallet" style={{padding: '7px 10px'}}>
        <img src={Wallet} className="" alt="avatar" style={{width: 22}}/>
      </button> */}
      <div className='d-flex align-items-center'>
        <CustomInput className='text-center wallet-switch' type="switch" id="customCheckbox1" checked={customerInOperatorWallet} onChange={handleConfirm}/>
        <Label>Minha carteira</Label>
      </div>
      {/* <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={"add-wallet"}
        toggle={tooltipToggle}>
        {customerInOperatorWallet ? 'Remover da carteira' : 'Adicionar a carteira'}
      </Tooltip> */}
      {/* <Modal isOpen={modal} toggle={modalToggle} centered className="call modal-dialog-zoom">
        <ModalBody>
          <div className="call-background" style={{ backgroundColor: '#e6e6e6', filter: 'initial', opacity: 'initial' }}></div>
          <div>
            {customerInOperatorWallet ?
            <h4 style={{ color: 'black' }}>Deseja remover o contato da sua carteira?</h4> :
            <h4 style={{ color: 'black' }}>Deseja adicionar o contato da sua carteira?</h4> }
            <div>
              <button type="button" onClick={modalToggle}
                className="btn btn-danger btn-md" style={{marginRight: 10}}
                data-dismiss="modal">
                Cancelar
              </button>
              <button type="button" onClick={handleConfirm}
                className="btn btn-success btn-md">
                Confirmar
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal> */}
    </div>
  )
}

export default WalletCustomerModal
