import React, { useRef, useState, useEffect } from "react";
import { Card, CardHeader, CardBody, CardTitle, CardText } from 'reactstrap';
import ReactECharts from 'echarts-for-react';

const ChartCard = ({ header, information, color, centered, classCss }) => {
  const chartRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <Card className={`${classCss || 'my-2'}`} style={{ backgroundColor: color, position:"relative", height:"135px", width:"100%", maxHeight: "12,5vh" }} inverse>
      <CardHeader style={{whiteSpace: 'normal'}}>
        {header}
      </CardHeader>
      <CardBody>
        <CardTitle tag="h3" style={{ textAlign:  'center'  }}>
          {information}
        </CardTitle>
      </CardBody>
    </Card>
  );
}

export default ChartCard;
