import React from "react"

export const UnreadCounter = ({
  customer,
  activeChatCustomer,
  unreadMessages,
}) => {
  const hasUnreadMessages = unreadMessages.find((msg) => msg.from === customer.from && msg.channelId === customer.channel.id)
  if(hasUnreadMessages && hasUnreadMessages.unreadCount > customer.unreadMessages){
    return customer._id !== activeChatCustomer?._id || customer.channel.id !== activeChatCustomer.channel.id ? (
      <div className="new-message-count">{hasUnreadMessages.unreadCount}</div>
    ) : null
  }

  if (customer.unreadMessages > 0) {
    return activeChatCustomer?._id !== customer._id ? (
      <div className="new-message-count">{customer.unreadMessages}</div>
    ) : null
  }

  if (!hasUnreadMessages) return <></>
}
