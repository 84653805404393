import React, { useEffect, useRef } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useState } from 'react';
import { getDepartment } from '../Services/Department-Service';
import AddNewDepartmentModal from './Modals/AddNewDepartmentModal';
import { Table, Input } from 'reactstrap';
import { FilterHeader } from './Partials/FilterHeader';
import { TableHeader } from './Partials/Table/TableHeader';
import { TableBody } from './Partials/Table/TableBody';
import { deleteDepartment } from '../Services/Department-Service';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useToast } from './Partials/Toast';
import moment from 'moment/moment';
import ConfirmationModal from './Components/ConfirmationModal';
import { formatDateString } from '../Utils/timestamp';
import { CardsLayoutDepartments } from './Partials/CardsLayout/CardsLayoutDepartments';
import { useMobile } from './Partials/useMobile/useMobile';

function LayoutDepartment() {
  const [departmentsList, setDepartmentsList] = useState([]);
  const [departmentFiltered, setDepartmentFiltered] = useState([]);
  const [loading, setLoading] = useState();
  const [openModalEditDepartment, setOpenModalEditDepartment] = useState(false);
  const { showSuccessToast } = useToast();
  const { showErrorToast } = useToast();
  const [openConfirmationModal, setOpenConfirmationModal] = useState('')
  const isMobile = useMobile();

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  });

  const inputRef = useRef();

  const handleGetDepartments = async () => {
    try {
      setLoading(true);
      const dateFormatted = moment().format('YYYY-MM-DD');
      const res = await getDepartment(dateFormatted);
      const filteredData = res.filter((item) => item);
      const formattedData = filteredData.map((item) => ({
        ...item,
        createdAtFormatted: formatDateString(item.updatedAt),
      }));
      setDepartmentsList(formattedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getDepartmentsByFilter = async (filter) => {
    const filteredDepartments = departmentsList.filter(department => department.name.toLowerCase().includes(filter.toLowerCase()))
    setDepartmentFiltered(filteredDepartments)
  };

  useEffect(() => {
    handleGetDepartments()
  }, []);

  const handleDropdown = (clickedItem) => {
    const index = departmentsList.findIndex((department) => department._id === clickedItem._id);

    if (index !== -1) {
      const updatedDepartmentList = [...departmentsList];

      updatedDepartmentList[index] = {
        ...updatedDepartmentList[index],
        dropdownOpen: !updatedDepartmentList[index].dropdownOpen || false,
      };

      setDepartmentsList(updatedDepartmentList);
    }
  };

  const handleDeleteDepartment = async (data) => {
      try {
        await deleteDepartment(data._id);
        showSuccessToast(`Departamento excluído com sucesso.`);
      } catch (error) {
        console.error(`Erro ao excluir departamento`, error);
      } finally {
        handleGetDepartments();
        setOpenConfirmationModal(null)
      }
  };

  const itemsTableHeader = [
    { label: 'Nome', order: 0 },
    { label: 'Usuários', order: 1, tooltip: true, tooltipId: 'tooltip-usuarios', tooltipText: 'Usuários associados' },
    { label: 'Data da criação', order: 2 },
    { label: '', order: 3 }
  ]

  const cellsTableData = [
    {
      type: 'longText',
      property: 'name',
      order: 0,
      width: 200,
      paddingLeft: 10,
    },
    {
      type: 'longText',
      property: 'totalUsers',
      order: 1,
      width: 200,
      paddingLeft: 15,
    },
    {
      type: 'longText',
      property: 'createdAtFormatted',
      order: 2,
      width: 200,
      paddingLeft: 10,
    },
    {
      type: 'actions',
      property: 'actions',
      order: 3,
      width: 50,
      toggle: (data) => handleDropdown(data),
      items: [
        {
          label: 'Editar',
          action: (data) => setOpenModalEditDepartment({ open: true, department: data }),
          show: () => true,
        },
        {
          label: 'Excluir',
          action: (data) => setOpenConfirmationModal({ open: true, info: data }),
          show: () => true,
        },
      ]
    },
  ].sort((a, b) => a.order - b.order);
  

  return (
    <div className="layout">
      <div className="content">
        <div className="content bg-light p-0 align-items-center text-black flex-column h-100">
          <FilterHeader
            maxRows={departmentsList.length}
            title="Departamentos"
            placeholder="Procurar por departamentos"
            inputCallback={getDepartmentsByFilter}
            addButtonChildren={
              <AddNewDepartmentModal
                onCloseModal={() => { setOpenModalEditDepartment({}) }}
                onAddDepartment={() => handleGetDepartments()}
                editingDepartment={openModalEditDepartment.open}
                department={openModalEditDepartment.department}
                onEditDepartment={() => handleGetDepartments()}
              />
            }
            inputRef={inputRef}
          />
          <div className="sidebar-body w-100 pb-4 px-5 pt-0"
            style={{ position: 'relative', height: '80%' }}
            id="sidebar-departments">
            {loading ?
              <div className="overlay-loading-customers">
                <i className="fa fa-spinner" style={{ animation: 'spin 3s linear infinite', fontSize: 42 }}></i>
              </div>
              :
              <PerfectScrollbar className='hide-rail-x'>
                {
                  isMobile ? (
                    <Table>
                      <CardsLayoutDepartments
                        data={departmentFiltered.length > 0 ? departmentFiltered : departmentsList}
                        loading={loading}
                        setOpenModalEditDepartment={setOpenModalEditDepartment}
                        setOpenConfirmationModal={setOpenConfirmationModal}
                        handleDropdown={ handleDropdown}
                      />
                      <ConfirmationModal
                        openConfirmationModal={openConfirmationModal}
                        setOpenConfirmationModal={setOpenConfirmationModal}
                        handleDeleteDepartment={handleDeleteDepartment}
                        messageModal='Deseja apagar esse departamento ?'
                      />
                    </Table>
                  ) : (
                    <Table>
                      <TableHeader itemsTableHeader={itemsTableHeader} theadClassName={'thead-customers'} />
                      <TableBody data={departmentFiltered.length > 0 ? departmentFiltered : departmentsList} cellsTableData={cellsTableData} bodyClassName={'tbody-customers'} loading={loading} />
                      <ConfirmationModal
                        openConfirmationModal={openConfirmationModal}
                        setOpenConfirmationModal={setOpenConfirmationModal}
                        handleDeleteDepartment={handleDeleteDepartment}
                        messageModal='Deseja apagar esse departamento ?'
                      />
                </Table>
                  )
                }
              </PerfectScrollbar>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default LayoutDepartment
