import React from "react";

const WhitoutDataChartModal = ({ message }) => {
    return (
        <div style={{ height: "565px" }} className='d-flex justify-content-center flex-column'>
            <i className="fa fa-exclamation-circle" style={{ textAlign: 'center', fontSize: '7em', color: '#ebebeb' }}></i>
            <p style={{ textAlign: 'center', fontSize: '1.2em' }}>{message}</p>
        </div>
    )
}

export default WhitoutDataChartModal