import React from "react";

export const Drawer = (props) => {
  const { open, anchor, onClose, children, extendable } = props;

  return (
    <div class="drawer-body">
      <div
        className={`overlay ${open ? 'overlayOpen' : 'overlayHidden'}`}
        onClick={onClose}
        aria-hidden="true"
      />
      <div
        tabIndex="-1"
        className={`${extendable && !open ? 'drawer withoutExtend' : 'drawer animate'} ${!open && (anchor === 'left' ? 'hiddenLeft' : 'hiddenRight')} ${changeAnchor(anchor)}`}
        id="drawer"
      >
        {children}
      </div>
    </div>
  );
};

export const changeAnchor = (anchor) => {
  switch (anchor) {
    case "left":
      return 'openLeft';
    case "right":
      return 'openRight';
    default:
      return 'openLeft';
  }
};
