import React, { Fragment, useEffect, useState } from 'react'
import { TableData } from './TableData'

export const TableBody = ({data, bodyClassName, cellsTableData, loading}) => {
  if(!data?.length && typeof loading === 'boolean' && !loading) {
    return (
      <tbody>
        <tr height={300}>
          <td colSpan={cellsTableData.length} className='text-center'>
            <div style={{height: 300}} className='d-flex justify-content-center flex-column'>
              <i className="fa fa-exclamation-circle" style={{fontSize: '7em', color: '#ebebeb'}}></i>
              <p style={{fontSize: '1.2em'}}>No momento não há registros.</p>
            </div>
          </td>
        </tr>
      </tbody>
    )
  }

  return (
    <tbody className={bodyClassName + ' tbody'}>
      {data.map((item, i) => <TableData key={i} data={item} i={i} cellsTableData={cellsTableData}/>)}
    </tbody>
  )
}