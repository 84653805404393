import { io } from 'socket.io-client'

let socketInstance

export const getSocketInstance = (token) => {
  if (!socketInstance) {
    socketInstance = io(process.env.REACT_APP_WS_API_URL || '', {
      transports: ['websocket'],
      query: { token },
    })
  }
  return socketInstance
}
