import EmojiPicker, {
  Categories,
  EmojiStyle,
  // SkinTones,
  // Theme,
  // Categories,
  // EmojiClickData,
  // Emoji,
  // SuggestionMode,
  SkinTonePickerLocation
} from "emoji-picker-react";
import React from 'react'

export const EmojiPickerBox = ({onChangeEmoji}) => {
  return (
    <EmojiPicker
      onEmojiClick={(emoji) => {onChangeEmoji(emoji)}}
      autoFocusSearch={false}
      // theme={Theme.AUTO}
      searchDisabled
      skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
      height={350}
      emojiVersion="5.0"
      // lazyLoadEmojis={true}
      // suggestedEmojisMode={SuggestionMode.RECENT}
      // skinTonesDisabled
      // defaultSkinTone={SkinTones.MEDIUM}
      emojiStyle={EmojiStyle.NATIVE}
      previewConfig={{ showPreview: false }}
      categories={[
        {
          category: Categories.SUGGESTED,
          name: 'Recentes'
        },
        {
          category: Categories.SMILEYS_PEOPLE,
          name: 'Smileys e pessoas'
        },
        {
          category: Categories.ANIMALS_NATURE,
          name: 'Animais e natureza'
        },
        {
          category: Categories.FOOD_DRINK,
          name: 'Comidas e bebidas'
        },
        {
          category: Categories.ACTIVITIES,
          name: 'Atividades'
        },
        {
          category: Categories.TRAVEL_PLACES,
          name: 'Viagens e lugares'
        },
        {
          category: Categories.OBJECTS,
          name: 'Objetos'
        },
        {
          category: Categories.SYMBOLS,
          name: 'Símbolos'
        },
        {
          category: Categories.FLAGS,
          name: 'Bandeiras'
        }
      ]}
    />
  )
}