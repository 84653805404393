import React from "react";

import SettingsNotifications from "../../Store/Actions/SettingsNotifications";
import { AttendanceProvider } from "../../Context/AttendanceContext";

const SettingsNotificationsComponent = () => {

    return (
        <AttendanceProvider>
            <SettingsNotifications />
        </AttendanceProvider>
    )

}

export default SettingsNotificationsComponent
