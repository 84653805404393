import React, { useEffect, useState } from "react";

const AttendanceTimer = ({ time, recording }) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [timer, setTimer] = useState();
  const getTimeCounter = (isoDate) => {
    const targetDate = new Date(isoDate);
    const currentDate = currentTime || new Date();

    const timeDifferenceInMillis = Math.abs(targetDate - currentDate);
    const timeDifferenceInSeconds = timeDifferenceInMillis / 1000;

    const counterInSeconds = Math.max(0, Math.floor(timeDifferenceInSeconds));

    const remainingSeconds = counterInSeconds % (24 * 3600);
    const totalHours = Math.floor(timeDifferenceInMillis / (3600 * 1000));
    const days = Math.floor(timeDifferenceInMillis / (24 * 3600 * 1000));
    const hours = Math.floor(remainingSeconds / 3600);
    const remainingMinutes = remainingSeconds % 3600;
    const minutes = Math.floor(remainingMinutes / 60);
    const seconds = remainingMinutes % 60;

    if (recording) {
      return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    }
    if (days > 0) {
      return `${days} dia(s), ${hours}:${String(minutes).padStart(2, "0")}:${String(
        seconds
      ).padStart(2, "0")}`;
    } else {
      return `${hours}:${String(minutes).padStart(2, "0")}:${String(
        seconds
      ).padStart(2, "0")}`;
    }

    // return `${totalHours}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    setTimer(getTimeCounter(time))

    return () => clearInterval(intervalId);

  });

  return (
    recording ? (
      <div style={{
        padding: '5px',
        marginTop: '3px'
      }}>
        {timer}
      </div>
    ) : (
      <>
        <i className="fa fa-clock-o" >
          <span style={{ fontFamily: 'Nunito, sans-serif', marginLeft: '3px' }}>
            {timer}
          </span>
        </i>
      </>
    )
  );
};

export default AttendanceTimer;
