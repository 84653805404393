import React from 'react';
import { Row, Col, Card, CardText, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PreviewCampaignComponent from '../../Components/PreviewCampaignComponent';
import DateRegisterCampaign from '../../Components/DateRegisterCampaign';
import CampaignStatusCheck from '../CampaignStatusCheck';

export const CardsLayoutCampaign = ({
  data,
  loading,
  findTranslate,
  setOpenModalEditCampaign,
  setOpenConfirmationModal,
  handleDropdown,
  ComponentCampaignPopover }) => {
  const toggle = (data) => {
    handleDropdown(data);
  };
  
  if (loading) {
    return (
      <div>
        <i className="fa fa-exclamation-circle" style={{ fontSize: '7em', color: '#ebebeb' }}></i>
        <p style={{ fontSize: '1.2em' }}>No momento não há registros.</p>
      </div>
    );
  };

  return (
    <div>
      {data.map((item, i) => (
        <div>
          <Card key={i} className='w-100 p-3 bg-light' style={{
            borderBottom:'2px solid #6161ff',
            borderRadius:'10px',
            borderTop:'none',
            borderLeft:'none',
            borderRight:'none'
            }}>
            <Row className='d-flex align-items-start mb-2 bg-primary' style={{borderRadius:'10px'}}>
              <Col className='d-flex justify-content-start p-2'>
                <CardText>
                  <strong style={{fontSize:'18px'}}>Nome:</strong> {item.name}
                </CardText>
              </Col>
            </Row>
            <Row className='d-flex align-items-start mb-2'>
              <Col className='d-flex justify-content-start p-2'>
                <CardText>
                  <strong style={{fontSize:'18px'}}>Tipo</strong><br/>
                  {findTranslate(item.types[0])}
                </CardText>
              </Col>
              <Col className='d-flex justify-content-center p-2'>
                <CardText>
                  <strong style={{fontSize:'18px'}}>Canais</strong><br/>
                  <div style={{textAlign:'end'}}>{ComponentCampaignPopover && <ComponentCampaignPopover data={item} />}</div>
                </CardText>
              </Col>
            </Row>
            <Row className='d-flex align-items-start mb-2'>
              <Col className='d-flex justify-content-start p-2'>
                <CardText>
                  <strong style={{fontSize:'18px'}}>Data</strong><br/>
                  <DateRegisterCampaign dateTo={item.dateTo} dateFrom={item.dateFrom} />
                </CardText>
              </Col>
              <Col className='d-flex justify-content-center p-2 text-center'>
                <CardText>
                  <strong style={{fontSize:'18px'}}>Status</strong><br/>
                  <CampaignStatusCheck campaign={item} />
                </CardText>
              </Col>
            </Row>
            <Row className='d-flex align-items-start mb-2'>
              <Col className='d-flex justify-content-start p-2'>
                <CardText>
                  <strong style={{fontSize:'18px'}}>Preview</strong><br/>
                  {item.mediaType && <PreviewCampaignComponent campaign={item} />}
                </CardText>
              </Col>
              <Col className='d-flex justify-content-center p-2'>
                <Dropdown isOpen={item.dropdownOpen} toggle={() => toggle(item)}>
                  <DropdownToggle tag="a">
                    <i className={'fa fa-ellipsis-v mt-3'}></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    {item.status === 'sended' ? (
                      <DropdownItem onClick={() => setOpenModalEditCampaign({ open: true, campaignInfo: item })}>
                        Consultar
                      </DropdownItem>
                    ) : (
                      <div>
                        <DropdownItem onClick={() => setOpenModalEditCampaign({ open: true, campaignInfo: item })}>
                          Editar
                        </DropdownItem>
                        <DropdownItem onClick={() => setOpenConfirmationModal({ open: true, info: item })}>
                          Cancelar
                        </DropdownItem>
                      </div>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
          </Card>
        </div>
      ))}
    </div>
  );
};