import React, { useContext, useEffect, useState } from 'react'
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from "react-router-dom"

import Layout from './App/Layout'
import { AuthContext, AuthProvider, useAuth } from './Context/AuthContext'
import SignIn from "./Pages/SignIn"
import { Toaster } from 'react-hot-toast';
import LayoutChannels from './App/LayoutChannels'
import { AttendanceContextWrapper } from './Context/AttendanceContext'
import LayoutCustomers from './App/LayoutCustomers'
import LayoutDepartments from './App/LayoutDepartments'
import { Dashboard } from './Dashboard/Dashboard'
import DashboardCampaign from './Dashboard-Campaign/DashboardCampaign'
import LayoutTags from './App/LayoutTags'
import LayoutCampaign from './App/LayoutCampaign'
import LayoutUsers from './App/LayoutUsers'
import DashboardAttendance from './DashboardAttendance/DashboardAttendance'
import Navigation, { initialNavigationItems } from './Navigation'
import LayoutGroup from './App/LayoutGroup'
import SettingsNotificationsComponent from './App/Components/SettingsNotificationsComponent'
import LayoutHome from './App/LayoutHome'

function App() {
  const { accessToken } = useContext(AuthContext)
  const { permissions } = useAuth()
  useEffect(() => {
    document.getElementsByTagName('body')[0].id = 'body'
  }, [])

  const canAccessRoute = (routeName) => {
    const route = initialNavigationItems.find(item => item.route === routeName)
    const allowRoute = route.permissions.some(permission => permissions.includes(permission));
    return allowRoute
  }

  const enableDashboard = permissions ? permissions.includes('financial_dashboard') : false

  return (
    <AuthProvider>
      <Toaster containerClassName="toaster" />
      <Router>
        {accessToken ?
          <>
            <Navigation />
            <SettingsNotificationsComponent />
          </>
          :
          null}
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              accessToken
                ? <Redirect to={enableDashboard ? "/dashboard-financeiro" : "/inicio"} />
                : <SignIn />
            )}
          />
          <Route
            exact
            path="/dashboard-campanhas"
            render={() => (
              accessToken && canAccessRoute('/dashboard-campanhas') ?
                <>
                  <AttendanceContextWrapper>
                    <DashboardCampaign />
                  </AttendanceContextWrapper>
                </>
                : DefaultRoute()
            )}
          />
          <Route
            exact
            path="/dashboard-atendimentos"
            render={() => (
              accessToken && canAccessRoute('/dashboard-atendimentos') ?
                <>
                  <AttendanceContextWrapper>
                    <DashboardAttendance />
                  </AttendanceContextWrapper>
                </>
                : DefaultRoute()
            )}
          />
          <Route
            exact
            path="/chat"
            render={() => (
              accessToken && canAccessRoute('/chat') ?
                <>
                  <Layout />
                </>
                : DefaultRoute()
            )}
          />
          <Route
            exact
            path="/contatos"
            render={() => (
              accessToken && canAccessRoute('/contatos') ?
                <>
                  <AttendanceContextWrapper >
                    <LayoutCustomers />
                  </AttendanceContextWrapper>
                </>
                : DefaultRoute()
            )}
          />
          <Route
            exact
            path="/canais"
            render={() => (
              accessToken && canAccessRoute('/canais') ?
                <>
                  <AttendanceContextWrapper>
                    <LayoutChannels />
                  </AttendanceContextWrapper>
                </>
                : DefaultRoute()
            )}
          />
          <Route
            exact
            path="/departamentos"
            render={() => (
              accessToken && canAccessRoute('/departamentos') ?
                <>
                  <AttendanceContextWrapper>
                    <LayoutDepartments />
                  </AttendanceContextWrapper>
                </>
                : DefaultRoute()
            )}
          />
          <Route
            exact
            path="/grupo-contatos"
            render={() => (
              accessToken && canAccessRoute('/grupo-contatos') ?
                <>
                  <AttendanceContextWrapper>
                    <LayoutGroup />
                  </AttendanceContextWrapper>
                </>
                : DefaultRoute()
            )}
          />
          <Route
            exact
            path="/usuarios"
            render={() => (
              accessToken && canAccessRoute('/usuarios') ?
                <>
                  <AttendanceContextWrapper>
                    <LayoutUsers />
                  </AttendanceContextWrapper>
                </>
                : DefaultRoute()
            )}
          />
          <Route
            exact
            path="/tags"
            render={() => (
              accessToken && canAccessRoute('/tags') ?
                <>
                  <AttendanceContextWrapper>
                    <LayoutTags />
                  </AttendanceContextWrapper>
                </>
                : DefaultRoute()
            )}
          />
          <Route
            exact
            path="/campanhas"
            render={() => (
              accessToken && canAccessRoute('/campanhas') ?
                <>
                  <AttendanceContextWrapper>
                    <LayoutCampaign />
                  </AttendanceContextWrapper>
                </>
                : DefaultRoute()
            )}
          />
          <Route
            exact
            path="/dashboard-financeiro"
            render={() => (
              accessToken && enableDashboard && canAccessRoute('/dashboard-financeiro') ?
                <>
                  <Dashboard />
                </>
                : DefaultRoute()
            )}
          />
          <Route
            exact
            path="/inicio"
            render={() => (
              accessToken ?
                <>
                  <AttendanceContextWrapper>
                    <LayoutHome />
                  </AttendanceContextWrapper>
                </>
                : DefaultRoute()
            )}
          />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </AuthProvider>
  )
}

export default App

const DefaultRoute = () => <Redirect to="/" />
