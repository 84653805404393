import { Button, Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Progress, Table, Tooltip } from "reactstrap"
import { AttendanceContext } from "../Context/AttendanceContext"
import { AuthContext } from "../Context/AuthContext"
import React, { Fragment, useContext, useMemo, useState, useEffect } from 'react'
import PerfectScrollbar from "react-perfect-scrollbar"
import Navigation from './../Navigation';
import { useDispatch } from "react-redux"
import { sidebarAction } from "../Store/Actions/sidebarAction"
import { postStartChannel, postStopChannel } from "../Services/Chatbot-Service"
import useFormattedPhone from '../Utils/phone-formatter';
import ChannelQrcodeModal from "./Modals/ChannelQrcodeModal"
import { TableHeader } from "./Partials/Table/TableHeader"
import { TableBody } from "./Partials/Table/TableBody"
import { FilterHeader } from "./Partials/FilterHeader"
import { CardsLayoutChannels } from "./Partials/CardsLayout/CardsLayoutChannles"
import { useMobile } from './Partials/useMobile/useMobile';

const handleStatusClassName = (channel) => {
  switch (channel.status) {
    case 'DISCONNECTED':
      return 'bg-danger rounded-lg'
    case 'WAITING_QRCODE':
      return 'bg-warning rounded-lg'
    case 'LOADING':
      return 'bg-dark-bright rounded-lg'
    case 'CONNECTED':      
        return 'bg-success rounded-lg'
    default: // Reconectando
      return 'bg-dark-bright rounded-lg'
  }
}

const translateStatusIcon = (channel) => {
  switch (channel.status) {
    case 'DISCONNECTED':
      return <i className="fa fa-times p-2" style={{ fontSize: 24 }}></i>
    case 'WAITING_QRCODE':
      return <i className="fa fa-qrcode p-2 text-black" style={{ fontSize: 24 }}></i>
    case 'LOADING':
      return <i className="fa fa-spinner p-2" style={{ animation: 'spin 3s linear infinite', fontSize: 24 }}></i>
    case 'CONNECTED':
        return <i className="fa fa-check-circle p-2" style={{ fontSize: 24 }}></i>
    default: // Reconectando
      return <i className="fa fa-spinner p-2" style={{ animation: 'spin 3s linear infinite', fontSize: 24 }}></i>
  }
}

const translateStatusText = (channel) => {
  switch (channel.status) {
    case 'DISCONNECTED':
      return 'Desconectado'
    case 'WAITING_QRCODE':
      return 'Aguardando QRCODE'
    case 'LOADING':
      return 'Carregando'
    case 'CONNECTED':
      return 'Conectado'
    default: // Reconectando
      return 'Reconectando'
  }
}

export const ChannelAction = ({ channel, qrCodes, loading }) => {
  const hasQrcode = qrCodes.find(qrcode => qrcode.id === channel._id)
  const channelDisconnected = channel.status === 'DISCONNECTED'
  const channelWaitingQrcode = channel.status === 'WAITING_QRCODE'
  const channelLoadingQrcode = channel.status === 'LOADING'
  const channelConnected = channel.status === 'CONNECTED'
  const [openChannelModal, setOpenChannelModal] = useState(false)
  const [isLoadingWaitingQrcode, setIsLoadingWaitingQrcode] = useState(false)
  const [openDropdown, setOpenDropdown] = useState(false)

  const handleDisconnectChannel = async () => {
    await postStopChannel(channel)
  }

  const handleConnectChannel = async () => {
    if(channel.status === 'DISCONNECTED'){
      const res = await postStartChannel(channel)
      setOpenDropdown(false)
      if (res.status === 'WAITING_QRCODE') {
        setIsLoadingWaitingQrcode(true)
      }
    } else {
      setOpenChannelModal(true)
      setOpenDropdown(false)
    }
  }

  useEffect(() => {
    setIsLoadingWaitingQrcode(false)
  }, [channel.status])

  const ButtonOptions = () => {
    if (isLoadingWaitingQrcode) {
      return <Button className="font-weight-bold mx-auto d-block" style={{color: '#3db16b'}} color="light" size="sm" disabled>Conectar</Button>
    }

    if (channelDisconnected) {
      return <Button className="font-weight-bold mx-auto d-block" style={{color: '#3db16b'}} color="light" size="sm" onClick={() => handleConnectChannel()}>Conectar</Button>
    }

    if (channelWaitingQrcode || channelLoadingQrcode) {
      return (
          <>
            <Button className="font-weight-bold mx-auto d-block mb-2" style={{color: '#3db16b'}} color="light" size="sm" onClick={() => handleConnectChannel()}>Conectar</Button>
            <Button className="font-weight-bold mx-auto d-block" style={{color: '#dc3545'}} color="light" size="sm" onClick={() => handleDisconnectChannel()}>Desconectar</Button>
          </>
        )
    }

    return <Button className="font-weight-bold mx-auto d-block" style={{color: '#dc3545'}} color="light" size="sm" onClick={() => handleDisconnectChannel()}>Desconectar</Button>
  }

  return (
    <>
      <ButtonOptions />
      {channelWaitingQrcode || channelLoadingQrcode || isLoadingWaitingQrcode ?
        <ChannelQrcodeModal openChannelModal={openChannelModal} setOpenChannelModal={setOpenChannelModal} loading={loading} hasQrcode={hasQrcode} isLoadingWaitingQrcode={isLoadingWaitingQrcode}/>
      : null}
    </>
  )
}

export const LayoutChannels = () => {
  const dispatch = useDispatch();
  const { channelList, setChannelList } = useContext(AuthContext)
  const { socketContext } = useContext(AttendanceContext)
  const [qrCodes, setQrcodes] = useState([])
  const [loading, setLoading] = useState()
  const [tooltipOpen, setTooltipOpen] = useState('');
  const toggle = (cellphone) => setTooltipOpen(cellphone);
  const isMobile = useMobile();
  
  const handleDropdown = (clickedItem) => {
    const index = channelList.findIndex((customer) => customer._id === clickedItem._id);
  
    if (index !== -1) {
      const updatedChannelList = [...channelList];

      updatedChannelList[index] = {
        ...updatedChannelList[index],
        dropdownOpen: !updatedChannelList[index].dropdownOpen || false,
      };
  
      setChannelList(updatedChannelList);
    }
  };

  const itemsTableHeader = [
    { label: '', order: 0 },
    { label: 'Número', order: 1 },
    { label: 'Nome ', order: 2 },
    { label: 'Status', order: 3 },
    { label: '', order: 4 }
  ]

  const cellsTableData = [
    {
      type: "image",
      property:"image",
      order: 0,
      width:50,
      relative:true,
      imageWidth:40,
      imageHeight: 40,
      className: "rounded-circle mr-2",
    },
    {
      type: 'text',
      property: 'cellPhone',
      order: 0,
      width: 200,
      mask: useFormattedPhone
    },
    {
      type: 'text',
      property: 'name',
      order: 0,
      width: 200,
    },
    {
      type: 'customComponent',
      property: 'status',
      order: 0,
      width: 200,
      component: (data) => (
        <>
          {data ? <Tooltip
            placement="right"
            isOpen={tooltipOpen === data.cellPhone}
            target={'tooltip-'+data.cellPhone}
            toggle={() => toggle(data.cellPhone)}>
            {translateStatusText(data)}
          </Tooltip> : null}
          <div className={handleStatusClassName(data)} style={{width: 'fit-content'}} id={'tooltip-'+data.cellPhone} onMouseLeave={toggle}>
            {translateStatusIcon(data)}
          </div>
        </>
      )
    },
    {
      type: 'actions',
      property: 'actions',
      order: 6,
      width: 50,
      toggle: (data) => handleDropdown(data),
      component: (data) => <ChannelAction channel={data} qrCodes={qrCodes} loading={loading}/>
    },
  ]

  const getWSEvent = () => {
    if (!socketContext) return

    if (socketContext.qrCode) {
      setQrcodes(prev => {
        const existingIndex = prev.findIndex(qrcode => qrcode.id === socketContext.id)

        if (existingIndex === -1) {
          return [...prev, socketContext]
        } else if (socketContext.date > prev[existingIndex].date) {
          const updatedQrcodeArray = [...prev]
          updatedQrcodeArray[existingIndex] = socketContext
          return updatedQrcodeArray
        } else {
          return prev
        }
      });
    }

    if (socketContext.type === 'loading') {
      setLoading(socketContext)
    }
  }

  useMemo(() => {
    getWSEvent()
    dispatch(sidebarAction('Canais'))
  }, [socketContext])

  useEffect(() => {
    setLoading(false)
  }, [qrCodes])

  return (
    <div className="layout">
      <div className="content">
        <div className="content bg-light p-0 align-items-center text-black flex-column h-100">
          <FilterHeader 
            title="Canais" 
            maxRows={channelList?.length}
          />
          <div className="sidebar-body w-100 pb-4 px-5 pt-0 position-relative" style={{ height: '80%' }} id="sidebar-customers">
            {channelList ?
              <PerfectScrollbar className="w-100 hide-rail-x">
                {
                 isMobile ? (
                  <Table>
                    <CardsLayoutChannels
                      data={channelList}
                      loading={loading}
                      handleDropdown={handleDropdown}
                      handleStatusClassName={handleStatusClassName}
                      translateStatusIcon={translateStatusIcon}
                      qrCodes={qrCodes}
                      ChannelAction={ChannelAction}
                    />
                  </Table>
                 ) : (
                  <Table>
                    <TableHeader itemsTableHeader={itemsTableHeader} theadClassName={'thead-channels'}/>
                    <TableBody cellsTableData={cellsTableData} data={channelList} bodyClassName={'tbody-channels'}/>
                  </Table>
                 )
                 }
              </PerfectScrollbar>
            : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LayoutChannels