import React, { createContext, useContext, useMemo, useState } from 'react'
import { getSocketInstance } from '../Services/Socket-Service'
import { AuthContext } from './AuthContext'
import { useEffect } from 'react'

export const MessageContext = createContext({
  socketContext: {
    _id: '',
    id: '',
    from: '',
    to: '',
    body: '',
    type: '',
    hasMedia: false,
    timestamp: 0,
    ack: 0,
    fromName: '',
    operatorId: '',
    departmentId: '',
    departmentName: '',
    cellPhone: '',
    reference: '',
    createdAt: '',
    updatedAt: '',
    operatorName: '',
    origin: '',
  },
  socketInstance: null,
  showActions: { actions: false, emojiOpened: false, id: '' },
  setShowActions: ({ actions, id, emojiOpened }) => null,
  openOverlay: false,
  setOpenOverlay: (open) => null,
  overlayClicked: false,
  setClickOverlay: (click) => null
})


export const MessageProvider = ({ children }) => {
  const { accessToken } = useContext(AuthContext)
  const [socketContext, setSocketContext] = useState(null)
  const [openOverlay, setOpenOverlay] = useState(false)
  const [overlayClicked, setClickOverlay] = useState(false)
  const socketInstance = getSocketInstance(accessToken)
  const [showActions, setShowActions] = useState({ id: '', actions: false, emojiOpened: false });

  useEffect(() => {
    socketInstance.on('chatbot_message', async (res) => {
      setSocketContext(res)
    })

    socketInstance.on('chatbot_message_revoke', async (res) => {
      setSocketContext({ ...res, type: 'delete' })
    })

    socketInstance.on('chatbot_message_ack', async (res) => {
      setSocketContext({ ...res, type: 'ack' })
    })

    socketInstance.on('chatbot_message_reaction', async (res) => {
      setSocketContext({ ...res, type: 'reaction' })
    });

    socketInstance.on("chatbot_message_edit" , async (res) => {
      setSocketContext({ ...res, type: 'edited' })
    })

    return () => {
      socketInstance.off('chatbot_message')
      socketInstance.off('chatbot_message_revoke')
      socketInstance.off('chatbot_message_ack')
      socketInstance.off('chatbot_message_reaction')
      socketInstance.off('chatbot_message_edit')
    }
  }, [accessToken])

  return (
    <MessageContext.Provider value={{ socketContext, socketInstance, showActions, setShowActions, openOverlay, setOpenOverlay, overlayClicked, setClickOverlay }}>
      {children}
    </MessageContext.Provider>
  )
}
