import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Select from 'react-select';

const ConfirmationModal = ({
  openConfirmationModal,
  setOpenConfirmationModal,
  messageModal,
  listOptions,
  choosedOption,
  setChoosedOption,
  editing,
  confirmationFunction,
  handleStartAttendance,
  handleStartAttendanceLayoutCustomer,
  handleRemoveCustomer,
  handleDisableUser,
  handleDeleteTag,
  handleCancelCampaign,
  handleCancelGroup,
  handleDeleteDepartment
}) => {

  const handleButtonClick = (handler, ...args) => {
    if (handler) {
      handler(...args);
    }
  };

  const renderButton = (handler, args, label = 'Confirmar') => (
    <button
      type="button"
      onClick={() => handleButtonClick(handler, ...args)}
      className="btn btn-primary btn-xs"
    >
      {label}
    </button>
  );

  return (
    <Modal
      isOpen={openConfirmationModal?.open}
      toggle={() => setOpenConfirmationModal(null)}
      centered
      className="call modal-dialog-zoom"
      zIndex={9999}
    >
      <ModalBody>
        <div
          className="call-background"
          style={{ backgroundColor: '#e6e6e6', filter: 'initial', opacity: 'initial' }}
        ></div>
        <div>
          <h4 style={{ color: 'black' }}>{messageModal}</h4>
          {listOptions && (
            <div className='mx-5 my-2'>
              <Select
                options={listOptions}
                name='departments'
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'Nenhum departamento disponível.'}
                placeholder="Selecione"
                classNamePrefix='multiselect'
                menuPortalTarget={document.querySelector('body')}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                  valueContainer: css => ({
                    ...css,
                    flexWrap: "nowrap",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: 'ellipsis'
                  })
                }}
                isMulti={false}
                onChange={setChoosedOption}
                value={choosedOption}
              />
            </div>
          )}
          <div>
            <button
              type="button"
              onClick={() => setOpenConfirmationModal(null)}
              className="btn btn-secondary btn-xs"
              style={{ marginRight: 10 }}
              data-dismiss="modal"
            >
              Cancelar
            </button>
            {confirmationFunction && renderButton(confirmationFunction, [editing, openConfirmationModal?.info, openConfirmationModal?.open, choosedOption])}
            {handleStartAttendance && renderButton(handleStartAttendance, [choosedOption])}
            {handleStartAttendanceLayoutCustomer && renderButton(handleStartAttendanceLayoutCustomer, [openConfirmationModal?.info, choosedOption])}
            {handleRemoveCustomer && renderButton(handleRemoveCustomer, [openConfirmationModal?.customer])}
            {handleDisableUser && renderButton(handleDisableUser, [openConfirmationModal?.info])}
            {handleDeleteTag && renderButton(handleDeleteTag, [openConfirmationModal?.info])}
            {handleCancelCampaign && renderButton(handleCancelCampaign, [openConfirmationModal?.info])}
            {handleCancelGroup && renderButton(handleCancelGroup, [openConfirmationModal?.info])}
            {handleDeleteDepartment && renderButton(handleDeleteDepartment, [openConfirmationModal?.info])}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationModal;

