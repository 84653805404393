import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane, CustomInput } from 'reactstrap';
import classnames from 'classnames';

const SettingsModal = ({
    openModalSettings,
    setModalSettings,
    actionFunction,
}) => {
    const [notifications, setNotifications] = useState(openModalSettings?.info);
    const [activeTab, setActiveTab] = useState('1');

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setNotifications(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <Modal
            isOpen={openModalSettings?.open} toggle={() => setModalSettings({ open: false })}
            centered
            className="call modal-dialog-zoom"
            size='md'
            zIndex={999}>
            <ModalHeader style={{ backgroundColor: '#d6d6d6' }}>
                <div className='d-flex my-1' style={{ alignItems: 'baseline' }}>
                    <i className='fa fa-cog' style={{ color: 'black', fontSize: '30px' }} />
                    <h5 style={{ color: 'black' }}>Configurações</h5>
                </div>
                <button className={'btn btn-light btn-modal-close'}
                    onClick={() => setModalSettings({ open: false })}>
                    <i className='fa fa-times' style={{ marginRight: '0px' }} />
                </button>
            </ModalHeader>
            <ModalBody className='py-3'>
                <div className="call-background"
                    style={{
                        backgroundColor: '#f5f5f5',
                        filter: 'initial',
                        opacity: 'initial',
                        margin: '0px',
                        border: '1px solid #ced4da',
                    }}></div>
                <div>
                    <Nav tabs className='ml-2'>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggleTab('1'); }}
                            >
                                Notificações
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <div className='ml-4' style={{ color: 'black', textAlign: 'left' }}>
                                <form>
                                    <h5>Em atendimento</h5>
                                    <div className="custom-control custom-switch">
                                        <CustomInput
                                            type="switch"
                                            id="inAttendanceSound"
                                            name="inAttendanceSound"
                                            label="Aviso sonoro"
                                            checked={notifications?.inAttendanceSound}
                                            onChange={handleCheckboxChange}
                                        />
                                    </div>
                                    <div className="custom-control custom-switch">
                                        <CustomInput
                                            type="switch"
                                            id="inAttendancePopup"
                                            name="inAttendancePopup"
                                            label="Aviso janela pop-up"
                                            checked={notifications?.inAttendancePopup}
                                            onChange={handleCheckboxChange}
                                        />
                                    </div>
                                    <h5 className='mt-3'>Aguardando atendimento</h5>
                                    <div className="custom-control custom-switch">
                                        <CustomInput
                                            type="switch"
                                            id="waitingAttendanceSound"
                                            name="waitingAttendanceSound"
                                            label="Aviso sonoro"
                                            checked={notifications?.waitingAttendanceSound}
                                            onChange={handleCheckboxChange}
                                        />
                                    </div>
                                    <div className="custom-control custom-switch">
                                        <CustomInput
                                            type="switch"
                                            id="waitingAttendancePopup"
                                            name="waitingAttendancePopup"
                                            label="Aviso janela pop-up"
                                            checked={notifications?.waitingAttendancePopup}
                                            onChange={handleCheckboxChange}
                                        />
                                    </div>
                                </form>
                            </div>
                        </TabPane>
                    </TabContent>
                    <div className='mr-4' style={{ textAlign: 'right' }}>
                        <button type="button"
                            onClick={() => {
                                actionFunction(notifications, notifications);
                                setModalSettings({ open: false });
                            }}
                            className="btn btn-primary btn-xs">
                            Salvar
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default SettingsModal;


