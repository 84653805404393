import React, { useEffect, useRef } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useState } from 'react';
import { Table, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { TableBody } from '../App/Partials/Table/TableBody';
import { TableHeader } from '../App/Partials/Table/TableHeader';
import { getCampaignData } from '../Services/Campaign-Service';
import CampaignStatusCheck from '../App/Partials/CampaignStatusCheck';
import ChannelStatusCampaignCheck from '../App/Partials/ChannelStatusCampaignCheck';
import { CardsLayoutDashboardCampaign } from '../App/Partials/CardsLayout/CardsLayoutDashboardCampaign';

function CampaignChartBar() {
  const [loading, setLoading] = useState();
  const [campaignList, setCampaignList] = useState(undefined);
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const scrollRef = useRef()
  const inputRef = useRef();

  const handleGetCampaign = async () => {
    try {
      setLoading(true)
      const pageLimit = 10;
      const type = 'broadcastList';
      const field = 'dateFrom';
      const res = await getCampaignData(page, pageLimit, type, field);
      
      setCampaignList(res.data);
      setMaxPages(res.meta.pages);
    } catch (error) {
        console.error(error)
    } finally {
        setLoading(false)
    }
  };

  const handleInfiniteGetCampaigns = async (page) => {
    if (loading || maxPages < page) {
      return
    }
    try {
      setLoading(true)
      const pageLimit = 10;
      const type = 'broadcastList';
      const field = 'dateFrom';
      const res = await getCampaignData(page, pageLimit, type, field);
      const newData = res.data
      const scrollHeight = scrollRef.current.scrollHeight
      setPage(page)
      setCampaignList((prevCustomerList) => [...prevCustomerList, ...newData]);
      setMaxPages(res.meta.pages);
      scrollRef.current.scrollTop = (scrollHeight * 2) - scrollHeight;
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  };

  const itemsTableHeader = [
    { label: 'Nome', order: 0 },
    { label: 'Canais', order: 1 },
    { label: 'Data', order: 2 },
    { label: 'Status', order: 3 }
  ]

  const cellsTableData = [
    {
      type: 'longText',
      property: 'name',
      order: 0,
      width: 150,
    },
    {
      type: 'customComponent',
      order: 1,
      width: 150,
      component: (data) => (
        <ComponentCampaignPopover
          data={data}
         />
      )
    },
    {
      type: 'customComponent',
      order: 2,
      width: 150,
      component: (data) => (
        <ComponentDatePopover
          data={data}
        />
      )
    },
    {
      type: 'customComponent',
      order: 3,
      width: 150,
      component: (data) => (
        <CampaignStatusCheck campaign={data} />
      )
    },
  ]

  const ComponentCampaignPopover = ({ data }) => {
    const [popoverCampaignOpen, setPopoverCampaignOpen] = useState(false);
    const toggleCampaignOpen = () => setPopoverCampaignOpen(!popoverCampaignOpen);

    return (
      <>
        <div
          id={`channelsList-${data._id}`}
          style={{ width: "30px" }}
          onMouseEnter={toggleCampaignOpen}
          onMouseLeave={toggleCampaignOpen}
        >
          {data.channels.length}
        </div>
        <Popover
          placement="right"
          isOpen={popoverCampaignOpen}
          target={`channelsList-${data._id}`}
        >
          <PopoverHeader>Canais vinculados</PopoverHeader>
          <PopoverBody>
            <table>
              <tbody>
                {data.channels?.map((channel, i) => (
                  <tr key={i}>
                    <td>{channel?.name}</td>
                    <td>
                      <ChannelStatusCampaignCheck channel={channel} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </PopoverBody>
        </Popover>
      </>
    );
  };

  const ComponentDatePopover = ({ data }) => {
    const [popoverDateOpen, setPopoverDateOpen] = useState(false);
    const toggleDateOpen = () => setPopoverDateOpen(!popoverDateOpen);

  return (
    <>
      <div
        id={`datePopover-${data._id}`}
        style={{ width: "30px" }}
        onMouseEnter={toggleDateOpen}
        onMouseLeave={toggleDateOpen}
      >
        <span><i class="fa fa-calendar" aria-hidden="true"></i></span>
      </div>
      <Popover
        placement="right"
        isOpen={popoverDateOpen}
        target={`datePopover-${data._id}`}
      >
        <PopoverBody>
          <div>
            <strong>Data{data.dateTo ? ' inicial' : ''}:</strong> {new Date(data.dateFrom).toLocaleString('pt-BR')}
          </div>
          {data.dateTo ? (
            <div>
              <strong>Data final:</strong> {new Date(data.dateTo).toLocaleString('pt-BR')}
            </div>
          ) : (
            null
          )}
        </PopoverBody>
      </Popover>
    </>
    );
  };

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  });

  useEffect(() => {
    handleGetCampaign()
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='layout'>
      <div className="sidebar-body"
        style={{ position: 'relative', height:'100%'}}
        id="sidebar-departments">
        {loading &&
          <div className="overlay-loading-customers">
            <i className="fa fa-spinner"
              style={{
                animation: 'spin 3s linear infinite',
                fontSize: 42
              }}></i>
          </div>
        }
        {Array.isArray(campaignList) ?
          <PerfectScrollbar
            onYReachEnd={(e) => { handleInfiniteGetCampaigns(page + 1) }}
            containerRef={ref => scrollRef.current = ref}
            className='hide-rail-x'>
              {
                isMobile ? (
                  <Table>
                    <CardsLayoutDashboardCampaign
                      data={campaignList}
                      loading={loading}
                      ComponentCampaignPopover={ComponentCampaignPopover}
                      />
                  </Table>
                ) : (
                  <Table>
                    <TableHeader
                      itemsTableHeader={itemsTableHeader}
                      theadClassName={'thead-customers'} />
                    <TableBody
                      data={campaignList}
                      cellsTableData={cellsTableData}
                      bodyClassName={'tbodyCampaign'}
                      loading={loading}
                    />
                  </Table>
                )
              }
          </PerfectScrollbar>
          : null}
      </div>
    </div>
  )
}

export default CampaignChartBar;