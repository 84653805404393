import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import store from '../../../Store';
import { mobileProfileAction } from "../../../Store/Actions/mobileProfileAction";
import { mobileQueuesAction } from '../../../Store/Actions/mobileQueuesAction';
import { profileAction } from "../../../Store/Actions/profileAction";
import { queuesAction } from '../../../Store/Actions/queuesAction';
import { selectedChatAction } from '../../../Store/Actions/selectedChatAction';

const ChatsDropdown = (props) => {

    const dispatch = useDispatch();

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const profileActions = () => {
        if(store.getState().profileSidebar && store.getState().mobileProfileSidebar){
          dispatch(profileAction(false))
          dispatch(mobileProfileAction(false))
        }

        dispatch(profileAction(true))
        dispatch(mobileProfileAction(true))
        dispatch(selectedChatAction(props.customer))

        if(store.getState().queuesSidebar && store.getState().mobileQueuesSidebar){
          dispatch(queuesAction(false))
          dispatch(mobileQueuesAction(false))
        }
    };

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} style={{zIndex: 999}}>
            <DropdownToggle tag="a">
                <i className="ti ti-more"></i>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={profileActions}>Perfil</DropdownItem>
                {/* <DropdownItem>
                  <FinishAttendanceModal dropdown/>
                </DropdownItem> */}
            </DropdownMenu>
        </Dropdown>
    )
};

export default ChatsDropdown
