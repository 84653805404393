import api from "./Axios.config"

const url = process.env.REACT_APP_API_URL + '/api/notification/v1'

export const getConsolidatedNotifications = async (page, startDate, endDate, types = 'DueToday,Delayed,Reminder,Serasa') => {
  try {
    const response = await api.get(`${url}/consolidated-notifications?page=${page}&startDate=${startDate}&endDate=${endDate}&types=${types}`)
    return response.data
  } catch (error) {
    if (error.response?.status === 400) return error.response?.data

    console.error('Error fetching data:', error)
    throw new Error('Failed to get consolidated notifications')
  }
}

export const getDetailedNotifications = async (page, startDate, endDate, status = 'Sended', types = 'Delayed,Reminder,Serasa') => {
  try {
    const response = await api.get(`${url}/detailed-notifications?page=${page}&startDate=${startDate}&endDate=${endDate}&types=${types}`)
    return response.data
  } catch (error) {
    if (error.response?.status === 400) return error.response?.data

    console.error('Error fetching data:', error)
    throw new Error('Failed to get detailed notifications')
  }
}
