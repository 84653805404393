import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useToast } from '../Toast';
import useEditableMessageVerification from '../useEditableMessageVerification';
import { saveAs } from 'file-saver';

const MessageActions = ({ customer, replyingMessage, message, deleteMessage, editMessage }) => {
    const { showWarningToast } = useToast()
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [openEditMessageModal, setOpenEditMessageModal] = useState({ open: false, info: null });

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const downloadAttachmentFunction = async (message, fileName) => {

        if (!message || !message.attachment) {
            showWarningToast('Falha no download.');
            return;
        }
        if (!fileName) {
            const url = message.attachment;
            const fileNameFromServer = url.substring(url.lastIndexOf('/') + 1);
            const splitFileName = fileNameFromServer.split('.');
            const fileExtension = splitFileName.pop();
            fileName = `Arquivo.${fileExtension}`;

        }
        try {
            const response = await fetch(message.attachment);
            if (!response.ok) {
                throw new Error('Erro na requisição do anexo.');
            }
            const blob = await response.blob();
            saveAs(blob, fileName);
        } catch (error) {
            console.error('Erro ao baixar o anexo:', error);
            showWarningToast('Ocorreu um erro ao tentar baixar o anexo.');
        }
    };

    const downloadAttachment = (message) => {
        if (!message.attachment) {
            showWarningToast('Falha no download. \n Não foi possível baixar. Por favor, peça para que seja reenviado.')
            return
        }
        downloadAttachmentFunction(message, message?.attachmentName)
    }
    
    const isEditable = useEditableMessageVerification({ timestamp: message?.timestamp }) && message.type === 'chat';
    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} style={{ zIndex: 999, position: 'absolute', right: 5, top: 3, borderRadius: 5, backgroundColor: message.fromMe ? '#cdcdcd82' : '#fff9', padding: '1px 3px' }}>
            <DropdownToggle tag="a">
                <i className="fa fa-chevron-down" style={{ cursor: 'pointer' }}></i>
            </DropdownToggle>
            <DropdownMenu>
                {isEditable !== null && (
                    <>
                        <DropdownItem onClick={() => { replyingMessage(message); document.getElementById('input-chat').focus() }}>Responder</DropdownItem>
                        {message.hasMedia && <DropdownItem onClick={() => { downloadAttachment(message) }}>Baixar</DropdownItem>}
                        {message.fromMe && (isEditable && (<DropdownItem onClick={() => { editMessage(message) }}>Editar</DropdownItem>))}
                        {message.fromMe && (<DropdownItem onClick={() => { deleteMessage(message) }}>Apagar</DropdownItem>)}
                    </>
                )}
            </DropdownMenu>
        </Dropdown >
    );
};

export default MessageActions
