import React, { useEffect, useState, useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import ptBR from 'date-fns/locale/pt-BR';

const DatePickerComponent = ({ selectedDate, setSelectedDate, placeholderText, filterTime, schedulingDate, isDisabled }) => {

  const customInputStyle = {
    width: '200px',
    padding: '8px',
    fontSize: '14px',
    className: 'multiselect__control css-13cymwt-control',
    marginLeft: '40px',

    
  };

  const customIconStyle = {
    fontSize: '160px',
    marginLeft: '80px',
  };

  const handleGetFilterTime = async () => {
    return filterTime
  }
  const hideDisabledTimeItems = `
  .react-datepicker__time-list-item--disabled {
    display: none;
  }
  `;

  const handleDayClick = (date) => {
    setSelectedDate(new Date(date.setHours(12)));
  };

  return (
    <>
      <style>{hideDisabledTimeItems}</style>
      <DatePicker
        //readOnly={isDisabled}
        timeIntervals={5}
        disabled={isDisabled}
        selected={selectedDate}
        onChange={(date) => setSelectedDate(date)}
        onSelect={handleDayClick}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        defaultTime={""}
        showLeadingZeros={true}
        showTimeSelect
        timeFormat="HH:mm"
        editable={false}
        dateFormat="dd/MM/yyyy, HH:mm"
        className="form-field"
        id="birthDate"
        placeholderText={placeholderText}
        locale={ptBR}
        minDate={new Date()}
        filterTime={filterTime}
        popperPlacement="bottom"
        customInput={<input className='multiselect__control css-13cymwt-control'
          style={{
            width: '14.55vw',
            paddingLeft: '15px',
            backgroundColor: isDisabled ? '#e9ecef' : '',
            border:isDisabled ? '1px solid #ced4da' : '', 
          }} />}
        customTimeInput={<input style={customInputStyle} />} // Estilizando o input do tempo
        customTimeIcon={<span style={customIconStyle} />} // Estilizando o ícone do tempo
      />
    </>
  );
};

export default DatePickerComponent;
