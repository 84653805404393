import axios from 'axios'
import { useToast } from '../App/Partials/Toast'
import jwtDecode from 'jwt-decode'

const api = axios.create()
const { showErrorToast } = useToast()
const accessToken = sessionStorage.getItem('accessToken')

const isTokenValid = (token) => {
  if (!token) {
    return false
  }

  const tokenExpiration = jwtDecode(token).exp
  const expirationTimestamp = tokenExpiration * 1000
  const currentTimestamp = Date.now()
  return currentTimestamp < expirationTimestamp
}

api.interceptors.request.use(
  (config) => {
    if (isTokenValid(accessToken)) {
      config.headers['x-access-token'] = accessToken
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      showErrorToast('Sessão expirada. Faça login novamente.', 3000,'sessionExpired')
      sessionStorage.removeItem('accessToken')
      setTimeout(() => {
        window.location.replace('/')
      }, 3100)
    }

    else if (error.response && error.response.status >= 400 && error.response.status < 500) {
      if (error.response.data?.description) {
        showErrorToast(error.response.data.description || "Desculpe, houve um problema com sua solicitação")
      }
    }
    return Promise.reject(error)
  }
)

export default api
