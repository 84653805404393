import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Progress } from "reactstrap";

const ChannelQrcodeModal = ({ openChannelModal, setOpenChannelModal, hasQrcode, loading, isLoadingWaitingQrcode }) => {
  const QrcodeInstructions = () => {
    return (
      <div>
        <h3>Use o WhatsApp no seu computador</h3>
        <ol>
          <li>Abra o WhatsApp no seu celular</li>
          <li>
            Toque em <strong>Mais opções <i className="fa fa-ellipsis-v mr-1" aria-hidden="true"></i></strong>  
             ou <strong>Configurações <i className="fa fa-cog mr-1" aria-hidden="true"></i></strong> 
            e selecione <strong>Aparelhos conectados</strong>.
          </li>
          <li>Toque em <strong>Conectar um aparelho</strong>.</li>
          <li>Aponte seu celular para esta tela para capturar o QR Code.</li>
        </ol>
      </div>
    )
  }

  const HandleQrcode = () => {
    if (hasQrcode) {
      if (loading?.id === hasQrcode.id) {
        return (
          <div className='text-center'>
            Carregando sessão
            <Progress animated style={{ height: 10 }} value={loading.percent} color="success" />
          </div>
        )
      }

      return (
        <div className="d-flex align-items-center" style={{ gap: 20 }}>
          <QrcodeInstructions />
          <div className='text-center'>
            <img src={hasQrcode.qrCode} alt="Qr code sessão" width={'80%'} height={'80%'} /> <br />
            <span>
              {hasQrcode.date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric', second: 'numeric' })}
            </span>
          </div>
        </div>
      )
    }

    if (isLoadingWaitingQrcode) {
      return (
        <>
          <span>Conectando, aguarde...</span><br /><br />
          <i className="fa fa-spinner" style={{ animation: 'spin 3s linear infinite', fontSize: 80, height: 'fit-content', margin: '10px 0' }}></i>
        </>
      )
    }

    return (
      <div className="d-flex align-items-center" style={{ gap: 20 }}>
        <QrcodeInstructions />
        <div className='text-center'>
          <i className="fa fa-spinner" style={{ animation: 'spin 3s linear infinite', fontSize: 42, margin: '10px 0px' }}></i> <br />
          <span>Carregando QRCODE... <br /></span>
        </div>
      </div>
    )
  }

  return (
    <>
      <Modal zIndex={9999} isOpen={openChannelModal} toggle={() => setOpenChannelModal(!openChannelModal)} centered backdrop={true} size='lg'
        className="modal-dialog-zoom">
        <ModalBody>
          <div className="connection-error">
            <h1 className="text-center">Conectar canal</h1>
            <hr />
            <HandleQrcode />
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ChannelQrcodeModal
