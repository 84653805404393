import api from './Axios.config'

const url = process.env.REACT_APP_API_URL + '/api/marketing/v1'

export const getAllCampaignTypes = async () => {
  try {
    const response = await api.get(`${url}/campaign-types`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error ('Failed to get all campaign types')
  }
}

export const postCampaign = async (body) => {
  try {
    await api.post(`${url}/campaigns`, body)
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to create tag')
  }
}

export const getAllCampaign = async (filter = '', page) => {
  try {
    const response = await api.get(`${url}/campaigns?filter=${filter}&page=${page}&order_field=dateFrom`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get campaign')
  }
}

export const getCampaignData = async (page, pageLimit, type, field) => {
  try {
      const response = await api.get(`${url}/campaigns?page=${page}&pageLimit=${pageLimit}&types=${type}&order_field=${field}`);
      return response.data;
  } catch (error) {
      console.error('Error fetching data:', error);
      throw new Error('Failed to get total campaign');
  }
};

export const findAllAgendas =  async (id) => {
  try {
    const response = await api.get(`${url}/campaign-types/${id}/agendas`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get planners')
    
  }
}  

export const cancelCampaign = async (id) => {
  try {
    const response = await api.delete(`${url}/campaigns/${id}`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to cancel campaign')
  }

}

export const findMediaTypes =  async (id) => {
  try {
    const response = await api.get(`${url}/campaigns/${id}/media-types`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get planners')
    
  }
}  

export const updateCampaign = async (payload) => {
  try {
    const response = await api.put(`${url}/campaigns/${payload.id}`, payload.body, {
    })
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to update campaign')
  }
}

export const findCampaignById = async (id) => {
  try {
    const response = await api.get(`${url}/campaigns/${id}`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw new Error('Failed to get campaign by id')
  }
}