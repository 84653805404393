import React, { Fragment } from "react";

export const convertText = (text) => {
  if(!text) return ''
  
  const parts = text.split('\n');

  return parts.map((part, index) => (
    <Fragment key={index}>
      {part
        .split('*')
        .map((subPart, subIndex) =>
          subIndex % 2 === 0 ? subPart : <strong key={subIndex}>{subPart}</strong>
        )}
      {index !== parts.length - 1 && <br />}
    </Fragment>
  ));
}
