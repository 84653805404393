import api from "./Axios.config";

const url = process.env.REACT_APP_API_URL + '/api/user/v1';

export const getUser = async () => {
    try {
        const response = await api.get(`${url}/users`)
        return response.data
    } catch (error) {
        console.error('Erro fetching data:', error)
        throw new Error('Failed to get user')
    }
};

export const getUserProfile = async () => {
    try {
        const response = await api.get(`${url}/profiles`)
        return response.data
    } catch (error) {
        console.error('Erro fetching data:', error)
        throw new Error('Failed to get profile')
    }
};

export const postUser = async (data) => {
    try {
        const response = await api.post(`${url}/users`, data);
        return response.data;
    } catch (error) {
        console.error('Erro ao enviar dados:', error);
        throw new Error('Falha ao criar usuário');
    }
};

export const putUser = async (_id, data) => {
    try {
        const response = await api.put(`${url}/users/${_id}`, data);
        return response.data;
    } catch (error) {
        console.error('Erro ao atualizar usuário:', error);
        throw new Error('Falha ao atualizar usuário');
    }
};

export const deleteUser = async (_id) => {
    try {
        const response = await api.delete(`${process.env.REACT_APP_API_URL}/api/user/v1/users/${_id}`);
        return response.data;
    } catch (error) {
        console.error('Erro ao excluir usuário:', error);
        throw new Error('Falha ao excluir usuário');
    }
};