import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import * as moment from 'moment';
import { Container, Row, Col, Card} from 'reactstrap';
import DoughnutChart from './DoughnutChart';
import StackedBarChart from './StackedBarChart';
import ChartCard from './ChartCard';
import HorizontalBarChart from './HorizontalBarChart';
import { sidebarAction } from '../Store/Actions/sidebarAction';
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom";

import {
  getTotalAttendance,
  getTotalStatusQueues,
  getTotalDailyStatusAttendace,
  getTotalDailyStatusDepartmentAttendances,
  getTotalFinishedReasons,
  getTotalFinishedReasonTypes,
  getTotalDailyStatusOperatorAttendances
} from '../Services/Attendance-Service';


const DashboardAttendance = () => {
  const [loading, setLoading] = useState(false);
  const [loadingStatusAttendace, setLoadingStatusAttendace] = useState(false)
  const [loadingFinishedReasons, setLoadingFinishedReasons] = useState(false)
  const [loadingDepartment, setLoadingDepartment] = useState(false)
  const [loadingDepartmentOperator, setLoadingDepartmentOperator] = useState(false)
  const [loadingFinishedCategory, setLoadingFinishedCategory] = useState(false)

  const [date, setDate] = useState(moment());
  const [showCalendar, setShowCalendar] = useState(false);


  const [inAttendances, setInAttendances] = useState([])
  const [totalAttendance, setTotalAttendance] = useState(0)
  const [transferredAttendances, setTransferredAttendances] = useState(0)
  const [inciatedAttendances, setIniciatedAttendances] = useState(0)
  const [completedAttendances, setCompletedAttendances] = useState(0)
  const [autoAttendances, setAutoAttendances] = useState(0)
  const [totalInAttendance, setTotalInAttendance] = useState(0)
  const [totalWaitingAttendance, SetTotalWaitingAttendance] = useState(0)
  const [dataAttendanceByDepartament, setDataAttendanceByDepartament] = useState([])
  const [dataAttendanceByOperator, setDataAttendanceByOperator] = useState([])
  const [bottonActive, setBottonActive] = useState(true)
  const [dataChartStackedChart, setDataChartStackedChart] = useState([])
  const [dataFinishAttendance, setDataFinishAttendance] = useState([])
  const [dataFinishCategoryTypes, setDataFinishCategoryTypes] = useState([])
  const dispatch = useDispatch()
  const history = useHistory()

  const handleGetCardsInformation = async (newDate) => {

    try {
      setLoading(true);

      const currentDate = moment(newDate);
      const dateFrom = currentDate.startOf('day').format('YYYY-MM-DDT00:00:00');
      const dateTo = currentDate.endOf('day').format('YYYY-MM-DDT23:59:59');

      const responseAttendance = await getTotalAttendance(dateFrom, dateTo)
      const { transfer, finished, started } = responseAttendance;
      const totalAttendance = parseInt(transfer) + parseInt(finished) + parseInt(started)
      setIniciatedAttendances(started)
      setCompletedAttendances(finished)
      setTransferredAttendances(transfer)
      setTotalAttendance(totalAttendance)

    } catch (error) {
      console.error(error.response.data.message)
    }
    finally {
      setLoading(false)
    }
  }

  const handleGetTotalStatusQueues = async () => {
    setLoading(true);
    try {
      const responseQueue = await getTotalStatusQueues()
      const { auto, inAttendance, waitingAttendance } = responseQueue
      setAutoAttendances(auto)
      setTotalInAttendance(inAttendance)
      SetTotalWaitingAttendance(waitingAttendance)

    } catch (error) {
      console.error(error.response.data.message)
    }
    finally {

      setLoading(false)
    }
  }
  const handleGetDailyStatusAttendace = async (newDate) => {
    try {
      setLoadingStatusAttendace(true);
      const currentDate = moment(newDate);
      const dateTo = currentDate.startOf('day').format('YYYY-MM-DDT23:59:59');
      const dateFrom = currentDate.endOf('day').subtract(7, 'days').format('YYYY-MM-DDT00:00:00');
      const response = await getTotalDailyStatusAttendace(dateFrom, dateTo)
      setDataChartStackedChart(response)
    } catch (error) {
      console.error(error.response.data.message)
    } finally {
      setLoadingStatusAttendace(false)
    }

  }


  const handleGetAttendancesByDepartment = async (newDate) => {
    try {
      setLoadingDepartment(true);

      const currentDate = moment(newDate);
      const dateFrom = currentDate.startOf('day').format('YYYY-MM-DDT00:00:00');
      const dateTo = currentDate.endOf('day').format('YYYY-MM-DDT23:59:59');
      const responseDepartament = await getTotalDailyStatusDepartmentAttendances(dateFrom, dateTo)
      setDataAttendanceByDepartament(responseDepartament)
    } catch (error) {
      console.error(error.response.data.message)
    } finally {
      setLoadingDepartment(false)
    }

  }

  const handleGetAttendancesByOperator = async (newDate) => {
    try {
      setLoadingDepartmentOperator(true);
      const currentDate = moment(newDate);
      const dateFrom = currentDate.startOf('day').format('YYYY-MM-DDT00:00:00');
      const dateTo = currentDate.endOf('day').format('YYYY-MM-DDT23:59:59');
      const responseOperator = await getTotalDailyStatusOperatorAttendances(dateFrom, dateTo)
      setDataAttendanceByOperator(responseOperator)
    } catch (error) {
      console.error(error.response.data.message)
    } finally {
      setLoadingDepartmentOperator(false)
    }

  }

  const handlegetTotalFinishedReasons = async (newDate) => {
    try {
      setLoadingFinishedReasons(true);

      const currentDate = moment(newDate);
      const dateFrom = currentDate.startOf('day').format('YYYY-MM-DDT00:00:00');
      const dateTo = currentDate.endOf('day').format('YYYY-MM-DDT23:59:59');
      const responseReasons = await getTotalFinishedReasons(dateFrom, dateTo)
      setDataFinishAttendance(responseReasons)
    } catch (error) {
      console.error(error.response.data.message)
    } finally {
      setLoadingFinishedReasons(false)
    }
  }

  const handlegetTotalFinishedCategory = async (newDate) => {
    try {
      setLoadingFinishedCategory(true);
      const currentDate = moment(newDate);
      const dateFrom = currentDate.startOf('day').format('YYYY-MM-DDT00:00:00');
      const dateTo = currentDate.endOf('day').format('YYYY-MM-DDT23:59:59');
      const repsonseTypes = await getTotalFinishedReasonTypes(dateFrom, dateTo)
      setDataFinishCategoryTypes(repsonseTypes)
    } catch (error) {
      console.error(error.response.data.message)
    } finally {
      setLoadingFinishedCategory(false)
    }
  }

  const increaseOrDecreaseDate = (isIncreasing) => {
    const newDate = isIncreasing ? moment(date).add(1, 'days') : moment(date).subtract(1, 'days');
    if (isIncreasing && newDate.isAfter(moment())) {

      return setBottonActive(false)
    }
    setDate(newDate);
    setBottonActive(true)
    handleGetCardsInformation(newDate);
    handleGetDailyStatusAttendace(newDate)
    handlegetTotalFinishedReasons(newDate)
    handlegetTotalFinishedCategory(newDate)
    handleGetAttendancesByDepartment(newDate)
    handleGetAttendancesByOperator(newDate)
  };

  const translateLegend = (legend) => {
    switch (legend) {
      case 'started':
        return 'Iniciados'
      case 'finished':
        return 'Finalizados'
      case 'transfer':
        return 'Transferidos'
      default:
        return 'Legenda não encontrada'
    }

  }

  useEffect(() => {
    handleGetCardsInformation()
    handleGetDailyStatusAttendace()
    handlegetTotalFinishedReasons()
    handlegetTotalFinishedCategory()
    handleGetAttendancesByDepartment()
    handleGetAttendancesByOperator()
    handleGetTotalStatusQueues()
    setTimeout(() => {
      dispatch(sidebarAction('Dashboard Atendimentos'))
    }, 100);
    history.push('/dashboard-atendimentos')
  }, []);

  const onChangeCalendar = (date) => {
    const selectedDate = moment(date);
    if (selectedDate.isAfter(moment())) {
      return;
    }
    setDate(selectedDate);
    handleGetCardsInformation(selectedDate);
    handleGetDailyStatusAttendace(selectedDate);
    handlegetTotalFinishedReasons(selectedDate);
    handlegetTotalFinishedCategory(selectedDate )
    handleGetAttendancesByDepartment(selectedDate)
    handleGetAttendancesByOperator(selectedDate)

    setShowCalendar(false);
  };

  return (
    <div className='layout'>
      <div className='content'>
        <div className='content bg-light p-0' style={{ flexDirection: 'column', color: 'black', alignItems: 'center' }}>
          <header className='d-lg-flex d-md-flex w-100 align-items-center p-4 px-5'>
            <p className='font-weight-bold mr-0 text-dark' style={{ fontSize: 18, whiteSpace: "nowrap" }}>
              Dashboard Atendimentos
            </p>
            <div className='w-100 position-relative mR-5' style={{ marginRight: '230px', marginLeft: "0px" }} >
              <div className='d-flex align-items-center justify-content-center ' style={{ gap: 20 }}>
                <i className='fa fa-arrow-left arrow-calendar rounded-pill' onClick={() => increaseOrDecreaseDate(false)}></i>
                <div className='w-lg-25 w-md-25 w-sm-100 py-2 px-5 input-calendar border-0 text-center font-weight-bold' style={{ borderRadius: "5px" }} onClick={() => setShowCalendar(!showCalendar)}>
                  {moment(date).format('DD/MM/YYYY')}
                </div>
                <i className={`${bottonActive ? 'fa fa-arrow-right arrow-calendar rounded-pill' : 'fa fa-times arrow-calendar arrow-calendar rounded-pill'}`} onClick={() => increaseOrDecreaseDate(true)}></i>
                {showCalendar ? <Calendar onChange={(date) => { onChangeCalendar(date) }} value={moment(date).format()} locale='pt-br' /> : null}
              </div>
            </div>
          </header>
          <hr style={{ width: '100%', height: 10, borderColor: 'lightgray', marginBottom: 30 }} />
          <div className='sidebar-body w-100 px-5 pt-0 d-flex flex-column mb-4' style={{ overflowY: 'scroll' }}>
            <Container fluid>
              <Row>
                <Col lg='4' md='6' xs='12' className='px-1 d-flex flex-column'>
                  <Card className='p-1 mt-1 mb-1 pb-0' style={{ flex: '1', border: '1px solid #ddd', borderTop: '3px solid #6161ff', borderRadius: '5px' }}>
                    <div>
                      <div className="col-md-12 px-1">
                        <div className="my-0">
                          <ChartCard header={'Total atendimentos'} information={totalAttendance} color={'#00bf63'} classCss={{ my: 'my-1' }} />
                        </div>
                      </div>
                      <div className="my-0 d-lg-flex d-md-flex" style={{ flex: '1' }}>
                        <div className="w-100 px-1 mb-0 mt-1 my-0">
                          <ChartCard header={'Auto atendimento'} information={autoAttendances} color={'#6161ff'} classCss={{ my: 'my-1' }} />
                        </div>
                        <div className="w-100  px-1 mb-0 mt-1 my-0">
                          <ChartCard header={'Iniciados'} information={inciatedAttendances} color={'#ff914d'} classCss={{ my: 'my-1' }} />
                        </div>
                      </div>
                      <div className="my-0 d-lg-flex d-md-flex" style={{ flex: '1' }}>
                        <div className="w-100 px-1 mb-0 mt-1 my-0">
                          <ChartCard header={'Aguardando Atendimento'} information={totalWaitingAttendance} color={'#6161ff'} classCss={{ my: 'my-1' }} />
                        </div>
                        <div className="w-100  px-1 mb-0 mt-1 my-0">
                          <ChartCard header={'Transferidos'} information={transferredAttendances} color={'#ff914d'} classCss={{ my: 'my-1' }} />
                        </div>
                      </div>
                      <div className="mb- d-lg-flex d-md-flex pb-1" style={{ flex: '1' }}>
                        <div className="w-100 px-1 mb-0 mt-1 my-0">
                          <ChartCard header={'Em atendimento'} information={totalInAttendance} color={'#6161ff'} classCss={{ my: 'my-1' }} />
                        </div>
                        <div className="w-100  px-1 mb-0 mt-1 my-0">
                          <ChartCard header={'Finalizados'} information={completedAttendances} color={'#ff914d'} classCss={{ my: 'my-1' }} />
                        </div>
                      </div>
                    </div>
                  </Card>
                  <Card className='p-1 mb-1 mt-2' style={{ flex: '1', border: '1px solid #ddd', borderTop: '3px solid #6161ff', borderRadius: '5px', height: '50vh' }}>
                    <StackedBarChart
                      title={'Total de atendimentos'}
                      legendText={['Iniciados', 'Transferidos', 'Finalizados']}
                      message={'No momento não há registro'}
                      data={dataChartStackedChart}
                      colors={['#00bf63', '#ff914d', '#6161ff']}
                      loading={loadingStatusAttendace}
                    />
                  </Card>
                </Col>
                <Col lg='4' md='6' xs='12' className='px-1 d-flex flex-column'>
                  <Card className='p-1 mb-1 mt-1' style={{ flex: '1', border: '1px solid #ddd', borderTop: '3px solid #6161ff', borderRadius: '5px', height: '50vh' }}>
                    <DoughnutChart title={'Finalização atendimento'}
                      data={dataFinishAttendance}
                      message={'No momento não há registro'}
                      titleToolTip={'Finalização atendimento:'}
                      loading={loadingFinishedReasons} />
                  </Card>
                  <Card className='p-1 mb-1 mt-2' style={{ flex: '1', border: '1px solid #ddd', borderTop: '3px solid #6161ff', borderRadius: '5px', height: '50vh' }}>
                    <DoughnutChart
                      title={'Categoria finalização'}
                      data={dataFinishCategoryTypes}
                      message={'No momento não há registro'}
                      titleToolTip={'Categoria finalização:'}
                      loading={loadingFinishedCategory} />
                  </Card>
                </Col>
                <Col lg='4' md='6' xs='12' className='px-1 d-flex flex-column'>
                  <Card className='p-1 mb-1 mt-1' style={{ flex: '1', border: '1px solid #ddd', borderTop: '3px solid #6161ff', borderRadius: '5px', height: '50vh' }}>
                    <HorizontalBarChart
                      title={'Atendimentos por departamento'}
                      data={dataAttendanceByDepartament}
                      legendText={['Iniciados', 'Transferidos', 'Finalizados']}
                      translateLegend={translateLegend}
                      message={'No momento não há registro'}
                      colors={['#00bf63', '#ff914d', '#6161ff']}
                      loading={loadingDepartment}
                    />
                  </Card>
                  <Card className='p-1 mb-1 mt-2' style={{ flex: '1', border: '1px solid #ddd', borderTop: '3px solid #6161ff', borderRadius: '5px', height: '50vh' }}>
                    <HorizontalBarChart
                      title={'Atendimentos por operador'}
                      data={dataAttendanceByOperator}
                      message={'No momento não há registro'}
                      legendText={['Iniciados', 'Transferidos', 'Finalizados']}
                      colors={['#00bf63', '#ff914d', '#6161ff']}
                      loading={loadingDepartmentOperator}
                    />
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg='6' md='6' xs='12' className='px-1 d-flex flex-column'>
                  <Card className='p-1 mt-1 mb-1 pb-1' style={{ flex: '1', border: '1px solid #ddd', borderTop: '3px solid #6161ff', borderRadius: '5px' }}>
                    <div style={{ height: '350px' }}>
                      <h5 style={{ color: '#6161FF', fontWeight: '150' }}>{"Componente 1"}</h5>
                    </div>
                  </Card>

                </Col>
                <Col lg='6' md='6' xs='12' className='px-1 d-flex flex-column'>
                  <Card className='p-1 mt-1 mb-1 pb-1' style={{ flex: '1', border: '1px solid #ddd', borderTop: '3px solid #6161ff', borderRadius: '5px' }}>
                    <div style={{ height: '350px' }}>
                      <h5 style={{ color: '#6161FF', fontWeight: '150' }}>{"Componente 2"}</h5>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>

  )

}

export default DashboardAttendance