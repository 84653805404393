import React, { useContext, useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import AudioWhatsapp from "./../../assets/audio/AudioWhatsapp.mp3";
import { Button } from 'reactstrap';
import { useToast } from "../../App/Partials/Toast";
import { postNotificationKey, postNotification } from "../../Services/Notifications-Message";
import { UserContext } from "../../Context/UserContext";
import { AttendanceContext } from "../../Context/AttendanceContext";
import useServiceWorkerRegistration from "../../useServiceWorkerRegistration";


const SettingsNotifications = () => {
  const { settings } = useContext(UserContext);
  const { socketContextUnread, socketContextInQueue } = useContext(AttendanceContext);
  const { registration } = useServiceWorkerRegistration('/sw.js');
  const [audioContext, setAudioContext] = useState(null);
  const [errorAudio, setErrorAudio] = useState('')

  const { showInfoNotificationToast, dismissAllToasts, showAdvertenceToast } = useToast()
  const location = useLocation();

  const [audioContextState, setAudioContextState] = useState(null);
  const [notificationShown, setNotificationShown] = useState(false);

  const verificationAuthNotification = async () => {
    if (Notification.permission !== "granted" && (settings?.inAttendancePopup || settings?.waitingAttendancePopup)) {
      Notification.requestPermission().then(function (getperm) {
      });
    }
  }

  const handleUserInteraction = () => {
    if (audioContext) {
      audioContext.resume().then(() => {
        setAudioContextState(audioContext.state);
      }).catch(error => {
        console.error("Erro ao ativar o AudioContext : ", error);
      });
    }
  };

  const playAudio = async () => {
    try {
      const audio = new Audio(AudioWhatsapp);
      await audio.play();
    } catch (error) {
      console.error("Error playing audio: ", error);
      setErrorAudio(true)
    }
  };

  const showNotification = async (message) => {

    if (Notification.permission === "granted") {
      const getSubForNotification = await getSubscription()
      const body = {
        subscription: getSubForNotification,
        content: {
          title: 'Você recebeu uma notificação',
          message: message,
          url: `${window.location.origin}/chat`
        }
      }
      try {
        await postNotification(body)

      } catch (error) {
        console.error("Error notification: ", error);
        setErrorAudio(true)
      }
    } else {
      showInfoNotificationToast(
        <span> Para habilitar Aviso de notificações pop-up é necessário autorização no seu navegador.</span>,
        3000,
      );

    }

  };

  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }


  const getSubscription = async () => {

    if (!registration) return;

    try {
      let getSubscription = await registration.pushManager.getSubscription();
      if (getSubscription) {
        return getSubscription
      }
      try {
        const publicKeyResponse = await postNotificationKey();
        const publicKey = urlBase64ToUint8Array(publicKeyResponse.publicKey);
        getSubscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: publicKey
        });

        return getSubscription
      } catch (error) {
        console.error('Error creating subscription:', error);
      }

    } catch (error) {
      console.error('Service Worker registration or push subscription failed:', error);
    }
  }

  useEffect(() => {

    if (!("Notification" in window)) {
      alert("Este navegador não suporta notificações de desktop.");
    } else {
      const context = new AudioContext();
      setAudioContext(context);
      setAudioContextState(context.state);
      verificationAuthNotification()
    }
  }, []);

  useEffect(() => {

    if (location.pathname === "/chat" && document.visibilityState === "visible") return;
    if (socketContextUnread?.unreadCount > 0) {
      if (socketContextUnread.attendanceType === "inAttendance" && settings?.inAttendanceSound) {
        if (audioContextState === 'running') {
          playAudio();
        }
      }
      if (socketContextUnread.attendanceType === "inAttendance" && settings?.inAttendancePopup) {
        showNotification(
          `O contato ${socketContextUnread.from.replace(
            "@c.us",
            ""
          )} enviou uma mensagem`
        );
      }
    }

  }, [socketContextUnread])

  useEffect(() => {

    if (location.pathname === "/chat" && document.visibilityState === "visible") return;
    if (socketContextInQueue?.action === "in" && socketContextInQueue?.type == "waitingAttendance" && settings?.waitingAttendanceSound) {
      if (audioContextState === 'running') {
        playAudio();
      }
    }
    if (socketContextInQueue?.action === "in" && socketContextInQueue?.type == "waitingAttendance" && settings?.waitingAttendancePopup) {

      showNotification(
        `O contato ${socketContextInQueue.from.replace(
          "@c.us",
          ""
        )} está aguardando atendimento`
      );
    }

  }, [socketContextInQueue])

  useEffect(() => {
    const handleClick = (event) => {
      const context = new AudioContext();
      setAudioContext(context);
      setAudioContextState(context.state);
      verificationAuthNotification();
    };

    const timer = setTimeout(() => {
      if (audioContextState && audioContextState !== 'running' && !notificationShown && (settings?.waitingAttendanceSound || settings?.inAttendanceSound)) {
        showInfoNotificationToast(
          <span>Para habilitar o aviso sonoro, por favor clique no botão abaixo</span>,
          Infinity,
          <Button className="btn btn-light btn-sm"
            style={{
              width: 'fit-content',
              margin: '10px auto 0 auto'
            }}
            onClick={(e) => {
              dismissAllToasts();
              setErrorAudio(false);
              handleUserInteraction();
              setNotificationShown(true);
            }}>Ativar aviso sonoro</Button>
        );
        verificationAuthNotification();
      }
    }, 5000);

    window.addEventListener('click', handleClick);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('click', handleClick);
    };
  }, [audioContextState, notificationShown, settings]);

  return (
    <></>
  );
};

export default SettingsNotifications;
