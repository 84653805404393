import React, { useEffect, useRef, useState } from 'react'
import Navigation from '../Navigation'
import { sidebarAction } from '../Store/Actions/sidebarAction'
import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Cards } from './Cards';
import { Table, Tooltip, Container, Row, Col } from 'reactstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PieChart from './PieChart'
import { Fragment } from 'react';
import { getConsolidatedNotifications, getDetailedNotifications } from '../Services/Notification-Service'
import useFormattedPhone from '../Utils/phone-formatter'
import * as moment from 'moment'
import { tagStyle } from './../App/Partials/Tags';

const translateStatus = (status) => {
  const statusItems = {
    WaitingSend: 'Aguardando',
    Sended: 'Enviado',
    ErrorSend: 'Erro no envio',
    ContactNotFound: 'Contato não encontrado',
  }

  return statusItems[status]
}

const translateType = (type) => {
  const typesItems = {
    DueToday: 'Vencendo hoje',
    Reminder: 'A vencer',
    Delayed: 'Vencido',
    Serasa: 'A ser negativado',
  }

  return typesItems[type]
}

export const Dashboard = () => {
  const [date, setDate] = useState(moment())
  const scrollRef = useRef()
  const [loading, setLoading] = useState()
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showCalendar, setShowCalendar] = useState(false)
  const [tableData, setTableData] = useState([])
  const [notifiedClients, setNotifiedClients] = useState(0)
  const [dueTodayData, setDueTodayData] = useState()
  const [reminderData, setReminderData] = useState()
  const [delayedData, setDelayedData] = useState()
  const [serasaData, setSerasaData] = useState()
  const dispatch = useDispatch()

  const handleConsolidatedNotifications = async () => {
    const startDateString = moment(date).format('YYYY-MM-DD')
    const endDateString = moment(date).format('YYYY-MM-DD')

    try {
      const res = await getConsolidatedNotifications('1', startDateString, endDateString);
      const dueTodayData = res.data.find(data => data.type === 'DueToday')
      const reminderData = res.data.find(data => data.type === 'Reminder')
      const delayedData = res.data.find(data => data.type === 'Delayed')
      const serasaData = res.data.find(data => data.type === 'Serasa')

      setDueTodayData(dueTodayData)
      setReminderData(reminderData)
      setDelayedData(delayedData)
      setSerasaData(serasaData)
    } catch (error) {
      console.error(error)
    }
  }

  const handleTableDetailedNotifications = async (page) => {
    const startDateString = moment(date).format('YYYY-MM-DD')
    const endDateString = moment(date).format('YYYY-MM-DD')
    
    setLoading(true)
    try {
      const res = await getDetailedNotifications(page, startDateString, endDateString);
      setTableData(res.data)
      setNotifiedClients(res.meta.rows)
      setMaxPages(res.meta.pages);
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleInfiniteTableDetailedNotifications = async (page) => {
    const startDateString = moment(date).format('YYYY-MM-DD')
    const endDateString = moment(date).format('YYYY-MM-DD')
    if (loading || maxPages < page) {
      return
    }

    try {
      setLoading(true)

      const res = await getDetailedNotifications(page, startDateString, endDateString)
      setPage(page)
      setTableData((prev) => [...prev, ...res.data])
      setMaxPages(res.meta.pages);

    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  };

  const increaseOrDecreaseDate = (increase = true) => {
    setPage(0)
    if (increase) {
      setDate(moment(date).add(1, "days"))
      return
    }

    setDate(moment(date).subtract(1, "days"))
  };

  const onChangeCalendar = (date) => {
    const formattedDate = moment(date).format()
    setDate(formattedDate)
    setShowCalendar(false)
  }

  useEffect(() => {
    handleConsolidatedNotifications()
    handleTableDetailedNotifications()
    dispatch(sidebarAction('Dashboard'))
  }, [date])

  return (
    <div className="layout">
      <div className="content">
        <div className="content bg-light p-0 align-items-center text-black flex-column h-100">
          <header className='content-header w-100 d-flex align-items-center p-4 px-5'>
            <p className="font-weight-bold m-0 text-dark" style={{fontSize: 18}}>
              Dashboard
            </p>
            <div className='w-75 position-relative'>
              <div className='d-flex align-items-center justify-content-center' style={{gap: 20}}>
                <i className="fa fa-arrow-left arrow-calendar rounded-pill" onClick={() => increaseOrDecreaseDate(false)}></i>
                <div className='form-control input-calendar border-0 text-center font-weight-bold w-25' onClick={() => setShowCalendar(!showCalendar)}>
                  {moment(date).format('DD/MM/YYYY')}
                </div>
                <i className="fa fa-arrow-right arrow-calendar rounded-pill ml-2" onClick={() => increaseOrDecreaseDate(true)}></i>
                {showCalendar ? <Calendar onChange={(date) => {onChangeCalendar(date)}} value={moment(date).format()} locale='pt-br'/> : null}
              </div>
            </div>
          </header>
          <hr style={{ width: '100%', height: 10, borderColor: 'lightgray', marginBottom: 10 }} />
          <div className="sidebar-body w-100 pb-4 px-5 pt-0 d-flex flex-column mb-4" style={{overflowY: 'scroll'}}>
              <div style={{height: 'auto', marginBottom: 20}}>
                <Cards notifiedClients={notifiedClients} dueTodayData={dueTodayData} reminderData={reminderData} delayedData={delayedData} serasaData={serasaData}/>
              </div>
              <Container fluid>
                <Row className='d-flex align-items-start justify-content-center px-1'>
                  <Col lg='4' md='6' xs='12'>
                    <PieChart className='pie' dueTodayData={dueTodayData} reminderData={reminderData} delayedData={delayedData} serasaData={serasaData}/>
                  </Col>
                  <Col lg='8' md='6' xs='12' className='table bg-dark-bright p-2 rounded-lg box-table-dashboard mt-2'>
                    <PerfectScrollbar onYReachEnd={(e) => { handleInfiniteTableDetailedNotifications(page + 1) }} containerRef={ref => scrollRef.current = ref}>
                      <Table className='table-dashboard'>
                        <thead className='thead-dashboard'>
                          <tr>
                            <th height={20}>Cód</th>
                            <th height={20}>Nome</th>
                            <th height={20}>Número</th>
                            <th height={20}>Tipo</th>
                            <th height={20}>Status</th>
                            <th height={20}>Data envio</th>
                            <th height={20}>Vencimento</th>
                            <th height={20}>Parcelas</th>
                            <th height={20}>Valor</th>
                          </tr>
                        </thead>
                        <tbody className={'tbody-dashboard'}>
                        {tableData.length ? 
                              <>
                                  { tableData.map((data, i) => <TableBody handleInfiniteTableDetailedNotifications={handleInfiniteTableDetailedNotifications} page={page} scrollRef={scrollRef} key={i} data={data} i={i}/>) }
                              </>
                              : 
                              <>
                                  { loading ? 
                                      <tr>
                                          <td colSpan={8} align='center'><i className="fa fa-spinner" style={{ padding: 10, animation: 'spin 3s linear infinite', fontSize: 50 }}></i></td>
                                      </tr> 
                                      : 
                                      <tr>
                                          <td colSpan={8} align='center'>Nenhum item disponível.</td>
                                      </tr> 
                                  }
                              </>
                            }
                            {loading &&
                              <div className="overlay-loading-customers">
                                  <i className="fa fa-spinner" style={{ animation: 'spin 3s linear infinite', fontSize: 42 }}></i>
                              </div>
                            }
                        </tbody> 
                      </Table>
                    </PerfectScrollbar>
                  </Col>
                </Row>
              </Container>
          </div>
        </div>
      </div>
    </div>
  )
}

const TableBody = ({data, i}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [tooltipOpenName, setTooltipOpenName] = useState(false);
  const toggleName = () => setTooltipOpenName(!tooltipOpenName);

  const formatTotalCorrectedBalanceAmount = (value) => {
     return new Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" }).format(value)
  }

  return (
    <Fragment key={i}>
        {data.description ?
          <Tooltip
            placement="left"
            isOpen={tooltipOpen}
            target={'tooltip-'+data._id}
            toggle={toggle}>
            {data.description}
          </Tooltip> 
        : null}
        {/* {data.contactName ?
          <Tooltip
            placement="left"
            isOpen={tooltipOpenName}
            target={'tooltip-'+data._id+'name'}
            toggle={toggleName}>
            {data.contactName}
          </Tooltip> 
        : null} */}
        <tr key={i} className={i % 2 === 0 ? 'bg-light' : ''} style={{borderSpacing: 20, borderCollapse: 'separate'}}>
          <td>
          {data.externalIds ?
            data.externalIds.map((externalId, index) => (
              <span key={index} title={externalId}>
                {externalId}
                {index < data.externalIds.length - 1 ? ', ' : ''}
              </span>
            ))
            : data.externalId ? <span title={data.externalId}>{data.externalId}</span> : null}
          </td>
          <td id={'tooltip-'+data._id+'name'}>{data.contactName || '-'}</td>
          <td>{useFormattedPhone(data.contactPhone || '-')}</td>
          <td>{translateType(data.type) || '-'}</td>
          <td><span id={'tooltip-'+data._id}>{translateStatus(data.status) || '-'}</span></td>
          <td>{data.sendedAt ? moment(data.sendedAt).format('DD/MM/YYYY HH:mm') : '-'}</td>
          <td>{data.details.dueDate || '-'}</td>
          <td>{data.details.totalInstallments}</td>
          <td>{formatTotalCorrectedBalanceAmount(data.details.totalCorrectedBalanceAmount)}</td>
        </tr>
    </Fragment>
  )
}