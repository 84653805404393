import jwtDecode from 'jwt-decode';
import React, { createContext, useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { getChatStatus } from '../Services/Chat-Service';

export const AuthContext = createContext({
  accessToken: sessionStorage.getItem('accessToken') || '',
  setAccessToken: (token) => { },
  channelList: null,
  setChannelList: (status) => { }
});

export const useAuth = () => {
  const token = useContext(AuthContext).accessToken
  const decodedToken = token ? jwtDecode(token) : {}
  return decodedToken;
};

export const useToken = () => sessionStorage.getItem('accessToken');

export const useCanAccessAction = (permissionString) => {
  const { accessToken } = useContext(AuthContext);
  const decodedToken = accessToken ? jwtDecode(accessToken) : {};
  return decodedToken.permissions?.includes(permissionString);
};

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(sessionStorage.getItem('accessToken') || null);
  const [channelList, setChannelList] = useState(null);

  const handleChatStatus = async () => {
    let status = await getChatStatus()
    setChannelList(status)
  }

  useMemo(() => {
    if(!accessToken) return
    
    handleChatStatus();
  }, []);

  return (
    <AuthContext.Provider value={{ accessToken: accessToken || '', setAccessToken, channelList, setChannelList }}>
      {children}
    </AuthContext.Provider>
  );
};
