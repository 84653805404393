import React, { useEffect, useRef } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useState } from 'react';
import { getUser } from '../Services/NewUser-Service';
import AddUserModal from './Modals/AddUserModal';
import { Table, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { FilterHeader } from './Partials/FilterHeader';
import { TableHeader } from './Partials/Table/TableHeader';
import { TableBody } from './Partials/Table/TableBody';
import { deleteUser } from '../Services/NewUser-Service';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useToast } from './Partials/Toast';
import ConfirmationModal from './Components/ConfirmationModal';
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom";
import { sidebarAction } from '../Store/Actions/sidebarAction';
import { CardsLayoutUsers } from './Partials/CardsLayout/CardsLayoutUsers';
import { useMobile } from './Partials/useMobile/useMobile';

function LayoutUsers() {
  const [usersList, setUsersList] = useState([]);
  const [userFiltered, setUserFiltered] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteringItems, setFilteringItems] = useState();
  const [openModalEditUser, setOpenModalEditUser] = useState(false);
  const { showSuccessToast } = useToast();
  const [openConfirmationModal, setOpenConfirmationModal] = useState('')
  const dispatch = useDispatch()
  const history = useHistory()
  const isMobile = useMobile();

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  });

  const inputRef = useRef();

  const handleGetUsers = async () => {
    try {
      setLoading(true);
      const res = await getUser();
      setUsersList(res);

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getUsersByFilter = async (filter) => {
    setFilteringItems(true)
    const filteredUsers = usersList.filter(user => user.name.toLowerCase().includes(filter.toLowerCase()))
    setUserFiltered(filteredUsers)
  };

  useEffect(() => {
    handleGetUsers()
    setTimeout(() => {
      dispatch(sidebarAction('Usuarios'))
    }, 100);
    history.push('/usuarios')
  }, []);

  const handleDropdown = (clickedItem) => {
    const index = filteringItems ? userFiltered.findIndex((user) => user._id === clickedItem._id) : usersList.findIndex((user) => user._id === clickedItem._id)
    if (index !== -1) {
      const updatedUserList = filteringItems ? [...userFiltered] : [...usersList];

      updatedUserList[index] = {
        ...updatedUserList[index],
        dropdownOpen: !updatedUserList[index].dropdownOpen || false,
      };
      filteringItems ? setUserFiltered(updatedUserList) : setUsersList(updatedUserList)
    }
  };

  const handleDisableUser = async (data) => {
    try {
      await deleteUser(data?._id);
      showSuccessToast('Usuário desabilitado com sucesso.');
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      handleGetUsers();
      setOpenConfirmationModal(null)
    }
  };

  const ChannelPopover = ({ data }) => {
    const [channelPopoverOpen, setChannelPopoverOpen] = useState(false);
    const toggleChannelPopoverOpen = () => setChannelPopoverOpen(!channelPopoverOpen);

    return (
      <>
        <div
          id={`channelsList-${data._id}`}
          style={{ width: "30px" }}
          onMouseEnter={toggleChannelPopoverOpen}
          onMouseLeave={toggleChannelPopoverOpen}
        >
          {data.channels.length}
        </div>
        <Popover placement="right" isOpen={channelPopoverOpen} target={`channelsList-${data._id}`}>
          <PopoverHeader>Canais vinculados</PopoverHeader>
          <PopoverBody>
            {data.channels?.map((channel, i) => (
              <p key={i}>{channel?.name}</p>
            ))}
          </PopoverBody>
        </Popover>
      </>
    );
  };

  const DepartmentPopover = ({ data }) => {
    const [DepartmentPopoverOpen, setDepartmentPopoverOpen] = useState(false);
    const toggleDepartmentPopoverOpen = () => setDepartmentPopoverOpen(!DepartmentPopoverOpen);
    return (
      <>
        <div
          id={`departmentsList-${data._id}`}
          style={{ width: "30px" }}
          onMouseEnter={toggleDepartmentPopoverOpen}
          onMouseLeave={toggleDepartmentPopoverOpen}
        >
          {data.departments.length}
        </div>
        <Popover placement="right" isOpen={DepartmentPopoverOpen} target={`departmentsList-${data._id}`}>
          <PopoverHeader>Departamentos vinculados</PopoverHeader>
          <PopoverBody>
            {data.departments.map((department, i) => (
              <p key={i}>{department.name}</p>
            ))}
          </PopoverBody>
        </Popover>
      </>
    );
  };

  const IconPopover = ({ data }) => {
    const [IconPopoverOpen, setIconPopoverOpen] = useState(false);
    const toggleIconPopoverOpen = () => setIconPopoverOpen(!IconPopoverOpen);
  
    return (
      <>
        <div
          id={`icon-${data._id}`}
          onMouseEnter={toggleIconPopoverOpen}
          onMouseLeave={toggleIconPopoverOpen}
          style={{ display: "inline-block" }}
        >
          <i className="fa fa-times" style={{ padding: "0px 10px" }}></i>
        </div>
        <Popover placement="right" isOpen={IconPopoverOpen} target={`icon-${data._id}`}>
          <PopoverBody>Usuário desabilitado</PopoverBody>
        </Popover>
      </>
    );
  };
  

  const itemsTableHeader = [
    { label: '', order: 0 },
    { label: 'Nome', order: 1 },
    { label: 'E-mail', order: 2 },
    { label: 'Canais', order: 3 },
    { label: 'Departamentos', order: 4 },
    { label: 'Perfil', order: 5 },
    { label: 'Ativo', order: 6 },
    { label: '', order: 7 }
  ]

  const cellsTableData = [
    {
      type: 'image',
      property: 'image',
      order: 0,
      width: 50,
      relative: true,
      childrenItem: {
        type: 'customComponent',
        component: (data) => (
          <div style={{ position: 'relative' }}>
            {data.status === "online" ? (
              <i className="fa fa-circle text-success" style={{ position: 'absolute', bottom: 28, textShadow: '0 0 1px white' }}></i>
            ) : (
              <i className="fa fa-circle text-danger" style={{ position: 'absolute', bottom: 28, textShadow: '0 0 1px white' }}></i>
            )}
          </div>
        )
      },
      imageWidth: 40,
      imageHeight: 40,
      className: "rounded-circle mr-2"
    },
    {
      type: 'longText',
      property: 'name',
      order: 1,
      width: 200,
      childrenItem: {
        type: 'tag',
        subProperty: 'externalIds',
      }
    },
    {
      type: 'longText',
      property: 'email',
      order: 2,
      width: 200
    },
    {
      type: 'customComponent',
      order: 3,
      width: 200,
      component: (data) => (
        <ChannelPopover data={data} />
      )
    },
    {
      type: 'customComponent',
      order: 4,
      width: 200,
      component: (data) => (
        <DepartmentPopover data={data} />
      )
    },
    {
      type: 'text',
      property: 'profile',
      subProperty: 'name',
      order: 5,
      width: 200
    },
    {
      type: 'customComponent',
      property: 'status',
      order: 6,
      width: 100,
      component: (data) => (
         <div>
            {data.deactivatedAt === null ? (
               <i className="fa fa-check" style={{padding: '0px 10px'}}></i>
            ) : (
            <>
              <IconPopover data={data}/>
            </>
            )}
         </div>
      ),
    },
    {
      type: 'actions',
      property: 'actions',
      order: 7,
      width: 50,
      toggle: (data) => {
        if (!data.deactivatedAt) {
          handleDropdown(data);
        }

      },
      items: [
        {
          label: 'Editar',
          action: (data) => setOpenModalEditUser({ open: true, user: data }),
          show: () => true,
        },
        {
          label: 'Desativar',
          action: (data) => setOpenConfirmationModal({ open: true, info: data }),
          show: () => true,
          disabled: (data) => data.deactivatedAt !== null
        }
      ]
    },
  ].sort((a, b) => a.order - b.order);


  return (
    <div className="layout">
      <div className="content">
        <div className="content bg-light p-0 align-items-center text-black flex-column h-100">
          <FilterHeader
            maxRows={usersList.length}
            title="Usuários"
            placeholder="Procurar por usuários"
            inputCallback={getUsersByFilter}
            addButtonChildren={
              <AddUserModal
                onCloseModal={(close) => setOpenModalEditUser({})}
                onAddUser={() => handleGetUsers()}
                editingUser={openModalEditUser.open}
                user={openModalEditUser.user}
                onEditUser={() => handleGetUsers()}
              />
            }
            inputRef={inputRef}
          />
          <div className="sidebar-body w-100 pb-4 px-5 pt-0" style={{ position: 'relative', height: '80%' }} id="sidebar-users">
            {loading &&
              <div className="overlay-loading-customers">
                <i className="fa fa-spinner" style={{ animation: 'spin 3s linear infinite', fontSize: 42 }}></i>
              </div>
            }
            {!usersList?.length ?
              <div className="chat">
                {!loading && <div className="chat-body no-message">
                  <div className="no-message-container">
                    <i className="fa fa-user-times"></i>
                    <p style={{ textAlign: 'center' }}>Não há usuário.</p>
                  </div>
                </div>}
              </div>
              :
              <PerfectScrollbar className='hide-rail-x'>
                {
                  isMobile ? (
                    <Table>
                      <CardsLayoutUsers
                        data={userFiltered && filteringItems ? userFiltered : usersList}
                        loading={loading}
                        setOpenModalEditUser={setOpenModalEditUser}
                        setOpenConfirmationModal={setOpenConfirmationModal}
                        handleDropdown={ handleDropdown}
                        ChannelPopover={ChannelPopover}
                        DepartmentPopover={DepartmentPopover}
                        IconPopover={IconPopover}
                        />
                        <ConfirmationModal
                        openConfirmationModal={openConfirmationModal}
                        setOpenConfirmationModal={setOpenConfirmationModal}
                        handleDisableUser={handleDisableUser}
                        messageModal='Deseja desativar esse usuário?'
                      />
                    </Table>
                  ) : (
                    <Table>
                      <TableBody data={userFiltered && filteringItems ? userFiltered : usersList} cellsTableData={cellsTableData} bodyClassName={'tbody-customers'} loading={loading} />
                      <TableHeader itemsTableHeader={itemsTableHeader} theadClassName={'thead-customers'} />
                      <ConfirmationModal
                        openConfirmationModal={openConfirmationModal}
                        setOpenConfirmationModal={setOpenConfirmationModal}
                        handleDisableUser={handleDisableUser}
                        messageModal='Deseja desativar esse usuário?'
                      />
                  </Table>
                  )
                }
              </PerfectScrollbar>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default LayoutUsers
