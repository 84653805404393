import api from "./Axios.config";

const url = process.env.REACT_APP_API_URL + '/api/department/v1';

export const getDepartment = async () => {
    try {
        const response = await api.get(`${url}/departments`)
        return response.data
    } catch (error) {
        console.error('Erro fetching data:', error)
        throw new Error('Failed to get department')
    }
};

export const postDepartment = async (data) => {
    try {
        const response = await api.post(`${url}/departments`, data);
        return response.data;
    } catch (error) {
        console.error('Erro ao enviar dados:', error);
        throw new Error('Falha ao criar departamento');
    }
};

export const putDepartment = async (_id, data) => {
    try {
        const response = await api.put(`${url}/departments/${_id}`, data);
        return response.data;
    } catch (error) {
        console.error('Erro ao atualizar departamento:', error);
        throw new Error('Falha ao atualizar departamento');
    }
};

export const deleteDepartment = async (_id) => {
    try {
        const response = await api.delete(`${process.env.REACT_APP_API_URL}/api/departemnt/v1/departments/${_id}`);
        return response.data;
    } catch (error) {
        console.error('Erro ao excluir departamento:', error);
        throw new Error('Falha ao excluir departamento');
    }
};

export const getDepartmentByUserId = async (id) => {
    try {
        const response = await api.get(`${url}/departments?userId=${id}`)
        return response.data;
    } catch (error) {
        console.error('Erro fetching data:', error)
        throw new Error('Failed to get department by id')
    }
}