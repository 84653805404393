import api from "./Axios.config"

const url = process.env.REACT_APP_API_URL + '/api/chatbot/v1'

export const getChatStatus = async (token) => {
  try {
    const response = await api.get(`${url}/status`)
    return response.data
  } catch (error) {
    if (error.response?.status === 400) return error.response?.data

    console.error('Error fetching data:', error)
    throw new Error('Failed to get chat status')
  }
}
