import React, { useContext, useState, useRef, useEffect, useMemo } from "react";
import { Modal, ModalBody, ModalHeader, Input, Button } from 'reactstrap';

import { Ack } from '../Partials/Ack';
import { timestampToHour } from '../../Utils/timestamp';
import { MessageContext } from '../../Context/MessageContext';
import { AttendanceContext } from "../../Context/AttendanceContext";
import { parseVCARD } from "../../Utils/parse-vcard";
import DefaultImage from '../../assets/img/no-image.png'
import MessageAttachments from "../Partials/Message/MessageAttachments";
import { convertText } from "../../Utils/convert-text";
import Emojis from "../Partials/Emojis";


const pickerBoxCustomStyle = {

    zIndex: 1000,
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    maxHeight: '400px',

};


const EditMessageModal = ({
    openEditMessageModal,
    setOpenEditMessageModal,
    editMessage,
}) => {
    const [inputMsg, setInputMsg] = useState('')
    const [paddingInput, setPaddingInput] = useState()
    const [messageSubmitted, setMessageSubmitted] = useState('')

    const inputRef = useRef(null)

    const handleChange = (e) => {
        setInputMsg(e)
    }

    const handleEnter = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            if (e.type === "keypress" || e.type === "keydown") {
                pasteIntoInput("\n");
            }
            e.preventDefault();
        }
    }

    const pasteIntoInput = (text) => {
        const el = inputRef.current
        el.focus()

        if (typeof el.selectionStart === 'number' && typeof el.selectionEnd === 'number') {
            const val = el.value
            const selStart = el.selectionStart
            el.value = val.slice(0, selStart) + text + val.slice(el.selectionEnd)
            el.selectionEnd = el.selectionStart = selStart + text.length
        } else if (typeof document.selection !== 'undefined') {
            const textRange = document.selection.createRange()
            textRange.text = text
            textRange.collapse(false)
            textRange.select()
        }
    }

    const UniqueDate = ({ message }) => {
        return (
            <small className="text-center">
                <strong>{message?.uniqueDate}</strong>
            </small>
        )
    }

    const VcardMessage = ({ message }) => {
        const parsedVcard = parseVCARD(message.body)
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    className="rounded-circle" style={{ cursor: 'pointer', marginRight: 10 }}
                    src={DefaultImage}
                    alt={'Imagem vcard'}
                    width={40}
                    height={40}
                />
                <p style={{ margin: '0 auto' }}>
                    {parsedVcard.fullName} <br />
                    {parsedVcard.phoneNumber}
                </p>
            </div>
        )
    }

    const Message = ({ message, isEditContent }) => {
        if (message?.hasMedia) return <MessageAttachments message={message} isEditContent={isEditContent} />

        if (message?.type === 'vcard') return <VcardMessage message={message} />

        if (message?.type === 'uniqueDate') return <UniqueDate message={message} />

        return (
            <span className="ml-3">
                {convertText(message?.body)}
            </span>
        )
    }

    const messageComponent = useMemo(() => (
        <Message message={openEditMessageModal?.message} isEditContent={true} />
    ), [openEditMessageModal?.message]);

    useEffect(() => {
        if (openEditMessageModal) {
            setInputMsg(openEditMessageModal?.message?.body)
        }
    }, [openEditMessageModal])
    return (
        <Modal
            isOpen={openEditMessageModal?.open} toggle={() => setOpenEditMessageModal({ open: false })}
            centered
            className="call modal-dialog-zoom"
            size='md'
            zIndex={999}
        >
            <button className={"btn btn-light btn-modal-close my-0"}
                onClick={() => setOpenEditMessageModal({ open: false })}>
                <i className="fa fa-times mr-0"></i>
            </button>
            <ModalBody className='py-4 my-0 ' style={{ backgroundColor: '#e6e6e6', padding: '5px', }}>
                <div className="my-3">
                    <h5 className='mt-3' style={{ color: 'black', width: '100%', textAlign: 'center' }}>Editar mensagem</h5>
                </div>
                <div className="message-item outgoing-message mx-3 my-2" style={{ color: 'black', backgroundColor: '#cdcdcd', borderRadius: '5px', }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 5,
                        wordWrap: 'break-word',
                        paddingTop: '5px',
                        border: '0px',
                    }}>
                        {messageComponent}
                    </div>
                </div>
                <div className="message-action mr-3" style={{ textAlign: 'right', fontSize: '10px' }}>
                    {openEditMessageModal && (
                        <div className="text-dark">
                            {openEditMessageModal.message?.fromMe && openEditMessageModal.message?.isEdited && <span className="mr-1">Editado</span>}
                            {timestampToHour(openEditMessageModal.message?.timestamp, true)}
                            {<Ack ack={openEditMessageModal?.message?.ack} />}
                        </div>
                    )}
                </div>
                <div className='d-flex align-items-center' style={{ padding: '10px' }}>
                    <Input
                        type='textarea'
                        onKeyDown={handleEnter}
                        ref={inputRef}
                        className='form-control'
                        value={inputMsg}
                        onChange={(e) => { setInputMsg(e.target.value) }}
                        id='input-chat'
                        style={{ paddingRight: paddingInput, resize: 'none', height: 100, border: 0, marginLeft: 10 }}
                        autoComplete='off'
                    />
                    <div className="ml-1">
                        <Emojis
                            style={{ position: 'fixed' }}
                            className='mx-1'
                            onChange={handleChange}
                            inputMsg={inputMsg}
                            messageSubmitted={messageSubmitted}
                            closeOnChange
                            pickerBoxStyle={{ bottom: 0, left: 100, ...pickerBoxCustomStyle }}

                        />
                    </div>
                    <Button
                        color="primary"
                        className="btn-floating mx-2 my-2"
                        onClick={(() => editMessage(openEditMessageModal?.message, inputMsg))}
                        id="submit"
                        style={{ height: '30px' }}
                    >
                        <i className="fa fa-send" ></i>
                    </Button>
                </div>

            </ModalBody>
        </Modal>
    );
}

export default EditMessageModal;


