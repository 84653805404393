import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import * as moment from 'moment';
import CardsCampaign from './CardsCampaign';
import { getCampaignToday, getNotificationToday, getNewContactToday } from '../Services/ReportsCampaign';
import EconomyChart from './EconomyChart';
import ProspectChart from './ProspectChart';
import PieChart from './PieChartCampaign';
import CampaignChartBar from './CampaignChartBar';
import { sidebarAction } from '../Store/Actions/sidebarAction';
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom";
import { Card, CardHeader, CardBody, Col, Row }from 'reactstrap';

const DashboardCampaign = () => {
  const [date, setDate] = useState(moment());
  const [showCalendar, setShowCalendar] = useState(false);
  const [notificationTotal, setnotificationTotal] = useState(0);
  const [campaignToday, setCampaignToday] = useState(0);
  const [notificationToday, setNotificationToday] = useState(0);
  const [newContacts, setNewContacts] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  const getCampaignData = async (dateFrom, dateTo) => {
    try {
      const res = await getCampaignToday(dateFrom, dateTo);
      const total = Object.values(res).reduce((acc, val) => acc + val, 0); return total;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  const handleGetCampaignToday = async (newDate) => {
    try {
      const currentDate = moment(newDate);
      const dateFrom = currentDate.startOf('day').format('YYYY-MM-DDT00:00:00');
      const dateTo = currentDate.endOf('day').format('YYYY-MM-DDT23:59:59');

      const campaignTodayTotal = await getCampaignData(dateFrom, dateTo);
      setCampaignToday(campaignTodayTotal);

    } catch (error) {
      console.error(error);
    }
  };

  const getNotificationData = async (dateFrom, dateTo) => {
    try {
      const res = await getNotificationToday(dateFrom, dateTo);
      let totalNotification = 0;
      res.campaigns.forEach(item => { totalNotification += item.totalNotifications; });
      return totalNotification;
    }
    catch (error) {
      console.error(error);
      return 0;
    }
  };

  const handleGetNotificationToday = async (newDate) => {
    try {
      const currentDate = moment(newDate);
      const dateFrom = currentDate.startOf('day').format('YYYY-MM-DDT00:00:00');
      const dateTo = currentDate.endOf('day').format('YYYY-MM-DDT23:59:59');
      const notificationTodayTotal = await getNotificationData(dateFrom, dateTo);
      const formattedTotal = notificationTodayTotal.toFixed(0).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      setNotificationToday(formattedTotal);

    } catch (error) {
      console.error(error);
    }
  };

  const getTotalNewContacts = async (dateFrom, dateTo) => {
    try {
      const res = await getNewContactToday(dateFrom, dateTo);
      const total = Object.values(res).reduce((acc, val) => acc + val, 0);
      return total;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  const handleGetNewContactToday = async (newDate) => {
    try {
      const currentDate = moment(newDate);
      const dateFrom = currentDate.startOf('day').format('YYYY-MM-DDT00:00:00');
      const dateTo = currentDate.endOf('day').format('YYYY-MM-DDT23:59:59');
      const totalNewContactToday = await getTotalNewContacts(dateFrom, dateTo);
      setNewContacts(totalNewContactToday);

    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  const getTotalNotifications = async (dateFrom, dateTo) => {
    try {
      const res = await getNotificationToday(dateFrom, dateTo);
      const cost = res.cost;
      let total = 0;
      res.campaigns.forEach(item => { total += item.totalNotifications * cost });
      return total;
    }
    catch (error) {
      console.error(error);
      return 0;
    }
  };

  const handleGetTotalNotification = async (newDate) => {
    try {
      const currentDate = moment(newDate);
      const dateFrom = currentDate.startOf('day').format('YYYY-MM-DDT00:00:00');
      const dateTo = currentDate.endOf('day').format('YYYY-MM-DDT23:59:59');
      const totalNotification = await getTotalNotifications(dateFrom, dateTo);
      const formattedTotal = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalNotification);
      setnotificationTotal(formattedTotal);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetCampaignToday();
    handleGetNotificationToday();
    handleGetNewContactToday();
    handleGetTotalNotification();
    setTimeout(() => {
      dispatch(sidebarAction('Dashboard Campanhas'))
    }, 100);
    history.push('/dashboard-campanhas')
  }, []);

  const increaseOrDecreaseDate = (isIncreasing) => {
    const newDate = isIncreasing ? moment(date).add(1, 'days') : moment(date).subtract(1, 'days');
    if (isIncreasing && newDate.isAfter(moment())) {
      return;
    }
    setDate(newDate);

    handleGetCampaignToday(newDate);
    handleGetNotificationToday(newDate);
    handleGetNewContactToday(newDate);
    handleGetTotalNotification(newDate);
  };

  const onChangeCalendar = (date) => {
    const selectedDate = moment(date);
    if (selectedDate.isAfter(moment())) {
      return;
    }
    setDate(selectedDate);

    handleGetCampaignToday(selectedDate);
    handleGetNotificationToday(selectedDate);
    handleGetNewContactToday(selectedDate);
    handleGetTotalNotification(selectedDate);

    setShowCalendar(false);
  };

  return (
    <div className="layout">
      <div className="content">
        <div className="content bg-light p-0 align-items-center text-black flex-column h-100">
          <header className='w-100 d-flex align-items-center p-4 px-5'>
            <p className="font-weight-bold m-0 text-dark" style={{ fontSize: 18 }}>
              Dashboard Campanhas
            </p>
          </header>
          <hr style={{ width: '100%', height: 10, borderColor: 'lightgray', marginBottom: 30 }} />
          <div className="sidebar-body w-100 pb-4 px-5 pt-0 d-flex flex-column mb-4" style={{overflowY: 'scroll'}}>
            <Card className="mb-4" style={{border:'none'}}>
              <CardsCampaign className="mb-4"/>
              <div className='w-100 position-relative mt-5 mb-2'>
                <div className='d-flex align-items-center justify-content-center' style={{ gap: 20 }}>
                  <i className="fa fa-arrow-left arrow-calendar rounded-pill" onClick={() => increaseOrDecreaseDate(false)}></i>
                  <div className='form-control input-calendar border-0 text-center font-weight-bold w-25' onClick={() => setShowCalendar(!showCalendar)}>
                    {moment(date).format('DD/MM/YYYY')}
                  </div>
                  <i className="fa fa-arrow-right arrow-calendar rounded-pill" onClick={() => increaseOrDecreaseDate(true)}></i>
                  {showCalendar ? <Calendar onChange={(date) => { onChangeCalendar(date) }} value={moment(date).format()} locale='pt-br' /> : null}
                </div>
              </div>
              <CardHeader style={{ border: 'none' }}>
                <Row className='d-flex' style={{ gap: '5px' }}>
                  <Col style={{ border: '1px solid #ddd', borderTop: '3px solid #007bff', borderRadius: '5px' }}>
                    <div className="text-color-secondary mt-2 fw-bold">Contatos</div>
                    <div className="fw-semibold text-truncate text-center" style={{ fontSize: '32px', color: '#3264f5' }}>
                      <p>{newContacts}</p>
                    </div>
                  </Col>
                  <Col style={{ border: '1px solid #ddd', borderTop: '3px solid #007bff', borderRadius: '5px' }}>
                    <div className="text-color-secondary mt-2 fw-bold">Campanhas</div>
                    <div className="fw-semibold text-truncate text-center" style={{ fontSize: '32px', color: '#3264f5' }}>
                      <p>{campaignToday}</p>
                    </div>
                  </Col>
                  <Col style={{ border: '1px solid #ddd', borderTop: '3px solid #007bff', borderRadius: '5px' }}>
                    <div className="text-color-secondary mt-2 fw-bold">Notificações</div>
                    <div className="fw-semibold text-truncate text-center" style={{ fontSize: '32px', color: '#3264f5' }}>
                      <p>{notificationToday}</p>
                    </div>
                  </Col>
                  <Col style={{ border: '1px solid #ddd', borderTop: '3px solid #007bff', borderRadius: '5px' }}>
                    <div className="text-color-secondary mt-2 fw-bold">Economizado</div>
                    <div className="fw-semibold text-truncate text-center" style={{ fontSize: '32px', color: '#3264f5' }}>
                      <p>{notificationTotal}</p>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className='d-flex p-2'>
                  <Col md={6} style={{ border: '1px solid #ddd', borderTop: '3px solid #00bf63', borderRadius: '5px' }}>
                    <EconomyChart />
                  </Col>
                  <Col md={6} style={{ border: '1px solid #ddd', borderTop: '3px solid #ff914d', borderRadius: '5px' }}>
                    <ProspectChart />
                  </Col>
                </Row>
                <Row className='d-flex p-2'>
                  <Col md={6} style={{ border: '1px solid #ddd', borderTop: '3px solid #6161ff', borderRadius: '5px' }}>
                    <h4 className='mt-5' style={{ color: '#6161ff', fontWeight: 'bolder' }}>Contatos por canal</h4>
                    <PieChart />
                  </Col>
                  <Col md={6} style={{ border: '1px solid #ddd', borderTop: '3px solid #6161ff', borderRadius: '5px' }}>
                    <h4 className='mt-5' style={{ color: '#6161ff', fontWeight: 'bolder' }}>Campanhas - Lista de transmissão</h4>
                    <CampaignChartBar />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )

}

export default DashboardCampaign