import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux"
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useState } from 'react';
import { getCustomers as getCustomersApi, removeCustomer } from '../Services/User-Service'
import { AttendanceContext } from '../Context/AttendanceContext'
import AddCustomerModal from './Modals/AddCustomerModal';
import { Table, Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import Chat from './Partials/Chat/Chat'
import Profile from './Sidebars/Profile'
import { FilterHeader } from './Partials/FilterHeader';
import { TableHeader } from './Partials/Table/TableHeader';
import { TableBody } from './Partials/Table/TableBody';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { mobileSidebarAction } from '../Store/Actions/mobileSidebarAction';
import { sidebarAction } from '../Store/Actions/sidebarAction';
import { selectedChatAction } from '../Store/Actions/selectedChatAction';
import { useHistory } from 'react-router-dom';
import { profileAction } from '../Store/Actions/profileAction';
import { mobileProfileAction } from '../Store/Actions/mobileProfileAction';
import { useAuth, useCanAccessAction } from '../Context/AuthContext';
import { postStartAttendance } from '../Services/Attendance-Service';
import { CardsLayoutCustomer } from './Partials/CardsLayout/CardsLayoutCustomer';
import { useMobile } from './Partials/useMobile/useMobile';
import { getDepartmentByUserId } from '../Services/Department-Service';
import ConfirmationModal from './Components/ConfirmationModal';
import { useToast } from './Partials/Toast';

export const optionsCustomersStatus = [
  {
    value: '',
    label: 'Todas',
  },
  {
    value: 'Active',
    label: 'Ativo'
  },
  {
    value: 'Block',
    label: 'Bloqueado'
  },
  {
    value: 'Invalid',
    label: 'Inválido'
  }
]

function LayoutCustomers() {
  const {
    setActiveChatCustomer,
    getCustomers,
    setGetCustomers,
    setChatReadOnly,
    setShowMessageHistoryModal
  } = useContext(AttendanceContext)
  const [customerList, setCustomerList] = useState(undefined);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState();
  const [openModalEditCustomer, setOpenModalEditCustomer] = useState(false);
  const [filter, setFilter] = useState('');
  const [maxPages, setMaxPages] = useState(0);
  const [maxCustomers, setMaxCustomers] = useState(0);
  const [departmentsOperatorList, setDepartmentsOperatorList] = useState('')
  const [choosedDepartmentByOperator, setChoosedDepartmentOperator] = useState('')
  const [openConfirmationModal, setOpenConfirmationModal] = useState('')
  const [customersStatusList, setCustomersStatusList] = useState({
    value: '',
    label: 'Todas',
  })
  const { showSuccessToast } = useToast()
  const scrollRef = useRef()
  const { profileSidebar, mobileProfileSidebar } = useSelector(state => state);
  const history = useHistory()
  const dispatch = useDispatch()
  const decodedToken = useAuth()
  const isMobile = useMobile();
  const canManageChat = useCanAccessAction('manage_chat');

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  });

  const inputRef = useRef();

  let previousRequestTimer = null;

  const handleGetCustomers = async (pageAfterEdit, status) => {
    try {
      setLoading(true);
      const res = await getCustomersApi('', pageAfterEdit ? pageAfterEdit : page, status);
      setCustomerList(res.data);
      setMaxPages(res.meta.pages);
      setMaxCustomers(res.meta.rows);
      if (pageAfterEdit) {
        setPage(pageAfterEdit);
        setFilter('');
        document.getElementById('input-customers').value = "";
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleInfiniteGetCustomers = async (page) => {
    if (loading || maxPages < page) {
      return
    }

    try {
      setLoading(true)

      const res = await getCustomersApi(filter, page, customersStatusList.value)
      const newData = res.data

      const scrollHeight = scrollRef.current.scrollHeight
      setPage(page)
      setCustomerList((prevCustomerList) => [...prevCustomerList, ...newData])
      setMaxPages(res.meta.pages);
      scrollRef.current.scrollTop = (scrollHeight * 2) - scrollHeight;
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  };

  const getCustomerByFilter = async (filter) => {
    clearTimeout(previousRequestTimer);
    previousRequestTimer = setTimeout(async () => {
      try {
        if (scrollRef.current && scrollRef.current.scrollTop) {
          scrollRef.current.scrollTop = 0
        }
        setLoading(true)
        const res = await getCustomersApi(filter, 1, customersStatusList.value);
        setPage(1)
        setCustomerList(res.data)
        setMaxPages(res.meta.pages);
        setFilter(filter);
      } catch (error) {
        console.log('error', error)
      } finally {
        setLoading(false)
      }
    }, 300);
  };

  const handleCustomerMessages = (item) => {
    setActiveChatCustomer(item)
    setChatReadOnly({ readOnly: true })
    setShowMessageHistoryModal(true)
  }

  const handleRemoveCustomer = async (data) => {
    try {
      await removeCustomer(data.channel.id, data.phone)
      handleGetCustomers('', customersStatusList.value)
      showSuccessToast('Contato removido com sucesso')
    } catch (error) {
      console.error('An error occurred:', error)
    }
    finally {
      setOpenConfirmationModal(null)
    }
  }

  const handleStartAttendanceLayoutCustomer = async (item, departmentId) => {
    try {
      if (!departmentId) {
        setOpenConfirmationModal({ open: true, info: item });
        return;
      }
      const { _id } = await postStartAttendance(item.phone, item.channel.id, departmentId?.value);
      const customer = { ...item, fromCustomers: true, attendance: { id: _id } };
      setActiveChatCustomer(customer);
      setTimeout(() => {
        dispatch(mobileSidebarAction(false));
        dispatch(selectedChatAction(customer));
        dispatch(sidebarAction('Chats'));
      }, 200);
      history.push('/chat');
    } catch (error) {
      console.error(error);
    }
  };


  const handleShowProfile = (item) => {
    if (!profileSidebar && !mobileProfileSidebar) {
      dispatch(profileAction(true))
      dispatch(mobileProfileAction(true))
    }
    dispatch(selectedChatAction(item))
  }

  const handleDropdown = (clickedItem) => {
    const foundCustomers = customerList.filter(customer => customer._id === clickedItem._id);
    let clickedIndex = foundCustomers.length > 1 ?
      customerList.findIndex(customer => customer._id === clickedItem._id && customer.channel?.id === clickedItem.channel?.id) :
      customerList.findIndex(customer => customer._id === clickedItem._id);
    if (clickedIndex !== -1) {
      const updatedCustomerList = [...customerList];

      let showOptionViewMessages = false
      let showOptionStartAttendance = false
      decodedToken.departments.map(({ id }) => {
        const departmentId = id
        const customerInSameDepartmentAttendance = updatedCustomerList[clickedIndex].attendance?.departmentId === departmentId
        const customerWithoutAttendance = updatedCustomerList[clickedIndex].attendance?.type === null || !updatedCustomerList[clickedIndex].attendance
        const customerInAutoAttendance = updatedCustomerList[clickedIndex].attendance?.type === 'auto'

        if (customerInSameDepartmentAttendance || customerWithoutAttendance || customerInAutoAttendance) {
          showOptionViewMessages = true
        } else {
          showOptionViewMessages = false
        }
      })

      const customerInAttendance = updatedCustomerList[clickedIndex].attendance?.type === 'inAttendance'

      if (customerInAttendance) {
        showOptionStartAttendance = false
      } else {
        showOptionStartAttendance = true
      }
      updatedCustomerList[clickedIndex] = {
        ...updatedCustomerList[clickedIndex],
        dropdownOpen: !updatedCustomerList[clickedIndex].dropdownOpen || false,
        showOptionViewMessages,
        showOptionStartAttendance
      };
      setCustomerList(updatedCustomerList);
    }
  };

  const GroupPopover = ({ data }) => {
    const [groupPopoverOpen, setGroupPopoverOpen] = useState(false);
    const toggleGroupPopoverOpen = () => setGroupPopoverOpen(!groupPopoverOpen);

    return (
      <>
        <div
          id={`groupList-${data._id}`}
          style={{ width: "30px" }}
          onMouseEnter={toggleGroupPopoverOpen}
          onMouseLeave={toggleGroupPopoverOpen}
        >
          {data.groups.length}
        </div>
        <Popover placement="right" isOpen={groupPopoverOpen} target={`groupList-${data._id}`}>
          <PopoverHeader>Grupos vinculados</PopoverHeader>
          <PopoverBody>
            {data.groups?.map((group, i) => (
              <p key={i}>{group?.name}</p>
            ))}
          </PopoverBody>
        </Popover>
      </>
    );
  };

  const getDepartmentByIdOperator = async (id) => {
    const response = await getDepartmentByUserId(id)
    setDepartmentsOperatorList(response.map(department =>
    ({
      value: department._id,
      label: department.name,
    })));
    if (response.length === 1) {
      setChoosedDepartmentOperator({
        value: response[0]?._id,
        label: response[0]?.name
      })
    }
  }


  useEffect(() => {
    setActiveChatCustomer({})
    const id = decodedToken._id
    getDepartmentByIdOperator(id);
  }, []);

  useEffect(() => {
    handleGetCustomers('', customersStatusList.value)
  }, [customersStatusList])

  useMemo(() => {
    if (getCustomers) {
      handleGetCustomers()
      setGetCustomers(false)
      document.getElementById('input-customers').value = ""
    }
  }, [getCustomers])

  const itemsTableHeader = [
    { label: '', order: 0 },
    { label: 'Nome', order: 1 },
    { label: 'Número', order: 2 },
    { label: 'Canal', order: 3 },
    { label: 'Grupos', order: 4 },
    { label: 'Tags', order: 5 },
    { label: '', order: 6 }
  ]

  const cellsTableData = [
    {
      type: 'image',
      property: 'image',
      order: 0,
      width: 50,
      relative: true,
      childrenItem: {
        type: 'status',
        subProperty: 'status',
      },
      imageWidth: 40,
      imageHeight: 40,
      className: "rounded-circle mr-2",
      action: (data) => handleShowProfile(data),
    },
    {
      type: 'longText',
      property: 'name',
      order: 1,
      width: 200,
      childrenItem: {
        type: 'tag',
        subProperty: 'externalIds',
      },
      action: (data) => handleShowProfile(data),
    },
    {
      type: 'text',
      property: 'phoneFormatted',
      order: 2,
      width: 200,
      action: (data) => handleShowProfile(data),
    },
    {
      type: 'text',
      property: 'channel',
      subProperty: 'name',
      order: 3,
      width: 200,
      action: (data) => handleShowProfile(data),
    },
    {
      type: 'customComponent',
      order: 4,
      width: 150,
      component: (data) => (
        <GroupPopover data={data} />
      )
    },
    {
      type: 'multiText',
      property: 'tags',
      subProperty: 'name',
      order: 5,
      width: 200,
      action: (data) => handleShowProfile(data),
    },
    {
      type: 'actions',
      property: 'actions',
      order: 6,
      width: 50,
      toggle: (data) => handleDropdown(data),
      items: [
        {
          label: 'Perfil',
          action: (data) => handleShowProfile(data),
          show: () => true,
        },
        {
          label: 'Iniciar atendimento',
          action: (data) => handleStartAttendanceLayoutCustomer(data, choosedDepartmentByOperator),
          show: (data) => data.showOptionStartAttendance && canManageChat,
        },
        {
          label: 'Visualizar conversa',
          action: (data) => handleCustomerMessages(data),
          show: (data) => data.showOptionViewMessages && canManageChat,
        },
        {
          label: 'Editar',
          action: (data) => setOpenModalEditCustomer({ open: true, customer: data }),
          show: () => true,
        },
        {
          label: 'Excluir',
          action: (data) => setOpenConfirmationModal({ open: true, customer: data, isDelete: true }),
          show: () => true,
        },
      ]
    },
  ].sort((a, b) => a.order - b.order);

  return (
    <div className="layout">
      <Chat customersRoute />
      <div className="content">
        <div className="content bg-light p-0 align-items-center text-black flex-column h-100" >
          <FilterHeader
            maxRows={maxCustomers}
            title="Contatos"
            inputCallback={getCustomerByFilter}
            hasSelect={true}
            customersStatusList={customersStatusList}
            setCustomersStatusList={setCustomersStatusList}
            addButtonChildren={
              <AddCustomerModal
                onCloseModal={(close) => setOpenModalEditCustomer({})}
                onAddCustomer={() => handleGetCustomers('', customersStatusList.value)}
                editingCustomer={openModalEditCustomer?.open}
                customer={openModalEditCustomer?.customer}
                onEditCustomer={() => handleGetCustomers(1, customersStatusList.value)}      
              />
            }
            inputRef={inputRef}
            placeholder="Procurar por nome ou telefone"
          />

          <div className="sidebar-body w-100 pb-4 px-5 pt-0 position-relative" style={{ height: '80%' }} id="sidebar-customers">
            {loading &&
              <div className="overlay-loading-customers">
                <i className="fa fa-spinner" style={{ animation: 'spin 3s linear infinite', fontSize: 42 }}></i>
              </div>
            }
            {Array.isArray(customerList) ?
              <PerfectScrollbar onYReachEnd={(e) => handleInfiniteGetCustomers(page + 1)} containerRef={ref => scrollRef.current = ref} className='hide-rail-x'>
                {
                  isMobile ? (
                    <Table>
                      <CardsLayoutCustomer
                        data={customerList}
                        loading={loading}
                        handleShowProfile={handleShowProfile}
                        handleStartAttendance={handleStartAttendanceLayoutCustomer}
                        handleCustomerMessages={handleCustomerMessages}
                        setOpenModalEditCustomer={setOpenModalEditCustomer}
                        handleDropdown={handleDropdown}
                        setOpenConfirmationModal={setOpenConfirmationModal}
                      />
                    </Table>
                  ) : (
                    <Table>
                      <TableHeader itemsTableHeader={itemsTableHeader} theadClassName={'thead-customers'} />
                      <TableBody data={customerList} cellsTableData={cellsTableData} bodyClassName={'tbody-customers'} loading={loading} />
                    </Table>
                  )
                }
              </PerfectScrollbar>
              : null}
          </div>
        </div>
      </div>
      {openConfirmationModal?.isDelete ?
        (
          <ConfirmationModal
            openConfirmationModal={openConfirmationModal}
            setOpenConfirmationModal={setOpenConfirmationModal}
            handleRemoveCustomer={() => handleRemoveCustomer(openConfirmationModal.customer)}
            messageModal={'Deseja excluir esse contato ?'}
          />
        ) : (
          <ConfirmationModal
            openConfirmationModal={openConfirmationModal}
            setOpenConfirmationModal={setOpenConfirmationModal}
            setChoosedOption={setChoosedDepartmentOperator}
            choosedOption={choosedDepartmentByOperator}
            listOptions={departmentsOperatorList}
            handleStartAttendanceLayoutCustomer={() => handleStartAttendanceLayoutCustomer(openConfirmationModal.info, choosedDepartmentByOperator)}
            messageModal={'Selecione o departamento para iniciar o atendimento'}
          />

        )

      }
      {profileSidebar && mobileProfileSidebar ?
        <div className='content pl-0' style={{ flexGrow: 'initial', zIndex: 1000 }}>
          <Profile />
        </div>
        : null}
    </div>
  )
}

export default LayoutCustomers
