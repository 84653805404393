import api from "./Axios.config"

const url = process.env.REACT_APP_API_URL + '/api/notification/v1'

export const postNotificationKey = async () => {
    try {
        const response = await api.get(`${url}/public-keys`)
        return response.data
    } catch (error) {
        console.error('Error fetching data notification:', error)
        throw new Error('Failed to get notification')
    }
}

export const postNotification = async (body) => {
    try {
        const response = await api.post(`${url}/sends`, body)
        return response.data
    } catch (error) {
        console.error('Error fetching data notification:', error)
        throw new Error('Failed to get notification')
    }
}
