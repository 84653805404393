import api from './Axios.config'

const url = process.env.REACT_APP_API_URL + '/api/configuration/v1'

export const getAllQuickMessages = async () => {
    try {
        const response = await api.get(`${url}/quick-messages`)
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error)
        throw new Error('Failed to get quick messages')
    }
}

export const deleteQuickMessage = async (id) => {
    try {
        const response = await api.delete(`${url}/quick-messages/${id}`)
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error)
        throw new Error('Failed to delete quick message')
    }
}

export const postQuickMessage = async (body) => {
    try {
        const response = await api.post(`${url}/quick-messages`, body)
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error)
        throw new Error('Failed to create quick message')
    }

}

export const putQuickMessage = async (payload) => {
    try {
        await api.put(`${url}/quick-messages/${payload.id}`, payload.body, {})
    } catch (error) {
        console.error('Error updating quick message:', error);
        throw new Error('Failed to update quick message');
    }
};