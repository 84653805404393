import React, { useState } from "react"
import { Tooltip } from "reactstrap"

const gridStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, minmax(0px, 1fr))',
  justifyItems: 'center',
  gap: '10px',
  marginTop: '5px',
  alignItems: 'center',
}

export const tagStyle = {
  padding: '3px',
  borderRadius: '5px',
  fontSize: '9px',
  textAlign: 'center',
  minWidth: '70px',
  maxWidth: '70px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textWrap: 'nowrap',
  zIndex: 9
}

const TagView = (props) => {
  const {tag, chat} = props
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={`tag-${tag.id}-${chat._id}`}
        toggle={toggle}>
        {tag.name}
      </Tooltip>
      <div className={`bg-${tag.type}`} style={tagStyle} id={`tag-${tag.id}-${chat._id}`}>
        <span className="position-relative" style={{ bottom: -1 }}>{tag.name.toUpperCase()}</span>
      </div>
    </>
  )
}

export const Tags = ({ tags, onClick, chat, cssControl }) => {
  if(!tags) return <></>
  
  return (
    <div style={cssControl || gridStyle } onClick={onClick}>
      {tags.map((tag, i) => <TagView tag={tag} key={i} chat={chat}/>)}
    </div>
  )
}
