import api from './Axios.config';

const url = process.env.REACT_APP_API_URL + '/api/contact/v1'

export const postGroup = async (body) => {
    try {
        const response = await api.post(`${url}/groups`, body)
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error)
        throw new Error('Failed to create group')
    }
}

export const getAllGroupsById = async (channelId) => {
    try {
        const response = await api.get(`${url}/groups?&order=asc&order_field=createdAt&channelId=${channelId}&active=true`)
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error)
        throw new Error('Failed to get groups')
    }
}

export const getAllGroups = async () => {
    try {
        const response = await api.get(`${url}/groups?&order=asc&order_field=createdAt&active=true`)
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error)
        throw new Error('Failed to get groups')
    }
}

export const updateGroup = async (payload) => {
    try {
        await api.put(`${url}/groups/${payload.id}`, payload.body, {
        })
    } catch (error) {
        console.error('Error fetching data:', error)
        throw new Error('Failed to update group')
    }
}

export const cancelGroup = async (id) => {
    try {
        const response = await api.delete(`${url}/groups/${id}`)
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error)
        throw new Error('Failed to cancel group')
    }

}

export const findGroupById = async (id) => {
    try {
        const response = await api.get(`${url}/groups/${id}`)
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error)
        throw new Error('Failed to get by id group')
    }

}