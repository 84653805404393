import React from 'react';
import { Row, Card, CardText } from 'reactstrap';
import DateRegisterCampaign from '../../Components/DateRegisterCampaign';
import CampaignStatusCheck from '../CampaignStatusCheck';

export const CardsLayoutDashboardCampaign = ({ data, loading, ComponentCampaignPopover }) => {
  if (loading) {
    return (
      <div>
        <i className="fa fa-exclamation-circle" style={{ fontSize: '7em', color: '#ebebeb' }}></i>
        <p style={{ fontSize: '1.2em' }}>No momento não há registros.</p>
      </div>
    );
  };
  return (
    <div>
      {data.map((item, i) => (
        <div>
          <Card key={i} className='w-100 p-3 bg-light' style={{
            borderBottom:'2px solid #6161ff',
            borderRadius:'10px',
            borderTop:'none',
            borderLeft:'none',
            borderRight:'none'
            }}>
            <Row className='d-flex align-items-start mb-2 bg-primary p-2' style={{borderRadius:'10px'}}>
              <CardText>
                  <strong style={{fontSize:'18px'}}>Nome:</strong> {item.name}
              </CardText>
            </Row>
            <Row className='d-flex align-items-start mb-2 p-2'>
              <CardText>
                <strong style={{fontSize:'18px'}}>Status</strong><br/>
                <CampaignStatusCheck campaign={item} />
              </CardText>
            </Row>
            <Row className='d-flex align-items-start mb-2 p-2'>
               <CardText>
                    <strong style={{fontSize:'18px'}}>Canais</strong><br/>
                    {ComponentCampaignPopover && <ComponentCampaignPopover data={item} />}
                </CardText>
            </Row>
            <Row className='d-flex align-items-start mb-2 p-2'>
              <CardText>
                <strong style={{fontSize:'18px'}}>Data</strong><br/>
                <DateRegisterCampaign dateTo={item.dateTo} dateFrom={item.dateFrom} />
              </CardText>
            </Row>
          </Card>
        </div>
      ))}
    </div>
  );
};