import React from "react"

export const Ack = ({ack}) => {
  switch (ack) {
    case -1:
      return <>ACK_ERROR</>
    case 0:
    case 1:
    case 2:
    case 4:
      return <i className="ti-double-check"></i>
    case 3:
      return <i className="ti-double-check text-info"></i>
    default:
      return <></>
  }
}
