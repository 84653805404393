import React from 'react'

export const MessageReactionIcon = ({reactions, fromMe}) => {
  if(!reactions.length) return <></>

  let groupedReactions = {};
  
  reactions.forEach(item => {
    const { reaction } = item;
    if (groupedReactions[reaction]) {
      groupedReactions[reaction]++;
    } else {
      groupedReactions[reaction] = 1;
    }
  });
  
  const modifiedReactions = reactions.map(item => {
    const { reaction } = item;
    if (groupedReactions[reaction] > 1) {
      return { ...item, reaction: `${reaction} ${groupedReactions[reaction]}` };
    } else {
      return item;
    }
  });

  const uniqueModifiedReactions = modifiedReactions.filter((item, index, self) => {
    return self.findIndex(innerItem => innerItem.reaction === item.reaction) === index;
  });
  
  return (
    <div style={{position: 'relative'}}>
      <div style={{position: 'absolute', minWidth: '30px', height: 'fit-content', padding: 5, right: fromMe ? 'auto' : 0, backgroundColor: fromMe ? '#cdcdcd' : 'white', boxShadow: '0px 0px 3px 4px rgba(0,0,0,0.2)', zIndex: 2, textAlign: 'center'}} className="rounded-pill">
      {uniqueModifiedReactions.map(({reaction}, i) => {
        return <span className="m-1" key={i}>{reaction}</span>
      })}
      </div>
    </div>
  )
}