import React from 'react';
import ReactECharts from 'echarts-for-react';
import WhitoutDataChartModal from '../App/Modals/WhitoutDataChartModal';

const DoughnutChart = ({ title, data, message, titleToolTip, loading }) => {

    if (loading) {
        return (
            <>
                <div style={{ height: '556px' }}>
                    <h5 style={{ color: '#6161FF', fontWeight: '200', margin: '0' }}>{title}</h5>
                    <div className='d-flex justify-content-center flex-column' style={{ textAlign: "center", alignItems: 'center', height: '100%' }}>
                        <i className="fa fa-spinner" style={{ animation: "spin 3s linear infinite", fontSize: 60 }}></i>
                    </div>
                </div>
            </>
        )
    }

    if (data?.length === 0) {
        return (
            <div style={{ height: '530px', width: '100%', margin: '0px' }}>
                <h5 style={{ color: '#6161FF', fontWeight: '200', margin: '0' }}>{title}</h5>
                <WhitoutDataChartModal message={message} />
            </div>
        );
    }

    const chartData = data?.map(item => ({
        value: item?.total,
        name: item?.type || item?.category
    }));

    const option = {
        title: {
            left: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        series: [
            {
                name: titleToolTip,
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '20',
                        formatter: (params) => {
                            const maxLineLength = 30;
                            const name = params.name;
                            if (name.length > maxLineLength) {
                                const words = name.split(' ');
                                let currentLine = '';
                                const lines = [];
                                for (const word of words) {
                                    if ((currentLine + word).length > maxLineLength) {
                                        lines.push(currentLine.trim());
                                        currentLine = word + ' ';
                                    } else {
                                        currentLine += word + ' ';
                                    }
                                }
                                if (currentLine.length > 0) {
                                    lines.push(currentLine.trim());
                                }
                                return lines.join('\n');
                            }
                            return `${params.name}`;
                        },
                        fontWeight: 'bold',
                    }
                },
                labelLine: {
                    show: false,
                    overflow: 'truncate'
                },
                data: chartData
            }
        ]
    }
    return (
        <>
            <h5 style={{ color: '#6161FF', fontWeight: '200', margin: '0' }}>{title}</h5>
            <div style={{ position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <ReactECharts option={option} style={{ height: '50vh', width: '100%' }} />
            </div>
        </>
    )
};

export default DoughnutChart;


