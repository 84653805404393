import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  getInAttendance,
  getInAutoAttendance,
  getWaitingAttendance,
  postStartAttendance,
  putFinishAttendance,
} from '../Services/Attendance-Service';
import { getMessagesHistory } from '../Services/Contact-Service';
import { getSocketInstance } from '../Services/Socket-Service';
import { AuthContext } from './AuthContext';
import { useToast } from '../App/Partials/Toast';
import { timestampToDate, formatDateForId } from '../Utils/timestamp';
import { useHistory } from 'react-router-dom';
import DisconnectedModal from '../App/Modals/DisconnectedModal';

export const AttendanceContext = createContext({
  socketContext: {
    type: '',
    from: '',
    action: '',
    unreadCount: 0,
    disconnected: false
  },
  socketContextUnread: {
    type: '',
    from: '',
    action: '',
    unreadCount: 0,
    disconnected: false
  },
  socketContextInQueue: {
    type: '',
    from: '',
    action: '',
    unreadCount: 0,
    disconnected: false
  },
  initialQueues: {
    inAutoAttendance: [],
    waitingAttendance: [],
    inAttendance: [],
  },
  isLoadingQueues: false,
  status: '',
  startAttendance: (customer, departmentId) => null,
  finishAttendance: (id, type, description) => null,
  activeChatCustomer: {},
  setActiveChatCustomer: (customer) => null,
  messageHistory: [],
  setMessageHistory: (message) => null,
  attachment: {},
  setAttachment: (file) => null,
  openModalAttachment: false,
  setOpenModalAttachment: (open) => null,
  resetMessages: false,
  setResetMessages: (reset) => { },
  showMessageHistoryModal: false,
  setShowMessageHistoryModal: (show) => null,
  maxPages: 0,
  setMaxPages: (number) => null,
  setChatReadOnly: (reset) => { },
  chatReadOnly: { readOnly: false },
  updateInAttendance: false,
  socketInstance: null,
  getCustomers: false,
  setGetCustomers: (get) => null,
  setFromCustomers: (fromCustomers) => null,
  loadingMessages: false
});

export const AttendanceProvider = ({ children }) => {
  const { accessToken, channelList, setChannelList } = useContext(AuthContext);
  const allChannelsDisconnected = channelList ? channelList.every(channel => channel.status === 'DISCONNECTED') : null
  const { showErrorToast } = useToast();
  const [socketContext, setSocketContext] = useState(null);
  const [socketContextUnread, setSocketContextUnread] = useState(null)
  const [socketContextInQueue, setSocketContextInQueue] = useState(null)
  const [initialQueues, setInitialQueues] = useState({
    inAutoAttendance: [],
    waitingAttendance: [],
    inAttendance: [],
  });
  const [isLoadingQueues, setIsLoadingQueues] = useState(true);
  const [updateInAttendance, setUpdateInAttendance] = useState(false);
  const [showMessageHistoryModal, setShowMessageHistoryModal] = useState(false);
  const [chatReadOnly, setChatReadOnly] = useState({ readOnly: false });
  const [activeChatCustomer, setActiveChatCustomer] = useState(
    JSON.parse(sessionStorage.getItem('customer') || '{}')
  );
  const [resetMessages, setResetMessages] = useState(false);
  const [messageHistory, setMessageHistory] = useState([]);
  const [maxPages, setMaxPages] = useState();
  const [attachment, setAttachment] = useState({});
  const [openModalAttachment, setOpenModalAttachment] = useState(false);
  const [getCustomers, setGetCustomers] = useState(false);
  const socketInstance = getSocketInstance(accessToken);
  const history = useHistory()
  const [loadingMessages, setLoadingMessages] = useState(false)

  const getQueueInAutoAttendance = async () => {
    const inAutoAttendance = await getInAutoAttendance();
    setInitialQueues((prev) => ({
      ...prev,
      inAutoAttendance,
    }));
  };

  const getQueueWaitingAttendance = async () => {
    const waitingAttendance = await getWaitingAttendance();
    setInitialQueues((prev) => ({
      ...prev,
      waitingAttendance,
    }));
  };

  const getQueueInAttendance = async () => {
    const inAttendance = await getInAttendance();
    setInitialQueues((prev) => ({
      ...prev,
      inAttendance,
    }));
  };

  const startAttendance = useCallback(async (customer, departmentId) => {
    const { _id } = await postStartAttendance(customer.phone, customer.channel.id, departmentId);
    setActiveChatCustomer({ ...customer, attendance: { id: _id } })
    return _id
  }, []);

  const finishAttendance = useCallback(async (id, type, description) => {
    await putFinishAttendance(id, type, description);
    setActiveChatCustomer({})
  }, []);

  const handleMessageHistory = async (phone) => {

    setLoadingMessages(true)
    try {

      let { messages, pages } = await getMessagesHistory(phone, 1, activeChatCustomer.channel.id);
      messages.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
      const uniqueDates = new Set();

      messages = messages.reduce((result, message) => {
        const timestamp = new Date(message.createdAt).toDateString();
        if (!uniqueDates.has(timestamp)) {
          uniqueDates.add(timestamp);
          result.push({
            id: `${formatDateForId(timestampToDate(message.timestamp), 'uniqueDate_')}`,
            uniqueDate: timestampToDate(message.timestamp),
            type: 'uniqueDate',
            timestamp: message.timestamp,

          });
        }

        result.push(message);
        return result;

      }, []);

      setMessageHistory(messages.map(msg => ({ formattedDate: timestampToDate(msg.timestamp), ...msg })));
      setMaxPages(pages)

    } catch (error) {
      console.error(error)
    }
    finally {
      setLoadingMessages(false)
    }
  };

  useEffect(() => {
    socketInstance.on('chatbot_status', async (res) => {
      setChannelList(prev => {
        const existingIndex = prev.findIndex(status => status._id === res.id)
        if (existingIndex !== -1) {
          prev[existingIndex].status = res.status
        }

        return prev
      })
      setSocketContext(res)
    });

    socketInstance.on('chatbot_loading', async (res) => {
      setSocketContext({ ...res, type: 'loading' })
    });

    socketInstance.on('disconnect', () => {
      setSocketContext({ disconnected: true })
    });

    socketInstance.on('chatbot_qrcode', async (res) => {
      setSocketContext({ ...res, date: new Date() })
    });

    socketInstance.on('chatbot_queue', async (res) => {
      setSocketContextInQueue(res)
      setSocketContext(res)
      if (res.type === 'inAttendance') {
        setUpdateInAttendance(true)
      }
    });

    socketInstance.on('chatbot_message_unread', async (res) => {
      setSocketContextUnread(res)
      setSocketContext(res)
    });

    socketInstance.on('chatbot_send_error', async (res) => {
      setSocketContext(res)
      showErrorToast(res.message, 3000, 'chatbot_send_error')
    });

    socketInstance.on('chatbot_auth_failure', async (res) => {
      setSocketContext(res)
      showErrorToast(res.message)
    });

    socketInstance.on('chatbot_response_file', async (res) => {
      setSocketContext({ res, type: 'responseFile' })
    });

    socketInstance.on('chatbot_message', async (res) => {
      setSocketContext(res)
    });

    return () => {
      socketInstance.off('chatbot_status')
      socketInstance.off('chatbot_send_error')
      socketInstance.off('chatbot_response_file')
      socketInstance.off('chatbot_message')
      socketInstance.off('disconnect')
    };
  }, [accessToken]);

  useMemo(() => {
    if (allChannelsDisconnected || !channelList) return

    if (history.location.pathname.includes('contatos')) return

    const fetchInitialQueues = async () => {
      await Promise.all([getQueueInAutoAttendance(), getQueueWaitingAttendance(), getQueueInAttendance()]);
      setIsLoadingQueues(false);
    };

    fetchInitialQueues();
  }, []);

  useMemo(() => {

    if (!activeChatCustomer.phone) return;

    handleMessageHistory(activeChatCustomer.phone);
  }, [activeChatCustomer]);

  return (
    <AttendanceContext.Provider
      value={{
        socketContext,
        socketContextUnread,
        socketContextInQueue,
        socketInstance,
        initialQueues,
        isLoadingQueues,
        startAttendance: (customer, departmentId) => startAttendance(customer, departmentId),
        finishAttendance: (id, type, description) => finishAttendance(id, type, description),
        activeChatCustomer,
        setActiveChatCustomer: (customer) => {
          setActiveChatCustomer(customer);
          setResetMessages(true);
          sessionStorage.setItem('customer', JSON.stringify(customer));
        },
        messageHistory,
        setMessageHistory,
        showMessageHistoryModal,
        setShowMessageHistoryModal,
        attachment,
        setAttachment,
        openModalAttachment,
        setOpenModalAttachment,
        resetMessages,
        setResetMessages,
        maxPages,
        setMaxPages,
        chatReadOnly,
        setChatReadOnly,
        updateInAttendance,
        setGetCustomers,
        getCustomers,
        loadingMessages
      }}
    >
      {children}
    </AttendanceContext.Provider>
  );
};

export const AttendanceContextWrapper = ({ children }) => {
  return (
    <AttendanceProvider>
      <DisconnectedModal />
      {children}
    </AttendanceProvider>
  )
}