import React from 'react';
import ReactECharts from 'echarts-for-react';
import WhitoutDataChartModal from '../App/Modals/WhitoutDataChartModal';
import { formatDate } from '../Utils/timestamp';

const StackedBarChart = ({ title,
    legendText,
    data,
    horizontal,
    message,
    colors,
    loading }) => {

    if (loading) {
        return (
            <>
                <div style={{ height: '556px' }}>
                    <h5 style={{ color: '#6161FF', fontWeight: '200', margin: '0' }}>{title}</h5>
                    <div className='d-flex justify-content-center flex-column' style={{ textAlign: "center", alignItems: 'center', height: '100%' }}>
                        <i className="fa fa-spinner" style={{ animation: "spin 3s linear infinite", fontSize: 60 }}></i>
                    </div>
                </div>
            </>
        )
    }

    if (data?.length === 0) {
        return (
            <div style={{ height: '556px', width: '100%', margin: '0px' }}>
                <h5 style={{ color: '#6161FF', fontWeight: '200', margin: '0' }}>{title}</h5>
                <WhitoutDataChartModal message={message} />
            </div>
        );
    }

    const extractMonthAndYear = (date) => {
        const parts = date.split('/')
        const day = parts[0];
        const month = parts[1].slice(-2);
        return `${day}/${month}`
    }

    const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
    const groupedData = sortedData.reduce((acc, curr) => {
        const preFormattedDate = new Date(curr.date)
        const formattedDate = extractMonthAndYear(formatDate(preFormattedDate))
        if (!acc[formattedDate]) {
            acc[formattedDate] = {
                date: formattedDate,
                started: 0,
                finished: 0,
                transfer: 0
            };
        }

        acc[formattedDate].started += curr.started;
        acc[formattedDate].finished += curr.finished;
        acc[formattedDate].transfer += curr.transfer;

        return acc;
    }, {});

    const xAxisData = Object.keys(groupedData);
    const startedData = Object.values(groupedData).map(item => item.started);
    const finishedData = Object.values(groupedData).map(item => item.finished);
    const transferData = Object.values(groupedData).map(item => item.transfer);

    const orientation = horizontal ? 'category' : 'value';
    const xAxis = horizontal ? 'value' : 'category';
    const barWidth = xAxisData.length <= 2 ? '30%' : null;

    const option = {
        tooltip: {
            tooltip: {
                formatter: '{d}'
            },
        },
        legend: {
            data: legendText
        },
        xAxis: {
            type: xAxis,
            data: xAxisData,
            axisLabel: {
                rotate: 45, 
                interval: 0,
                formatter: function (value) {
                    return value.split(' ').join('\n');
                }
            }
        },
        yAxis: {
            type: orientation,
            data: data?.map(item => item.departmentName)
        },
        series: [
            {
                name: legendText ? legendText[0] : '',
                type: 'bar',
                stack: 'total',
                data: startedData,
                color: colors ? colors[0] : '',
                barWidth: barWidth
            },
            {
                name: legendText ? legendText[1] : '',
                type: 'bar',
                stack: 'total',
                data: transferData,
                color: colors ? colors[1] : '',
                barWidth: barWidth
            },
            {
                name: legendText ? legendText[2] : '',
                type: 'bar',
                stack: 'total',
                data: finishedData,
                color: colors ? colors[2] : '',
                barWidth: barWidth,
            }
        ]
    };

    return (
        <>
            <h5 style={{ color: '#6161FF', fontWeight: '200', margin: '0' }}>{title}</h5>
            <ReactECharts option={option} style={{ height: '530px', width: '100%' }} />
        </>

    )
};

export default StackedBarChart;

