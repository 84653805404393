import React from 'react';
import { Row, Col, Card, CardText, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

export const CardsLayoutUsers = ({
  data,
  loading,
  setOpenModalEditUser,
  setOpenConfirmationModal,
  handleDropdown,
  ChannelPopover,
  DepartmentPopover,
  IconPopover
  }) => {
  const toggle = (data) => {
    handleDropdown(data);
  };
  
  if (loading) {
    return (
      <div>
        <i className="fa fa-exclamation-circle" style={{ fontSize: '7em', color: '#ebebeb' }}></i>
        <p style={{ fontSize: '1.2em' }}>No momento não há registros.</p>
      </div>
    );
  };

  return (
    <div>
      {data.map((item, i) => (
        <div>
          <Card key={i} className='w-100 p-3 bg-light' style={{
            borderBottom:'2px solid #6161ff',
            borderRadius:'10px',
            borderTop:'none',
            borderLeft:'none',
            borderRight:'none'
            }}>
            <Row className='d-flex align-items-start mb-2 bg-primary' style={{borderRadius:'10px'}}>
              <Col className='d-flex justify-content-start p-2'>
                <CardText>
                  <strong style={{fontSize:'18px'}}>Nome: </strong><br/>
                  {item.name}
                </CardText>
              </Col>
              <Col className='d-flex justify-content-center p-2'>
                <div>
                  {item.status === "online" ? (
                    <div className='d-flex flex-column justify-content-center align-items-center mt-2'>
                      Online<br/>
                      <i className="fa fa-circle text-success" style={{ textShadow: '0 0 1px white' }}></i>
                    </div>
                  ) : (
                    <div className='d-flex flex-column justify-content-center align-items-center mt-2'>
                      Offline<br/>
                      <i className="fa fa-circle text-danger" style={{ textShadow: '0 0 1px white' }}></i>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row className='d-flex align-items-start mb-2'>
              <Col className='d-flex justify-content-start p-2 text-start'>
                <CardText>
                  <strong style={{fontSize:'18px'}}>E-mail</strong><br/>
                  {item.email}
                </CardText>
              </Col>
              <Col className='d-flex justify-content-center p-2'>
                <CardText>
                  <strong style={{fontSize:'18px'}}>Canais</strong><br/>
                  {ChannelPopover && <ChannelPopover data={item} />}
                </CardText>
              </Col>
            </Row>
            <Row className='d-flex align-items-start mb-2'>
              <Col className='d-flex justify-content-start p-2'>
                <CardText>
                  <strong style={{fontSize:'18px'}}>Departamentos</strong><br/>
                  {DepartmentPopover && <DepartmentPopover data={item} />}
                </CardText>
              </Col>
              <Col className='d-flex justify-content-center p-2'>
                <CardText>
                  <strong style={{fontSize:'18px'}}>Perfil</strong><br/>
                  {item.profile.name}
                </CardText>
              </Col>
            </Row>
            <Row className='d-flex align-items-start mb-2'>
              <Col className='d-flex justify-content-start p-2 text-start'>
                <CardText>
                  <strong style={{fontSize:'18px'}}>Ativo</strong><br/>
                    {item.deactivatedAt === null ? (
                    <i className="fa fa-check" style={{padding: '0px 10px'}}></i>
                    ) : (
                    <>
                    <IconPopover data={data}/>
                    </>
                    )}
                </CardText>
              </Col>
              <Col className='d-flex justify-content-center p-2'>
                <Dropdown isOpen={item.dropdownOpen} toggle={() => toggle(item)}>
                  <DropdownToggle tag="a">
                    <i className={'fa fa-ellipsis-v mt-3'}></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => setOpenModalEditUser({ open: true, user: item })}>
                      Editar
                    </DropdownItem>
                    <DropdownItem onClick={() => setOpenConfirmationModal({ open: true, info: item })}>
                      Cancelar
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
          </Card>
        </div>
      ))}
    </div>
  );
};