import React, { useState, useEffect } from 'react';

const useEditableMessageVerification = ({timestamp}) => {
    const [isWithinTenMinutes, setIsWithinTenMinutes] = useState(false);

    useEffect(() => {
        const checkTime = () => {
            const now = Date.now() / 1000; 
            const tenMinutesLater = timestamp + 10 * 60;
            setIsWithinTenMinutes(now < tenMinutesLater);
        };

        checkTime();  

        const interval = setInterval(checkTime, 1000);  

        return () => clearInterval(interval);  
    }, [timestamp]);
    return isWithinTenMinutes;
};

export default useEditableMessageVerification;

