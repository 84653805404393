import React, { useEffect, useMemo, useState } from 'react';
import { Input, Label, Modal, ModalBody, Tooltip } from 'reactstrap';
import Select from 'react-select';
import { getChannels } from '../../Services/Attendance-Service';
import { getDepartment } from '../../Services/Department-Service';
import { postUser, putUser, getUserProfile } from '../../Services/NewUser-Service';
import { useToast } from '../Partials/Toast';
import 'react-bootstrap-tagsinput/dist/index.css';
import 'react-tagsinput/react-tagsinput.css';

function AddUserModal({ onAddUser, onEditUser, editingUser, user, onCloseModal }) {
  const { showSuccessToast } = useToast();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [channels, setChannels] = useState([]);
  const [choosedChannel, setChoosedChannel] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [choosedDepartment, setChoosedDepartment] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [choosedProfile, setChoosedProfile] = useState([]);
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const tooltipToggle = () => setTooltipOpen(!tooltipOpen);
  const [isUsernameReadOnly, setIsUsernameReadOnly] = useState(false);


  const handleGetChannels = async () => {
    const res = await getChannels()
    setChannels(res.map(channel => {
      return {
        value: channel._id,
        label:channel.shortName || channel.name,
      }
    }))
    if (res.length === 1) {
      setChoosedChannel([{
        value: res[0]._id,
        label: res[0]?.shortName || res[0]?.name
      }])
    }
  };

  const handleGetDepartments = async () => {
    const res = await getDepartment()
    setDepartments(res.map(department => {
      return {
        value: department._id,
        label: department.name
      }
    }))
    if (res.length === 1) {
      setChoosedDepartment([{
        value: res[0]._id,
        label: res[0].name
      }])
    }
  };

  const handleGetProfiles = async () => {
    const res = await getUserProfile();
    const mappedProfiles = res.map(profile => ({
      value: profile._id,
      label: profile.name,
    }));
    setProfiles(mappedProfiles);
    if (res.length === 1) {
      setChoosedProfile(mappedProfiles[0].value);
    }
  };

  const handleDisableSaveButton = () => {
    if (!name || !email || !username || !choosedChannel.length == 1 || !choosedDepartment.length == 1 || !choosedProfile) return true;
    
    if (editingUser && !password) {
      return false;
    }
  
    if (choosedProfile && choosedProfile.length === 2) {
      return true;
    }
  
    return false;
  };

  const handleSave = async () => {
    const data = {
      name,
      email,
      username,
      password,
      channels: choosedChannel.map(channel => channel.value),
      departments: choosedDepartment ? choosedDepartment.map(department => department.value) : [],
      profileId: choosedProfile.value,
    };
    
    try {
      const isEditing = editingUser
      if (isEditing) {
        let editedUser = { ...user, ...data }

        const res = await putUser( user._id, editedUser);
        
        setName(editedUser.name);
        setEmail(editedUser.email);
        setUserName(editedUser.username);
        setPassword(editedUser.password);
        setChannels(editedUser.channels);
        setDepartments(editedUser.departments);
        setProfiles(editedUser.profiles);
        onEditUser(true);
        modalToggle();
        showSuccessToast('Usuário atualizado com sucesso.');
      } else {

        const res = await postUser(data);
  
        resetForm();
        onAddUser(true);
        modalToggle();
        showSuccessToast('Usuário adicionado com sucesso.');
      }
    } catch (error) {
      console.error('Ocorreu um erro:', error);
    }
  };

  const setFormEditingUser = () => {
    setName(user.name);
    setEmail(user.email);
    setUserName(user.username);
    setIsUsernameReadOnly(true);
  //  setPassword(user.password);
    setChoosedChannel(
      user.channels.length ?
        user.channels.map(channel => {
          return {
            value: channel.id,
            label: channel.name
          }
        }) : (
          [{
            value: user.channels.id,
            label: user.channels.name
          }]
        )
    )
    setChoosedDepartment(
      user.departments.map(department => {
        return {
          value: department.id,
          label: department.name
        }
      })
    )
    const mappedProfile = {
      value: user.profile.id,
      label: user.profile.name
    };
    setChoosedProfile(mappedProfile);
  };

  const resetForm = () => {
    setName('');
    setEmail('');
    setUserName('');
    setPassword('');
    setChannels([]);
    setChoosedChannel([]);
    setDepartments([]);
    setChoosedDepartment([]);
    setProfiles([]);
    setChoosedProfile([]);
  };

  useEffect(() => {
    if (!modal) {
      resetForm();
    }
    if (modal && editingUser && user) {
      setFormEditingUser();
    }
    modal && handleGetChannels();
    modal && handleGetDepartments();
    modal &&  handleGetProfiles();
  }, [modal, editingUser, user])

  useMemo(() => {
    if (editingUser && user) {
      modalToggle();
    }
  }, [editingUser, user]);

  useEffect(() => {
    if (modal && editingUser) {
      setIsUsernameReadOnly(true);
    } else {
      setIsUsernameReadOnly(false);
    }
  }, [modal, editingUser]);
  
  return (
    <>
      <button className={ "btn btn-primary font-weight-bold"} onClick={modalToggle} id={"add-user"}>
        Adicionar <i className={"fa fa-plus ml-2"} aria-hidden="true"></i>
      </button>
      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={"add-user"}
        toggle={tooltipToggle}>
        {'Adicionar usuário'}
      </Tooltip>
      <Modal zIndex={9999} isOpen={modal} toggle={(toggle) => {modalToggle(toggle); setIsUsernameReadOnly(false);}} centered className="call modal-dialog-zoom" size='lg' onClosed={(toggle) => {onCloseModal(toggle)}}>
        <button className={"btn btn-light btn-modal-close"} onClick={modalToggle}>
          <i className="fa fa-times"></i>
        </button>
        <ModalBody>
          <div className="call-background" style={{ backgroundColor: '#e6e6e6', filter: 'initial', opacity: 'initial' }}></div>
          <div>
            <h4 style={{ color: 'black' }}>{editingUser ? 'Editar usuário' : 'Adicionar usuário'}</h4>
            <div className="transfer-info-box">
              <div style={{ padding: '0 50px', color: 'black', fontWeight: 'bold' }}>
                <div className="w-100" style={{display:'flex', flexDirection:'row', gap:'1rem'}}>
                  <div className='w-50' style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                    <Label for="name" className='mt-4'>Nome:</Label>
                    <Input type="text" name="name" id="name" style={{ maxHeight: 200 }} onChange={(e) => setName(e.target.value)} autoComplete='off' value={name} />
                  </div>
                  <div className='w-50' style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                    <Label for="email" className='mt-4'>E-mail:</Label>
                    <Input type="text" name="email" id="email" style={{ maxHeight: 200 }} onChange={(e) => setEmail(e.target.value)} autoComplete='off' value={email} />
                  </div>
                </div>
                <div className="w-100" style={{display:'flex', flexDirection:'row', gap:'1rem'}}>
                  <div className='w-50'style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                    <Label for="username" className='mt-4'>Login:</Label>
                    <Input type="text" name="username" id="username" style={{ maxHeight: 200 }} onChange={(e) => setUserName(e.target.value)} autoComplete='off' value={username} readOnly={isUsernameReadOnly} />
                  </div>
                  <div className='w-50'style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                    <Label for="password" className='mt-4'>Senha:</Label>
                    <Input type="text" name="password" id="password" style={{ maxHeight: 200 }} onChange={(e) => setPassword(e.target.value)} autoComplete='off' value={password} />
                  </div>
                </div>
                <div className="w-100">
                  <div style={{width:'100', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                    <Label for="channels" className='mt-4'>Canais:</Label>
                  </div>
                    {channels?.length > 1 && !choosedChannel ?
                  <Select
                    options={channels}
                    name='channels'
                    value={choosedChannel}
                    closeMenuOnSelect={false}
                    noOptionsMessage={(e) => 'Nenhum canal disponível.'}
                    placeholder="Selecione"
                    classNamePrefix={'multiselect'}
                    menuPortalTarget={document.querySelector('body')}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), valueContainer: css => ({ ...css, flexWrap: "nowrap", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' }) }} isMulti={channels.length > 1}
                    onChange={e => setChoosedChannel(e)}
                  />
                  : <Select
                    options={channels}
                    name='channels'
                    value={choosedChannel}
                    closeMenuOnSelect={false}
                    noOptionsMessage={(e) => 'Nenhum canal disponível.'}
                    placeholder="Selecione"
                    classNamePrefix={'multiselect'}
                    menuPortalTarget={document.querySelector('body')}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), valueContainer: css => ({ ...css, flexWrap: "nowrap", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' }) }} isMulti={channels?.length > 1}
                    onChange={e => setChoosedChannel(e)}
                  />}
                  
                </div>
                <div className="w-100">
                  <div style={{width:'100', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                    <Label for="departments" className='mt-4'>Departamento(s):</Label>
                  </div>
                  <Select
                    options={departments}
                    name='departments'
                    value={choosedDepartment}
                    closeMenuOnSelect={false}
                    noOptionsMessage={(e) => 'Nenhum departamento disponível.'}
                    placeholder="Selecione"
                    classNamePrefix={'multiselect'}
                    menuPortalTarget={document.querySelector('body')}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), valueContainer: css => ({ ...css, flexWrap: "nowrap", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' }) }} isMulti={departments?.length > 1}
                    onChange={e => { setChoosedDepartment(e) }}
                  />
                </div>
                <div className="w-100">
                  <div style={{width:'100', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                    <Label for="profiles" className='mt-4'>Perfil:</Label>
                  </div>
                  <Select
                    options={profiles}
                    name='profiles'
                    value={choosedProfile}
                    closeMenuOnSelect={true}
                    noOptionsMessage={(e) => 'Nenhum perfil disponível.'}
                    placeholder="Selecione"
                    classNamePrefix={'multiselect'}
                    menuPortalTarget={document.querySelector('body')}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), valueContainer: css => ({ ...css, flexWrap: "nowrap", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' }) }} 
                    isMulti={false}
                    onChange={e => setChoosedProfile(e)}
                  />
                  </div>
                  <div className='text-right ml-auto'>
                      <button
                        type="button"
                        className="btn btn-primary mt-4 mr-1"
                        data-dismiss="modal"
                        disabled={handleDisableSaveButton()}
                        onClick={() => handleSave()}
                      >
                        Salvar
                      </button>
                  </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default AddUserModal
