import api from './Axios.config';

export const getTotalCampaign = async () => {
    try {
        const response = await api.get(`${process.env.REACT_APP_API_URL}/api/report-marketing/v1/total-campaigns`)
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error)
        throw new Error ('Failed to get total campaign')
    }
};

export const getTotalContact = async () => {
    try {
        const response = await api.get(`${process.env.REACT_APP_API_URL}/api/report-contact/v1/total-active-contacts`)
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error)
        throw new Error ('Failed to get total contact')
    }
};

export const getTotalNotification = async () => {
    try {
        const response = await api.get(`${process.env.REACT_APP_API_URL}/api/report-marketing/v1/total-campaign-sends`)
        return response.data
    } catch (error) {
        console.error('Error fetching data:', error)
        throw new Error ('Failed to get total notification')
    }
};

export const getCampaignToday = async (dateFrom, dateTo) => {
    try {
        const response = await api.get(`${process.env.REACT_APP_API_URL}/api/report-marketing/v1/total-campaigns?dateFrom=${dateFrom}&dateTo=${dateTo}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw new Error('Failed to get campaign today');
    }
};

export const getNotificationToday = async (dateFrom, dateTo) => {
    try {
        const response = await api.get(`${process.env.REACT_APP_API_URL}/api/report-marketing/v1/total-campaign-sends?dateFrom=${dateFrom}&dateTo=${dateTo}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw new Error('Failed to get notification today');
    }
};

export const getNewContactToday = async (dateFrom, dateTo) => {
    try {
        const response = await api.get(`${process.env.REACT_APP_API_URL}/api/report-contact/v1/total-active-contacts?dateFrom=${dateFrom}&dateTo=${dateTo}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw new Error('Failed to get new contact today');
    }
};

export const getContactMonth = async (retroactiveMonths) => {
    try {
        const response = await api.get(`${process.env.REACT_APP_API_URL}/api/report-contact/v1/total-monthly-active-contacts?retroactiveMonths=${retroactiveMonths}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw new Error('Failed to get contact month');
    }
};

export const getChannelContactMonth = async (retroactiveMonths) => {
    try {
        const response = await api.get(`${process.env.REACT_APP_API_URL}/api/report-contact/v1/total-monthly-active-contact-channels?retroactiveMonths=${retroactiveMonths}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw new Error('Failed to get channel contact month');
    }
};

export const getCampaignMonth = async(retroactiveMonths) => {
    try {
        const response = await api.get(`${process.env.REACT_APP_API_URL}/api/report-marketing/v1/total-monthly-campaign-sends?retroactiveMonths=${retroactiveMonths}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw new Error('Failed to get campaign month');
    }
};

export const getChannelCampaignMonth = async(retroactiveMonths) => {
    try {
        const response = await api.get(`${process.env.REACT_APP_API_URL}/api/report-marketing/v1/total-monthly-channel-campaign-sends?retroactiveMonths=${retroactiveMonths}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw new Error('Failed to get channel campaign month');
    }
};

export const getContactChannel = async () => {
    try {
        const response = await api.get(`${process.env.REACT_APP_API_URL}/api/report-contact/v1/total-active-contact-channels`);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw new Error('Failed to get channel');
    }
};