import React, { useState, useEffect } from 'react';
import { formatTimeString, formatDateString } from '../../Utils/timestamp';

const DateRegisterCampaign = ({ dateTo, dateFrom }) => {

    return (
        <>
            {dateTo ?
                <>
                    <div>
                        <i className="fa fa-calendar-check-o" aria-hidden="true" />
                        <span> {formatDateString(dateFrom)} - {formatTimeString(dateFrom)}</span>
                    </div>
                    <div>
                        <i className="fa fa-calendar-times-o" aria-hidden="true" />
                        <span> {formatDateString(dateTo)} - {formatTimeString(dateTo)}</span>
                    </div>
                </>
                :
                <>
                    <i className="fa fa-calendar-check-o" aria-hidden="true" />
                    <span> {formatDateString(dateFrom)} - {formatTimeString(dateFrom)}</span>
                </>
            }
        </>
    )
}

export default DateRegisterCampaign;