import React, { useState, useRef, useEffect } from 'react'
import { tagStyle } from './../../Tags'
import { Tooltip } from 'reactstrap';


export const ChildrenItemCell = (item, childrenItem) => {

  switch (childrenItem.type) {
    case 'tag':
      return TagChildrenCell(item, childrenItem)
    case 'status':
      return StatusChildrenCell(item, childrenItem)
    case 'customComponent':
      return childrenItem.component(item)
    default:
      return null
  }
}

const TagChildrenCell = (item, childrenItem) => (
  <div className='d-flex' style={{ gap: 5 }}>
    {item[childrenItem?.subProperty]?.map((externalId, i) => (
      <div key={i} className="bg-light mb-1 p-0" style={{ ...tagStyle, fontSize: 10, color: '#212529' }}>
        <span class="position-relative" style={{ bottom: -1 }} title={externalId}>Cód: {externalId}</span>
      </div>
    ))}
  </div>
)

const StatusChildrenCell = (item, childrenItem) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const statusIconRef = useRef(null);
  const tooltipToggle = () => setTooltipOpen(!tooltipOpen);

  const linkRef = useRef()
  const [ready, setReady] = useState(false)

  const statusIconClass = getStatusIconClass(item[childrenItem?.subProperty])

  useEffect(() => {
    if (linkRef.current) {
      setReady(true)
    }
  }, [linkRef.current])

  return (
    <>
      {statusIconClass && (
        <i ref={linkRef}
          className={`fa ${statusIconClass} status-icon`}
        />
      )}
      {ready && (
        <Tooltip
          placement="right"
          isOpen={tooltipOpen}
          target={linkRef.current}
          toggle={tooltipToggle}
          style={{ zIndex: 99 }}

        >
          {statusIconClass && statusIconClass === 'fa-ban bg-danger' ? 'Contato Bloqueado' : 'Contato Inválido'}
        </Tooltip>

      )}
    </>
  )
}

const getStatusIconClass = (status) => {
  switch (status) {
    case 'Invalid':
      return 'fa-exclamation-triangle bg-warning'
    case 'Block':
      return 'fa-ban bg-danger'
    default:
      return null
  }
}