import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { TooltipComponent, LegendComponent } from 'echarts/components';
import { PieChart as EchartPieChart } from 'echarts/charts';
import { getContactChannel } from '../Services/ReportsCampaign';
import { LabelLayout } from 'echarts/features';
import { SVGRenderer } from 'echarts/renderers';
echarts.use([TooltipComponent, LegendComponent, EchartPieChart, SVGRenderer, LabelLayout])



const PieChartCampaign = () => {
  const [channel, setChannel] = useState();
  const [loading, setLoading] = useState(false);
  const handleGetTotalChannel = async () => {
    try {
      setLoading(true);
      const res = await getContactChannel();
      const data = res.map(item => ({ value: item.totalContacts, name: item.channelShortName || item.channelName }));
      setChannel(data);
    } catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetTotalChannel();
  }, []);

  const option = {
    tooltip: {
      trigger: 'item',
      formatter: '{b} <br/>{a} : {c} ' /*({d}%)*/
    },
    series: [
      {
        name: 'Contatos',
        type: 'pie',
        radius: '75%',
        center: ['50%', '50%'],
        data: channel,
        label: {
          show: true,
          formatter: '{b}',
          color: '#000000'
        },
      },
    ],
  };

  if (loading) {
    return (
      <div style={{ height: '421px' }}>
        <div className='d-flex justify-content-center flex-column' style={{ textAlign: "center", alignItems: 'center', height: '100%' }}>
          <i className="fa fa-spinner" style={{ animation: "spin 3s linear infinite", fontSize: 60 }}></i>
        </div>
      </div>
    )
  }
  return (
    <ReactECharts
      option={option}
      style={{ width: '100%', position: 'sticky !important', height: '350px' }}
      renderer="svg"
    />
  );
};

export default PieChartCampaign