import api from "./Axios.config"

const url = process.env.REACT_APP_API_URL + '/api/chatbot/v1'

export const postStartChannel = async (channel) => {
  try {
    const response = await api.post(`${url}/start`, {channelId: channel._id})
    return response.data
  } catch (error) {
    if (error.response?.status === 400) return error.response?.data

    console.error('Error fetching data:', error)
    throw new Error('Failed to start channel')
  }
}

export const postStopChannel = async (channel) => {
  try {
    const response = await api.post(`${url}/stop`, {channelId: channel._id})
    return response.data
  } catch (error) {
    if (error.response?.status === 400) return error.response?.data

    console.error('Error fetching data:', error)
    throw new Error('Failed to stop channel')
  }
}
