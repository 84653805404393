import React from 'react';
import { Progress } from 'reactstrap';

const ProgressBar = ({value, text}) => {
    return (
        <div style={{ marginTop: '5px', position: 'relative', width: '100px' }}>
            <Progress
                color="info"
                style={{
                    marginTop: '5px',
                    height: '18px',
                    width: '100px',
                    position: 'absolute'
                }}
                value={value}
            >
                <span style={{ position: 'absolute', width: '100%', textAlign: 'center', color: "black" }}>{value}%</span>
            </Progress>
            <span>{text}</span>
        </div>
    )
}

export default ProgressBar