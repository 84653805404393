import { combineReducers, createStore } from "redux"

import mobileProfileSidebarReducer from "./Reducers/mobileProfileSidebarReducer"
import mobileQueuesSidebarReducer from './Reducers/mobileQueuesSidebarReducer'
import mobileSidebarReducer from "./Reducers/mobileSidebarReducer"
import profileSidebarReducer from "./Reducers/profileSidebarReducer"
import queuesSidebarReducer from './Reducers/queuesSidebarReducer'
import selectedChatReducer from "./Reducers/selectedChatReducer"
import sidebarReducer from "./Reducers/sidebarReducer"
import mainQueueSidebarReducer from "./Reducers/mainQueueSidebarReducer"
import mobileMainQueueSidebarReducer from "./Reducers/mobileMainQueueSidebarReducer"

const reducers = combineReducers({
    selectedSidebar: sidebarReducer,
    mobileSidebar: mobileSidebarReducer,
    profileSidebar: profileSidebarReducer,
    mobileProfileSidebar: mobileProfileSidebarReducer,
    selectedChat: selectedChatReducer,
    mainQueueSidebar: mainQueueSidebarReducer,
    mobileMainQueueSidebar: mobileMainQueueSidebarReducer,
    queuesSidebar: queuesSidebarReducer,
    mobileQueuesSidebar: mobileQueuesSidebarReducer,
});


const store = createStore(reducers);

export default store
