import { useMemo, useContext, useEffect, useState } from 'react'
import { AttendanceContext } from '../../Context/AttendanceContext'
import { Button, Input, Modal, ModalBody } from 'reactstrap'
import React from 'react'
import { useToast } from '../Partials/Toast'
import ExcelImageDefault from '../../assets/img/Excel.png'
import DocImageDefault from '../../assets/img/Doc.png'

const { showErrorToast } = useToast()

const getExtensionFromMime = (mimeType, extensionFromMime) => {
  return extensionFromMime[mimeType] || '';
}

function PreviewAttachmentModal({ details, setDetails, extensionFromMime, extensionsForDefaultPreview }) {

  const [message, setMessage] = useState('')
  const [showLoading, setShowLoading] = useState(false)
  const [isNotAllowedFile, setIsNotAllowedFile] = useState(false)
  const {
    attachment,
    openModalAttachment,
    setOpenModalAttachment,
    socketContext,
    socketInstance,
    activeChatCustomer,
    setAttachment
  } = useContext(AttendanceContext)

  const disablePreVisualization = extensionsForDefaultPreview?.includes(attachment?.type)

  const convertToReadableSize = (bytes) => {
    if (bytes < 1024) {
      return bytes + ' B'
    } else if (bytes < 1024 * 1024) {
      const kilobytes = Math.round((bytes / 1024) * 100) / 100
      return kilobytes + ' KB'
    } else {
      const megabytes = Math.round((bytes / (1024 * 1024)) * 100) / 100
      return megabytes + ' MB'
    }
  }

  const handleSendAttachment = (e) => {
    e.preventDefault()

    const fileSizeLimitAttachment = 16 * 1024 * 1024 // 16 MB in bytes
    const fileSizeLimitBase64 = 21.6 * 1024 * 1024 //  16 + 35% MB in bytes

    if (attachment.size >= fileSizeLimitAttachment) {
      showErrorToast('Arquivo ultrapassa o limite máximo de 8mb.');
      return
    } else {
      const reader = new FileReader()

      reader.readAsDataURL(attachment)

      reader.onload = () => {
        const base64DataUrl = reader.result
        const base64String = base64DataUrl.split(',')[1]

        const base64StringLength = base64String.length
        const base64FileSize = 4 * Math.ceil(base64StringLength / 3)
        if (base64FileSize >= fileSizeLimitBase64) {
          showErrorToast('Arquivo ultrapassa o limite máximo de 8mb.')
          return
        }

        const titleValue = document.getElementById('input-attachment').value

        if (details !== undefined) {

          setDetails({
            type: attachment.type === 'audio/mpeg' ? 'audio/mp3' : attachment.type,
            content: base64String,
            name: attachment.name
          })
          setShowLoading(false)
          setOpenModalAttachment(false)
          return
        }

        socketInstance.emit('chatbot_send_file', {
          cellPhone: activeChatCustomer.phone,
          channelId: activeChatCustomer.channel.id,
          type: attachment.type === 'audio/mpeg' ? 'audio/mp3' : attachment.type,
          content: base64String,
          title: message || titleValue || "",
          fileName: attachment?.name
        })
        document.getElementById('input-attachment').value = ""
        setAttachment({})
      }

      reader.onerror = (error) => {
        console.error('Error', error)
      }
    }

    setShowLoading(true)
  }

  const handleKeyDownEnter = function (evt) {
    if (evt.keyCode === 13) {
      handleSendAttachment(evt)
    }
  };

  const getWSEvent = () => {
    if (socketContext) {
      if (socketContext.type === 'responseFile') {
        setOpenModalAttachment(false)
        setShowLoading(false)
        setMessage('')
      }
    }
  }

  useMemo(() => {
    getWSEvent()
  }, [socketContext])

  useEffect(() => {
    if (openModalAttachment) {
      document.addEventListener('keydown', handleKeyDownEnter);
    } else {
      document.removeEventListener('keydown', handleKeyDownEnter);
    }
    setIsNotAllowedFile(false)
    return () => {
      document.removeEventListener('keydown', handleKeyDownEnter);
    };
  }, [attachment, openModalAttachment])


  return (
    <>
      {openModalAttachment && attachment ? (
        <Modal zIndex={9999} isOpen={openModalAttachment}
          toggle={() => {
            setOpenModalAttachment(false); setShowLoading(false);
            setAttachment({})
          }} centered className="call modal-dialog-zoom">
          <button className={"btn btn-light btn-modal-close"}
            onClick={() => {
              setOpenModalAttachment(false);
              setShowLoading(false); setAttachment({})
            }} style={{ top: 15 }}>
            <i className="fa fa-times"></i>
          </button>
          <ModalBody style={{

            backgroundColor: '#f1f2f6'
          }}>
            {showLoading ?
              <div className="spinner-box">
                <i className="fa fa-spinner"
                  style={{
                    animation: 'spin 3s linear infinite',
                    fontSize: 42
                  }}></i>
              </div>
              : null}
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '0 20px' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20 }}>
                <p style={{ color: 'black', textAlign: 'center', width: '100%', margin: 0 }}>{attachment?.name}</p>
              </div>
              {disablePreVisualization ? (
                <div style={{ color: 'black', textAlign: 'center' }}>
                  <img
                    src={attachment.type === 'application/vnd.ms-excel' ? ExcelImageDefault : DocImageDefault}
                    width={120}
                    height={100}
                  />
                  <p style={{ margin: '20px 0' }}>
                    <br />
                    {convertToReadableSize(attachment.size)} - {getExtensionFromMime(attachment.type, extensionFromMime)}
                  </p>
                </div>
              ) : (
                <RenderPreVisualization attachment={attachment} loading={showLoading} extensionFromMime={extensionFromMime} setIsNotAllowedFile={setIsNotAllowedFile} />
              )}
              <div className="chat-footer">
                <form onSubmit={(e) => { e.preventDefault(); handleSendAttachment(e) }} style={{ display: 'flex', position: 'relative', marginTop: 10, alignItems: 'center' }}>
                  <Input type="text"
                    className="form-control"
                    placeholder={details === undefined ? "Mensagem" : "Enviar arquivo"}
                    disabled={details !== undefined}
                    value={message}
                    onChange={(e) => { setMessage(e.target.value) }}
                    style={{ padding: 25 }}
                    autoComplete="off"
                    id="input-attachment" />
                  <div className="form-buttons" style={{ position: 'absolute', right: 15 }}>
                    <Button color="primary" className="btn-floating" disabled={isNotAllowedFile}>
                      <i className="fa fa-send"></i>
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      ) : null}
    </>
  )
}

const RenderPreVisualization = ({ attachment, loading, extensionFromMime, setIsNotAllowedFile }) => {
  const [previewUrl, setPreviewUrl] = useState('')

  const base64PDFToBlobUrl = (base64) => {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    const blob = new Blob([arr], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    return url;
  }

  useEffect(() => {
    if (!attachment.size) return undefined

    else if (attachment.type === 'application/pdf') {
      const reader = new FileReader()
      reader.readAsDataURL(attachment)
      reader.onload = () => {
        const base64DataUrl = reader.result
        const base64String = base64DataUrl.split(',')[1]
        setPreviewUrl(base64PDFToBlobUrl(base64String))
      }
      return
    }

    const reader = new FileReader()

    reader.onload = () => {
      const imageUrl = URL.createObjectURL(attachment)
      setPreviewUrl(imageUrl)
    }

    reader.readAsDataURL(attachment)
  }, [])

  if (loading) {
    return <h6 style={{ color: 'black', textAlign: 'center' }}>Enviando arquivo...</h6>
  }
  if (getExtensionFromMime(attachment.type, extensionFromMime) === 'IMAGE') {
    return <img src={previewUrl} alt="Imagem pré visualização" style={{ margin: '0 auto', width: 250 }} />
  } else if (getExtensionFromMime(attachment.type, extensionFromMime) === 'PDF') {
    return <embed src={previewUrl} style={{ margin: '0 auto', width: 200, height: 220 }} />
  } else if (getExtensionFromMime(attachment.type, extensionFromMime) === 'VIDEO') {
    return (
      <video
        src={previewUrl}
        style={{ margin: '0 auto', width: 320, }}
        controls
        controlsList="nodownload noplaybackrate nopictureinpicture"
        disablePictureInPicture
        preload="metadata"
      />
    )
  }
  else if (getExtensionFromMime(attachment.type, extensionFromMime) === 'AUDIO') {
    return (
      <div style={{ color: 'black', textAlign: 'center' }}>
        <i className="fa fa-file-audio-o" style={{ fontSize: 100 }}></i>
        <p style={{ margin: '20px 0' }}>
          <br />
        </p>
      </div>
    )
  }
  else {
    setIsNotAllowedFile(true)
    return (
      <div style={{ color: 'black', textAlign: 'center' }}>
        <i className="fa fa-file-excel-o" style={{ fontSize: 100 }}></i>
        <p style={{ margin: '20px 0' }}>
          Arquivo não suportado! <br />
        </p>
      </div>
    )
  }
}


export default PreviewAttachmentModal