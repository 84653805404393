import React, { useMemo, useState } from 'react'
import { Button } from 'reactstrap';
import { ReactComponent as EmojiSVG } from "../../../assets/emoji.svg";
import { EmojiPickerBox } from '../EmojiPickerBox';
import { Emoji } from 'emoji-picker-react';
import { MessageContext } from '../../../Context/MessageContext';
import { useContext } from 'react';
import { AttendanceContext } from '../../../Context/AttendanceContext';

export const MessageReaction = ({message, showActionsOrEmojis}) => {
  const [showEmojisList, setShowEmojisList] = useState(false)
  const [showEmojisListBox, setShowEmojisListBox] = useState(false)
  const {setShowActions, socketInstance, setOpenOverlay, overlayClicked, setClickOverlay} = useContext(MessageContext)
  const {activeChatCustomer} = useContext(AttendanceContext)

  const showEmojisListFn = () => {
    setShowActions({actions: false, id: message._id, emojiOpened: true})
    setShowEmojisList(!showEmojisList)
    setOpenOverlay(true)
  }

  useMemo(() => {
    if(overlayClicked){
      setShowEmojisList(false)
      setShowEmojisListBox(false)
      setOpenOverlay(false)
      setClickOverlay(false)
    }
  }, [overlayClicked])

  const handleEmojiReaction = (emoji, box) => {
    let convertedEmoji = ''
    if(box){
      emoji = emoji.emoji
    } else {
      convertedEmoji = String.fromCodePoint(parseInt(emoji, 16))
    }
    
    socketInstance.emit('chatbot_send_reaction', {
      cellPhone: activeChatCustomer.phone,
      messageId: message._id,
      reaction: convertedEmoji ? convertedEmoji : emoji,
      channelId: activeChatCustomer.channel.id
    })
    setShowActions({actions: false, id: message._id, emojiOpened: false})
    setShowEmojisList(false)
    setShowEmojisListBox(false)
    setOpenOverlay(false)
  }

  const emojis = ["1f44d", "2764-fe0f", "1f602", "1f62e", "1f622", "1f64f"]

  return (
    <div style={{position: 'relative'}}>
      {showEmojisList ? 
        <div style={{display: 'flex', alignItems: 'center', gap: 10, position: 'absolute', bottom: 50, right: -150, zIndex: 4}} className="bg-light rounded-pill p-2 shadow-lg">
          {emojis.map((e, i) => 
            <div style={{cursor: 'pointer'}} onClick={() => handleEmojiReaction(e)} key={i}>
              <Emoji unified={e} size={25}/>
            </div>
          )}
          <Button color="lightgray" className="btn-floating" style={{fontSize: 24}} onClick={() => {setShowEmojisListBox(!showEmojisListBox);setShowEmojisList(false)}}>+</Button>
        </div>
      : null}
      {showEmojisListBox ?
        <div style={{position: 'absolute', bottom: 50, right: message.fromMe ? -100 : -200, zIndex: 4}}>
          <EmojiPickerBox onChangeEmoji={(emoji) => handleEmojiReaction(emoji, true)} />
        </div>
        : null}
      {showActionsOrEmojis ?
        <Button color="light" className="btn-floating" style={{ background: 'transparent' }} onClick={() => showEmojisListFn()}>
          <EmojiSVG />
        </Button> 
      : <div style={{width: 35, height: 35}}></div>}
    </div>
  )
}