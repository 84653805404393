import React, { useEffect, useState } from 'react';
import {
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Button,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import Select from 'react-select';
import { getChannelsActives } from '../../Services/Attendance-Service';
import { postGroup, updateGroup } from '../../Services/Group-Service';
import { useToast } from '../Partials/Toast';
import { useMemo } from 'react';
import 'react-bootstrap-tagsinput/dist/index.css'
import AddButton from '../Components/AddButton';
import 'react-datepicker/dist/react-datepicker.css'
import { Overlay } from '../Partials/Overlay';
import { useRef } from 'react';
import Emojis from '../Partials/Emojis';
import { getCustomersByChannelId } from '../../Services/User-Service';
import PerfectScrollbar from 'react-perfect-scrollbar'
import DefaultImage from '../../assets/img/no-image.png'

function AddGroupModal({
  onCloseModal,
  onAddGroup,
  editingGroup,
  groupInfo,
  onEditGroup
}) {
  const { showSuccessToast, showErrorToast } = useToast();
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [disableSaveButton, setDisableSaveButton] = useState(false)
  const [channels, setChannels] = useState([])
  const [chosenChannels, setChosenChannels] = useState([])
  const [groupName, setGroupName] = useState('')
  const [paddingInput, setPaddingInput] = useState()
  const [inputMsg, setInputMsg] = useState('')
  const inputRef = useRef(null)
  const [messageSubmitted, setMessageSubmitted] = useState('')
  const [loadingSave, setLoadingSave] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [customerList, setCustomerList] = useState(undefined);
  const [maxPages, setMaxPages] = useState(0);
  const [maxCustomers, setMaxCustomers] = useState(0);
  const [linkedContacts, setLinkedContacts] = useState([]);
  const [loading, setLoading] = useState();
  const [filter, setFilter] = useState('');
  const scrollRef = useRef()
  const [contacts, setContacts] = useState([]);
  const [contactSearchTerm, setContactSearchTerm] = useState('');
  const [linkedContactSearchTerm, setLinkedContactSearchTerm] = useState('');
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedLinkedContacts, setSelectedLinkedContacts] = useState([]);
  const [allContactsSelected, setAllContactsSelected] = useState(false);
  const [allLinkedContactsSelected, setAllLinkedContactsSelected] = useState(false);
  const [filtredContacts, setFiltredContacts] = useState([])

  let previousRequestTimer = null;

  const handleGetChannels = async () => {
    const res = await getChannelsActives()
    setChannels
      (res.map(channel =>
      ({
        value: channel._id,
        label: channel.shortName || channel.name,
      })));
    if (res?.length === 1) {
      setChosenChannels([{
        value: res[0]?._id,
        label: res[0]?.shortName || res[0]?.name
      }])
    }
  }

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleDisableSaveButton = () => {
    const shouldDisable =
      !groupName ||
      !chosenChannels ||
      !inputMsg
    setDisableSaveButton(shouldDisable);
  }

  const handleSave = async (start = false) => {
    setLoadingSave(true)
    const chosenChannelsList = chosenChannels.map(channel => ({
      id: channel.value
    }))
    const body = {
      name: groupName,
      description: inputMsg || null,
      channelId: chosenChannelsList[0].id,
      contacts: linkedContacts.map(contact => {
        return { id: contact._id }
      })
    }
    try {
      const isEditing = editingGroup
      if (isEditing) {
        const payload = {
          id: groupInfo._id,
          body
        }
        await updateGroup(payload)
        setLoadingSave(false)
        modalToggle()
        onEditGroup(true)
        showSuccessToast('Grupo atualizado com sucesso.')
        return
      }
      await postGroup(body)
      setLoadingSave(false)
      onAddGroup(true)
      modalToggle()
      showSuccessToast('Grupo cadastrado com sucesso.')
    } catch (error) {
      console.error('An error occurred:', error)
      setLoadingSave(false)
    }
  }

  const setFormEditingGroup = () => {

    setGroupName(groupInfo.name)
    setChosenChannels(
      groupInfo?.channels?.length ?
        groupInfo?.channels?.map(channel => {
          return {
            value: channel.id,
            label: channel.name
          }
        }) : (
          [{
            value: groupInfo.channel.id,
            label: groupInfo.channel.name
          }]
        ))
    setInputMsg(groupInfo.description)
    setLinkedContacts(
      groupInfo.contacts.map(contact => {
        return {
          _id: contact.id,
          name: contact.name,
          phone: contact.phone,
          image: contact.image
        }
      })
    )
  }

  const resetForm = () => {
    setChosenChannels([])
    setGroupName('')
    setInputMsg('')
    setLoadingSave(false)
    setDisableSaveButton(false)
    setLinkedContacts([])
    setContactSearchTerm('')
    setLinkedContactSearchTerm('')
  }

  const handleGetCustomers = async (channelId, pageAfterEdit) => {

    try {

      setLoading(true)
      const res = await getCustomersByChannelId(channelId, '', pageAfterEdit ? pageAfterEdit : page);
      setCustomerList(
        res.data.map(contact => {
          return {
            _id: contact._id,
            name: contact.pushname,
            phone: contact.phone,
            image: contact.image
          }
        })

      );
      setMaxPages(res.meta.pages);
      setMaxCustomers(res.meta.rows);
      if (pageAfterEdit) {
        setPage(pageAfterEdit)
        setFilter('')
        document.getElementById('input-customers').value = ""
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  };


  const pasteIntoInput = (text) => {
    const el = inputRef.current
    el.focus()

    if (typeof el.selectionStart === 'number' && typeof el.selectionEnd === 'number') {
      const val = el.value
      const selStart = el.selectionStart
      el.value = val.slice(0, selStart) + text + val.slice(el.selectionEnd)
      el.selectionEnd = el.selectionStart = selStart + text.length
    } else if (typeof document.selection !== 'undefined') {
      const textRange = document.selection.createRange()
      textRange.text = text
      textRange.collapse(false)
      textRange.select()
    }
  }

  const handleEnter = (e) => {
    if (e.keyCode === 13 && e.shiftKey) {
      if (e.type === 'keypress' || e.type === 'keydown') {
        pasteIntoInput('\n')
      }
      e.preventDefault()
    }
  }

  const handleChange = (e) => {
    setInputMsg(e)
  }

  const getCustomerByFilter = async (channelId, filter) => {
    clearTimeout(previousRequestTimer);
    previousRequestTimer = setTimeout(async () => {
      try {
        if (scrollRef.current && scrollRef.current.scrollTop) {
          scrollRef.current.scrollTop = 0
        }
        setLoading(true)
        const res = await getCustomersByChannelId(channelId, filter, 1);
        setPage(1)
        setFiltredContacts(
          res.data.map(contact => {
            return {
              _id: contact._id,
              name: contact.pushname,
              phone: contact.phone,
              image: contact.image
            }
          })
        )
        setMaxPages(res.meta.pages);
        setFilter(filter);
      } catch (error) {
        console.log('error', error)
      } finally {
        setLoading(false)
      }
    }, 300);
  };

  const handleInfiniteGetCustomers = async (channelId, page) => {

    if (loading || maxPages < page) {
      return
    }

    try {
      setLoading(true)

      const res = await getCustomersByChannelId(channelId, filter, page)
      const newData = res.data

      const scrollHeight = scrollRef.current.scrollHeight
      setPage(page)
      setCustomerList((prevCustomerList) => [...prevCustomerList, ...newData])
      setMaxPages(res.meta.pages);
      scrollRef.current.scrollTop = (scrollHeight * 2) - scrollHeight;
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  };

  const handleCheckboxChange = (contact, isLinked) => {
    if (isLinked) {
      setSelectedLinkedContacts(prev =>
        prev.includes(contact._id) ?
          prev.filter(id => id !== contact._id) :
          [...prev, contact._id]
      );
    } else {
      setSelectedContacts(prev =>
        prev.includes(contact._id) ?
          prev.filter(id => id !== contact._id) :
          [...prev, contact._id]
      );
    }
  };

  const moveSelectedContacts = (toLinked) => {
    if (toLinked) {
      const contactsToMove = contacts.filter(contact => selectedContacts.includes(contact._id));
      setLinkedContacts([...linkedContacts, ...contactsToMove]);
      setContacts(contacts.filter(contact => !selectedContacts.includes(contact._id)));
      setSelectedContacts([]);
    } else {
      const linkedContactsToMove = linkedContacts.filter(contact => selectedLinkedContacts.includes(contact._id));
      setContacts([...contacts, ...linkedContactsToMove]);
      setLinkedContacts(linkedContacts.filter(contact => !selectedLinkedContacts.includes(contact._id)));
      setSelectedLinkedContacts([]);
    }
  };

  const selectAllContacts = () => {
    if (allContactsSelected) {
      setSelectedContacts([]);
    } else {
      const allContactIds = filteredContacts.map(contact => contact._id);
      setSelectedContacts(allContactIds);
    }
    setAllContactsSelected(!allContactsSelected);
  };

  const selectAllLinkedContacts = () => {
    if (allLinkedContactsSelected) {
      setSelectedLinkedContacts([]);
    } else {
      const allLinkedContactIds = filteredLinkedContacts.map(contact => contact._id);
      setSelectedLinkedContacts(allLinkedContactIds);
    }
    setAllLinkedContactsSelected(!allLinkedContactsSelected);
  };

  let filteredContacts = contacts?.filter(contact => {
    if (!loading && contacts) {
      const searchTerm = contactSearchTerm?.toLowerCase();
      return contact?.name?.toLowerCase()?.includes(searchTerm) || contact?.phone?.toLowerCase()?.includes(searchTerm);
    }
  });

  let filteredLinkedContacts = linkedContacts?.filter(contact => {
    const searchTerm = linkedContactSearchTerm?.toLowerCase();
    return contact?.name?.toLowerCase()?.includes(searchTerm) || contact?.phone?.toLowerCase()?.includes(searchTerm);
  });

  const isLargeScreen = window.innerWidth >= 992;

  useEffect(() => {
    if (!modal) {
      resetForm()
    }

    if (modal && editingGroup && groupInfo) {
      setFormEditingGroup()
      return
    }
    modal && handleGetChannels()
    modal && handleDisableSaveButton()
  }, [modal])

  useEffect(() => {

    if (chosenChannels.length > 0) {
      modal && handleGetCustomers(chosenChannels[0].value)
    }
  }, [chosenChannels])

  useEffect(() => {
    handleDisableSaveButton()
    return () => handleDisableSaveButton()
  }, [groupName, inputMsg])

  useEffect(() => {
    if (linkedContacts) {
      const isEqual = (obj, linkedContacts) => {
        return linkedContacts.some(item => item._id === obj._id)
      }
      let filtredContactsList = customerList?.filter(obj => !isEqual(obj, linkedContacts))
      setContacts(filtredContactsList);
      return
    }
    setContacts(customerList);

  }, [customerList, linkedContacts]);

  useMemo(() => {
    if (editingGroup) {
      modalToggle()
    }
  }, [editingGroup])

  return (
    < >
      <AddButton tooltipText='Adicionar grupo' onClick={modalToggle} id={'add-btn-1'} className='ml-auto' />
      <Modal isOpen={modal}
        toggle={(toggle) => { modalToggle(toggle) }}
        centered className='call modal-dialog-zoom'
        size='lg'
        zIndex={9999}
        onClosed={(toggle) => { onCloseModal(toggle) }}>
        {loadingSave && (
          <div className='spinner-box'>
            <i className='fa fa-spinner'
              style={{
                animation: 'spin 3s linear infinite',
                fontSize: 42
              }}></i>
          </div>
        )}
        <button className={'btn btn-light btn-modal-close'} onClick={modalToggle}>
          <i className='fa fa-times'></i>
        </button>
        <Overlay />
        <div style={{ backgroundColor: '#e6e6e6' }}>
          <ModalBody>
            <div className='call-background' style={{ backgroundColor: '#e6e6e6', filter: 'initial', opacity: 'initial' }}></div>
            <div className='mb-2'>
              <h4 style={{ color: 'black' }}>{editingGroup ? 'Editar grupo' : 'Adicionar grupo'}</h4>
              <div className='transfer-info-box' >
                <div style={{ padding: '0 50px', color: 'black', fontWeight: 'bold' }}>
                  <div className='d-flex align-items-end'>
                    <div className='w-100 text-left ml-2'>
                      <Label for='nameCampaign' className='mt-4'>Nome:</Label>
                      <Input type='text'
                        placeholder='Nome do grupo'
                        name='nameCampaign'
                        id='name'
                        style={{ maxHeight: 200 }}
                        onChange={(e) =>
                          setGroupName(e.target.value)}
                        autoComplete='off'
                        value={groupName}
                      />
                    </div>
                    <div className='w-100 text-left ml-2'>
                      <Label for='channels'>Selecionar canal:</Label>
                      <Select
                        options={channels}
                        name='channels'
                        closeMenuOnSelect={false}
                        noOptionsMessage={(e) => 'Nenhum canal disponível.'}
                        placeholder='Selecione'
                        classNamePrefix={'multiselect'}
                        menuPortalTarget={document.querySelector('body')}
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                          valueContainer: css => ({
                            ...css,
                            flexWrap: 'nowrap',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          })
                        }}
                        isMulti={false}
                        onChange={e => setChosenChannels([e])}
                        value={chosenChannels}
                      />
                    </div>
                  </div>
                  <div className='d-flex align-items-end'>
                    <div className='w-100 text-left  ml-2'>
                      <Label className='w-100 mt-4'>Selecionar contatos:</Label>
                      <Row style={{
                        justifyContent: 'space-between',
                        flexWrap: 'wrap'
                      }}>
                        <Col md={5} className='mx-0'>
                          <Card style={{ color: 'black', textAlign: 'center', width: '270px' }}>
                            <CardTitle tag="h6" className='mt-2'>
                              Contatos
                            </CardTitle>
                            <div>
                              <Button size="sm" onClick={selectAllContacts} className='btn btn-secondary font-weight-bold'>
                                {'Selecionar/Desmarcar todos'}
                              </Button>
                            </div>
                            <CardBody style={{
                              height: '300px',
                              userSelect: 'none',
                              width: '100%',
                              overflow: 'hidden'
                            }}>
                              <Input
                                type="text"
                                placeholder="Pesquisar contatos..."
                                value={contactSearchTerm}
                                onChange={(e) => {
                                  setContactSearchTerm(e.target.value);
                                  getCustomerByFilter(chosenChannels[0].value, e.target.value);
                                }}
                              />
                              <PerfectScrollbar
                                containerRef={ref => scrollRef.current = ref}
                                onYReachEnd={() => handleInfiniteGetCustomers(chosenChannels[0]?.value, page + 1)}
                                className='hide-rail-x'>
                                {loading && (
                                  <div className='d-flex justify-content-center flex-column' style={{ textAlign: "center", alignItems: 'center', height: '100%' }}>
                                    <i className="fa fa-spinner" style={{ animation: "spin 3s linear infinite", fontSize: 30 }}></i>
                                  </div>
                                )}
                                <ListGroup style={{ textAlign: 'left' }}>
                                  {filteredContacts?.map((contact) => (
                                    <ListGroupItem key={contact._id} action style={{
                                      whiteSpace: 'nowrap',
                                      verticalAlign: 'middle',
                                      overflow: 'hidden',
                                      cursor: 'pointer'
                                    }} onClick={() => handleCheckboxChange(contact, false)}>
                                      <input
                                        type="checkbox"
                                        checked={selectedContacts.includes(contact._id)}
                                        style={{ marginRight: '10px' }}
                                        readOnly={true}
                                      />
                                      <img src={contact.image || DefaultImage}
                                        alt={contact.name}
                                        className="rounded-circle mr-2"
                                        style={{
                                          width: '40px',
                                          height:'40px',
                                          marginRight: '5px',
                                          marginBottom: '10px'
                                        }}
                                      />
                                      <div style={{ display: 'inline-block', fontSize: '12px' }}>
                                        <strong>{contact.name}</strong><br />
                                        <small>{contact.phone}</small>
                                      </div>
                                    </ListGroupItem>
                                  ))}
                                </ListGroup>
                              </PerfectScrollbar>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={1} className="d-flex flex-column align-items-center justify-content-center mx-0" style={{}}>
                          <Button color="primary" onClick={() => moveSelectedContacts(true)} style={{ marginBottom: '5px' }}>
                            <i className="fa fa-arrow-right" aria-hidden="true" />
                          </Button>
                          <Button color="primary" onClick={() => moveSelectedContacts(false)}>
                            <i className="fa fa-arrow-left" aria-hidden="true" />
                          </Button>
                        </Col>
                        <Col md={5} className='mx-0'>
                          <Card style={{ color: 'black', textAlign: 'center', width: '270px', marginLeft: isLargeScreen ? '-30px' : '0' }}>
                            <CardTitle tag="h6" className='mt-2'>
                              Contatos agrupados
                            </CardTitle>
                            <div>
                              <Button color="secondary" size="sm" onClick={selectAllLinkedContacts} className='btn btn-secondary font-weight-bold'>
                                {'Selecionar/Desmarcar todos'}
                              </Button>
                            </div>
                            <CardBody style={{
                              height: '300px',
                              userSelect: 'none',
                              width: '100%',
                              overflow: 'hidden'
                            }}>
                              <Input
                                type="text"
                                placeholder="Pesquisar contatos..."
                                value={linkedContactSearchTerm}
                                onChange={(e) => setLinkedContactSearchTerm(e.target.value)}
                              />
                              <PerfectScrollbar className='hide-rail-x'>
                                <ListGroup style={{ textAlign: 'left' }}>
                                  {filteredLinkedContacts.map((contact) => (
                                    <ListGroupItem key={contact._id} action style={{
                                      whiteSpace: 'nowrap',
                                      verticalAlign: 'middle',
                                      overflow: 'hidden',
                                      cursor: 'pointer'
                                    }} onClick={() => handleCheckboxChange(contact, true)}>
                                      <input
                                        type="checkbox"
                                        checked={selectedLinkedContacts.includes(contact._id)}
                                        style={{ marginRight: '10px' }}
                                        readOnly={true}
                                      />
                                      <img src={contact.image || DefaultImage}
                                        alt={contact.name}
                                        className="rounded-circle mr-2"
                                        style={{
                                          width: '40px',
                                          height:'40px',
                                          marginRight: '5px',
                                          marginBottom: '10px'
                                        }} />
                                      <div style={{ display: 'inline-block', fontSize: '12px' }}>
                                        <strong>{contact.name}</strong><br />
                                        <small>{contact.phone}</small>
                                      </div>
                                    </ListGroupItem>
                                  ))}
                                </ListGroup>
                              </PerfectScrollbar>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className='w-100 mt-4 text-left ml-2'>
                    <div>
                      <Label for='nameCampaign' className='mt-4'>Descrição:</Label>
                      <div className='d-flex align-items-center'>
                        <Emojis
                          onChange={handleChange}
                          inputMsg={inputMsg}
                          messageSubmitted={messageSubmitted}
                          closeOnChange pickerBoxStyle={{ bottom: 0, left: 60, top: 140 }} />
                        <Input
                          type='textarea'
                          onKeyDown={handleEnter}
                          ref={inputRef}
                          className='form-control'
                          placeholder='Descrição do grupo...'
                          value={inputMsg}
                          onChange={(e) => { setInputMsg(e.target.value) }}
                          id='input-chat'
                          style={{ paddingRight: paddingInput, resize: 'none', height: 100, border: 0, marginLeft: 10 }}
                          autoComplete='off'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='text-right ml-auto'>
                    <button
                      type="button"
                      className="btn btn-primary mt-4 mr-1"
                      data-dismiss="modal"
                      disabled={disableSaveButton}
                      onClick={() => handleSave()}
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal >
    </>
  )
}

export default AddGroupModal


