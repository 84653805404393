import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Button } from 'reactstrap'

const waveSm = keyframes`
  0% {
    opacity: 0.35;
    height: 2px;
  }
  100% {
    opacity: 1;
    height: 25px;
  }
`;

const waveMd = keyframes`
  0% {
    opacity: 0.35;
    height: 5px;
  }
  100% {
    opacity: 1;
    height: 30px;
  }
`;

const waveLg = keyframes`
  0% {
    opacity: 0.35;
    height: 5px;
  }
  100% {
    opacity: 1;
    height: 30px;
  }
`;

const Body = styled.div`
  height: 10px;
  display: grid;
  place-items: center;
`;

const SoundWave = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bar = styled.div`
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  background: #aaa;
  margin: 0 1.5px;
  height: 10px;
  width: 1px;

  &:nth-child(-n + 7),
  &:nth-last-child(-n + 7) {
    animation-name: ${waveMd};
  }

  &:nth-child(-n + 3),
  &:nth-last-child(-n + 3) {
    animation-name: ${waveSm};
  }

  &:nth-child(n + 8) {
    animation-name: ${waveLg};
  }
`;

const SoundWaveComponent = ({ handleResetAudio }) => {
  useEffect(() => {
    const bars = document.querySelectorAll('.bar');
    bars.forEach((item) => {
      item.style.animationDuration = `${Math.random() * (0.7 - 0.2) + 0.2}s`;
    });
  }, []);

  const bars = Array.from({ length: 60 }).map((_, i) => <Bar key={i} className="bar" />);

  return (
    <>
      <Button color="light" className="btn-floating"
        onClick={() => {
          handleResetAudio();
        }}>
        <i className="fa fa-trash" style={{ padding: '15px' }} />
      </Button>
      <Body>
        <SoundWave >
          {bars}
        </SoundWave>
      </Body>
    </>
  );
};

export default SoundWaveComponent;
