import React, { useEffect, useRef, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { getChannelContactMonth, getContactMonth } from '../Services/ReportsCampaign';
import { useMobile } from '../App/Partials/useMobile/useMobile';

const ProspectChart = () => {
  const chartRef = useRef(null);
  const [contactMonthData, setContactMonthData] = useState([]);
  const [channelMonthData, setChannelMonthData] = useState([]);
  const [selectedTypeChart, setSelectedTypeChart] = useState('channel');
  const [haveButtonsOptions, setHaveButtonsOptions] = useState(false)
  const [loading, setLoading] = useState(false);
  const isMobile = useMobile();

  const getMonthName = (month) => {
    const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    return months[month - 1];
  };

  const handleGetContactMonthData = async () => {
    const retroactiveMonths = 6;
    try {
      setLoading(true);
      const res = await getContactMonth(retroactiveMonths);
      setContactMonthData(res);
    } catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  };

  const xAxisData = contactMonthData.map((data) => {
    const [year, month] = data.monthYear.split('-');
    const monthName = getMonthName(Number(month))
    return monthName;
  }).reverse();

  const seriesData = contactMonthData.map((data) => data.total).reverse();

  const handleGetChannelCampaignMonthData = async () => {
    const retroactiveMonths = 6;
    try {
      setLoading(true);
      const res = await getChannelContactMonth(retroactiveMonths);
      setContactMonthData(res);
      const channels = res[0].channels;
      const uniqueChannelNames = new Set(channels.map(channel => channel.name));

      const hasMultipleChannelNames = uniqueChannelNames.size > 1;
      setHaveButtonsOptions(hasMultipleChannelNames)

      const channelData = res.flatMap((data) => data.channels.map((channel) => ({
        monthYear: data.monthYear, name: channel.shortName || channel.name, total: channel.total
      })));
      setChannelMonthData(channelData);
    } catch (error) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  };

  const seriesDataPerChannel = (data) => {
    const sortedData = data.sort((a, b) => {
      if (a.monthYear < b.monthYear) {
        return -1;
      }
      if (a.monthYear > b.monthYear) {
        return 1;
      }
      return 0;
    });
    return sortedData.reduce((acc, item) => {
      const index = acc.findIndex((series) => series.name === item.name);
      if (index === -1) {
        acc.push({
          name: item.name,
          type: 'bar',
          stack: 'total',
          data: [{
            name: item.monthYear,
            value: item.total,
          }],
          itemStyle: {
            borderWidth: 1,
          },
        });
      } else {
        acc[index].data.push({
          name: item.monthYear,
          value: item.total,
        });
      }
      return acc;
    }, []);
  };

  const dataPerChannel = seriesDataPerChannel(channelMonthData);

  useEffect(() => {
    handleGetChannelCampaignMonthData()
  }, []);

  if (loading) {
    return (
      <div style={{ height: '421px' }}>
        <h5 style={{ color: '#00bf63', fontWeight: '900' }} className='mt-4'>Novos contatos</h5>
        <div className='d-flex justify-content-center flex-column' style={{ textAlign: "center", alignItems: 'center', height: '100%' }}>
          <i className="fa fa-spinner" style={{ animation: "spin 3s linear infinite", fontSize: 60 }}></i>
        </div>
      </div>
    )
  };

  if (seriesData.length === 0) {
    return (
      <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: '421px' }}>
        <i className="fa fa-exclamation-circle" style={{ fontSize: '7em', color: '#ebebeb' }}></i>
        <p style={{ fontSize: '1.2em' }}>No momento não há registros.</p>
      </div>
    );
  };

  return (
    <div className='w-100 d-flex flex-column overflow-hidden'>
      <div>
        <h5 style={{ color: '#ff914d', fontWeight: '900' }} className='mt-4'>Novos contatos</h5>
      </div>
      {selectedTypeChart === 'consolidate' && (
        <ReactECharts
          ref={chartRef}
          style={{ width: '100%', height: '350px' }}
          option={{
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              formatter: (params) => {
                const contacts = params[0].value;
                return `Contatos: ${contacts}`;
              },
              borderColor: '#ff914d',
              position: function (point, params, dom, rect, size) {
                if (isMobile) {
                  const x = (size.viewSize[0] - size.contentSize[0]) / 2;
                  const y = Math.min(point[1], size.viewSize[1] - size.contentSize[1]);
                  return [x, y];
                }
                return null;
              }
            },
            xAxis: {
              type: 'category',
              data: xAxisData,
              axisLabel: {
                interval: 0,
              },
            },
            yAxis: {
              type: 'value',
              axisTick: {
                show: false,
              },
              splitLine: {
                lineStyle: {
                  color: '#f0ad4e99',
                },
              },
            },
            series: [{
              data: seriesData,
              type: 'bar',
              itemStyle: {
                color: '#ff914d',
                borderColor: '#f0ad4e',
                borderWidth: 1,
              }
            }],
          }}
        />
      )}
      {selectedTypeChart === 'channel' && (
        <ReactECharts
          ref={chartRef}
          option={{
            tooltip: (dataPerChannel.length <= 6) ?
              {
                trigger: 'item',
                axisPointer: {
                  type: 'shadow'
                },
                formatter: (params) => {
                  let tooltipContent = '';
                  const channelName = params.seriesName;
                  const contacts = params.value;
                  tooltipContent += `${channelName}<br/>Contatos: ${contacts}<br/>`;
                  return tooltipContent;
                },
                position: function (point, params, dom, rect, size) {
                  if (isMobile) {
                    const x = (size.viewSize[0] - size.contentSize[0]) / 2;
                    const y = Math.min(point[1], size.viewSize[1] - size.contentSize[1]);
                    return [x, y];
                  }
                  return null;
                }
              } :
              {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                },
                formatter: (params) => {
                  let tooltipContent = '';
                  params.forEach((param) => {
                    const channelName = param.seriesName;
                    const contacts = param.value;
                    tooltipContent += `${channelName}<br/>Contatos: ${contacts}<br/>`;
                  });
                  return tooltipContent;
                },
                position: function (point, params, dom, rect, size) {
                  if (isMobile) {
                    const x = (size.viewSize[0] - size.contentSize[0]) / 2;
                    const y = Math.min(point[1], size.viewSize[1] - size.contentSize[1]);
                    return [x, y];
                  }
                  return null;
                }
              },
            xAxis: {
              type: 'category',
              data: xAxisData,
              axisLabel: {
                interval: 0,
              },
            },
            yAxis: {
              type: 'value',
              axisTick: {
                show: false,
              },
              splitLine: {
                lineStyle: {
                  color: '#28a74580',
                },
              },
            },
            series: dataPerChannel,
            legend: {
              type: 'scroll',
            },
          }}
          style={{ width: '100%', height: '350px' }}
        />
      )}
      <div className='w-100 d-flex flex-row justify-content-center'>
        {haveButtonsOptions && (
          <div style={{ marginRight: '30px' }}>
            <input
              style={{ marginRight: '5px' }}
              type="radio"
              name="selectedTypeChart"
              value="channel"
              checked={selectedTypeChart === 'channel'}
              onChange={() => {
                setSelectedTypeChart('channel');
                handleGetChannelCampaignMonthData();
              }}
            />
            <label htmlFor="channel">Por canal</label>
          </div>
        )}

        {haveButtonsOptions && (
          <div >
            <input
              style={{ marginRight: '5px' }}
              type="radio"
              name="selectedTypeChart"
              value="consolidate"
              checked={selectedTypeChart === 'consolidate'}
              onChange={() => {
                setSelectedTypeChart('consolidate');
                handleGetContactMonthData();
              }}
            />
            <label htmlFor="consolidate">Consolidado</label>
          </div>
        )}


      </div>
    </div>
  )
}

export default ProspectChart;