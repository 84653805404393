import React from 'react';
import { Row, Col, Card, CardText, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { formatDateString } from '../../../Utils/timestamp';

export const CardsLayoutGroup = ({
    data,
    loading,
    setOpenModalEditGroup,
    setOpenConfirmationModal,
    handleDropdown
}) => {

    const toggle = (data) => {
        handleDropdown(data);
    };

    if (loading) {
        return (
            <div>
                <i className="fa fa-exclamation-circle" style={{ fontSize: '7em', color: '#ebebeb' }}></i>
                <p style={{ fontSize: '1.2em' }}>No momento não há registros.</p>
            </div>
        );
    };

    return (
        <div>
            {data.map((item, i) => (
                <div>
                    <Card key={i} className='w-100 p-3 bg-light' style={{
                        borderBottom: '2px solid #6161ff',
                        borderRadius: '10px',
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderRight: 'none'
                    }}>
                        <Row className='d-flex align-items-start mb-2 bg-primary' style={{ borderRadius: '10px' }}>
                            <Col className='d-flex justify-content-start p-2'>
                                <CardText>
                                    <strong style={{ fontSize: '18px' }}>Nome:</strong><br />
                                    {item.name}
                                </CardText>
                            </Col>
                            <Col className='d-flex justify-content-center p-2'>
                                <Dropdown isOpen={item.dropdownOpen} toggle={() => toggle(item)}>
                                    <DropdownToggle className='text-light' tag="a">
                                        <i className={'fa fa-ellipsis-v mt-3'}></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => setOpenModalEditGroup({ open: true, tagInfo: item })}>
                                            Editar
                                        </DropdownItem>
                                        <DropdownItem onClick={() => setOpenConfirmationModal({ open: true, info: item })}>
                                            Cancelar
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row className='d-flex align-items-start mb-2'>
                            <Col className='d-flex justify-content-start p-2'>
                                <CardText>
                                    <strong style={{ fontSize: '18px' }}>Descrição</strong><br />
                                    {item.description}
                                </CardText>
                            </Col>
                            <Col className='d-flex justify-content-center p-2'>
                                <CardText>
                                    <strong style={{ fontSize: '18px' }}>Data de Criação</strong><br />
                                    {formatDateString(item.createdAt)}
                                </CardText>
                            </Col>
                        </Row>
                        <Row className='d-flex align-items-start mb-2'>
                            <Col className='d-flex justify-content-center p-2'>
                                <CardText>
                                    <strong style={{ fontSize: '18px' }}>Canal</strong><br />
                                    {item.channel.name}
                                </CardText>
                            </Col>
                            <Col className='d-flex justify-content-start p-2'>
                                <CardText>
                                    <strong style={{ fontSize: '18px' }}>Contatos</strong><br />
                                    {item.contacts.length}
                                </CardText>
                            </Col>
                        </Row>
                    </Card>
                </div>
            ))}
        </div>
    );
};