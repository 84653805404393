import { usePhoneInput } from 'react-international-phone';
import parsePhoneNumber from 'libphonenumber-js';

function useFormattedPhone(phoneNumber) {
  const { phone } = usePhoneInput({
    value: phoneNumber,
  });

  if (phoneNumber === '-') {
    return '-';
  }

  return phone;
}

export default useFormattedPhone;

export const isPhoneValid = (phone) => {
  try {
    const phoneNumber = parsePhoneNumber(phone);
    return phoneNumber.isValid();
  } catch (error) {
    return false;
  }
};
