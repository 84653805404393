import React, { useState, useEffect } from 'react';
import { Tooltip } from 'reactstrap';

const AddButton = ({ tooltipText, onClick, disabledButton, id, className }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [modal, setModal] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const modalToggle = () => setModal(!modal);

  return (
    <>
      <button
        className={className + " btn btn-primary font-weight-bold"}
        onClick={onClick}
        id={id || 'add-btn'}
        disabled={disabledButton}>
        Adicionar <i className="fa fa-plus ml-2" aria-hidden="true"></i>
      </button>

      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen && disabledButton}
        target={id || 'add-btn'}
        toggle={toggleTooltip}>
        {tooltipText}
      </Tooltip>
    </>
  );
};

export default AddButton;
