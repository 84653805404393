import React, { useContext, useEffect, useState } from 'react';
import { FormGroup, Input, Label, Modal, ModalBody, Tooltip } from 'reactstrap';
import { AttendanceContext } from '../../Context/AttendanceContext';
import { getFinishReasons } from '../../Services/Attendance-Service';
import { useToast } from '../Partials/Toast';

function FinishAttendanceModal() {
  const { showWarningToast } = useToast()
  const {finishAttendance, activeChatCustomer, setActiveChatCustomer} = useContext(AttendanceContext)
  const [finishReasons, setFinishReasons] = useState([])
  const [choosedReason, setChoosedReason] = useState()
  const [description, setDescription] = useState()
  const [descriptionRequired, setDescriptionRequired] = useState()

  const [modal, setModal] = useState(false);

  const modalToggle = () => setModal(!modal);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tooltipToggle = () => setTooltipOpen(!tooltipOpen);

  const handleFinishReasons = async () => setFinishReasons(await getFinishReasons())

  const handleConfirm = () => {
    if (descriptionRequired && !description && choosedReason) {
      showWarningToast('Preencha a descrição para confirmar.')
      return
    }

    finishAttendance(activeChatCustomer.attendance.id, choosedReason, description)
    setActiveChatCustomer({})
    modalToggle()
    return
  }

  const handleDescriptionRequired = (reason) => {
    setDescriptionRequired(finishReasons.find((f) => f._id === reason).descriptionRequired)
  }

  useEffect(() => {
    modal && handleFinishReasons()
  }, [modal])

  return (
    <div>
      <button className="btn btn-danger" onClick={modalToggle} id="Tooltip-Voice-Call">
        <i className="fa fa-ban"></i>
      </button>
      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={"Tooltip-Voice-Call"}
        toggle={tooltipToggle}>
        Finalizar atendimento
      </Tooltip>
      <Modal zIndex={9999} isOpen={modal} toggle={modalToggle} centered className="call modal-dialog-zoom">
        {/* <button className={"btn btn-light btn-modal-close"} onClick={modalToggle} style={{top: 15}}>
          <i className="fa fa-times"></i>
        </button> */}
        <ModalBody>
          <div className="call-background" style={{ backgroundColor: '#e6e6e6', filter: 'initial', opacity: 'initial' }}></div>
          <div>
            <h4 style={{ color: 'black' }}>Deseja finalizar o atendimento?</h4>
            <div style={{padding: '0 50px', color: 'black', fontWeight: 'bold'}}>
              <Label for="reason">Motivo:</Label>
              <Input defaultValue={''} type="select" name="reason" id="reason" onChange={(e) => { setChoosedReason(e.target.value); handleDescriptionRequired(e.target.value) }}>
                <option disabled value=''></option>
                {finishReasons?.map((reason, i) => (
                  <option key={i} value={reason._id}>
                    {reason.name}
                  </option>
                ))}
              </Input>
              <FormGroup style={{ color: 'black', fontWeight: 'bold', marginTop: 10 }}>
                {choosedReason &&
                  finishReasons.find((f) => f._id === choosedReason)?.descriptionRequired ? (
                  <Label for="descricao">Descrição(obrigatório):</Label>
                ) : (
                  <Label for="descricao">Descrição:</Label>
                )}
                <Input type="textarea" name="descricao" id="descricao" style={{maxHeight: 200}} onChange={(e) => setDescription(e.target.value)}/>
              </FormGroup>
            </div>
            <div>
              <button type="button" onClick={modalToggle}
                className="btn btn-secondary btn-md" style={{marginRight: 10}}
                data-dismiss="modal">
                Cancelar
              </button>
              <button type="button" onClick={handleConfirm} disabled={choosedReason ? false : true}
                className="btn btn-primary btn-md">
                Confirmar
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default FinishAttendanceModal
