import React, { useEffect, useRef, useMemo } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useState } from 'react';
import { Table, Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import { TableBody } from './Partials/Table/TableBody';
import { CardsLayoutCampaign } from './Partials/CardsLayout/CardsLayoutCampaign';
import { TableHeader } from './Partials/Table/TableHeader';
import { getAllCampaign, getAllCampaignTypes } from '../Services/Campaign-Service';
import AddCampaign from './Modals/AddCampaignModal';
import { FilterHeader } from './Partials/FilterHeader';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ConfirmationModal from './Components/ConfirmationModal';
import { cancelCampaign } from '../Services/Campaign-Service';
import { useToast } from './Partials/Toast';
import DateRegisterCampaign from './Components/DateRegisterCampaign';
import PreviewCampaignComponent from './Components/PreviewCampaignComponent';
import CampaignStatusCheck from './Partials/CampaignStatusCheck';
import ChannelStatusCampaignCheck from './Partials/ChannelStatusCampaignCheck';
import { useMobile } from './Partials/useMobile/useMobile';

function LayoutCampaign() {
  const [loading, setLoading] = useState();
  const [openModalEditCampaign, setOpenModalEditCampaign] = useState(false);
  const [filter, setFilter] = useState('');
  const scrollRef = useRef()
  const [page, setPage] = useState(1);
  const [campaignList, setCampaignList] = useState(undefined)
  const [maxPages, setMaxPages] = useState(0);
  const [maxCampaigns, setMaxCampaigns] = useState(0);
  const [campaignTypesList, setCampaignTypesList] = useState([])
  const [openConfirmationModal, setOpenConfirmationModal] = useState('')
  const { showSuccessToast } = useToast()
  const [isVisible, setIsVisible] = useState(false);
  const isMobile = useMobile();

  const inputRef = useRef();

  const handleGetCampaign = async (pageAfterEdit) => {
    try {
      setLoading(true)
      const res = await getAllCampaign('', pageAfterEdit ? pageAfterEdit : page);
      setCampaignList(res.data)
      setMaxPages(res.meta.pages)
      setMaxCampaigns(res.meta.rows)
      if (pageAfterEdit) {
        setPage(pageAfterEdit)
        setFilter('')
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleInfiniteGetCampaigns = async (page) => {

    if (loading || maxPages < page) {
      return
    }

    try {
      setLoading(true)

      const res = await getAllCampaign(filter, page)
      const newData = res.data

      const scrollHeight = scrollRef?.current?.scrollHeight
      setPage(page)
      setCampaignList((prevCustomerList) => [...prevCustomerList, ...newData])
      setMaxPages(res.meta.pages);
      scrollRef.current.scrollTop = (scrollHeight * 2) - scrollHeight;
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleGetCampaignTypes = async () => {
    const response = await getAllCampaignTypes()
    const resultCampaignsType = response.map(CampaignType =>
    ({
      id: CampaignType._id,
      value: CampaignType.type,
      label: CampaignType.name,
      enableContactGroup: CampaignType?.enableContactGroup || ''
    }))
    setCampaignTypesList(resultCampaignsType)

  }

  const itemsTableHeader = [
    { label: 'Nome', order: 0 },
    { label: 'Tipo', order: 1 },
    { label: 'Data/Hora', order: 2 },
    { label: 'Preview', order: 3 },
    { label: 'Canais', order: 4 },
    { label: 'Status', order: 5 },
    { label: '', order: 6 }
  ]

  const cellsTableData = [
    {
      type: 'longText',
      property: 'name',
      order: 0,
      width: 250,
      height: 50,
      paddingLeft: 10,

    },
    {
      type: 'customComponent',
      order: 1,
      paddingLeft: 10,
      height: 50,
      component: (data) => (
        <div width={250}>{findTranslate(data.types[0])}</div>
      )
    },
    {
      type: 'customComponent',
      order: 2,
      width: 250,
      height: 50,
      paddingLeft: 10,
      component: (data) => (
        <DateRegisterCampaign
          dateTo={data.dateTo}
          dateFrom={data.dateFrom}
        />
      )
    },
    {
      type: 'customComponent',
      order: 3,
      height: 50,
      width: 150,
      paddingLeft: 10,
      component: (data) => (
        <PreviewCampaignComponent
          campaign={data}
        />
      )
    },
    {
      type: 'customComponent',
      order: 4,
      height: 50,
      paddingLeft: 10,
      component: (data) => (
        <ComponentCampaignPopover data={data} />
      )
    },
    {
      type: 'customComponent',
      order: 5,
      height: 50,
      paddingLeft: 10,
      component: (data) => (
        <CampaignStatusCheck campaign={data} />
      )
    },
    {
      type: 'actions',
      property: 'actions',
      order: 6,
      with: 70,
      height: 50,

      toggle: (data) => handleDropdown(data),
      items: [
        {
          label: 'Editar',
          action: (data) =>
            setOpenModalEditCampaign({ open: true, campaignInfo: data }),
          show: () => true,
        },
        {
          label: 'Cancelar',
          action: (data) => setOpenConfirmationModal({ open: true, info: data }),
          show: () => true,
        },
      ],
      itemsChange:
        [
          {
            label: 'Consultar',
            action: (data) =>
              setOpenModalEditCampaign({ open: true, campaignInfo: data }),
            show: () => true,
          },
        ]
    }
  ]

  const findTranslate = (value) => {
    const item = campaignTypesList.find(item => item.value === value);
    return item ? item?.label : 'Valor não encontrado ';
  }

  const handleDropdown = (clickedItem) => {
    const index = campaignList.findIndex((campaign) =>
      campaign._id === clickedItem._id);

    if (index !== -1) {
      const updatedCampaignList = [...campaignList];

      updatedCampaignList[index] = {
        ...updatedCampaignList[index],
        dropdownOpen: !updatedCampaignList[index].dropdownOpen || false,
      };

      setCampaignList(updatedCampaignList);
    }
  };

  const handleCancelCampaign = async (data) => {
    try {
      const id = data?._id
      await cancelCampaign(id);
      showSuccessToast('Campanha cancelada com sucesso')
    } catch (error) {
      console.error('An error occurred:', error)
    } finally {
      handleGetCampaign();
      setOpenConfirmationModal(null)
    }
  }

  const ComponentCampaignPopover = ({ data }) => {
    const [popoverCampaignOpen, setPopoverCampaignOpen] = useState(false);
    const toggleCampaignOpen = () => setPopoverCampaignOpen(!popoverCampaignOpen);

    return (
      <>
        <div
          id={`channelsList-${data._id}`}
          style={{ width: "30px" }}
          onMouseEnter={toggleCampaignOpen}
          onMouseLeave={toggleCampaignOpen}
        >
          {data.channels.length}
        </div>
        <Popover
          placement="right"
          isOpen={popoverCampaignOpen}
          target={`channelsList-${data._id}`}
        >
          <PopoverHeader>Canais vinculados</PopoverHeader>
          <PopoverBody>
            <table>
              <tbody>
                {data.channels?.map((channel, i) => (
                  <tr key={i}>
                    <td>{channel?.shortName}</td>
                    <td>
                      <ChannelStatusCampaignCheck channel={channel} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </PopoverBody>
        </Popover>
      </>
    );
  };

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  });

  useEffect(() => {
    handleGetCampaign()
    if (campaignTypesList?.length === 0) {
      handleGetCampaignTypes()
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isVisible && !event.target.closest('#closeDocument')) {
        setIsVisible(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isVisible]);

  return (
    <div className='layout'>
      <div className='content'>
        <div className="content bg-light p-0 align-items-center text-black flex-column h-100">
          <FilterHeader
            maxRows={maxCampaigns}
            title="Campanhas"
            addButtonChildren={
              <AddCampaign
                onCloseModal={(close) => { setOpenModalEditCampaign({}) }}
                onAddCampaign={() => handleGetCampaign(1)}
                editingCampaign={openModalEditCampaign.open}
                campaignInfo={openModalEditCampaign.campaignInfo}
                onEditCampaign={() => handleGetCampaign(1)}
                campaignTypesList={campaignTypesList}
                setCampaignTypesList={setCampaignTypesList}
              />
            }
          />
          <div className="sidebar-body w-100 pb-4 px-5 pt-0"
            style={{ position: 'relative', height: '80%' }}
            id="sidebar-departments">
            {loading &&
              <div className="overlay-loading-customers">
                <i className="fa fa-spinner"
                  style={{
                    animation: 'spin 3s linear infinite',
                    fontSize: 42
                  }}></i>
              </div>
            }
            {Array.isArray(campaignList) ?
              <PerfectScrollbar
                onYReachEnd={(e) => { handleInfiniteGetCampaigns(page + 1) }}
                containerRef={ref => scrollRef.current = ref}
                className='hide-rail-x'>
                {
                  isMobile ? (
                    <Table>
                      <CardsLayoutCampaign
                        data={campaignList}
                        loading={loading}
                        findTranslate={findTranslate}
                        setOpenModalEditCampaign={setOpenModalEditCampaign}
                        setOpenConfirmationModal={setOpenConfirmationModal}
                        handleDropdown={handleDropdown}
                        ComponentCampaignPopover={ComponentCampaignPopover}
                      />
                      <ConfirmationModal
                        openConfirmationModal={openConfirmationModal}
                        setOpenConfirmationModal={setOpenConfirmationModal}
                        handleCancelCampaign={handleCancelCampaign}
                        messageModal='Deseja cancelar essa campanha ?'
                      />
                    </Table>
                  ) : (
                    <Table>
                      <TableHeader
                        itemsTableHeader={itemsTableHeader}
                        theadClassName={'thead-customers'} />
                      <TableBody
                        data={campaignList}
                        cellsTableData={cellsTableData}
                        bodyClassName={'tbodyCampaign'}
                        loading={loading}
                      />
                      <ConfirmationModal
                        openConfirmationModal={openConfirmationModal}
                        setOpenConfirmationModal={setOpenConfirmationModal}
                        handleCancelCampaign={handleCancelCampaign}
                        messageModal='Deseja cancelar essa campanha ?'
                      />
                    </Table>
                  )
                }
              </PerfectScrollbar>
              : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LayoutCampaign