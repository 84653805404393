import React, { useContext, useEffect, useState } from 'react';
import { FormGroup, Input, Label, Modal, ModalBody, Tooltip } from 'reactstrap';
import { AttendanceContext, } from '../../Context/AttendanceContext';
import { getDepartments, getFinishReasons } from '../../Services/Attendance-Service';
import { useToast } from '../Partials/Toast';
import { getUsersByDepartment, postTransferAttendance, customerQueueTransfer } from './../../Services/User-Service';
import { ReactComponent as DisconnectedSvg } from "../../assets/disconnected.svg";
import { useAuth } from '../../Context/AuthContext';
import TransferModal from './TransferModal';

function TransferAttendanceModal() {
  const { showWarningToast } = useToast()
  const decodedToken = useAuth()
  const { activeChatCustomer, setActiveChatCustomer } = useContext(AttendanceContext)
  const [departments, setDepartments] = useState([])
  const [operators, setOperators] = useState([])
  const [choosedDepartment, setChoosedDepartment] = useState()
  const [choosedOperator, setChoosedOperator] = useState()
  const [privateMessage, setPrivateMessage] = useState('')

  const [modal, setModal] = useState(false);

  const modalToggle = () => setModal(!modal);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tooltipToggle = () => setTooltipOpen(!tooltipOpen);

  const handleGetDepartments = async () => setDepartments(await getDepartments())
  const handleGetOperators = async (department) => {
    let res = await getUsersByDepartment(department)
    res = res.filter(operator => operator._id !== decodedToken._id)
    setOperators(res)
  }

  const handleQueueTransfer = async () => {
    const payload = {
      cellPhone: activeChatCustomer.cellPhone,
      body: {
        channelId: activeChatCustomer._id,
        departmentId: choosedDepartment,
        privateMessage
      }
    }
    try {
      await customerQueueTransfer(payload)

    } catch (error) {
      console.error(error)
    } finally {
      modalToggle()
      setActiveChatCustomer({})
    }
  }

  const handleTransfer = async () => {
    const body = {
      departmentId: choosedDepartment,
      userId: choosedOperator || null,
      privateMessage,
    }
    try {
      await postTransferAttendance(activeChatCustomer.attendance.id, body)

    } catch (error) {
      console.error(error)
    }
    finally{
      modalToggle()
      setActiveChatCustomer({})
    }
  }

  const handleOnChangeDepartment = (department) => {
    setChoosedDepartment(department)
    handleGetOperators(department)
  }

  const resetForm = () => {
    setChoosedDepartment(null)
    setChoosedOperator(null)
    setPrivateMessage(null)
  }

  const operatorStatusIndicator = (status) => {
    if (status === 'online') {
      return '🟢'
    }

    return '🔴'
  }

  useEffect(() => {
    if (!modal) {
      resetForm()
    }
    modal && handleGetDepartments()
  }, [modal])

  return (
    <div>
      <button className="btn btn-primary d-flex flex-column" onClick={modalToggle} id="Tooltip-Voice-Call2">
        <i className="fa fa-long-arrow-right" aria-hidden="true" style={{ position: 'relative', top: -5 }}></i>
        <i className="fa fa-long-arrow-left" aria-hidden="true" style={{ marginTop: -13, position: 'relative', top: 5 }}></i>
      </button>
      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={"Tooltip-Voice-Call2"}
        toggle={tooltipToggle}>
        Transferir atendimento
      </Tooltip>
      {modal && (
        <TransferModal
          activeChatCustomer={activeChatCustomer}
          departments={departments}
          operators={operators}
          choosedDepartment={choosedDepartment}
          choosedOperator={choosedOperator}
          handleOnChangeDepartment={handleOnChangeDepartment}
          setChoosedOperator={setChoosedOperator}
          handleTransfer={activeChatCustomer.attendance?.id ? handleTransfer: handleQueueTransfer}
          privateMessage={privateMessage}
          setPrivateMessage={setPrivateMessage}
          operatorStatusIndicator={operatorStatusIndicator}
          modal={modal}
          modalToggle={modalToggle}
        />
      )}
    </div>
  )
}

export default TransferAttendanceModal
