import React, { useContext, useMemo, useState } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { AttendanceContext } from '../../Context/AttendanceContext';
import { getInAutoAttendance, getWaitingAttendance } from '../../Services/Attendance-Service';
import { mobileQueuesAction } from './../../Store/Actions/mobileQueuesAction';
import { queuesAction } from './../../Store/Actions/queuesAction';
import ChatsDropdown from './Chats/ChatsDropdown';
import { UnreadCounter } from '../Partials/UnreadCounter';
import DefaultImage from "../../assets/img/no-image.png"
import AttendanceTimer from '../Partials/AttendanceTimer';
import { Tooltip } from 'reactstrap';
import { tagStyle } from './../Partials/Tags';
import { Tags } from './../Partials/Tags';

const gridStyleQueue = {
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, minmax(0px, 1fr))',
  gap: '2px',
  alignItems: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingLeft: '5px',
  

  
}

function Queues() {
  const [unreadMessages, setUnreadMessages] = useState([])
  const [openQueues, setOpenQueues] = useState(false)
  const [autoAttendances, setAutoAttendances] = useState([])
  const [waitingAttendances, setWaitingAttendances] = useState([])

  const {
    socketContext,
    initialQueues,
    isLoadingQueues,
    setActiveChatCustomer,
    setShowMessageHistoryModal,
    startAttendance
  } = useContext(AttendanceContext)

  const dispatch = useDispatch();

  const { queuesSidebar, mobileQueuesSidebar } = useSelector(state => state);

  const queuesActions = (e) => {
    e.preventDefault();
    dispatch(queuesAction(false));
    dispatch(mobileQueuesAction(false))
  };

  const handleInAutoAttendances = async () => {
    setAutoAttendances(await getInAutoAttendance())
    setOpenQueues(true)
  }

  const handleWaitingAttendances = async () => {
    setWaitingAttendances(await getWaitingAttendance())
    setOpenQueues(true)
  }

  const handleStartAttendance = (chat) => {
    startAttendance(chat)
    setShowMessageHistoryModal(false)
  }

  const openMessageHistoryModal = (chat) => {
    setActiveChatCustomer(chat)
    setShowMessageHistoryModal(true)
  }

  const getWSEvent = async () => {
    if (!socketContext) return

    if (socketContext.unreadCount || socketContext.unreadCount === 0) {
      const unreadCount = socketContext

      if (socketContext.unreadCount === 0) {
        setUnreadMessages((prevMessages) =>
          prevMessages.map((msg) => {
            if (msg.from === unreadCount.from && msg.channelId === unreadCount.channelId) {
              return { ...msg, unreadCount: 0 }
            }
            return msg
          })
        )
      }

      setUnreadMessages((prevMessages) => {
        const counterExists = prevMessages.find(msg => msg.from === unreadCount.from && msg.channelId === unreadCount.channelId)
        if (counterExists) {
          const counterIndex = prevMessages.indexOf(counterExists)
          prevMessages.splice(counterIndex, 1)
        }

        return [
          ...prevMessages,
          unreadCount
        ]
      })
    }

    if (socketContext && socketContext.type === 'auto') {
      await handleInAutoAttendances()
    }

    if (socketContext && socketContext.type === 'waitingAttendance') {
      await handleWaitingAttendances()
    }
  }

  useMemo(() => {
    getWSEvent()
  }, [socketContext])

  useMemo(() => {
    if (!isLoadingQueues) {
      setAutoAttendances(initialQueues.inAutoAttendance)
      setWaitingAttendances(initialQueues.waitingAttendance)
    }
  }, [isLoadingQueues])

  const WaitingAttendancesChatListView = (props) => {
    const { chat, i } = props;

    const [tooltipAttendanceOpen, setTooltipAttendanceOpen] = useState(false);
    const [tooltipViewOpen, setTooltipViewOpen] = useState(false);

    const toggleAttendance = () => {
      setTooltipViewOpen(false)
      setTooltipAttendanceOpen(!tooltipAttendanceOpen)
    }

    const toggleView = () => {
      setTooltipAttendanceOpen(false)
      setTooltipViewOpen(!tooltipViewOpen)
    };

    return (
      <div>
        <li className="list-group-item" style={{ display: 'block', padding: '10px 20px' }}>
          <div className="users-list-action-new action-toggle" style={{ boxShadow: 'none', order: 1 }}>
            <ChatsDropdown customer={chat}/>
          </div>
          <div className="users-list-top users-top" onClick={() => { openMessageHistoryModal(chat) }}>
            {chat.attendance?.transfer ? <i className="fa fa-arrow-right bg-info" aria-hidden="true" style={{ position: 'absolute', top: 15, left: 25, fontSize: 10, borderRadius: 10, padding: '3px 4px 4px 4px' }}></i> : null}
            <img
              className="rounded-circle" style={{ cursor: 'pointer', margin: '0 10px' }}
              src={chat.image || DefaultImage}
              onError={(e) => e.target.src = DefaultImage}
              alt={'Imagem usuário ' + chat.name}
              width={50}
              height={50}
            />
            <div style={{ marginRight: 'auto', marginLeft: 'auto', textAlign: 'center', maxWidth: '65%' }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5 }}>
                <h5 style={{ fontSize: 16, fontWeight: 600, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', margin: '0 auto' }}>{chat.name}</h5>
                <div>
                  <UnreadCounter
                    activeChatCustomer={null}
                    customer={chat}
                    unreadMessages={unreadMessages}
                  />
                </div>
              </div>
              <span>{chat.phoneFormatted}</span>
              <br />
              <span>{chat.attendance.departmentName}</span>
            </div>
          </div>
          <div
            className="users-list-body"
            style={{ textAlign: 'center' }}>
            <div className="users-info" >
              <div className="d-flex-aligns-items-center">
                {/* <div className={!chat.externalIds ? '' : 'bg-dark-bright'} style={{...tagStyle, fontSize: 10, padding: '1px 0'}}>
                <span style={!chat.externalIds ? {opacity: 0} : { position: 'relative', bottom: -1, fontWeight: 'bold' }}>Cód.: {chat.externalIds}</span>
              </div> */}
                {chat.externalIds ?
                  <div className='d-block'>
                    {chat.externalIds.map((externalId, i) => (
                      <div key={i} className={`bg-dark-bright mb-1`} style={{ ...tagStyle, fontSize: 10, padding: '1px 0' }}>
                        <span style={{ position: 'relative', bottom: -1, fontWeight: 'bold' }} title={externalId}>Cód.: {externalId}</span>
                      </div>
                    ))}
                  </div>
                  : null}
                <small className="bg-dark-bright">{chat.channel.name}</small>
              </div>

              <div className={chat?.tags?.length > 0 ? 'mt-4' : 'mt-0'} style={{ order: 3 }}>
                <AttendanceTimer time={chat.attendance.createdAt} />
                <button className="btn btn-info" style={{ padding: '3px 10px' }} onClick={() => { handleStartAttendance(chat) }} id={"tooltip-attendance" + chat._id}>
                  <i className="fa fa-phone" style={{ fontSize: 16 }}></i>
                </button>
                <Tooltip
                  placement="bottom"
                  isOpen={tooltipAttendanceOpen}  
                  target={"tooltip-attendance" + chat._id}
                  toggle={toggleAttendance}>
                  Iniciar atendimento
                </Tooltip>
                <button className="btn btn-light-dark" style={{ padding: '3px 8px' }} onClick={() => { openMessageHistoryModal(chat) }} id={"tooltip-view" + chat._id}>
                  <i className="fa fa-eye" style={{ fontSize: 16 }}></i>
                  <Tooltip
                    placement="bottom"
                    isOpen={tooltipViewOpen}
                    target={"tooltip-view" + chat._id}
                    toggle={toggleView}>
                    Visualizar conversa
                  </Tooltip>
                </button>
              </div>
            </div>
            <div className='tags-queue mt-4 mx-4' style={{
              order: 4, position: 'absolute',
              zIndex: 200,
              paddingTop: chat.externalIds && chat.externalIds.length > 1 ? 
              `${chat.externalIds?.length * 10}px` : ''
            }}>
              <Tags
                tags={chat.tags}
                chat={chat}
                cssControl={gridStyleQueue}
              />
            </div>
          </div>
        </li>
      </div>
    )
  };

  return (
    <div className={`sidebar-group ${mobileQueuesSidebar || openQueues ? "mobile-open" : ""}`} style={{ marginLeft: '30px' }}>
      <div className={queuesSidebar || openQueues ? 'sidebar active' : 'sidebar'}>
        <header>
          <span>Aguardando atendimento</span>
          <ul className="list-inline">
            <li className="list-inline-item" style={{ position: 'relative' }}>
              {waitingAttendances.length ? <div className="new-message-count" style={{ position: 'absolute', right: 50, top: 10 }}>{waitingAttendances.length > 90 ? '99+' : waitingAttendances.length}</div> : ''}
              <a href="/#/" onClick={(e) => queuesActions(e)}
                className="btn btn-light">
                <i className="ti ti-close"></i>
              </a>
            </li>
          </ul>
        </header>
        <div className="sidebar-body">
          <div style={{ height: '70%', borderBottom: '2px solid #e6e6e6', overflowY: 'scroll' }}>
            {!waitingAttendances.length ?
              <div className="chat">
                <div className="chat-body no-message">
                  <div className="no-message-container">
                    <i className="fa fa-exclamation-circle"></i>
                    <p style={{ textAlign: 'center' }}>No momento não há atendimentos aguardando.</p>
                  </div>
                </div>
              </div>
              :
              <ul className="list-group list-group-flush">
                {
                  waitingAttendances.map((chat, i) => <WaitingAttendancesChatListView chat={chat} key={i} />)
                }
              </ul>
            }
          </div>
          <div style={{ height: '30%', borderBottom: '2px solid #e6e6e6', overflowY: 'scroll' }}>
            <header style={{ padding: '20px', fontSize: 18, fontWeight: 700, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <span>Auto atendimento</span>
              {autoAttendances.length ? <div className="new-message-count">{autoAttendances.length > 90 ? '99+' : autoAttendances.length}</div> : ''}
            </header>
            {!autoAttendances.length ?
              <div className="chat">
                <div className="chat-body no-message">
                  <div className="no-message-container">
                    <p style={{ textAlign: 'center' }}>No momento não há auto atendimentos.</p>
                  </div>
                </div>
              </div>
              : null}
            <div
              style={{ padding: '0 20px', display: 'grid', gridTemplateColumns: 'repeat(6, minmax(0, 1fr))', justifyItems: 'center', gap: '10px', marginBottom: 10 }}>
              {autoAttendances
                ?.map((customer) => (
                  <div
                    style={{ cursor: 'pointer' }}
                    key={customer._id}
                    onClick={() => {
                      setShowMessageHistoryModal(true)
                      setActiveChatCustomer(customer)
                    }}
                  >
                    <img
                      className="rounded-circle" style={{ cursor: 'pointer' }}
                      src={customer.image || DefaultImage}
                      onError={(e) => e.target.src = DefaultImage}
                      alt={'Imagem usuário ' + customer.name}
                      width={30}
                      height={30}
                    />
                  </div>
                ))
                .reverse()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Queues
